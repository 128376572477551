/* global $, MatgenGlobal */

import { Table } from '../components/Table.js';

const UserActions = item => {
  let actions = [
    {
      action: 'edit',
      title: 'Edit User',
      icon: 'far fa-user-tag',
      color: 'primary',
    },
    {
      action: 'delete',
      title: 'Delete User',
      icon: 'fas fa-trash',
      color: 'danger',
      role: ['admin', 'super'],
    },
  ];
  if (MatgenGlobal.CustomUserActions) {
    const custom = MatgenGlobal.CustomUserActions(item);
    actions = custom.concat(actions);
  }
  return actions;
};
const UserColumns = [
  {
    data: 'email',
    title: 'Email',
  },
  //{ data: 'tag_count', title: 'Tags' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item => Table.actionsHTML(item, 'user', UserActions(item)),
  },
];

const UserTable = new Table({
  columns: UserColumns,
  table: 'users',
  title: 'Users',
  callbacks: {
    createdRow: (row, data) => {
      if (data.published !== 1) {
        $(row).addClass('unpublished');
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .removeClass('btn-primary')
          .addClass('btn-warning');
      } else {
        $(row)
          .find('[data-action="template-toggle-publish"]')
          .addClass('btn-primary')
          .removeClass('btn-warning');
      }
      if (data.tag_count === 0) {
        $(row).addClass('untagged');
      }
      if (data.type === 'FILE') {
        $(row).addClass('file-type');
      }
    },
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
});

export { UserTable };
