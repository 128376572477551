import {
  Form,
  TextInput,
  SelectInput,
  //CheckboxInput,
} from '../components/form/index.js';
//import { emit } from '../../util/helpers.js';

const QuestionInputs = [
  /*new TextInput({
    type: 'text',
    label: 'Section ID',
    id: 'inputSectionID',
    classes: 'top',
  }),*/
  new SelectInput({
    label: 'Select Component',
    id: 'inputComponent',
    classes: 'middle',
    options: [
      {
        label: 'Single Select',
        value: 'select-single',
      },
      {
        label: 'Multi Select',
        value: 'select-multiple',
      },
      {
        label: 'Text',
        value: 'text',
      },
      {
        label: 'Other',
        value: 'other',
      },
    ],
  }),
  new TextInput({
    type: 'text',
    label: 'Question',
    id: 'inputText',
    classes: 'middle',
  }),
  new TextInput({
    type: 'subtext',
    label: 'Subtext',
    id: 'inputSubtext',
    classes: 'middle',
  }),
  /*new TextInput({
    type: 'number',
    label: 'Sort order',
    id: 'inputSortOrder',
    classes: 'middle',
  }),*/
  /*new TextInput({
    type: 'text',
    label: 'List Add Name',
    id: 'inputListAddName',
    classes: 'middle',
  }),*/
  new TextInput({
    type: 'text',
    label: 'Tooltip',
    id: 'inputTooltip',
    classes: 'bottom',
  }),
  /*new CheckboxInput({
    label: 'Insert Sub Text?',
    id: 'inputInsertSubText',
  }),*/
];

const QuestionForm = new Form({
  inputs: QuestionInputs,
  id: 'question-form',
  title: 'Create/Edit Question',
});

export { QuestionForm };
