/* global MatgenGlobal, $, bootstrap */

import { basics, create_template, add_items } from './help-content.js';

class SidebarHeader {
  static sidebarHeader(template, pages) {
    const actions = [];
    if (MatgenGlobal.AuthUser.getUserRole() === 'super') {
      actions.push(
        ['show-grid', 'Enable grid/snapping', `fas fa-border-all`],
        ['theme-color', 'Set Theme Color', `far fa-palette`]
      );
    }
    if (
      MatgenGlobal.AuthUser.isM4CSuper() ||
      MatgenGlobal.AuthUser.getUserRole() === 'admin'
    ) {
      actions.push([
        'template-tag',
        'Manage template tags',
        [`fas fa-tags`],
        template.id,
      ]);
    }
    return `
      <li href="#" id="sidebar-header" class="list-group-item active d-flex flex-row sidebar-li">
        <div id="sidebar-title" style="display:none;">Editable Items</div>
        <div class="sidebar-actions">

          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? SidebarHeader.helpDropHTML()
              : ''
          }
          ${actions.map(a => SidebarHeader.headerActionHTML(...a)).join('')}

          ${
            MatgenGlobal.AuthUser.isM4CSuper() ||
            MatgenGlobal.AuthUser.getUserRole() === 'admin'
              ? SidebarHeader.pagesDropHTML(template, pages)
              : ''
          }
          ${
            MatgenGlobal.AuthUser.isM4CSuper() ||
            MatgenGlobal.AuthUser.getUserRole() === 'admin'
              ? SidebarHeader.headerActionHTML(
                  'template-save',
                  'Save template',
                  [`fas fa-save`, `fa fa-exclamation dirty`],
                  template.id
                )
              : ''
          }

          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? `
            <div class="dropdown" data-bs-toggle="tooltip" data-placement="auto" title="Canvas Zoom">
              <button id="template-zoom" type="button" class="btn btn-sm btn-secondary"  data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false" title="Canvas Zoom">
                <i class="fa-solid fa-magnifying-glass"></i>
              </button>
              <div id="template-zoom-dropdown"class="dropdown-menu" aria-labelledby="template-zoom">
                <a class="dropdown-item" id="zoom-default" href="#">Default/auto</a>
                <a class="dropdown-item" id="zoom-fit" href="#">Fit to viewport</a>
              </div>
            </div>
            `
              : ''
          }

          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? SidebarHeader.headerActionHTML(
                  'template-add-item',
                  'Add item',
                  [`fa-solid fa-circle-plus`]
                )
              : ''
          }
        </div>
      </li>
      `;
  }

  static headerActionHTML(id, title, icon, dataId = false) {
    const icons = SidebarHeader.buildIconArray(icon);
    return `
      <button id="${id}" ${
      dataId !== false ? `data-id="${dataId}"` : ''
    } type="button" class="btn btn-sm btn-secondary" data-bs-toggle="tooltip" data-placement="auto" title="${title}">
        ${icons.map(i => `<i class="${i}"></i>`).join('')}
      </button>
      `;
  }

  static buildIconArray(icon) {
    let icons = [];
    if (!Array.isArray(icon)) {
      icons.push(icon);
    } else {
      icons = icon;
    }
    return icons;
  }

  /*static pagesDropDownHTML(template, pages) {
    return template.type === 'PDF' &&
      MatgenGlobal.AuthUser.getUserRole() !== 'user' &&
      !MatgenGlobal.SuppressSidebarActions &&
      (MatgenGlobal.AuthUser.isM4CSuper() || pages.length > 1)
      ? `
      <div id="template-pages" class="dropdown" data-bs-toggle="tooltip" data-placement="auto" title="Template Pages">
        <button class="btn btn-sm btn-secondary badge-btn" id="template-pages" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <i class="fas fa-xs fa-file"></i> <span class="badge badge-light">${
          pages.length
        }</span>
        </button>
        <div id="template-pages-dropdown"class="dropdown-menu" aria-labelledby="template-pages">
          ${
            pages.length > 1
              ? pages
                  .map(
                    (p, i) =>
                      `<a id="page-${i}" data-id="${
                        p.id
                      }" class="dropdown-item template-page-edit" href="#">Page ${i +
                        1}</a>`
                  )
                  .join('')
              : ''
          }
          ${
            MatgenGlobal.AuthUser.isM4CSuper()
              ? `<a id="template-add-page" class="dropdown-item" href="#">Add Page</a>`
              : ''
          }
          <!--<a class="dropdown-item" href="#">Manage Pages</a>-->
        </div>
      </div>
      `
      : '';
  }*/

  static helpDropHTML() {
    return SidebarHeader.headerDropDownHTML(
      'editor-help',
      'Help',
      `fa-solid fa-circle-question`,
      false,
      [
        {
          id: 'help-basics',
          title: 'Matgen basics and terms',
          icon: `fa-solid fa-code-simple`,
          classes: ['matgen-help'],
        },
        /*{
          id: 'help-create-template',
          title: 'How to create a template',
          icon: `fa-solid fa-file-plus`,
          classes: ['matgen-help'],
        },
        {
          id: 'help-add-items',
          title: 'How to add items to a template',
          icon: `fa-solid fa-layer-plus`,
          classes: ['matgen-help'],
        },*/
      ]
    );
  }

  static downloadsDropHTML(id) {
    return SidebarHeader.headerDropDownHTML(
      'template-download',
      'Template Download',
      `fa-solid fa-folder-arrow-down`,
      false,
      [
        {
          id: 'template-download',
          title: 'Download template',
          icon: `fa-solid fa-folder-arrow-down`,
          classes: ['template-download'],
          dataId: id,
        },
        /*{
          id: 'template-pdf-render-508',
          title: 'Download page 508',
          icon: `fa-regular fa-file-pdf`,
          classes: ['template-download'],
        },
        {
          id: 'template-pdf-render',
          title: 'Download page non 508',
          icon: `fa-solid fa-file-pdf`,
          classes: ['template-download'],
        },*/
      ]
    );
  }

  static headerDropDownHTML(id, title, icon, badge, items, classes = []) {
    const iconHTML = SidebarHeader.badgedIcon(icon, badge);
    return `
    <div id="${id}-div" class="dropdown"">
      <button class="btn btn-sm btn-secondary ${classes.join(
        ' '
      )}" id="${id}-btn" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
      <span data-bs-toggle="tooltip" data-placement="auto" title="${title}">
        ${iconHTML}
      </span>
      </button>
      <div id="${id}-dropdown"class="dropdown-menu" aria-labelledby="${id}-btn">
        ${items
          .map(i => {
            if (!i.role || i.role === MatgenGlobal.AuthUser.getUserRole()) {
              const icons = SidebarHeader.buildIconArray(i.icon);
              return `
                <a id="${i.id}" ${
                i.dataId ? `data-id="${i.dataId}"` : ''
              } class="dropdown-item ${i.classes.join(' ')} ${i.id}" href="#">
                  ${icons.map(i => `<i class="${i}"></i>`).join('')}
                  ${i.title} ${
                MatgenGlobal.AuthUser.isM4CSuper() && i.delete
                  ? `<span class="drop-delete text-danger"><i class="${i.delete}"></i></span>`
                  : ''
              }
                </a>
              `;
            } else {
              return '';
            }
          })
          .join('')}
      </div>
    </div>
    `;
  }

  static pagesDropHTML(template, pages) {
    let items = [];
    //const p = pages.filter(p => p.id !== MatgenGlobal.editor.curPageId);
    if (pages.length > 0) {
      items = pages.map((p, i) => {
        return {
          id: `page-${i}`,
          dataId: p.id,
          title: `Page ${p.number + 1}`,
          icon: `fa-solid fa-page`,
          classes: ['template-page-edit', `page-${i}`],
          delete: `fa-solid fa-file-xmark`,
        };
      });
    }
    return SidebarHeader.headerDropDownHTML(
      'template-pages',
      'Template Pages',
      `fa-solid fa-file-circle-info`,
      {
        count: pages.length,
        classes: ['btn-badge', 'header'],
      },
      [
        ...items,
        {
          id: 'template-add-page',
          title: 'Add page',
          icon: `fa-solid fa-plus`,
          classes: ['template-add-page'],
          role: 'super',
        },
      ]
    );
  }

  static badgedIcon(icon, badge) {
    const icons = SidebarHeader.buildIconArray(icon);
    let html = icons.map(i => `<i class="${i}"></i>`).join('');
    if (badge) {
      html += `<span class="badge ${
        badge.classes ? badge.classes.join(' ') : ''
      }">${badge.count}</span>`;
    }
    return html;
  }

  static shapesModal() {
    const cards = [
      {
        id: 'polygon',
        title: 'Polygon',
        icon: '<i class="fa-solid fa-draw-polygon fa-2x"></i>',
        text:
          'Select this option to draw a polygon element. <i>(Use ESC key to cancel drawing.)</i>',
      },
      {
        id: 'circle',
        title: 'Circle',
        icon: '<i class="fa-solid fa-circle fa-2x"></i>',
        text: 'Select this option to add a circle element.',
      },
      {
        id: 'ellipse',
        title: 'Ellipse',
        icon: '<i class="fa-solid fa-vector-circle fa-2x"></i>',
        text: 'Select this option to add an ellipse element.',
      },
      {
        id: 'rectangle',
        title: 'Rectangle',
        icon: '<i class="fa-solid fa-rectangle fa-2x"></i>',
        text: 'Select this option to add a rectangle or square element.',
      },
      {
        id: 'triangle',
        title: 'Triangle',
        icon: '<i class="fa-solid fa-triangle fa-2x"></i>',
        text: 'Select this option to add a triangle element.',
      },
    ];
    bootstrap.Modal.getOrCreateInstance($(`#add-item-modal`)[0]).hide();
    SidebarHeader.cardsModal(
      'add-shape',
      'Add shape',
      'Select shape type',
      cards
    );
    $('#add-shape-modal .modal-body').prepend(`
      <div class="d-flex">
        <input type="hidden" id="inputShapeColor" />
        <div class="color-picker-form form-control middle">Shape Color: <span id="shape-color-picker" class="color-picker"></span></div>
        <div class="form-control middle">Shape Stroke Width:
          <input type="text" id="inputShapeStrokeWidth" style="height:max-content" value="0" />
        </div>
        <input type="hidden" id="inputShapeStrokeColor" />
        <div class="color-picker-form form-control middle">Shape Stroke Color: <span id="shape-stroke-color-picker" class="color-picker"></span></div>
      </div>
      <div class="form-control middle">Use Theme Color:
        <input type="checkbox" id="inputUseThemeColorShape" style="height:max-content" />
      </div>
      <br />
    `);

    MatgenGlobal.UI.initColorPicker(
      {
        el: '#shape-color-picker',
        container: '#add-shape-modal',
        default: '#74BC1E',
      },
      'shape',
      '#inputShapeColor'
    );

    MatgenGlobal.UI.initColorPicker(
      {
        el: '#shape-stroke-color-picker',
        container: '#add-shape-modal',
        default: '#00000000',
      },
      'shapeStroke',
      '#inputShapeStrokeColor'
    );
  }

  static richTextModal(obj = false) {
    const inputs = [
      {
        component: 'TextArea',
        options: {
          type: 'text',
          label: 'Text',
          id: 'inputRichText',
          required: true,
          autofocus: true,
          value: obj ? obj.text : '',
        },
      },
    ];
    if (obj) {
      inputs.push({
        component: 'RawHTML',
        html: `<input type ="hidden" id="curObjId" value="${obj.id}" />`,
      });
    }
    MatgenGlobal.MatgenUIFunctions.modalFormUI({
      title: 'Edit Rich Text',
      inputs,
      buttons: [
        {
          id: 'study-data-rich-text-form-submit',
          classname: 'btn btn-primary primary',
          label: 'Update',
        },
      ],
      idPrefix: 'rich-text',
      width: '650px',
    });
  }

  static cardsModal(id, title, subtitle, cards) {
    SidebarHeader.simpleModal(
      id,
      title,
      `
      <h5 style="text-align:center;margin-bottom:.75em;">${subtitle}</h5>
      <div class="card-group container">
      ${cards.map(c => SidebarHeader.cardHTML(c)).join('')}
      </div>
      `
    );
  }

  static cardHTML(card) {
    return `
    <div class="card ${card.disabled === true ? 'disabled' : ''}" id="${
      card.id
    }">
      <div class="card-body">
        <h5 class="card-title">
          ${card.icon}
          <div class="title-text">${card.title}</div>
        </h5>
        <div>
          <p class="card-text">${card.text}</p>
        </div>
      </div>
    </div>
    `;
  }

  static simpleModal(id, title, content, buttonText = 'Close') {
    /*if ($(`#${id}-modal`).length > 0) {
      bootstrap.Modal.getOrCreateInstance($(`#${id}-modal`)[0]).hide();
    }*/

    MatgenGlobal.M4CModal.show({
      id: `${id}-modal`,
      title: title,
      content,
      buttons: [
        {
          id: `${id}-close-button`,
          classname: 'primary btn btn-primary',
          label: buttonText,
        },
      ],
      width: 'fit-content',
    });

    $(`#${id}-close-button`).attr('data-bs-dismiss', 'modal');

    /*const m = new Modal(
      `${id}-modal`,
      title,
      content,
      false,
      [
        {
          id: `${id}-ok-button`,
          classname: 'primary btn btn-primary',
          label: buttonText,
        },
      ],
      `matgen-${id}-modal`
    );
    $('body').append(m.markup);
    $(`#${id}-modal`).modal('toggle');*/

    /*$(`#${id}-close-button`).off('click');
    $(`#${id}-close-button`).on('click', () => {
      bootstrap.Modal.getOrCreateInstance($(`#${id}-modal`)[0]).hide();
    });*/
  }

  static modal508Content(id) {
    const objects = MatgenGlobal.editor
      .cur()
      .fabric.getObjects()
      .filter(o => o.visible)
      .map(o => MatgenGlobal.MatgenUIFunctions.getReadOrder(o));

    objects.sort(MatgenGlobal.MatgenUIFunctions.readOrderSort);
    console.log(
      id,
      objects.map(o => {
        return {
          tag: o.pdfTag ? o.pdfTag : o.componentPdfTag,
          type: o.type,
          text: o.text ? o.text : '<image>',
          ro1: o.ro1,
          ro2: o.ro2,
        };
      })
    );
    return basics;
  }

  static modal508(id) {
    SidebarHeader.simpleModal(
      '508',
      '508 Compliance',
      SidebarHeader.modal508Content(id),
      'Done'
    );
  }

  static helpModal(title, content) {
    SidebarHeader.simpleModal('help', title, content);
  }

  static helpPage(id) {
    let title, content;
    switch (id) {
      default:
      case 'help-basics':
        title = 'Matgen basics and terms';
        content = basics;
        break;
      case 'help-create-template':
        title = 'Creating templates';
        content = create_template;
        break;
      case 'help-add-items':
        title = 'Creating template images and text';
        content = add_items;
        break;
    }
    return SidebarHeader.helpModal(title, content);
  }
}

export { SidebarHeader };
