/* global MatgenGlobal */

import { Table } from '../components/Table.js';

const SectionsActions = () => {
  const actions = [
    {
      action: 'edit',
      title: 'Edit Section',
      icon: 'fas fa-edit',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'view-questions',
      title: 'View Questions',
      icon: 'fa-solid fa-clipboard-question',
      color: 'primary',
      role: 'super',
    },
    {
      action: 'delete',
      title: 'Delete Section',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];

  return actions;
};

const SectionsColumns = [
  { data: 'id', title: 'ID' },
  { data: 'name', title: 'Name' },
  { data: 'questionnaire_id', title: 'Questionnaire ID' },
  { data: 'type', title: 'Type' },
  { data: 'title', title: 'Title' },
  { data: 'sub_text', title: 'subtext' },
  { data: 'sort_order', title: 'Sort Order' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item => Table.actionsHTML(item, 'sections', SectionsActions(item)),
  },
];

const SectionsTable = new Table({
  columns: SectionsColumns,
  table: 'section',
  title: 'Sections',
  callbacks: {
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
  arg: window.location.search,
});

export { SectionsTable };
