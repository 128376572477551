import { Form, TextInput } from '../components/form/index.js';

const SectionInputs = [
  new TextInput({
    type: 'text',
    label: 'Title',
    id: 'inputTitle',
    classes: 'top',
  }),
  new TextInput({
    type: 'text',
    label: 'Subtext',
    id: 'inputSubText',
    classes: 'middle',
  }),
  new TextInput({
    type: 'number',
    label: 'Sort order',
    id: 'inputSortOrder',
    classes: 'bottom',
  }),
];

const SectionForm = new Form({
  inputs: SectionInputs,
  id: 'section-form',
});

export { SectionForm };
