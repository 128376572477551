/* global $, MatgenGlobal */

import { Table } from '../components/Table.js';

const MaterialActions = () => [
  {
    action: 'download',
    title: 'Download Material',
    icon: 'fas fa-file-download',
    color: 'primary',
  },
  {
    action: 'download-instructions',
    title: 'Download Material Instructions',
    icon: 'fas fa-person-chalkboard',
    color: 'primary',
  },
  {
    action: 'view',
    title: 'Preview Material',
    icon: 'far fa-image',
    color: 'primary',
  },
  {
    action: 'edit-data',
    title: 'Edit Name',
    icon: 'fas fa-edit',
    color: 'primary',
  },
  {
    action: 'edit',
    title: 'Edit Material',
    icon: 'fas fa-paint-brush',
    color: 'primary',
    classes: 'hide-file-type',
  },
  {
    action: 'delete',
    title: 'Delete Material',
    icon: 'fas fa-trash',
    color: 'danger',
  },
];
const MaterialColumns = [
  { data: 'id', title: 'ID', visible: false },
  { data: 'name', title: 'Name' },
  {
    data: 'type',
    title: 'Type',
    render: item => item.toUpperCase(),
  },
  { data: 'template_name', title: 'Template Name' },
  {
    data: 'created',
    title: 'Created',
    type: 'date',
    render: item => new Date(item).toLocaleString('en-US'),
  },
  //{ data: 'updated', title: 'Updated', render: item => new Date(item) },
  { data: 'user_name', title: 'User Name', visible: false },
  //{ data: 'tag_count', title: 'Tags' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item => Table.actionsHTML(item, 'material', MaterialActions(item)),
  },
];

const MaterialTable = new Table({
  columns: MaterialColumns,
  table: 'materials',
  title: 'My Materials',
  tableSelector: '#materials-table',
  callbacks: {
    createdRow: (row, data) => {
      if (data.type === 'FILE') {
        $(row).addClass('file-type');
      }
      if (data.associated_file === null) {
        $(row)
          .find('button[data-action=material-download-instructions]')
          .hide();
      }
    },
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
    initComplete: s => {
      s.oInstance
        .api()
        .order([4, 'desc'])
        .draw();
    },
  },
});

export { MaterialTable };
