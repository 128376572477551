/* global $, MatgenGlobal */

//import { init as keysInit } from './key-events.js';
//import { init as userKeysInit } from './user-key-events.js';
import { emit } from '../../util/helpers.js';
import { debounce } from 'throttle-debounce';
import { v4 as UUID } from 'uuid';
import { Uploader } from '../components/Uploader.js';

import MatgenUIFunctions from '../ui/ui-functions.js';

const debouncedResize = debounce(100, false, () => {
  resizeHandler();
});

function resizeHandler() {
  MatgenGlobal.UI.resizeHandler();
}

/*function genericClickHandler(e) {
  if (!MatgenGlobal.SuppressTooltips) {
    $('[data-bs-toggle="tooltip"]').tooltip('hide');
    MatgenGlobal.hideTooltips();
    //$('[data-bs-toggle="dropdown"]').dropdown('hide');
  } else {
    MatgenGlobal.hideTooltips();
    //$('.m4c-matgen .dropdown-menu').hide();
  }

  if (
    e.target.tagName !== 'CANVAS' &&
    MatgenGlobal.editor &&
    MatgenGlobal.editor.cur() &&
    $(e.target).parents('.tree-li').length === 0
  ) {
    MatgenGlobal.editor.cur().hoverOutline(e, true);
    MatgenGlobal.editor.cur().fabric.discardActiveObject();
    MatgenGlobal.editor.cur().fabric.requestRenderAll();
  }
}*/

/*const decode = str => {
  return $('<textarea/>')
    .html(str)
    .text();
};*/

/*function commonNavHandler() {
  //console.log('MATGEN NAVIGATION:');
  //MatgenGlobal.navigating = e;
  //console.log('REMOVE SIDEBAR');
  console.error(new Error());
  $('#sidebar').remove();
}*/

const init = () => {
  $(window).resize(() => {
    debouncedResize();
  });

  MatgenGlobal.lastBG = '#fff';

  $(document).off('click keypress', '#options-bg span');
  $(document).on('click keypress', '#options-bg span', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#options-bg span').removeClass('active');
      $(e.target).addClass('active');
      MatgenGlobal.lastBG = $(e.target).css('background');
      $('.option-wrapper.m4c-matgen').css('background', MatgenGlobal.lastBG);
    }
  });

  $(document).off('mouseenter', '#options-bg span');
  $(document).on('mouseenter', '#options-bg span', e => {
    e.preventDefault();
    MatgenGlobal.lastBG = $('.option-wrapper.m4c-matgen').css('background');
    $('.option-wrapper.m4c-matgen').css(
      'background',
      $(e.target).css('background')
    );
  });

  $(document).off('mouseout', '#options-bg span');
  $(document).on('mouseout', '#options-bg span', e => {
    e.preventDefault();
    $('.option-wrapper.m4c-matgen').css('background', MatgenGlobal.lastBG);
  });

  $(document).off('click', '#study-data-text-form-submit');
  $(document).on('click', '#study-data-text-form-submit', e => {
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      const curbObjId = $('#curObjId').val();
      const curObj = MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .find(o => o.id === curbObjId);
      if (curObj) {
        curObj.text = $('#inputText').val();
        curObj.fontSize = $('#inputFontSize').val();
        MatgenGlobal.editor.cur().fabric.renderAll();
      } else {
        MatgenGlobal.UI.handleError(
          'Object not found',
          'There is a problem finding the object on the canvas.'
        );
      }
      $('#study-data-text-modal').modal('hide');
    }
  });

  $(document).off('matgen-sidebar-loading-stop');
  $(document).on('matgen-sidebar-loading-stop', () => {
    if (!MatgenGlobal.supressSideBarStopLoading) {
      MatgenGlobal.UI.stopLoading();
    } else {
      delete MatgenGlobal.supressSideBarStopLoading;
    }
  });

  $(document).off('click', '.user-toggle-object');
  $(document).on('click', '.user-toggle-object', async e => {
    e.preventDefault();
    e.stopPropagation();
    const id = $(e.currentTarget).attr('data-id');
    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const obj = objects.filter(o => o.id === id);
    const treeli = $(e.currentTarget)
      .parent()
      .parent()
      .parent();
    if ($(e.currentTarget).attr('data-hidden') !== 'true') {
      for (let i = 0; i < obj.length; i++) {
        obj[i].visible = false;
      }
      $(e.currentTarget).html('Show <i class="fa-regular fa-eye fa-sm"></i>');
      $(e.currentTarget).attr('data-hidden', 'true');
      treeli.addClass('matgen-disabled');
    } else {
      for (let i = 0; i < obj.length; i++) {
        obj[i].visible = true;
      }
      $(e.currentTarget).html(
        'Hide <i class="fa-regular fa-eye-slash fa-sm"></i>'
      );
      $(e.currentTarget).attr('data-hidden', 'false');
      treeli.removeClass('matgen-disabled');
    }

    MatgenGlobal.editor.cur().fabric.renderAll();
  });

  $(document).off('click', '#remove-associated-file');
  $(document).on('click', '#remove-associated-file', () => {
    $('#file-association-file').remove();
    $('#associated-file-uploader').remove();
    $('#associated-file-link-text').remove();
  });

  $(document).off('click', '#template-file-associate');
  $(document).on('click', '#template-file-associate', e => {
    e.preventDefault();
    e.stopPropagation();
    MatgenGlobal.UI.promptModal(
      'Text For User Download Link:',
      'Link Text:',
      text => {
        const uploader = new Uploader();
        uploader.fileSelect(
          'associated-file-uploader',
          async () => {
            const file = document.getElementById('associated-file-uploader')
              .files[0];
            if (file) {
              let associated_file = file.name;
              const ext = associated_file.split('.').pop();
              associated_file = associated_file.replace(`.${ext}`, '');
              associated_file = `${associated_file}-${$(
                '#inputTemplateID'
              ).val()}.${ext}`;

              $('#file-association').append(`
              <div id="file-association-file">
                <b>Link text: </b>${text}<br>
                <b>File: </b>${associated_file}
                <span id="remove-associated-file" style="color:#f00;cursor:pointer;">
                  <i class="fa-sharp fa-solid fa-delete-left"></i>
                </span><br>
              </div>
            `);
              $('#template-form').append(
                `<input id="associated-file-link-text" name="associated-file-link-text" type="hidden" value="${text}" />`
              );
              $('#template-form').append(
                $('#associated-file-uploader').clone()
              );
            }
          },
          'body',
          '.pdf'
        );
      },
      'Continue',
      () => {},
      [],
      'View instructions',
      '60vw'
    );
  });

  $(document).off('matgen-table-action');
  $(document).on('matgen-table-action', async e => {
    console.log('matgen-table-action:', e.detail);
    MatgenGlobal.hideTooltips();
    let template, data;
    switch (e.detail.action) {
      default:
        break;
      case 'material-download':
        MatgenGlobal.MatgenUIFunctions.handleMaterialDownload(e.detail.id);
        break;
      case 'material-download-instructions':
        MatgenGlobal.MatgenUIFunctions.handleMaterialInstructionsDownload(
          e.detail.id
        );
        break;
      case 'material-view':
        MatgenGlobal.MatgenUIFunctions.viewMaterial(e.detail.id);
        break;
      case 'material-edit':
        MatgenGlobal.Router.goTo(`/materials/${e.detail.id}`);
        break;
      case 'material-edit-data':
        MatgenGlobal.MatgenUIFunctions.editMaterialData(
          e.detail.id,
          e.detail.row
        );
        break;
      case 'material-delete':
        MatgenGlobal.UI.confirm(
          'Delete Material?',
          '<p style="max-width:320px;">Are you sure you want to delete this material?</p>',
          'Continue',
          'Cancel',
          () => {
            MatgenGlobal.MatgenUIFunctions.deleteMaterial(
              e.detail.id,
              e.detail.row
            );
          }
        );
        break;
      case 'template-download':
        template = await MatgenGlobal.Data.getTemplate(e.detail.id);
        MatgenGlobal.CurrentTemplate = template;
        MatgenGlobal.hideSidebar = true;
        if (template.type === 'PDF' && !MatgenGlobal.Suppress508) {
          await MatgenGlobal.MatgenUIFunctions.downloadTemplate508(
            e.detail.id,
            true,
            template
          );
        } else {
          await MatgenGlobal.MatgenUIFunctions.downloadTemplate(e.detail.id);
        }
        delete MatgenGlobal.hideSidebar;
        break;
      case 'template-view':
        MatgenGlobal.MatgenUIFunctions.viewTemplate(e.detail.id);
        break;
      case 'new-template':
        // MatgenUIFunctions.templateForm();
        MatgenGlobal.MatgenUIFunctions.editTemplateData();
        break;
      case 'template-edit':
        MatgenGlobal.MatgenUIFunctions.editTemplate(e.detail.id);
        break;
      case 'template-delete':
        MatgenGlobal.MatgenUIFunctions.deleteTemplate(e.detail.id);
        break;
      case 'template-edit-data':
        MatgenUIFunctions.editTemplateData(e.detail.id);
        break;
      case 'template-edit-name':
        MatgenGlobal.MatgenUIFunctions.editTemplateName(
          e.detail.id,
          e.detail.row
        );
        break;
      case 'template-tags':
        MatgenGlobal.Router.goTo(`/tags/${e.detail.id}`);
        //MatgenGlobal.UI.loadTagger(id);
        break;
      case 'template-toggle-publish':
        MatgenGlobal.MatgenUIFunctions.togglePublished(e.detail.id);
        break;
      case 'template-export':
        //MatgenGlobal.UI.promptModal('Export Template', 'New Name:', name =>
        MatgenUIFunctions.exportTemplate(e.detail.id);
        //);
        break;
      case 'template-duplicate':
        MatgenGlobal.UI.promptModal('Copy Template', 'New Name:', name =>
          MatgenUIFunctions.duplicateTemplate(e.detail.id, name)
        );
        break;
      case 'template-mark-tested':
        data = $(MatgenGlobal.Tables.TemplateTable.tableSelector)
          .DataTable()
          .row($(e.detail.row))
          .data();
        console.log(data.tested, data.id);
        if (parseInt(data.tested) === 1) {
          console.log('Mark untested');
        } else {
          console.log('Mark tested');
        }
        break;
      case 'microsite-toggle-publish':
        MatgenGlobal.MatgenUIFunctions.togglePublished(
          e.detail.id,
          'microsite',
          e.detail.row
        );
        break;
      case 'microsite-delete':
        data = await MatgenGlobal.MatgenUIFunctions.deleteMicrosite(
          e.detail.id,
          e.detail.row
        );
        break;
      case 'microsite-edit':
        window.location.href = `microsite-generator.html?study_id=${e.detail.alt_id}`;
        break;
      case 'user-edit':
        data = $(e.detail.row)
          .parent()
          .parent()
          .DataTable()
          .row($(e.detail.row))
          .data();
        MatgenGlobal.MatgenUIFunctions.userModal(data);
        break;
      case 'user-delete':
        MatgenGlobal.UI.loading('Deleting user...');
        try {
          const token = await MatgenGlobal.AuthUser.getSessionToken();
          const opts = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const response = await MatgenGlobal.Amplify.API.del(
            'authenticated',
            `/users/${e.detail.id}`,
            opts
          );
          console.log(response);
          MatgenGlobal.UI.alertModal(
            'User Deleted',
            `The user has been deleted.`
          );
          $(e.detail.row)
            .parent()
            .parent()
            .DataTable()
            .row($(e.detail.row))
            .remove()
            .draw();
          MatgenGlobal.UI.stopLoading();
        } catch (e) {
          console.error(e);
          MatgenGlobal.UI.stopLoading();
          MatgenGlobal.UI.alertModal(
            'Error Deleting User',
            `There was an error deleting the user.`
          );
        }
        break;
      case 'new-tenant':
        MatgenUIFunctions.saveForm('tenant', 'Tenant');
        break;
      case 'tenant-edit':
        MatgenUIFunctions.saveForm('tenant', 'Tenant', e.detail.id);
        break;
      /*case 'tenant-create-questionnaire':
          MatgenGlobal.Router.goTo(`tenant-questionnaire/${e.detail.id}`);
          break;*/
      case 'new-questionnaire':
        //MatgenUIFunctions.saveForm('questionnaire', 'Questionnaire');
        //MatgenGlobal.Router.goTo(`/tenant-questionnaire`);
        MatgenUIFunctions.createQuestionnaire();
        break;
      case 'new-section':
        MatgenUIFunctions.saveForm('section', 'Section');
        break;
      case 'new-question':
        MatgenUIFunctions.saveForm('question', 'Question');
        break;
      case 'new-answer':
        MatgenUIFunctions.saveForm('answer', 'Answer');
        break;
      case 'questionnaire-edit':
        MatgenGlobal.Router.goTo(`edit-questionnaire/${e.detail.id}`);
        //MatgenUIFunctions.saveForm('questionnaire', 'Questionnaire', e.detail.id);
        break;
      case 'questionnaire-delete':
        MatgenGlobal.UI.confirm(
          'Confirm Delete',
          'Are you sure you wish to delete this questionnaire?',
          'Continue',
          'Cancel',
          () => MatgenUIFunctions.deleteQuestionnaire(e.detail.id)
        );
        break;
      case 'sections-edit':
        MatgenUIFunctions.saveForm('section', 'Section', e.detail.id);
        break;
      case 'Questions-edit':
        MatgenUIFunctions.saveForm('question', 'Question', e.detail.id);
        break;
      case 'Answers-edit':
        MatgenUIFunctions.saveForm('answer', 'Answer', e.detail.id);
        break;

      case 'tenant-view-questionnaires':
        MatgenUIFunctions.viewQuestionnaires(e.detail.id);
        break;
      case 'questionnaire-view-sections':
        MatgenUIFunctions.viewSections(e.detail.id);
        break;
      case 'sections-view-questions':
        MatgenUIFunctions.viewQuestions(e.detail.id);
        break;
      case 'Questions-view-answers':
        MatgenUIFunctions.viewAnswers(e.detail.id);
        break;

      case 'new-study':
        window.location.href = `/microsite-generator.html`;
        break;
      case 'study-edit':
        window.location.href = `/microsite-generator.html?study_id=${e.detail.id}`;
        break;
      case 'study-materials':
        window.location.href = `/generator.html?study_id=${e.detail.id}#/step3`;
        break;
      case 'study-delete':
        $(`#studymodal`).modal('hide');
        MatgenGlobal.UI.loading('Deleting study...', '#studymodal .modal-body');
        try {
          const token = await MatgenGlobal.AuthUser.getSessionToken();
          const opts = {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          };
          const data_response = await MatgenGlobal.Amplify.API.del(
            'authenticated',
            `/studies/${e.detail.id}`,
            opts
          );
          console.log(data_response);
          const json_response = await MatgenGlobal.Amplify.Storage.remove(
            `${e.detail.id}.json`,
            {
              level: 'protected',
              contentType: 'application/json',
              progressCallback(e) {
                MatgenGlobal.UI.loaderProgress(e);
              },
            }
          );
          console.log(json_response);

          MatgenGlobal.UI.alertModal(
            'Study Deleted',
            '<p>The study has been deleted.<p>'
          );

          $(e.detail.row)
            .parent()
            .parent()
            .DataTable()
            .row($(e.detail.row))
            .remove()
            .draw();
        } catch (e) {
          console.error(e);
          MatgenGlobal.UI.alertModal(
            'Error Deleting Study',
            '<p>There was an error deleting the study.<p>'
          );
        }
        MatgenGlobal.UI.stopLoading();
        break;
    }
  });

  $(document).off('click', '#matgen-resend-confirmation-link-submit');
  $(document).on('click', '#matgen-resend-confirmation-link-submit', e => {
    e.preventDefault();

    if (!$('#matgen-resend-confirmation-link-form')[0].checkValidity()) {
      if ($('#matgen-resend-confirmation-link-form')[0].reportValidity) {
        $('#matgen-resend-confirmation-link-form')[0].reportValidity();
      } else {
        alert('Verification email resend form input invalid');
      }
    } else {
      $('#matgen-resend-confirmation-link-modal').modal('hide');
      MatgenGlobal.AuthUserModule.resendConfirmationCode(
        $('#matgen-resend-confirmation-link-email').val()
      );
    }
  });

  $(document).off('click', '#matgen-resend-confirmation-link');
  $(document).on('click', '#matgen-resend-confirmation-link', e => {
    e.preventDefault();
    $('#matgen-signin-modal').modal('hide');
    emit({ event: 'matgen-close-login-modal' });
    MatgenGlobal.UI.resendVerificationModal();
  });

  $(document).off('click', '#m4c-login, #m4c-save-prompt-login');
  $(document).on('click', '#m4c-login, #m4c-save-prompt-login', e => {
    e.preventDefault();
    $('#save-prompt-modal').modal('hide');
    MatgenUIFunctions.showLoginModal();
  });

  $(document).off('click keypress', '#m4c-logout');
  $(document).on('click keypress', '#m4c-logout', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      MatgenGlobal.UI.loading('Logging out...');
      MatgenGlobal.AuthUser.signOut(false, () => {
        window.location.reload();
      });
    }
  });

  $(document).off('click keypress', '#m4c-mfa-disable');
  $(document).on('click keypress', '#m4c-mfa-disable', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      MatgenGlobal.UI.loading('Disabling MFA...');
      try {
        await MatgenGlobal.Amplify.Auth.setPreferredMFA(
          MatgenGlobal.AuthUser.user,
          'NOMFA'
        );
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.stopLoading();
        MatgenGlobal.UI.handleError(
          'API error',
          'There was an error changing your MFA settings, please try again or contact support.'
        );
      }
      MatgenGlobal.UI.stopLoading();
    }
  });

  $(document).off('click keypress', '#m4c-mfa');
  $(document).on('click keypress', '#m4c-mfa', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      MatgenGlobal.UI.mfaSetupModal();
    }
  });

  $(document).off('click keypress', '#copy-mfa-code');
  $(document).on('click keypress', '#copy-mfa-code', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      navigator.clipboard.writeText($('#mfa-text-code').text());
      MatgenGlobal.UI.alertModal(
        'Code copied',
        `<p>MFA code copied to clipboard.</p>`
      );
    }
  });

  $(document).off('click keypress', '#matgen-mfa-totp-submit');
  $(document).on('click keypress', '#matgen-mfa-totp-submit', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#mfa-error-message').empty();
      const code = $('#matgen-mfa-totp').val();
      if (code && code !== '') {
        MatgenGlobal.UI.loading('Verifying TOTP code...');
        let user;
        try {
          user = await MatgenGlobal.Amplify.Auth.currentAuthenticatedUser();
        } catch (e) {
          console.error(e);
          MatgenGlobal.UI.stopLoading();
          $('#mfa-error-message')
            .empty()
            .append(
              '<p>There was an error retrieving the user info. Please try again or contact the help desk.</p>'
            );
        }

        try {
          await MatgenGlobal.Amplify.Auth.verifyTotpToken(user, code);
        } catch (e) {
          console.error(e);
          MatgenGlobal.UI.stopLoading();
          $('#mfa-error-message')
            .empty()
            .append(
              '<p>There was an error verifying the token or the token is invalid. Please try again or contact the help desk.</p>'
            );
        }

        try {
          await MatgenGlobal.Amplify.Auth.setPreferredMFA(user, 'TOTP');
          //await MatgenGlobal.Amplify.Auth.confirmSignIn(user, code, 'TOTP');
        } catch (e) {
          console.error(e);
          MatgenGlobal.UI.stopLoading();
          $('#mfa-error-message')
            .empty()
            .append(
              '<p>There was an error finalizing your MFA settings. Please try again or contact the help desk.</p>'
            );
        }
        MatgenGlobal.UI.stopLoading();
      } else {
        $('#mfa-error-message')
          .empty()
          .append(
            '<p>You must enter the TOTP code from your chosen MFA application.</p>'
          );
      }
    }
  });

  $(document).off('click keypress', '#matgen-login-submit');
  $(document).on('click keypress', '#matgen-login-submit', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      if (!$('#m4c-login-form')[0].checkValidity()) {
        if ($('#m4c-login-form')[0].reportValidity) {
          $('#m4c-login-form')[0].reportValidity();
        } else {
          alert('Login form input invalid');
        }
      } else {
        MatgenGlobal.UI.loading('Logging in...');
        $('#matgen-signin-modal .modal-body .alert').remove();
        try {
          const response = await MatgenGlobal.AuthUser.signIn(
            $('#m4c-login-email').val(),
            $('#m4c-login-password').val()
          );
          //console.error('LOGIN RESPONSE', response);
          MatgenGlobal.UI.stopLoading();
          if (response === true) {
            $('#matgen-signin-modal').modal('hide');
            emit({ event: 'matgen-close-login-modal' });

            if (
              MatgenGlobal.AuthUser.user.challengeName === 'SOFTWARE_TOKEN_MFA'
            ) {
              MatgenGlobal.UI.totpModal();
            } else {
              if (
                MatgenGlobal.AuthCallback &&
                typeof MatgenGlobal.AuthCallback === 'function'
              ) {
                MatgenGlobal.AuthCallback();
              } else {
                if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
                  MatgenGlobal.Router.goTo('templates');
                } else {
                  MatgenGlobal.Router.goTo('materials');
                }
              }
              //MatgenGlobal.Router.goTo('/');
            }
          } else if (response === 'NEW_PASSWORD_REQUIRED') {
            $('#matgen-signin-modal').modal('hide');
            emit({ event: 'matgen-close-login-modal' });
            MatgenGlobal.UI.changePassword();
          } else if (response === 'RESET_PASSWORD_REQUIRED') {
            $('#matgen-signin-modal').modal('hide');
            emit({ event: 'matgen-close-login-modal' });
            MatgenGlobal.UI.forgotPassword();
          } else if (response === 'UNCONFIRMED_EMAIL') {
            $('#matgen-resend-confirmation-link').show();
            $('#matgen-signin-modal .modal-body')
              .prepend(
                $(
                  `
                <div class="alert alert-danger" role="alert">
                  User email not verified. Please check your email and click the verification link, or use the resend link below to resend.
                </div>
              `
                )
              )
              .show();
          } else {
            $('#matgen-signin-modal .modal-body').prepend(
              $(`
              <div class="alert alert-danger" role="alert">
                User credentials not recognized. Please check your information and try again.
              </div>
            `)
            );
          }
        } catch (e) {
          console.error(e);
          MatgenGlobal.UI.handleError(
            'Authentication error',
            'There was an error during authentication. Please try again later or contact support.'
          );
        }
      }
    }
  });

  $(document).off('click', '#matgen-totp-submit');
  $(document).on('click', '#matgen-totp-submit', async e => {
    e.preventDefault();
    try {
      MatgenGlobal.AuthUser.user = await MatgenGlobal.Amplify.Auth.confirmSignIn(
        MatgenGlobal.AuthUser.user,
        $('#matgen-totp').val(),
        'SOFTWARE_TOKEN_MFA'
      );
      $('#matgen-totp-modal').modal('hide');
      if (
        MatgenGlobal.AuthCallback &&
        typeof MatgenGlobal.AuthCallback === 'function'
      ) {
        MatgenGlobal.AuthCallback();
      } else {
        if (MatgenGlobal.AuthUser.getUserRole() !== 'user') {
          MatgenGlobal.Router.goTo('templates');
        } else {
          MatgenGlobal.Router.goTo('materials');
        }
      }
    } catch (e) {
      console.log(e);
      MatgenGlobal.UI.handleError(
        'Authentication error',
        'There was an error during authentication. Please try again later or contact support.'
      );
    }
  });

  $(document).off('click', '#m4c-save-prompt-quit');
  $(document).on('click', '#m4c-save-prompt-quit', async e => {
    e.preventDefault();
    $('#save-prompt-modal').modal('hide');
  });

  $(document).off('click', '#m4c-signup, #m4c-save-prompt-signup');
  $(document).on('click', '#m4c-signup, #m4c-save-prompt-signup', async e => {
    e.preventDefault();
    $('#save-prompt-modal').modal('hide');
    MatgenUIFunctions.showSignupModal();
  });

  $(document).off('click keypress', '#m4c-signup-submit');
  $(document).on('click keypress', '#m4c-signup-submit', async e => {
    e.preventDefault();

    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#signup-error-message').remove();
      const signupForm = document.getElementById('m4c-signup-form');
      MatgenGlobal.AuthUserModule.validateSignup({
        email_element: $('#m4c-signup-email')[0],
        email_confirm_element: $('#m4c-signup-email-confirm')[0],
        password_element: $('#m4c-signup-password')[0],
        password_confirm_element: $('#m4c-signup-password-confirm')[0],
      });
      if (!signupForm.checkValidity()) {
        if (signupForm.reportValidity) {
          signupForm.reportValidity();
        } else {
          alert('Signup form invalid');
        }
      } else {
        const metadata = {};
        if (MatgenGlobal.amplify_config_vars.redirectSignIn) {
          metadata.redirect = MatgenGlobal.amplify_config_vars.redirectSignIn;
        }
        MatgenGlobal.UI.loading('Processing signup...');
        const response = await MatgenGlobal.AuthUser.signUp(
          document.getElementById('m4c-signup-email').value,
          document.getElementById('m4c-signup-password').value,
          MatgenGlobal.tenant,
          metadata
        );

        if (response === true) {
          $('#m4c-signup-modal').modal('hide');

          MatgenGlobal.UI.alertModal(
            'Signup complete',
            `
            <p>You have successfully registered. An email has been sent to the
            submitted address. You must confirm your email address to complete the
            signup process.</p>`
          );
          emit({ event: 'matgen-create-account' });
        } else {
          const errDiv = $(
            `<div id="signup-error-message" class="alert alert-danger" role="alert">
              ${response.message}
            </div>`
          );
          errDiv.insertBefore('#m4c-signup-form');
        }
        MatgenGlobal.UI.stopLoading();
      }
    }
  });

  /*$(document).off('click', '#toggle-icon-chooser');
  $(document).on('click', '#toggle-icon-chooser', () => {
    initIconPicker();
  });*/

  $(document).off('click', '#sort-question-submit');
  $(document).on('click', '#sort-question-submit', async () => {
    MatgenGlobal.UI.loading(`Saving sort orders...`);
    const data = [];
    const sid = $('#sort-list li')
      .first()
      .attr('data-section-id');
    const section = await MatgenGlobal.Data.getSection(sid);
    $('#sort-list li').each((i, el) => {
      data.push($(el).attr('data-id'));
    });
    //console.log(data);
    $('#sort-question-modal').modal('hide');
    try {
      const result = await Promise.all(
        data.map((d, i) =>
          MatgenGlobal.Data.saveItem(
            'question',
            {
              id: d,
              sort_order: i,
            },
            true
          )
        )
      );
      console.log(result);
      const questions = await MatgenUIFunctions.buildSectionQuestions(
        section.id,
        section.type
      );

      $(`#${section.type}-questions`)
        .empty()
        .append($(questions));
      MatgenGlobal.UI.stopLoading();
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'There was a problem saving the sort orders.'
      );
      MatgenGlobal.UI.stopLoading();
      return false;
    }
  });

  $(document).off('click', '#sort-answer-submit');
  $(document).on('click', '#sort-answer-submit', async () => {
    MatgenGlobal.UI.loading(`Saving sort orders...`);
    const data = [];
    $('#sort-list li').each((i, el) => {
      data.push($(el).attr('data-id'));
    });
    const question_id = $('#sort-list li')
      .first()
      .attr('data-question-id');
    //console.log(data);
    $('#sort-answer-modal').modal('hide');
    try {
      const result = await Promise.all(
        data.map((d, i) =>
          MatgenGlobal.Data.saveItem(
            'answer',
            {
              id: d,
              sort_order: i,
            },
            true
          )
        )
      );
      console.log(result);
      const answerAccordion = await MatgenUIFunctions.buildAnswerAccordion(
        question_id
      );
      $(`#accordion-content-${question_id}`)
        .empty()
        .append($(answerAccordion));

      MatgenGlobal.UI.stopLoading();
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'There was a problem saving the sort orders.'
      );
      MatgenGlobal.UI.stopLoading();
      return false;
    }
  });

  $(document).off('click', '.sort-section-question-answers');
  $(document).on('click', '.sort-section-question-answers', e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-question-id'));
    MatgenUIFunctions.sortAnswers($(e.currentTarget).attr('data-question-id'));
  });

  $(document).off('click', '.sort-section-questions');
  $(document).on('click', '.sort-section-questions', e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-section-id'));
    MatgenUIFunctions.sortQuestions($(e.currentTarget).attr('data-section-id'));
  });

  $(document).off('click', '.delete-answer');
  $(document).on('click', '.delete-answer', e => {
    e.preventDefault();
    const id = $(e.currentTarget).attr('data-id');
    MatgenGlobal.UI.confirm(
      'Confirm Delete',
      'Are you sure you wish to delete this answer?',
      'Continue',
      'Cancel',
      () => MatgenUIFunctions.deleteQuestionAnswer(id)
    );
  });

  $(document).off('click', '.edit-answer');
  $(document).on('click', '.edit-answer', async e => {
    e.preventDefault();
    //const id = $(e.currentTarget).attr('data-id');
    /*const answer = await MatgenGlobal.Data.getAnswer(
      $(e.currentTarget).attr('data-id')
    );*/
    MatgenUIFunctions.saveForm(
      'answer',
      'Answer',
      $(e.currentTarget).attr('data-id')
    );
    //MatgenUIFunctions.editAnswerData($(e.currentTarget).attr('data-id'));
  });

  $(document).off('click', '#edit-section-submit');
  $(document).on('click', '#edit-section-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['edit-section-form'];
    const valid = form.validate();
    if (valid === true) {
      MatgenGlobal.UI.loading('Saving section...');
      const values = form.getValues();
      $('#edit-section-modal').modal('hide');
      //console.log(values);
      const section = {
        id: $('#section-id').val(),
        name: values.find(v => v.id === 'inputName').value,
        title: values.find(v => v.id === 'inputTitle').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
      };
      //console.log(section);
      try {
        const response = await MatgenGlobal.Data.saveItem(
          'sections',
          section,
          true
        );
        console.log(response);
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the section.'
        );
        MatgenGlobal.UI.stopLoading();
        return false;
      }
      $('#goal-detail-s-id').text(section.id);
      $('#goal-detail-s-name').text(section.name);
      $('#goal-detail-s-title').text(section.title);
      $('#goal-detail-s-subtext').text(section.subtext);
      MatgenGlobal.UI.stopLoading();
    }
  });

  $(document).off('click', '.add-section-question-answer');
  $(document).on('click', '.add-section-question-answer', async e => {
    //console.log($(e.currentTarget).attr('data-question-id'));
    MatgenUIFunctions.createAnswer($(e.currentTarget).attr('data-question-id'));
  });

  $(document).off('click', '#edit-questionnaire-submit');
  $(document).on('click', '#edit-questionnaire-submit', async e => {
    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['edit-questionnaire-form'];
    const valid = form.validate();
    if (valid === true) {
      MatgenGlobal.UI.loading('Saving questionnaire...');
      const values = form.getValues();
      $('#edit-questonnaire-modal').modal('hide');
      //console.log(values);
      const questionnaire = {
        id: $('#questionnaire-id').val(),
        name: values.find(v => v.id === 'inputName').value,
        tenant_id: values.find(v => v.id === 'inputTenantID').value,
        active: values.find(v => v.id === 'inputActive').value,
      };
      //console.log(questionnaire);
      try {
        const response = await MatgenGlobal.Data.saveItem(
          'questionnaires',
          questionnaire,
          true
        );
        console.log(response);
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the questionnaire.'
        );
        MatgenGlobal.UI.stopLoading();
        return false;
      }
      const tenant = await MatgenGlobal.Data.getTenant(questionnaire.tenant_id);
      $('#detail-q-id').text(questionnaire.id);
      $('#detail-q-name').text(questionnaire.name);
      $('#detail-q-type').text(questionnaire.type);
      $('#detail-q-tenant').text(tenant.name);
      $('#detail-q-active').text(questionnaire.active ? 'Y' : 'N');
      MatgenGlobal.UI.stopLoading();
    }
    //console.log($('#questionnaire-id').attr('id'));
  });

  $(document).off('click', '.add-section-question');
  $(document).on('click', '.add-section-question', e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-section-id'));
    MatgenUIFunctions.createQuestion(
      $(e.currentTarget).attr('data-section-id')
    );
  });

  $(document).off('click', '.q-view');
  $(document).on('click', '.q-view', e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-section-id'));
    $('.question-accordion')
      .not(`#accordion-content-${$(e.currentTarget).attr('data-id')}`)
      .hide();
    $(`#accordion-content-${$(e.currentTarget).attr('data-id')}`).toggle();
  });

  $(document).off('click', '.q-edit');
  $(document).on('click', '.q-edit', e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-id'));
    MatgenUIFunctions.saveForm(
      'question',
      'Question',
      $(e.currentTarget).attr('data-id')
    );
  });

  $(document).off('click', '.q-delete');
  $(document).on('click', '.q-delete', e => {
    e.preventDefault();
    //console.log('DELETE Q:', $(e.currentTarget).attr('data-id'));
    const id = $(e.currentTarget).attr('data-id');
    MatgenGlobal.UI.confirm(
      'Confirm Delete',
      'Are you sure you wish to delete this question? <b class="text-danger">This will delete all the question\'s answers, as well.</b>',
      'Continue',
      'Cancel',
      () => MatgenUIFunctions.deleteSectionQuestion(id)
    );
  });

  $(document).off('click', '#create-question-submit');
  $(document).on('click', '#create-question-submit', async e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-section-id'));

    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-question-form'];
    const valid = form.validate();

    if (valid === true) {
      const sid = $('#q-section-id').val();
      MatgenGlobal.UI.loading('Saving question...');
      const questions = await MatgenGlobal.Data.getSectionQuestions(sid);
      const sort_orders = questions.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      if (!max_sort_order) {
        max_sort_order = 0;
      }
      //console.error(max_sort_order, sort_orders, questions);
      //const subtext = values.find(v => v.id === 'inputSubtext').value;
      const question = {
        id: UUID(),
        text: values.find(v => v.id === 'inputText').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
        component: values.find(v => v.id === 'inputComponent').value,
        section_id: sid,
        sort_order: max_sort_order + 1,
      };
      //console.log(question);
      $('#create-question-modal').modal('hide');
      try {
        const response = await MatgenGlobal.Data.saveItem(
          'questions',
          question
        );
        console.log(response);
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the question.'
        );
        MatgenGlobal.UI.stopLoading();
        return false;
      }
      const section = await MatgenGlobal.Data.getSection(question.section_id);
      //const id = $(`accordion-${section.type} .accordion-item`).length;

      $(`#accordion-${section.type}`)
        .find('.alert')
        .remove();
      const answerAccordion = await MatgenUIFunctions.buildAnswerAccordion(
        question.id
      );
      $(`#accordion-${section.type}`).append(
        $(MatgenUIFunctions.buildQuestionAccordion(question, answerAccordion))
      );
      MatgenGlobal.UI.stopLoading();
    }
  });

  $(document).off('click', '#create-answer-submit');
  $(document).on('click', '#create-answer-submit', async e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-section-id'));

    e.preventDefault();
    const form = MatgenGlobal.DynamicForms['create-answer-form'];

    const valid = form.validate();

    if (valid === true) {
      MatgenGlobal.UI.loading('Saving answer...');
      const answers = await MatgenGlobal.Data.getQuestionAnswers(
        $('#q-question-id').val()
      );
      const sort_orders = answers.map(q => q.sort_order);
      sort_orders.sort();
      let max_sort_order = sort_orders[sort_orders.length - 1];
      const values = form.getValues();
      const qid = $('#q-question-id').val();
      if (!max_sort_order) {
        max_sort_order = 0;
      }
      let icon = 'fa-solid fa-circle-question';
      if (MatgenGlobal.ChosenIcon && MatgenGlobal.ChosenIcon.iconName) {
        icon = `${MatgenGlobal.ChosenIcon.prefix} fa-${MatgenGlobal.ChosenIcon.iconName}`;
      }
      console.error(values);
      //const subtext = values.find(v => v.id === 'inputSubtext').value;
      const answer = {
        id: UUID(),
        text: values.find(v => v.id === 'inputText').value,
        sub_text: values.find(v => v.id === 'inputSubtext').value,
        icon,
        question_id: qid,
        sort_order: max_sort_order + 1,
      };
      //console.log(answer);
      $('#create-answer-modal').modal('hide');
      try {
        const response = await MatgenGlobal.Data.saveItem('answers', answer);
        console.log(response);
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.handleError(
          'Server Error',
          'There was a problem saving the answer.'
        );
        MatgenGlobal.UI.stopLoading();
        return false;
      }
      //const question = await MatgenGlobal.Data.getQuestion(answer.question_id);
      // /const id = $(`#accordion-${question.id} .accordion-item`).length;

      const answerContent = `
            <div id="${answer.id}-answer" class="answer-card">
              <div class="answer-title">
                <i class="${answer.icon}"></i>
                ${answer.text}
              </div>
              <div style="margin-right:.25em;" class="icon-link edit-answer" data-id="${answer.id}"><i class="fa-solid fa-pen-to-square"></i></div>
              <div class="icon-link delete-answer" data-id="${answer.id}"><i class="fa-solid fa-trash-can"></i></div>
            </div>
          `;
      $(`#accordion-${answer.question_id}-answers-content`)
        .find('.alert')
        .remove();
      $(`#accordion-${answer.question_id}-answers-content`).append(
        $(answerContent)
      );
    }
    MatgenGlobal.UI.stopLoading();
  });

  $(document).off('click', '.edit-questionnaire');
  $(document).on('click', '.edit-questionnaire', e => {
    e.preventDefault();
    MatgenUIFunctions.editQuestionnaireData($(e.currentTarget).attr('data-id'));
    //console.log($(e.currentTarget).attr('data-id'));
  });

  $(document).off('click', '.edit-section');
  $(document).on('click', '.edit-section', e => {
    e.preventDefault();
    MatgenUIFunctions.editSectionData($(e.currentTarget).attr('data-id'));
    //console.log($(e.currentTarget).attr('data-id'));
  });

  $(document).off('click', '.section-toggle');
  $(document).on('click', '.section-toggle', e => {
    e.preventDefault();
    //console.log($(e.currentTarget).attr('data-id'));
    $(`#${$(e.currentTarget).attr('data-id')}`).toggle();
  });

  $(document).off('click', '#create-questionnaire-submit');
  $(document).on('click', '#create-questionnaire-submit', async () => {
    const valid = MatgenGlobal.DynamicForms[
      'create-questionnaire-form'
    ].validate();
    if (valid === true) {
      const tenant_id = $('#inputTenantID').val();
      const name = $('#inputName').val();
      const sectionValues = [
        'goal',
        'demographics',
        'distribution',
        'materials',
      ].map(s => {
        return {
          section: s,
          name: $(`#section-${s}-name`).val(),
          title: $(`#section-${s}-title`).val(),
          subtext: $(`#section-${s}-subtext`).val(),
        };
      });

      MatgenGlobal.UI.loading(`Saving questionnaire data...`);

      /*if (sectionValues) {
        return false;
      }*/
      const newId = UUID();

      $('#create-questonnaire-modal').modal('hide');

      MatgenGlobal.UI.loading('Creating questionnaire...');

      const q_resp = await MatgenGlobal.Data.saveItem(
        'questionnaires',
        {
          name,
          tenant_id,
          type: 'material',
          active: 0,
          id: newId,
        },
        false
      );
      console.log(q_resp);

      MatgenGlobal.UI.loading('Creating sections...');

      const data = await Promise.all(
        ['goal', 'demographics', 'distribution', 'materials'].map((s, i) => {
          const item = sectionValues.find(v => v.section === s);
          return MatgenGlobal.Data.saveItem(
            'sections',
            {
              name: item.name,
              questionnaire_id: newId,
              tenant_id,
              type: s,
              active: 0,
              id: UUID(),
              sort_order: i + 1,
              title: item.title,
              subtext: item.subtext,
            },
            false
          );
        })
      );
      console.log(data);

      MatgenGlobal.UI.stopLoading();
      MatgenGlobal.Router.goTo(`edit-questionnaire/${newId}`);
    }
  });

  $(document).off('change', 'select');
  $(document).on('change', 'select', e => {
    if (
      $(e.currentTarget)
        .find('option:selected')
        .hasClass('default')
    ) {
      $(e.currentTarget).css('color', '#aaa');
    } else {
      $(e.currentTarget).css('color', 'inherit');
    }
  });

  $(document).on('show.bs.modal, show-page', () => {
    //console.log($('select').length);
    $('select').each((i, el) => {
      if (
        $(el)
          .find('option:selected')
          .hasClass('default')
      ) {
        $(el).css('color', '#aaa');
      } else {
        $(el).css('color', 'inherit');
      }
    });
  });

  $(document).on('click', '#matgen-logout', async () => {
    MatgenGlobal.AuthUser.signOut({ global: true }, () => {
      MatgenGlobal.AuthUI();
    });
  });

  $(document).off('click', '#matgen-login-signup-link');
  $(document).on('click', '#matgen-login-signup-link', e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.signUp();
    }
  });

  $(document).off('click', '#matgen-login-signup-link');
  $(document).on('click', '#matgen-login-signup-link', e => {
    e.preventDefault();
    $('#matgen-signin-modal').modal('hide');
    emit({ event: 'matgen-close-login-modal' });
    MatgenUIFunctions.showSignupModal();
  });

  $(document).off('matgen-signin');
  $(document).on('matgen-signin', () => {
    MatgenGlobal.CommonInit();
    MatgenGlobal.EditorInit();
  });

  $(document).off('click', '#matgen-signup-submit');
  $(document).on('click', '#matgen-signup-submit', async () => {
    $('#matgen-signup-modal').modal('hide');
    MatgenGlobal.UI.loading('Processing sign up...');
    const signupForm = document.getElementById('matgen-signup-form');
    MatgenGlobal.AuthUserModule.validateSignup({
      email_element: document.getElementById('matgen-signup-email'),
      email_confirm_element: document.getElementById(
        'matgen-signup-email-confirm'
      ),
      password_element: document.getElementById('matgen-signup-password'),
      password_confirm_element: document.getElementById(
        'matgen-signup-password-confirm'
      ),
    });
    if (!signupForm.checkValidity()) {
      if (signupForm.reportValidity) {
        signupForm.reportValidity();
      } else {
        alert('Signup form invalid');
        MatgenGlobal.UI.stopLoading();
      }
    } else {
      const signup_response = await MatgenGlobal.AuthUser.signUp(
        document.getElementById('matgen-signup-email').value,
        document.getElementById('matgen-signup-password').value,
        MatgenGlobal.base_config.tenant,
        {
          redirect: MatgenGlobal.base_config.RedirectURL,
          tenant: MatgenGlobal.base_config.tenant,
        }
      );
      MatgenGlobal.UI.stopLoading();
      if (
        signup_response === true ||
        signup_response.code === 'UsernameExistsException'
      ) {
        MatgenGlobal.UI.alertModal(
          'Signup Confirmation Sent',
          `
            <div class="alert alert-success" role="alert">
              Signup successful. To log in, you'll need to confirm your email address. Please check your email (allow a few minutes for delivery).
            </div>
          `
        );
        /*if (
          signup_response.code &&
          signup_response.code === 'UsernameExistsException'
        ) {
          console.log('Email user???');
        }*/
      } else {
        MatgenGlobal.UI.handleError('Sign up error', signup_response.message);
      }
    }
  });

  $(document).off('submit', '#matgen-login-form');
  $(document).on('submit', '#matgen-login-form', async e => {
    e.preventDefault();

    MatgenGlobal.UI.loading('Logging in...');

    const response = await MatgenGlobal.AuthUser.signIn(
      $('#matgen-login-email').val(),
      $('#matgen-login-password').val()
    );
    console.error('SIGNIN RESPONSE:', response);
    MatgenGlobal.UI.stopLoading();
    if (
      response === true &&
      (window.location.hash === '' ||
        window.location.hash === '#' ||
        window.location.hash === '#*')
    ) {
      MatgenGlobal.AuthUI();
      //MatgenGlobal.Router.goTo('/');
    } else if (response === 'NEW_PASSWORD_REQUIRED') {
      MatgenGlobal.UI.changePassword();
    } else if (response === 'RESET_PASSWORD_REQUIRED') {
      MatgenGlobal.UI.forgotPassword();
    } else if (response === 'UNCONFIRMED_EMAIL') {
      $('#matgen-resend-confirmation-link').show();
    } else {
      $('#matgen-login-form .form-error')
        .text(response.message)
        .show();
    }
  });

  $(document).off('click keypress', '#matgen-resend-confirmation-submit');
  $(document).on(
    'click keypress',
    '#matgen-resend-confirmation-submit',
    async e => {
      e.preventDefault();
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        $('#matgen-signin-modal').modal('hide');
        emit({ event: 'matgen-close-login-modal' });
        MatgenGlobal.UI.resendConfirmationSubmit();
      }
    }
  );

  $(document).off('click keypress', '#matgen-forgot-password-submit');
  $(document).on(
    'click keypress',
    '#matgen-forgot-password-submit',
    async e => {
      e.preventDefault();
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        MatgenGlobal.UI.validateForm('matgen-forgot-password-form', () => {
          $('#matgen-forgot-password-modal').modal('hide');
          MatgenGlobal.UI.forgotPasswordSubmit();
        });
      }
    }
  );

  $(document).off('click keypress', '#matgen-forgot-password-code');
  $(document).on('click keypress', '#matgen-forgot-password-code', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $(`#matgen-forgot-password-modal`).modal('hide');
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.confirmPassword();
    }
  });

  $(document).off('click keypress', '#matgen-forgot-password-link');
  $(document).on('click keypress', '#matgen-forgot-password-link', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.forgotPassword();
    }
  });

  $(document).off('click keypress', '#matgen-reset-password-link');
  $(document).on('click keypress', '#matgen-reset-password-link', async e => {
    e.preventDefault();
    if (MatgenGlobal.UI.a11yClick(e) === true) {
      $('#matgen-signin-modal').modal('hide');
      emit({ event: 'matgen-close-login-modal' });
      MatgenGlobal.UI.confirmPassword();
    }
  });

  $(document).off('click', '#matgen-reset-password-link');
  $(document).on('click', '#matgen-reset-password-link', e => {
    e.preventDefault();
    $('#matgen-signin-modal').modal('hide');
    emit({ event: 'matgen-close-login-modal' });
    MatgenGlobal.UI.confirmPassword();
  });

  $(document).off('click keypress', '#matgen-confirm-password-submit');
  $(document).on(
    'click keypress',
    '#matgen-confirm-password-submit',
    async e => {
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        e.preventDefault();
        if (!$('#confirm-password-form')[0].checkValidity()) {
          if ($('#confirm-password-form')[0].reportValidity) {
            $('#confirm-password-form')[0].reportValidity();
          } else {
            alert('Password change confirmation form input invalid');
          }
        } else {
          MatgenGlobal.UI.confirmPasswordSubmit();
        }
      }
    }
  );

  $(document).off('click keypress', '#matgen-change-password-submit');
  $(document).on(
    'click keypress',
    '#matgen-change-password-submit',
    async e => {
      e.preventDefault();
      if (MatgenGlobal.UI.a11yClick(e) === true) {
        MatgenGlobal.UI.changePasswordSubmit();
      }
    }
  );

  $(document).off('matgen-loading-start');
  $(document).on('matgen-loading-start', async e => {
    if (MatgenGlobal.DEBUG_LOADER === true) {
      console.error('EVENT: matgen-loading-start', e.detail);
    }
    MatgenGlobal.UI.loading(e.detail.message);
  });

  $(document).off('matgen-loading-stop');
  $(document).on('matgen-loading-stop', async () => {
    if (MatgenGlobal.DEBUG_LOADER === true) {
      console.error('EVENT: matgen-loading-stop');
    }
    MatgenGlobal.UI.stopLoading();
    /*window.setTimeout(() => {
      MatgenGlobal.UI.stopLoading();
    }, 250);*/
  });

  $(document).on('click', () => {
    MatgenGlobal.hideTooltips();
  });
};

export { init };
