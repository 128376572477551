import {
  Form,
  TextInput,
  //CheckboxInput,
  ButtonInput,
} from '../components/form/index.js';
//import { emit } from '../../util/helpers.js';

const LoginInputs = [
  new TextInput({
    type: 'email',
    label: 'Email Address',
    id: 'inputEmail',
    classes: 'top',
    required: true,
    autofocus: true,
  }),
  new TextInput({
    type: 'password',
    label: 'Password',
    id: 'inputPassword',
    classes: 'middle',
    required: true,
  }),
  /*new CheckboxInput({
    type: 'checkbox',
    label: 'Remember Me',
    id: 'remember-me',
    classes: 'bottom',
  }),*/
  new ButtonInput({
    type: 'submit',
    label: 'Log In',
    id: 'login-form-submit',
    classes: 'btn-lg btn-primary',
  }),
];

const LoginForm = new Form({
  inputs: LoginInputs,
  id: 'login-form',
  title: 'Log In',
  bottomContent: `
    <p style="margin-top:1.5rem;">
      <a href="#" id="forgot-password-link">Forgot Password?</a><br>
      <a href="#" id="reset-password-link">Have Confirmation Code?</a>
    </p>
  `,
});

export { LoginForm };
