/* global $, MatgenGlobal */

import { Table } from '../components/Table.js';

const MaterialTemplateActions = () => [
  {
    action: 'view',
    title: 'Preview Template',
    icon: 'far fa-image',
    color: 'primary',
  },
  {
    action: 'edit',
    title: 'Create Material From Template',
    icon: 'fas fa-paint-brush',
    color: 'primary',
  },
];
const MaterialTemplateColumns = [
  /*{
    data: 'tenant_name',
    title: 'Tenant',
    render: item => item.toUpperCase(),
  },
  { data: 'id', title: 'ID' },*/
  { data: 'name', title: 'Name' },
  { data: 'tenant_id', visible: false },
  {
    data: 'type',
    title: 'Type',
    render: item => item.toUpperCase(),
  },
  //{ data: 'tag_count', title: 'Tags' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item =>
      Table.actionsHTML(
        item,
        'material-templates',
        MaterialTemplateActions(item)
      ),
  },
];

const MaterialTemplateTable = new Table({
  columns: MaterialTemplateColumns,
  table: 'material_templates',
  title: 'Materials',
  callbacks: {
    createdRow: (row, data) => {
      if (data.published !== 1) {
        $(row).addClass('unpublished');
      }
      if (data.tag_count === 0) {
        $(row).addClass('untagged');
      }
    },
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
});

export { MaterialTemplateTable };
