import { Input } from './Input.js';

class SelectInput extends Input {
  constructor({ options = [] }) {
    super(...arguments);
    this.options = options;
  }

  async getHTML() {
    let options = this.options;
    if (typeof this.options === 'function') {
      options = await this.options();
    }
    const selectedOptions = options.filter(o => {
      //console.log(o.value, this.value, o.value === this.value);
      return o.value === this.value;
    });
    return `
    <div class="row">
        <label for="${this.id}" class="col-5 col-form-label">${
      this.label
    }</label>
      <div class="col-7">
        <select ${this.required ? 'required' : ''} id="${this.id}" name="${
      this.id
    }" class="form-control ${this.classes ? this.classes : ''}" ${
      this.multiple ? 'multiple' : ''
    } ${this.disabled ? 'disabled' : ''}>
          <option  ${
            selectedOptions.length === 0 ? 'selected' : ''
          } class="default" value="">${this.label} (Select)</option>
          ${options
            .map(
              o =>
                `<option value="${o.value}" ${
                  o.value === this.value ? 'selected' : ''
                }>${o.label}</option>`
            )
            .join('')}
        </select>
      </div>
    </div>

      `;
  }
}

export { SelectInput };
