/* global $, MatgenGlobal, Buffer, noUiSlider, wNumb, bootstrap */

import { v4 as UUID } from 'uuid';
//import { init as eventsInit } from './events/canvas-events.js';
//import Pickr from '@simonwep/pickr';
import Pickr from '@simonwep/pickr/dist/pickr.es5.min';
import QRCode from 'qrcode';

import { MatgenEditor } from '../../core/matgen-editor.js';
//import { MatgenCanvas } from '../canvas/matgen-canvas.js';
import { loadMenus } from '../components/intra-menu.js';

const PREVIEW_WIDTH = 400;

//import { Modal } from '../components/Modal.js';
//import { Form } from '../components/Form.js';
import merge from 'deepmerge';
import { fabric } from 'fabric';
import { emit, findObjectById } from '../../util/helpers.js';
import { OptionsModal } from '../components/OptionsModal.js';
import { Sidebar } from '../components/Sidebar/Sidebar.js';
import { Uploader } from '../components/Uploader.js';
import * as MatgenFormObjects from '../components/form';
import * as MatgenForms from '../forms';

import MatgenUIFunctions from './ui-functions.js';

import {
  SelectInput,
  TextAreaInput,
  TextInput,
} from '../components/form/index.js';

function preventDefaults(e) {
  e.preventDefault();
  e.stopPropagation();
}

async function editUpload(file, uploader, id, obj) {
  if (file) {
    const durl = await uploader.bufferUpload(file);
    uploader.mimeType = durl.substring(
      durl.indexOf(':') + 1,
      durl.indexOf(';')
    );

    /*const m = new Modal(
      'cropper-modal',
      'Edit/Confirm Image',
      '<div id="cropper-div"></div>',
      true,
      [
        {
          id: 'save-upload-crop',
          classname: 'primary btn btn-primary',
          label: 'Continue',
        },
      ]
    );
    $('body').append(m.markup);
    $('#cropper-modal').modal('show');*/

    MatgenGlobal.M4CModal.show({
      id: 'cropper-modal',
      title: 'Crop/Confirm Image',
      content: `
      <div id="cropper-div"></div>
      <p class="disclaimer">By uploading the selected image, you acknowledge your right to use this image and assume responsibility for any violation to applicable copyright, terms and conditions related to the use of the image on this material and subsequent distribution of the material.</p>
      `,
      buttons: [
        {
          id: 'save-upload-crop',
          classname: 'primary btn btn-primary',
          label: 'Continue',
        },
      ],
    });

    $(`#upload-${id}`).remove();
    const img = await uploader.cropper.result;
    $('body').append(
      $(`
        <input type="hidden" id="upload-${id}" value="${img.toDataURL(
        uploader.mimeType
      )}" />
      `)
    );

    $('#cropper-modal').modal('hide');

    UI.initCroppie(uploader, obj, durl);
  }
}

const uploaderForm = (type = 'file') => {
  // /console.error('UPLOADER FORM UI');
  return `
  <form id="image-upload-form">
    <div class="form-group">
      <!--<img id="recommended-example" src="https://via.placeholder.com/150" />-->
      <div class="upload-section">
        <div id="drop-area-matgen" class="upload-area">
            <div id="uploader-preview-matgen" style="height:100%;"><p class="text-center">Place image here</p></div>
        </div>
        <!--<a href="#" id="matgen-uploader-link" class="upload-link">Upload from computer<a>-->
      </div>
      <div>
        Recommended image size: <span id="recommended-size"></span>
        <br><br>
      </div>
      <button class="btn btn-outline-primary" id="uploader">Select File</button>
    </div>
    <div class="form-group" id="uploaded-image-group" style="display:none;"></div>
    <br>
    <p class="disclaimer">By uploading the selected image, you acknowledge your right to use this image and assume responsibility for any violation to applicable copyright, terms and conditions related to the use of the image on this material and subsequent distribution of the material.</p>
    <br>
    <div class="form-group" id="alt-text-group">
      <label for="title" style="font-weight:bold;">Alt Text</label>
      <input class="form-control" placeholder="Alt text" name="alt" type="text" id="alt">
      <div id="altHelp" class="form-text">Enter a brief image description for accessibility purposes. This text is not displayed on the material, but is used by screen readers.
      </div>
    </div>
    <div class="mt-3">
      ${
        type === 'option'
          ? '<button id="save-option-upload" type="button" class="btn btn-primary primary">Save &amp; Upload</button>'
          : ''
      }
    </div>
  </form>
`;
};

class UI {
  static route(route) {
    switch (route) {
      default:
      case '/404':
        UI.notFound();
        break;
      case '/':
        UI.showPage('<h1>M4C Intranet</h1>');
        break;
      case '/unauth':
        UI.unauth();
        break;
      case '/forgot-password':
        //UI.showPage(ForgotPasswordForm.markup);
        break;
      case '/forgot-password-confirm':
        //UI.showPage(ForgotPasswordConfirmForm.markup);
        break;
      case '/change-password':
        //UI.showPage(ChangePasswordForm.markup);
        break;
      case '/new-template':
        UI.showPage(MatgenForms.TemplateForm.getHTML(), () => {
          $('#inputPreviewType')
            .closest('.row')
            .hide();
        });
        break;
      case '/new-answer':
        //UI.showPage(AnswerForm.markup);
        break;
    }
  }

  static signUp() {
    MatgenGlobal.M4CModal.show({
      id: 'signup-modal',
      title: 'Enter your information',
      content: `
      <form id="matgen-signup-form">
        <div class="mb-3">
          <label for="matgen-signup-email" class="form-label">Email address</label>
          <input type="email" class="form-control" id="matgen-signup-email" required aria-describedby="email-help">
          <div id="email-help" class="form-text">We'll never share your email with anyone else.</div>
        </div>
        <div class="mb-3">
          <label for="matgen-signup-email-confirm" class="form-label">Confirm Email address</label>
          <input type="email" class="form-control" id="matgen-signup-email-confirm" required>
        </div>
        <div class="mb-3">
          <p><b>Note:</b> Password must be at least 8 characters in length, and include at least one uppercase letter, one lowercase letter, one number, and one special character.</p>
        </div>
        <div class="mb-3">
          <label for="matgen-signup-password" class="form-label">Password</label>
          <input type="password" class="form-control" id="matgen-signup-password" required>
        </div>
        <div class="mb-3">
          <label for="matgen-signup-password-confirm" class="form-label">Confirm Password</label>
          <input type="password" class="form-control" id="matgen-signup-password-confirm" required>
        </div>
      </form>
      `,
      buttons: [
        {
          id: 'matgen-signup-submit',
          classname: 'primary btn btn-primary',
          label: 'Sign Up!',
        },
      ],
    });
  }

  static changePassword() {
    const modalId = 'change-password-modal';
    const title = 'Change password';
    const content = `
    <form id="change-password-form" class="modal-form" aria-label="Change password" style="max-width: 450px;">

      <p><b>Note:</b> Password must be at least 8 characters in length, and include at least one uppercase letter, one lowercase letter, one number, and one special character.</p>
      <label for="change-password">Password</label>
      <input
        type="password"
        id="change-password"
        class="form-control"
        placeholder="Password"
        required
        autofocus
      />

      <label for="confirm-password">Confirm Password</label>
      <input
        type="password"
        id="confirm-password"
        class="form-control"
        placeholder="Confirm Password"
        required
        autofocus
      />

    </form>
    `;
    const actions = [
      {
        id: 'matgen-change-password-submit',
        classname: 'primary btn btn-primary',
        label: 'Change password',
      },
    ];
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
    });
  }

  static totpModal() {
    const modalId = 'matgen-totp-modal';
    const title = 'MFA verification';
    const content = `
    <form id="matgen-totp-form" class="modal-form" aria-label="MFA verification" style="max-width: 450px;">
      <p>Enter the code from your MFA verification app.</p>
      <label for="matgen-totp" class="sr-only">MFA Code (TOTP)</label>
      <input type="email" id="matgen-totp" name="matgen-mfa-totp" class="form-control" placeholder="MFA Code" required autofocus>
    </form>
    `;
    const actions = [
      {
        id: 'matgen-totp-submit',
        classname: 'primary btn btn-primary',
        label: 'Submit',
      },
    ];
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
    });
  }

  static async mfaSetupModal() {
    const modalId = 'matgen-mfa-setup-modal';
    const title = 'Set Up Multi-factor <br> Authentication';
    const content = `
    <form id="matgen-mfa-setup-form" class="modal-form" aria-label="Set Up MFA" style="max-width: 450px;margin:auto;">

      <div class="d-flex flex-column align-items-center justify-content-center">
        <div id="mfa-qr-code"><canvas id="mfa-qr-code-canvas"></canvas></div>
        <button id="copy-mfa-code" type="button" class="btn btn-primary mb-2" style="height: max-content;">Copy code text</button>
      </div>

      <div id="mfa-text-code" style="visibility:hidden"></div>

      <div id="mfa-error-message""></div>

      <p>Scan or copy the code (above) into the authenticator app of your choice. Enter the code from your app (below).</p>
      <label for="matgen-mfa-totp" class="sr-only visually-hidden">MFA Code (TOTP)</label>
      <input type="email" id="matgen-mfa-totp" name="matgen-mfa-totp" class="form-control" placeholder="Enter MFA Code Here" required autofocus>

    </form>
    `;
    const actions = [
      {
        id: 'matgen-mfa-totp-submit',
        classname: 'primary btn btn-primary',
        label: 'Submit',
      },
    ];
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
    });
    let code, user;
    try {
      user = await MatgenGlobal.Amplify.Auth.currentAuthenticatedUser();
      code = await MatgenGlobal.Amplify.Auth.setupTOTP(user);
    } catch (e) {
      console.error(e);
      $('#mfa-error-message')
        .empty()
        .append(
          '<p>There was an error retrieving the MFA secret. Please try again or contact the help desk.</p>'
        );
    }

    const canvas = document.getElementById('mfa-qr-code-canvas');

    const authCode = `otpauth://totp/AWSCognito:${user.username}?secret=${code}&issuer=Cognito`;

    QRCode.toCanvas(canvas, authCode, error => {
      if (error) console.error(error);
      $('#mfa-text-code').text(code);
    });
  }

  static resendVerificationModal() {
    const modalId = 'matgen-resend-confirmation-link-modal';
    const title = 'Resend verification email';
    const content = `
    <form id="matgen-resend-confirmation-link-form" class="modal-form" aria-label="Resend verification email" style="max-width: 450px;">

      <p>Enter your username/email to resend your verification email.</p>
      <label for="matgen-resend-confirmation-link-email" class="sr-only">Email Address</label>
      <input type="email" id="matgen-resend-confirmation-link-email" name="matgen-resend-confirmation-link-email" class="form-control" placeholder="Email Address" required autofocus>

    </form>
    `;
    const actions = [
      {
        id: 'matgen-resend-confirmation-link-submit',
        classname: 'primary btn btn-primary',
        label: 'Send',
      },
    ];
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
    });
  }

  static forgotPassword() {
    const modalId = 'forgot-password-modal';
    const title = 'Reset password';
    const content = `
    <form id="matgen-forgot-password-form" class="modal-form" aria-label="Reset password" style="max-width: 450px;">

      <p>Enter your username/email for a confirmation code to change your password. <b class="text-danger">Please ensure the email address you enter is correct. For security reasons, this form will not report if an email address is invalid.</b></p>
      <label for="forgot-password-email">Email Address</label>
      <input type="email" id="forgot-password-email" name="forgot-password-email" class="form-control" placeholder="Email Address" required autofocus>

    </form>
    `;
    const actions = [
      {
        id: 'matgen-forgot-password-code',
        classname: 'secondary btn btn-secondary',
        label: 'Already Have Code',
      },
      {
        id: 'matgen-forgot-password-submit',
        classname: 'primary btn btn-primary',
        label: 'Reset password',
      },
    ];
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
    });
  }

  static confirmPassword() {
    const modalId = 'confirm-password-modal';
    const title = 'Reset password';
    const content = `
      <form id="confirm-password-form" class="modal-form" aria-label="Reset password" style="max-width: 450px;">
        <p><b>Note:</b> Password must be at least 8 characters in length, and include at least one uppercase letter, one lowercase letter, one number, and one special character.</p>
        <label for="confirm-password-email">Email Address</label>
        <input type="email" id="confirm-password-email" name="confirm-password-email" class="form-control top" placeholder="Email Address" required autofocus>

        <label for="confirm-password-password">Password</label>
        <input type="password" id="confirm-password-password" name="confirm-password-password" class="form-control middle pwcheck" placeholder="Password" required>

        <label for="confirm-password-password2">Confirm Password</label>
        <input type="password" id="confirm-password-password2" name="confirm-password-password2" class="form-control middle" placeholder="Confirm Password" required>

        <label for="inputConfirmationCode">Confirmation Code</label>
        <input type="text" id="inputConfirmationCode" name="inputConfirmationCode" class="form-control bottom" placeholder="Confirmation Code" required>
      </form>
    `;
    const actions = [
      {
        id: 'matgen-confirm-password-submit',
        classname: 'primary btn btn-primary',
        label: 'Reset password',
      },
    ];

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
    });
  }

  static async confirmPasswordSubmit() {
    MatgenGlobal.UI.loading('Resetting password...');

    const response = await MatgenGlobal.AuthUser.forgotPasswordSubmit(
      $('#confirm-password-email').val(),
      $('#inputConfirmationCode').val(),
      $('#confirm-password-password').val()
    );
    MatgenGlobal.UI.stopLoading();
    if (response === true) {
      $('#confirm-password-modal').modal('hide');
      MatgenGlobal.UI.alertModal(
        'Password Reset',
        `
          <div class="alert alert-success" role="alert">
            Your password has been reset. You can now login to the site.
          </div>
        `
      );
    } else {
      $('#confirm-password-modal').modal('hide');
      MatgenGlobal.UI.alertModal(
        'Password Reset Error',
        `
          <div class="alert alert-danger" role="alert">
            <i class="fas fa-bomb"></i> ${response.message}
          </div>
        `
      );
    }
  }

  static async changePasswordSubmit() {
    const form = document.getElementById('change-password-form');
    const password = document.getElementById('change-password');
    const confirm_password = document.getElementById('confirm-password');
    if (password.value != confirm_password.value) {
      confirm_password.setCustomValidity("Passwords don't match");
      if (form.reportValidity) {
        form.reportValidity();
      } else {
        MatgenGlobal.UI.alertModal(
          'Form input error',
          `
              <div class="alert alert-danger" role="alert">
                <i class="fas fa-bomb"></i> There are errors in the form, please check your input and try again.
              </div>
            `
        );
      }
      return false;
    } else {
      confirm_password.setCustomValidity('');
    }
    if (!form.checkValidity()) {
      if (form.reportValidity) {
        form.reportValidity();
      } else {
        MatgenGlobal.UI.alertModal(
          'Form input error',
          `
              <div class="alert alert-danger" role="alert">
                <i class="fas fa-bomb"></i> There are errors in the form, please check your input and try again.
              </div>
            `
        );
      }
    } else {
      MatgenGlobal.UI.loading('Updating password...');
      try {
        await MatgenGlobal.Amplify.Auth.completeNewPassword(
          MatgenGlobal.AuthUser.user,
          password.value,
          {}
        );
        MatgenGlobal.UI.stopLoading();
        MatgenGlobal.UI.alertModal(
          'Password updated',
          `
            <div class="alert alert-success" role="alert">
              Your password has been updated.
            </div>
          `
        );
        $(`#change-password-modal`).modal('hide');
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.stopLoading();
        MatgenGlobal.UI.alertModal(
          'Password change unsuccessful',
          `
            <div class="alert alert-danger" role="alert">
              <i class="fas fa-bomb"></i> There was an error updating your password.
            </div>
          `
        );
        $(`#change-password-modal`).modal('hide');
      }
    }
  }

  static async forgotPasswordSubmit() {
    MatgenGlobal.UI.loading('Sending confirmation code...');
    const response = await MatgenGlobal.AuthUser.forgotPassword(
      $('#forgot-password-email').val()
    );
    MatgenGlobal.UI.stopLoading();
    if (response === true) {
      MatgenGlobal.UI.alertModal(
        'Confirmation Code Sent',
        `
          <div class="alert alert-success" role="alert">
            Confirmation sent. Please check your email (allow a few minutes for delivery).
          </div>
        `
      );
      $(`#forgot-password-modal`).modal('toggle');
    } else {
      MatgenGlobal.UI.alertModal(
        'Confirmation Error',
        `
          <div class="alert alert-danger" role="alert">
            <i class="fas fa-bomb"></i> ${response.message}
          </div>
        `
      );
    }
  }

  static a11yClick(event) {
    if (event.type === 'click') {
      return true;
    }
    if (event.type === 'input') {
      return true;
    } else if (event.type === 'keypress') {
      const code = event.charCode || event.keyCode;
      if (code === 32 || code === 13) {
        return true;
      }
    } else {
      return false;
    }
  }

  static showFormModal({
    modalId = 'form-modal',
    formId = 'form',
    title = '',
    content = '',
    actions = [],
    width = false,
  } = {}) {
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
      width,
    });
    $(`#${formId} h1`).hide();
    $(`#${formId} button`)
      .not('#template-file-associate')
      .hide();
  }

  static unauth() {
    console.error('UNAUTH');
    $(MatgenGlobal.ControllerTargetSelector).removeClass('editor');
    $(`#${MatgenGlobal.SidebarId}`).remove();
    UI.showPage(
      `
      <div class="container">
        <div id="error-container" class="alert alert-warning" role="alert">
          <h1>Signed out</h1>
          <p>Only authorized users may access this application. Please use the link at the top right to sign in.</p>
          </div>
      </div>
    `
    );
  }

  static notFound() {
    console.error('NOT FOUND');
    $(MatgenGlobal.ControllerTargetSelector).removeClass('editor');
    $(`#${MatgenGlobal.SidebarId}`).remove();
    UI.showPage(
      `
      <div id="error-container" class="alert alert-info" role="alert">
        <h4>Not Found</h4>
        <div>Sorry, an error has occured, the page or data you requested was not found.</div>
      </div>
    `
    );
  }

  static appError() {
    console.error('APP ERROR');
    $(MatgenGlobal.ControllerTargetSelector).removeClass('editor');
    $(`#${MatgenGlobal.SidebarId}`).remove();
    UI.showPage(
      `
      <div id="error-container" class="alert alert-danger" role="alert">
        <h4>Application Error</h4>
        <div>Sorry, an error has occured.</div>
        <div>Click <a href="#" onclick="window.location.reload()">here</a> or reload the page to start again.</div>
      </div>
    `
    );
  }

  static async userManagement() {
    const formHTML = await MatgenForms.UserForm.getHTML();
    UI.showPage(formHTML, () => {
      $(document).off('click', '#user-form-submit');
      $(document).on('click', '#user-form-submit', async e => {
        e.preventDefault();
        if ($('#inputEmail').val() === '') {
          UI.alertModal(
            'Input required',
            '<p>You must enter the beginning of an email address to search for.</p>'
          );
          return false;
        }
        UI.loading('Searching users...');
        let users = await MatgenGlobal.Data.getUsers($('#inputEmail').val());
        users = users.Users.map(u => {
          return {
            id: u.Username,
            email: u.Attributes.find(a => a.Name === 'email').Value,
          };
        });
        MatgenGlobal.UI.showPage(
          `
            <form class="form-inline" id="user-form" style="margin-top:12px;margin-bottom:12px;">
              <div class="form-group mb-2">
                <label for="inputEmail">Email</label>
                <input style="width:250px;margin-right:6px;" type="text" class="form-control" placeholder="Email Address Begins With" id="inputEmail" value="">
              </div>
              <button id="user-form-submit" type="submit" class="btn btn-primary mb-2">Find User</button>
            </form>
          ${MatgenGlobal.Tables.UserTable.getHTML()}
          `,
          () => {
            MatgenGlobal.Tables.UserTable.load(users);
          }
        );
        UI.stopLoading();
        console.log('User Search', users);
      });
    });
  }

  static async login() {
    const formHTML = await MatgenForms.LoginForm.getHTML();
    MatgenGlobal.UI.showPage(formHTML);
  }

  /*static signIn() {
    MatgenGlobal.M4CModal.show({
      id: 'matgen-signin-modal',
      title: 'Log In',
      content: `
        <form id="matgen-login-form" class="modal-form" aria-label="Log in">

          <label for="matgen-login-email" class="">Email address</label>
          <input type="email" id="matgen-login-email" class="form-control top" placeholder="Email address" required="" autofocus="" autocomplete="off">

          <label for="matgen-login-password" class="">Password</label>
          <input type="password" id="matgen-login-password" class="form-control bottom" placeholder="Password" required="" autofocus="" autocomplete="off">

        </form>
        <div>
          <p>
            <a id="matgen-forgot-password-link" href="#" style="float:left;">Forgot password</a>
            <a id="matgen-reset-password-link" href="#" style="float:right;">Password change confirmation</a></p>
          <p>
            <a id="matgen-resend-confirmation-link" href="#" style="float:left;">Resend signup confirmation</a>
            <a id="matgen-login-signup-link" href="#" style="float:right;">Create Account</a></p>
        </div>
      `,
      buttons: [
        {
          id: 'matgen-signin-submit',
          classname: 'primary btn btn-primary',
          label: 'Log In',
        },
      ],
    });
  }*/

  static resendConfirmation() {
    const modalId = 'resend-confirmation-modal';
    const title = 'Resend Confirmation';
    const content = `
    <form id="resend-confirmation-form" class="modal-form" aria-label="Resend Confirmation" style="max-width: 450px;">

      <p>Enter your username/email to resend the confirmation email.</p>
      <label for="resend-confirmation-email">Email Address</label>
      <input type="email" id="resend-confirmation-email" name="resend-confirmation-email" class="form-control" placeholder="Email Address" required autofocus>

    </form>
    `;
    const actions = [
      {
        id: 'resend-confirmation-submit',
        classname: 'primary btn btn-primary',
        label: 'Resend Confirmation',
      },
    ];
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: actions,
    });
  }

  static async resendConfirmationSubmit() {
    $(`#resend-confirmation-modal`).modal('hide');
    MatgenGlobal.UI.loading('Sending confirmation email...');

    const response = await MatgenGlobal.AuthUser.resendConfirmationCode(
      $('#resend-confirmation-email').val()
    );
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    if (response === true) {
      MatgenGlobal.UI.alertModal(
        'Confirmation Sent',
        `
          <div class="alert alert-success" role="alert">
            Confirmation sent. Please check your email (allow a few minutes for delivery).
          </div>
        `
      );
      $(`#resend-confirmation-modal`).modal('toggle');
    } else {
      MatgenGlobal.UI.alertModal(
        'Confirmation Error',
        `
          <div class="alert alert-danger" role="alert">
            <i class="fas fa-bomb"></i> ${response.message}
          </div>
        `
      );
    }
  }

  static async home() {
    MatgenGlobal.UI.showPage(`
      <h1>Home</h1>
    `);
  }

  static validateForm(id, cb) {
    if ($(`#${id}`)[0].checkValidity()) {
      if (cb && typeof cb === 'function') {
        cb();
      }
    } else {
      $(`#${id}`)[0].reportValidity();
    }
  }

  static editorInit(type = 'template', req) {
    //console.error('EDITOR INIT:', type, req, MatgenGlobal.ScalingTargetId);

    $(`#${MatgenGlobal.ScalingTargetId}`).remove();
    UI.showPage(``, async () => {
      const p =
        MatgenGlobal.Router.query && MatgenGlobal.Router.query.get('page')
          ? parseInt(MatgenGlobal.Router.query.get('page'))
          : 0;
      const id = req.param ? req.param.id : req;
      let templateId = id;
      let pages, pageId;
      let material;
      if (type === 'material') {
        try {
          material = await MatgenGlobal.Data.getMaterial(id);
          templateId = material.template_id;
        } catch (e) {
          console.error(e);
        }
      }
      try {
        pages = await MatgenGlobal.Data.getPages(templateId);
        pages.sort((a, b) => a.number - b.number).map(p => p.id);
        const page = pages.find(o => o.number === p);
        pageId = false;
        if (page) {
          pageId = page.id;
        }
        await UI.loadEditor(id, pageId, type, false, false, true);
      } catch (e) {
        console.error(e);
      }

      if (type === 'material') {
        if (MatgenGlobal.ExtraPageSave) {
          $(MatgenGlobal.SidebarTargetSelector).append(
            $(`
              <div class="col-12" id="extra-save-div" style="padding:12px;">
                <button id="extra-save-button" data-pid="${pageId}" type="button" class="btn btn-primary">Save</button>
              </div>
            `)
          );
        }
        if (pages.length > 1) {
          $(MatgenGlobal.ControllerTargetSelector).append(
            $(`
              <div class="col-12" id="material-page-div" style="padding:12px;text-align:center;">
                ${pages
                  .map(
                    p =>
                      `<a href="#" class="extra-page" data-id=${
                        p.id
                      }>${p.number + 1}</a>`
                  )
                  .join(' | ')}
              </div>
          `)
          );

          $(document).off('click', '.extra-page');
          $(document).on('click', '.extra-page', async e => {
            e.preventDefault();
            console.log('Change to page:', $(e.target).attr('data-id'));
            $('#extra-save-button').attr(
              'data-pid',
              $(e.target).attr('data-id')
            );
            const pid = $(e.target).attr('data-id');
            UI.loadEditor(id, pid, 'material', false, false, true);
          });
        }
        $(document).off('click', '#extra-save-button');
        $(document).on('click', '#extra-save-button', async e => {
          e.preventDefault();
          console.log('save page:', $('#extra-save-button').attr('data-pid'));

          UI.loading('Saving material...');
          if (!id) {
            UI.handleError('Missing material ID', 'Material ID is required.');
            return false;
          }
          await UI.savePage(id, $('#extra-save-button').attr('data-pid'));

          const template = MatgenGlobal.Data.getTemplate(templateId);

          emit({
            event: 'matgen-material-save',
            detail: { template: template },
          });

          window.setTimeout(() => {
            MatgenGlobal.sidebar.markTemplateClean();
          }, 350);

          UI.stopLoading();
        });
      }
    });
  }

  static async getEditorState(req, type) {
    const p =
      MatgenGlobal.Router.query && MatgenGlobal.Router.query.get('page')
        ? parseInt(MatgenGlobal.Router.query.get('page'))
        : 0;
    const id = req.param ? req.param.id : req;
    let templateId = id;
    let pages, pageId;
    let material;
    if (type === 'material') {
      try {
        material = await MatgenGlobal.Data.getMaterial(id);
        templateId = material.template_id;
      } catch (e) {
        console.error(e);
      }
    }
    try {
      pages = await MatgenGlobal.Data.getPages(templateId);
      pages.sort((a, b) => a.number - b.number).map(p => p.id);
      const page = pages.find(o => o.number === p);
      pageId = false;
      if (page) {
        pageId = page.id;
      }
      return {
        id,
        pageId,
      };
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  static showMaterialEditor(req) {
    UI.loading('Loading editor...');
    delete MatgenGlobal.hideSidebar;
    UI.editorInit('material', req);
  }

  static async showTemplateEditor(req) {
    UI.loading('Loading editor...');
    delete MatgenGlobal.hideSidebar;
    const { id, pageId } = await UI.getEditorState(req, 'template');
    await MatgenGlobal.UI.loadEditor(
      id,
      pageId,
      'template',
      false,
      () => {
        if (!MatgenGlobal.suppressMenus) {
          loadMenus();
        }
      },
      true
    );
  }

  static showTenantEditor(req) {
    UI.loading('Loading editor...');
    delete MatgenGlobal.hideSidebar;
    UI.editorInit('tenant', req);
  }

  static showPage(content, cb, target = MatgenGlobal.ControllerTargetSelector) {
    //$(target).fadeOut(300, () => {
    //console.error('SHOW PAGE:', target, content);
    $(target)
      .empty()
      .append(content)
      .fadeIn(300, () => {
        if (cb) {
          cb();
        }
        MatgenGlobal.emit({ event: 'show-page' });
      });
    //});
    if (!MatgenGlobal.suppressMenus) {
      loadMenus();
    }
    //UI.stopLoading();
  }

  static group(activeObject) {
    let badGroup = false;
    if (!activeObject) {
      console.error('No item selected');
      return;
    }
    if (activeObject.type !== 'activeSelection') {
      return;
    }

    activeObject.getObjects().forEach(o => {
      if (o.uploader || o.userEditable || o.componentId) {
        badGroup = true;
      }
    });

    if (badGroup) {
      UI.alertModal(
        'Incompatible Group',
        '<p>Groups can only be created at the root canvas level, to create group components.</p>'
      );
      return;
    }

    const group = activeObject.toGroup();

    if (MatgenGlobal.editor.groupId) {
      group.id = MatgenGlobal.editor.groupId;
    } else {
      group.id = UUID();
    }
    group.isParent = true;
    /*group = Object.assign(
      group,
      MatgenGlobal.editor.cur().FabricObjectDefaults
    );*/
    group.getObjects().forEach(o => {
      o.groupId = group.id;
    });
    MatgenGlobal.editor.cur().fabric.setActiveObject(group);
    MatgenGlobal.editor.cur().fabric.requestRenderAll();
    UI.canvasChanged();
    return group;
  }

  static canvasChanged(componentId = false) {
    document.dispatchEvent(
      new CustomEvent('canvas-objects-changed', {
        composed: true,
        detail: { componentId },
      })
    );
  }

  static showGrid(grid, color = false) {
    const fabric = MatgenGlobal.editor.cur().fabricJS;
    if (!grid) {
      grid = MatgenGlobal.editor.cur().width / 10;
    }
    if (grid.includes('%')) {
      grid = parseInt(grid);
      if (grid > 100) {
        grid = 100;
      }
      if (grid < 2.5) {
        grid = 2.5;
      }
      grid = MatgenGlobal.editor.cur().width * (grid / 100);
    }

    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    objects.forEach(o => {
      if (o.id.includes('grid-line')) {
        MatgenGlobal.editor.cur().fabric.remove(o);
      }
    });

    for (let i = 0; i < MatgenGlobal.editor.cur().width / grid; i++) {
      MatgenGlobal.editor.cur().fabric.add(
        new fabric.Line(
          [i * grid, 0, i * grid, MatgenGlobal.editor.cur().height],
          {
            stroke: color ? color : MatgenGlobal.GridColor,
            selectable: false,
            sidebarIgnore: true,
            width: 2,
            id: `vert-grid-line-${i}`,
          }
        )
      );
    }

    for (let i = 0; i < MatgenGlobal.editor.cur().height / grid; i++) {
      MatgenGlobal.editor.cur().fabric.add(
        new fabric.Line(
          [0, i * grid, MatgenGlobal.editor.cur().width, i * grid],
          {
            stroke: color ? color : MatgenGlobal.GridColor,
            selectable: false,
            sidebarIgnore: true,
            width: 2,
            id: `horiz-grid-line-${i}`,
          }
        )
      );
    }

    MatgenGlobal.editor.cur().fabric.on('object:moving', options => {
      options.target.set({
        left: Math.round(options.target.left / grid) * grid,
        top: Math.round(options.target.top / grid) * grid,
      });
    });

    MatgenGlobal.editor.cur().fabric.renderAll();
  }

  static initColorPicker(config, id, inputSelector = '#inputColorPicker') {
    let recent = sessionStorage.getItem('recentColors');
    if (recent) {
      recent = JSON.parse(recent);
    }
    const defaults = {
      el: '#color-picker',
      container: '#color-picker-container',
      theme: 'classic', // or 'monolith', or 'nano'
      default: '#000000',
      swatches: recent ? recent : false,
      position: 'top-middle',
      components: {
        // Main components
        preview: true,
        opacity: true,
        hue: true,

        // Input / output Options
        interaction: {
          hex: true,
          rgba: true,
          hsla: true,
          hsva: true,
          cmyk: true,
          input: true,
          //clear: true,
          save: true,
        },
      },
    };

    const initConfig = merge(defaults, config);
    MatgenGlobal.pickr[id] = Pickr.create(initConfig);

    MatgenGlobal.pickr[id].on('save', () => {
      MatgenGlobal.pickr[id].hide();
      let recent = sessionStorage.getItem('recentColors');
      const color = MatgenGlobal.pickr[id]
        .getColor()
        .toRGBA()
        .toString();
      if (recent) {
        recent = JSON.parse(recent);
        if (!recent.includes(color)) {
          recent.push(color);
        }
      } else {
        recent = [];
        recent.push(color);
      }
      sessionStorage.setItem('recentColors', JSON.stringify(recent));
      $(inputSelector).val(
        MatgenGlobal.pickr[id]
          .getColor()
          .toHEXA()
          .toString()
      );
    });
    /*MatgenGlobal.pickr[id].on('change', color => {
      $(inputSelector).val(color.toHEXA().toString());
    });*/
  }

  static async showThemeColorForm() {
    const inputs = [
      {
        component: 'RawHTML',
        html: `
        <input type="hidden" id="inputThemeColor" />
        <div class="color-picker-form form-control middle">Grid Color: <span id="theme-color-picker" class="color-picker"></span></div>
        `,
      },
    ];
    const DynamicForm = await new MatgenForms.DynamicForm(
      inputs,
      [],
      'theme-color-form'
    );
    const content = await DynamicForm.form.getElement();

    MatgenGlobal.M4CModal.show({
      id: 'theme-color-modal',
      title: 'Theme color',
      content: content[0].outerHTML,
      buttons: [
        {
          id: 'theme-color-submit',
          classname: 'primary btn btn-primary',
          label: 'Apply',
        },
      ],
      width: '450px',
    });

    UI.initColorPicker(
      {
        el: '#theme-color-picker',
        container: '#theme-color-modal',
        default: MatgenGlobal.editor.cur().fabric.themeColor
          ? MatgenGlobal.editor.cur().fabric.themeColor
          : '#74BC1E',
        /*swatches: false,
      components: {
        opacity: false,
        interaction: {
          hex: true,
          rgba: false,
          hsla: false,
          hsva: false,
          cmyk: false,
          input: true,
          //clear: true,
          save: true,
        },
      },*/
      },
      'theme',
      '#inputThemeColor'
    );

    $(document).off('submit', '#theme-color-form');
    $(document).on('submit', '#theme-color-form', e => {
      e.preventDefault();
      UI.validateForm('theme-color-form', () => {
        const color = $('#inputThemeColor').val();
        MatgenGlobal.editor.cur().fabric.themeColor = color;
        $('#theme-color-modal').modal('hide');
      });
    });
  }

  static async showGridForm(grid = false) {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Grid size',
          helpText:
            'Enter a number (pixels), or add % sign to use a percentage',
          id: 'inputGridSize',
          value: grid,
        },
      },
      {
        component: 'RawHTML',
        html: `
        <input type="hidden" id="inputGridColor" />
        <div class="color-picker-form form-control middle">Grid Color: <span id="grid-color" class="color-picker"></span></div>
        `,
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Nudge size',
          helpText:
            'Enter the number of pixels to move an item on arrow key press',
          id: 'inputNudgeSize',
        },
      },
    ];
    const DynamicForm = await new MatgenForms.DynamicForm(
      inputs,
      [],
      'grid-form'
    );
    const content = await DynamicForm.form.getElement();

    MatgenGlobal.M4CModal.show({
      id: 'grid-modal',
      title: 'Grid',
      content: content[0].outerHTML,
      buttons: [
        {
          id: 'grid-remove',
          classname: 'primary btn btn-secondary',
          label: 'Remove Grid',
        },
        {
          id: 'grid-submit',
          classname: 'primary btn btn-primary',
          label: 'Apply',
        },
      ],
      width: '450px',
    });

    UI.initColorPicker(
      {
        el: '#grid-color',
        container: '#grid-modal',
        default: MatgenGlobal.GridColor ? MatgenGlobal.GridColor : '#74BC1E',
      },
      'grid',
      'inputGridColor'
    );

    $(document).off('submit', '#grid-form');
    $(document).on('submit', '#grid-form', e => {
      e.preventDefault();
      UI.validateForm('grid-form', () => {
        const grid = $('#inputGridSize').val();
        const color = $('#inputGridColor').val();
        MatgenGlobal.NUDGE_STEP = isNaN(parseInt($('#inputNudgeSize').val()))
          ? 1
          : parseInt($('#inputNudgeSize').val());
        if (grid && parseInt(grid) > 0) {
          UI.showGrid(grid, color);
        }

        $('#grid-modal').modal('hide');
      });
    });
  }

  static loading(
    message = false,
    containerSelector = false,
    fullPage = true,
    callback = false
  ) {
    if (MatgenGlobal.DEBUG_LOADER === true) {
      console.error('START LOADING', message);
    }
    MatgenGlobal.lastLoadingCall = performance.now();
    const detail = { message, trace: new Error().stack };
    emit({
      event: 'matgen-loading',
      detail,
    });
    emit({
      event: 'matgen-event-loader-start',
      detail,
    });
    let selector = 'body';
    if (!containerSelector) {
      if (MatgenGlobal.PageLoaderTarget) {
        selector = MatgenGlobal.PageLoaderTarget;
      }
    } else {
      selector = containerSelector;
    }
    if (MatgenGlobal.PageLoaderTarget) {
      selector = MatgenGlobal.PageLoaderTarget;
      //$(`${selector}`).css('pointer-events', 'auto');
    }
    if (MatgenGlobal.DEBUG === true) {
      console.log('Loader target:', selector);
    }
    if ($(`${selector}`).length > 0) {
      if (!document.getElementById('matgen-loader-container')) {
        const container = $(
          `<div id="matgen-loader-container" class="${
            fullPage ? 'full-page' : 'fit-container'
          } m4c-matgen" />`
        );
        container.css('transform', 'translateZ(0)');
        const liveContent = MatgenGlobal.liveContentSelector
          ? $(MatgenGlobal.liveContentSelector)
          : container;
        liveContent.attr('aria-live', 'assertive');
        liveContent.attr('aria-busy', 'true');
        if (MatgenGlobal.srNotifierSelector) {
          $(MatgenGlobal.srNotifierSelector).text(message);
        } else {
          $('#sr-notifier').text(message);
        }
        container.append(`
        <loading-indicator loading id="matgen-loader" aria-hidden="false" aria-label="${message}"></loading-indicator>
     `);
        //console.log($(`${containerSelector}`), container);
        $(`${selector}`).append(container);

        if (message) {
          $('#matgen-loader').attr('message', message);
        }
      } else {
        if (message) {
          $('#matgen-loader').attr('message', message);
        }
      }
    }

    if (callback && typeof callback === 'function') {
      callback();
    }
  }

  static stopLoadingUI() {
    const callTime = performance.now();

    if (MatgenGlobal.lastLoadingCall > callTime - 250) {
      if (MatgenGlobal.DEBUG_LOADER === true) {
        console.error('Skipping stoploading call');
      }
      return false;
    }
    if (MatgenGlobal.DEBUG_LOADER === true) {
      console.error(
        'STOP LOADING',
        callTime,
        callTime - 500,
        MatgenGlobal.lastLoadingCall
      );
    }
    if (MatgenGlobal.editorLoading) {
      return;
    }
    $('#matgen-loader-container').remove();
    if (MatgenGlobal.liveContentSelector) {
      $(MatgenGlobal.liveContentSelector).attr('aria-busy', 'false');
    } else {
      $('#matgen-loader-container').attr('aria-busy', 'false');
    }
    if (MatgenGlobal.srNotifierSelector) {
      $(MatgenGlobal.srNotifierSelector).text('');
    } else {
      $('#sr-notifier').text('');
    }
    /*if (MatgenGlobal.PageLoaderTarget) {
      const selector = MatgenGlobal.PageLoaderTarget;
      //$(`${selector}`).css('pointer-events', 'auto');
    }*/
    /*if (
      document.getElementById('matgen-loader-container') &&
      (MatgenGlobal.generating !== true || force === true)
    ) {
      document.getElementById('matgen-loader-container').remove();
      if (MatgenGlobal.PageLoaderTarget && !MatgenGlobal.AllowLoaderEvents) {
        const selector = MatgenGlobal.PageLoaderTarget;
        $(`${selector}`).css('pointer-events', 'none');
      }
    }*/
  }

  static stopLoading() {
    const detail = { trace: new Error().stack };
    emit({
      event: 'matgen-event-loader-stop',
      detail,
    });

    if (!MatgenGlobal.stopLoadWait) {
      MatgenGlobal.stopLoadWait = window.setTimeout(() => {
        MatgenGlobal.UI.stopLoadingUI();
      }, 250);
    } else {
      window.clearTimeout(MatgenGlobal.stopLoadWait);
      MatgenGlobal.stopLoadWait = window.setTimeout(() => {
        MatgenGlobal.UI.stopLoadingUI();
      }, 250);
    }
  }

  static initTooltips() {
    if (
      MatgenGlobal.initTooltips &&
      typeof MatgenGlobal.initTooltips === 'function'
    ) {
      MatgenGlobal.initTooltips();
      return;
    }
    console.error('DEFAULT TOOLTIP INIT');
    let target = 'body';
    if (MatgenGlobal.PageLoaderTarget) {
      target = MatgenGlobal.PageLoaderTarget;
    }
    if (
      typeof bootstrap !== 'undefined' &&
      MatgenGlobal.SuppressTooltips !== true
    ) {
      const tooltipTriggerList = document.querySelectorAll(
        '[data-bs-toggle="tooltip"]'
      );
      [...tooltipTriggerList].map(
        tooltipTriggerEl =>
          new bootstrap.Tooltip(tooltipTriggerEl, {
            delay: { show: 650, hide: 100 },
            trigger: 'hover',
            container: target,
            //manual: true,
            //offset: '140px',
          })
      );
    }
    /*
    if (MatgenGlobal.SuppressTooltips !== true) {
      $('[data-bs-toggle="tooltip"]').tooltip('hide');

      $('[data-bs-toggle="tooltip"]').tooltip({
        delay: { show: 650, hide: 100 },
        trigger: 'hover',
        container: target,
        manual: true,
        offset: '-150px',
      });

      $('[data-bs-toggle="tooltip"]').mouseenter(function() {
        const that = $(this);
        that.tooltip('show');
        setTimeout(() => {
          that.tooltip('hide');
        }, 2000);
      });

      $('[data-bs-toggle="tooltip"]').mouseleave(function() {
        $(this).tooltip('hide');
      });

      $('[data-bs-toggle="tooltip"]').click(function() {
        $(this).tooltip('hide');
      });

      $('[data-bs-toggle="tooltip"]').on('shown.bs.tooltip', () => {
        $('.tooltip').addClass('animated swing');
      });
    }
    */
  }

  static async checkPage(id, pid, type) {
    let pageId;
    if (type === 'template' && !pid) {
      const first = await MatgenGlobal.Data.getFirstPage(id);
      if (first && first.length) {
        pageId = first[0].id;
      }
    } else {
      pageId = pid;
    }

    if (!pageId) {
      UI.handleError(
        'Application Error',
        "There was a problem finding the template's pages",
        () => MatgenGlobal.Router.goTo('templates')
      );
      return false;
    } else {
      return pid;
    }
  }

  static async loadEditor(
    id,
    pid = false,
    type = 'template',
    targetOverride = false,
    cb = false,
    keepLoading = false
  ) {
    //console.error('UI LOAD EDITOR', id, pid, type, targetOverride);
    //const role = authUser.getUserRole();
    //console.log('LOAD EDITOR', id, pid, type, targetOverride);
    $(MatgenGlobal.ControllerTargetSelector)
      .empty()
      .show();
    $('#sidebar').empty();
    emit({
      event: 'matgen-load-editor-start',
      detail: { id, pid, type },
    });
    MatgenGlobal.editorLoading = true;
    UI.loading('Loading editor...');
    if (MatgenGlobal.editor) {
      $(`#${MatgenGlobal.editor.containerId}`).empty();
      $(`#${MatgenGlobal.editor.containerId}`).css('visibility', 'hidden');
    }

    const pageId = await UI.checkPage(id, pid, type);

    if (pageId) {
      MatgenGlobal.UI.loading(`Loading ${type}...`);

      let json, template, material;
      if (type === 'template') {
        json = await MatgenGlobal.Data.getTemplateFile(pageId);
        template = await MatgenGlobal.Data.getTemplate(id);
      } else {
        json = await MatgenGlobal.Data.getMaterialPageFile(id, pageId);
        material = await MatgenGlobal.Data.getMaterial(id);
        template = await MatgenGlobal.Data.getTemplate(material.template_id);
      }

      if (!MatgenGlobal.editor) {
        try {
          //console.error('SET EDITOR');
          MatgenGlobal.editor = await new MatgenEditor({
            id: id,
            templateId: template.id,
            pageId,
            width: template.width,
            height: template.height,
          });
        } catch (e) {
          console.error(e);
          return false;
        }
      } else {
        MatgenGlobal.editor.curPageId = pageId;
      }

      //const loaded = await MatgenGlobal.editor.loadPage(pageId, id, type);

      if (!json) {
        if (MatgenGlobal.Router) {
          if (MatgenGlobal.editor) {
            $(`#${MatgenGlobal.editor.containerId}`).css(
              'visibility',
              'visible'
            );
          }
          UI.stopLoading();
          MatgenGlobal.UI.notFound();
        } else {
          console.error(Error('Template page not found'));
        }
      } else {
        //MatgenGlobal.canvasContainerId = `matgen-scale-container-${id}`;
        await MatgenGlobal.editor.load({
          json,
          template,
          canvasContainerId: `matgen-scale-container-${id}`,
          targetSelector: targetOverride
            ? targetOverride
            : MatgenGlobal.ControllerTargetSelector
            ? MatgenGlobal.ControllerTargetSelector
            : 'body',
          cb: async () => {
            if (type === 'template') {
              try {
                await MatgenGlobal.editor.loadDefaults(template.id);

                emit({
                  event: 'matgen-load-editor-end',
                  detail: { id, pid, type },
                });
                emit({
                  event: 'matgen-image-editor-loaded',
                  detail: { editor: MatgenGlobal.editor },
                });
                //resolve(this);
              } catch (e) {
                console.error(e);
                //reject(e);
              }
            } else {
              try {
                await MatgenGlobal.editor.loadSelectedOptions(json.id);

                emit({
                  event: 'matgen-load-editor-end',
                  detail: { id, pid, type },
                });
                emit({
                  event: 'matgen-image-editor-loaded',
                  detail: { editor: MatgenGlobal.editor },
                });
                //resolve(this);
              } catch (e) {
                console.error(e);
                //reject(e);
              }
            }

            if (!MatgenGlobal.hideSidebar) {
              //console.log('NEW SIDEBAR');
              MatgenGlobal.sidebar = new Sidebar();
              const target = MatgenGlobal.SidebarTargetSelector
                ? MatgenGlobal.SidebarTargetSelector
                : 'body';
              $('#sidebar').remove();
              MatgenGlobal.UI.loading('Loading sidebar...');
              MatgenGlobal.editorLoading = false;
              const s = await MatgenGlobal.sidebar.container(id, pageId);
              $(target).prepend(s);
              if (
                window.location.hash.includes('materials/') ||
                window.location.pathname.includes('/materials/')
              ) {
                $('#sidebar').addClass('editor');
              }
              if (MatgenGlobal.sidebar) {
                MatgenGlobal.sidebar.refresh(
                  MatgenGlobal.sidebar,
                  null,
                  'editor-load',
                  false
                );
              }
            }

            if (cb && typeof cb === 'function') {
              cb();
            }

            //resolve(ed);
          },
          keepLoading,
        });
        /*await MatgenGlobal.editor.loadImageEditor(
          {
            fabric: json,
            width: template.width,
            height: template.height,
            id: template.id,
            fonts: template.fonts,
          },
          type,
          targetOverride
            ? targetOverride
            : MatgenGlobal.ControllerTargetSelector
            ? MatgenGlobal.ControllerTargetSelector
            : 'body'
        );*/

        if (MatgenGlobal.editor) {
          $(`#${MatgenGlobal.editor.containerId}`).css('visibility', 'visible');
        }

        if (MatgenGlobal.generating === true) {
          UI.loading('Generating page...');
        } else {
          //UI.stopLoading();
        }

        //eventsInit(MatgenGlobal.editor);
        //$('#sidebar').remove();

        //MatgenGlobal.editor.loadObjectExplorer();
        /*$('.oe-item').each((i, el) => {
          $(el).sortable();
        });*/
      }
    }
    return MatgenGlobal.editor;
  }

  static async savePagePreview(materialId = false, tenant_id = false) {
    MatgenGlobal.editor
      .cur()
      .fabric.getObjects()
      .forEach(o => {
        if (o.id === 'hover-rect') {
          MatgenGlobal.editor.cur().fabric.remove(o);
        }
      });
    MatgenGlobal.editor.cur().fabric.renderAll();
    const preview = await MatgenGlobal.editor.cur().fabric.toDataURL();

    const base64Data = new Buffer.from(
      preview.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );
    if (!materialId) {
      return MatgenGlobal.Data.saveTemplateImage(base64Data, tenant_id);
    } else {
      return MatgenGlobal.Data.saveMaterialImage(base64Data, materialId);
    }
  }

  static async saveEditorTemplate() {
    /*const obj2 = {
      Bucket: "m4c-public",
      Key: `matgen/template/${payload.tenant}/${payload.id}.png`,
      Body: base64Data,
      ContentEncoding: "base64",
      ContentType: "image/png"
    };
    const res2 = await S3.putObject(obj2).promise();*/
    //console.log(base64Data, preview);
    //console.log(Storage);
    //UI.loading();
    /* eslint-disable-next-line */
    /*
    return new Promise(async resolve => {
      const saveObjs = {};
      const curObj = MatgenGlobal.editor.cur().fabric.getActiveObject();

      MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .forEach((o, i) => {
          if (o.componentId && !o.uploader) {
            saveObjs[o.componentId] = o;
            MatgenGlobal.editor.cur().fabric.remove(o);
            const group = new MatgenGlobal.editor.fabric.Group();
            group.componentId = o.componentId;
            group.id = o.id;
            MatgenGlobal.editor.cur().fabric.insertAt(group, i);
          }
        });

      const template = JSON.parse(MatgenGlobal.editor.cur().getJSON());

      const preview = await MatgenGlobal.editor.cur().fabric.toDataURL();

      const template_file_response = await MatgenGlobal.Data.saveEditorTemplate({
        id: MatgenGlobal.editor.id,
        tenant: MatgenGlobal.editor.tenant,
        data: template,
        preview,
      });

      MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .forEach((o, i) => {
          if (o.componentId && !o.uploader) {
            MatgenGlobal.editor.cur().fabric.remove(o);
            MatgenGlobal.editor
              .cur()
              .fabric.insertAt(saveObjs[o.componentId], i);
          }
        });
      MatgenGlobal.editor.cur().fabric.setActiveObject(curObj);
      console.log('TEMPLATE SAVED', template_file_response);
      UI.stopLoading();
      resolve(template_file_response);
    });
    */
  }

  static async saveFont(family, variant, id) {
    const template = await MatgenGlobal.Data.getTemplate(id);
    let fonts = '';
    if (!template.fonts || template.fonts === '') {
      fonts = [`${family}${variant ? `:${variant}` : ''}`];
    } else {
      const templateFonts = template.fonts.split('|');
      const existingFamily = templateFonts.find(f => f.includes(family));
      const existingFamilyIndex = templateFonts.findIndex(f =>
        f.includes(family)
      );
      if (variant && existingFamily) {
        const existingVariants = existingFamily.split(':')[1]
          ? existingFamily.split(':')[1].split(',')
          : [];
        if (!existingVariants.includes(variant)) {
          existingVariants.push(variant);
          existingVariants.sort();
          const variants = existingVariants.join();
          templateFonts[existingFamilyIndex] = `${family}:${variants}`;
          fonts = templateFonts.join('|');
        }
      } else if (!existingFamily) {
        templateFonts.push(`${family}${variant ? `:${variant}` : ''}`);
        fonts = templateFonts.join('|');
      }
    }
    if (fonts !== '') {
      const response = await MatgenGlobal.Data.saveTemplate(
        {
          id,
          fonts,
        },
        true
      );
      console.log(response);
    }
  }

  static image508Form(id) {
    const prefix = 'image508';
    let objects;
    if (MatgenGlobal.editor) {
      objects = MatgenGlobal.editor.cur().fabric.getObjects();
    }
    if (MatgenGlobal.JSON) {
      objects = [MatgenGlobal.JSON];
    }
    const curObj = UI.findById(objects, id);
    let readOrder = curObj.readOrder;
    if (!readOrder) {
      readOrder = curObj.componentReadOrder;
    }
    let tag = curObj.pdfTag;
    if (!tag) {
      tag = curObj.componentPdfTag;
    }
    MatgenUIFunctions.modalForm({
      prefix,
      inputs: [
        new SelectInput({
          label: 'Tag',
          id: 'inputImageTag',
          dataId: 'tag',
          required: true,
          options: [
            {
              label: 'Figure',
              value: 'FIGURE',
            },
            {
              label: 'Artifact',
              value: 'ARTIFACT',
            },
          ],
        }),
        new TextInput({
          type: 'text',
          label: 'Alt Text',
          id: 'inputAltText',
          dataId: 'altText',
          required: false,
          autofocus: true,
        }),
        new TextInput({
          type: 'number',
          label: 'Read Order',
          dataId: 'readOrder',
          id: 'inputReadOrder',
          required: true,
          autofocus: true,
        }),
      ],
      title: 'Image Accessibility',
      data: { id, tag, altText: curObj.altText, readOrder },
      options: { inline: false },
      actions: [
        {
          id: 'image508-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
      listeners: () => {
        if ($('#inputImageTag').val() === 'FIGURE') {
          $('#inputAltText').attr('required', true);
          $('#inputAltText')
            .closest('.row')
            .show();
        } else {
          $('#inputAltText').removeAttr('required');
          $('#inputAltText')
            .closest('.row')
            .hide();
        }

        $(document).off('change', '#inputImageTag');
        $(document).on('change', '#inputImageTag', () => {
          if ($('#inputImageTag').val() === 'FIGURE') {
            $('#inputAltText').attr('required', true);
            $('#inputAltText')
              .closest('.row')
              .show();
          } else {
            $('#inputAltText').removeAttr('required');
            $('#inputAltText')
              .closest('.row')
              .hide();
          }
        });

        $(document).off('click', '#image508-form-submit');
        $(document).on('click', '#image508-form-submit', () => {
          $('#image508-form').submit();
        });

        $(document).off('submit', '#image508-form');
        $(document).on('submit', '#image508-form', async e => {
          e.preventDefault();

          if ($('#image508-form')[0].checkValidity()) {
            const objects = MatgenGlobal.editor.cur().fabric.getObjects();
            const curObj = UI.findById(objects, $('#image508-data-id').val());
            const componentObjects = objects.filter(
              o => o.componentId === curObj.componentId
            );

            if ($('#inputImageTag').val() === 'FIGURE') {
              curObj.set({
                altText: $('#inputAltText').val(),
              });
            }
            if (curObj.componentId && componentObjects.length < 2) {
              curObj.set({
                componentReadOrder: $('#inputReadOrder').val(),
              });
              curObj.set({
                componentPdfTag: $('#inputImageTag').val(),
              });
              curObj.set({
                readOrder: $('#inputReadOrder').val(),
              });
              curObj.set({
                pdfTag: $('#inputImageTag').val(),
              });
              //delete curObj.readOrder;
              //delete curObj.pdfTag;
            } else if (curObj.componentId && componentObjects.length > 1) {
              curObj.set({
                readOrder: $('#inputReadOrder').val(),
              });
              curObj.set({
                pdfTag: $('#inputImageTag').val(),
              });
              delete curObj.componentPdfTag;
            } else {
              curObj.set({
                pdfTag: $('#inputImageTag').val(),
              });
              curObj.set({
                readOrder: $('#inputReadOrder').val(),
              });
              delete curObj.componentReadOrder;
              delete curObj.componentPdfTag;
            }
            MatgenGlobal.editor.cur().fabric.renderAll();
            if (curObj.componentId) {
              MatgenGlobal.sidebar.markComponentDirty(curObj.componentId);
            } else {
              MatgenGlobal.sidebar.markTemplateDirty();
            }
            //console.log(curObj);
            $(`#image508-form-modal`).modal('hide');
          } else {
            $(`#image508-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static text508Form(id) {
    const prefix = 'text508';
    let objects;
    if (MatgenGlobal.editor) {
      objects = MatgenGlobal.editor.cur().fabric.getObjects();
    }
    if (MatgenGlobal.JSON) {
      objects = [MatgenGlobal.JSON];
    }
    const curObj = UI.findById(objects, id);
    let readOrder = curObj.readOrder;
    if (!readOrder) {
      readOrder = curObj.componentReadOrder;
    }
    let tag = curObj.pdfTag;
    if (!tag) {
      tag = curObj.componentPdfTag;
    }
    MatgenUIFunctions.modalForm({
      prefix,
      inputs: [
        new SelectInput({
          label: 'Tag',
          id: 'inputTextTag',
          dataId: 'tag',
          classes: 'middle',
          required: true,
          options: [
            {
              label: 'Heading 1',
              value: 'H1',
            },
            {
              label: 'Heading 2',
              value: 'H2',
            },
            {
              label: 'Heading 3',
              value: 'H3',
            },
            {
              label: 'Heading 4',
              value: 'H4',
            },
            {
              label: 'Heading 5',
              value: 'H5',
            },
            {
              label: 'Heading 6',
              value: 'H6',
            },
            {
              label: 'Paragraph',
              value: 'P',
            },
            {
              label: 'Link',
              value: 'A',
            },
          ],
        }),
        new TextInput({
          type: 'url',
          label: 'URL',
          id: 'inputLink',
          dataId: 'link',
          required: false,
          autofocus: true,
        }),
        new TextInput({
          type: 'number',
          label: 'Read Order',
          id: 'inputReadOrder',
          dataId: 'readOrder',
          required: true,
          autofocus: true,
        }),
      ],
      title: 'Text Accessibility',
      data: { id, tag, link: curObj.link, readOrder },
      options: { inline: false },
      actions: [
        {
          id: 'text508-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
      listeners: () => {
        if ($('#inputTextTag').val() === 'A' && curObj.userEditable !== true) {
          $('#inputLink').attr('required', true);
          $('#inputLink')
            .closest('.row')
            .show();
        } else {
          $('#inputLink').removeAttr('required');
          $('#inputLink')
            .closest('.row')
            .hide();
        }

        $(document).off('change', '#inputTextTag');
        $(document).on('change', '#inputTextTag', () => {
          if (
            $('#inputTextTag').val() === 'A' &&
            curObj.userEditable !== true
          ) {
            $('#inputLink').attr('required', true);
            $('#inputLink')
              .closest('.row')
              .show();
          } else {
            $('#inputLink').removeAttr('required');
            $('#inputLink')
              .closest('.row')
              .hide();
          }
        });

        $(document).off('click', '#text508-form-submit');
        $(document).on('click', '#text508-form-submit', () => {
          $('#text508-form').submit();
        });

        $(document).off('submit', '#text508-form');
        $(document).on('submit', '#text508-form', async e => {
          e.preventDefault();
          if ($('#text508-form')[0].checkValidity()) {
            let objects;
            if (MatgenGlobal.editor) {
              objects = MatgenGlobal.editor.cur().fabric.getObjects();
            }
            const curObj = UI.findById(objects, $('#text508-data-id').val());
            const componentObjects = objects.filter(
              o => o.componentId === curObj.componentId
            );
            if (MatgenGlobal.editor) {
              if ($('#inputTextTag').val() === 'A' && !curObj.userEditable) {
                curObj.set({
                  link: $('#inputLink').val(),
                });
              }
              if (curObj.componentId && componentObjects.length < 2) {
                curObj.set({
                  componentReadOrder: $('#inputReadOrder').val(),
                });
                curObj.set({
                  componentPdfTag: $('#inputTextTag').val(),
                });
                delete curObj.readOrder;
                delete curObj.pdfTag;
              } else {
                curObj.set({
                  pdfTag: $('#inputTextTag').val(),
                });
                curObj.set({
                  readOrder: $('#inputReadOrder').val(),
                });
                //delete curObj.componentReadOrder;
                delete curObj.componentPdfTag;
              }
              MatgenGlobal.editor.cur().fabric.renderAll();
              if (curObj.componentId) {
                MatgenGlobal.sidebar.markComponentDirty(curObj.componentId);
              } else {
                MatgenGlobal.sidebar.markTemplateDirty();
              }
            }
            //console.log(curObj);
            $(`#text508-form-modal`).modal('hide');
          } else {
            $(`#text508-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static group508Form(curObj) {
    const prefix = 'group508';
    const readOrder = curObj.readOrder;
    MatgenUIFunctions.modalForm({
      prefix,
      inputs: [
        new TextInput({
          type: 'number',
          label: 'Read Order',
          id: 'inputReadOrder',
          dataId: 'readOrder',
          required: true,
          autofocus: true,
        }),
      ],
      title: 'Group Accessibility',
      data: { id: curObj.id, readOrder },
      options: { inline: false },
      actions: [
        {
          id: 'group508-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
      listeners: () => {
        $(document).off('click', '#group508-form-submit');
        $(document).on('click', '#group508-form-submit', () => {
          $('#group508-form').submit();
        });

        $(document).off('submit', '#group508-form');
        $(document).on('submit', '#group508-form', async e => {
          e.preventDefault();
          if ($('#group508-form')[0].checkValidity()) {
            let objects;
            if (MatgenGlobal.editor) {
              objects = MatgenGlobal.editor.cur().fabric.getObjects();
            }
            const cid = $('#group508-data-id')
              .val()
              .replace('gcid-', '');
            //const curObj = UI.findById(objects, cid);
            //console.log($('#group508-data-id').val());
            if (
              $('#group508-data-id')
                .val()
                .includes('gcid')
            ) {
              objects.forEach(o => {
                if (o.componentId === cid) {
                  o.componentReadOrder = $('#inputReadOrder').val();
                }
              });
              MatgenGlobal.editor.cur().fabric.renderAll();
              //$(`a.save-option[data-id="${id}"]`).trigger('click');
              MatgenGlobal.sidebar.markComponentDirty(cid);
            }
            //console.log(curObj);
            $(`#group508-form-modal`).modal('hide');
          } else {
            $(`#group508-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static async studyDataForm(curObj) {
    const prefix = 'study-data';
    //console.log('CONNECT TYPE:', curObj.type);
    //console.log('CONNECTION:', curObj.studyDataConnection);
    const studyDataQuestions = await MatgenGlobal.Data.getStudyDataQuestions();
    let options = [];
    switch (curObj.type) {
      default:
        break;
      case 'textbox':
        studyDataQuestions.forEach(studyDataQuestion => {
          if (
            ![
              '498e0def-e28e-48ce-95c4-77d7e5553cf9',
              '9a3e2212-632e-41ad-96cc-bc76c4231c70',
            ].includes(studyDataQuestion.id)
          ) {
            options.push({
              label: studyDataQuestion.text,
              value: studyDataQuestion.id,
            });
          }
        });
        break;
      case 'image':
        options = [
          {
            label: 'Please select from available photos for your main image.',
            value: '498e0def-e28e-48ce-95c4-77d7e5553cf9',
          },
          {
            label: 'Do you have a logo to upload to the site?',
            value: '9a3e2212-632e-41ad-96cc-bc76c4231c70',
          },
        ];
        break;
    }

    //console.log('tt');
    MatgenUIFunctions.modalForm({
      prefix,
      inputs: [
        new SelectInput({
          label: 'Data Field',
          id: 'inputDataField',
          dataId: 'data',
          required: true,
          options,
          value: curObj.studyDataConnection,
        }),
      ],
      title: 'Study Data Item',
      data: { id: curObj.id, data: curObj.studyDataConnection },
      options: { inline: false },
      actions: [
        {
          id: 'study-data-form-remove',
          classname: 'secondary btn btn-secondary',
          label: 'Remove ',
        },
        {
          id: 'study-data-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
      listeners: () => {
        $(document).off('click', '#study-data-form-submit');
        $(document).on('click', '#study-data-form-submit', () => {
          $('#study-data-form').submit();
        });

        $(document).on('click', '#study-data-form-remove', async e => {
          e.preventDefault();
          //console.log('REMOVE STUDY DATA CONNECTION');
          const objects = MatgenGlobal.editor.cur().fabric.getObjects();
          const obj = MatgenGlobal.UI.findById(
            objects,
            $('#study-data-data-id').val()
          );
          if (obj) {
            obj.studyDataConnection = '';
            MatgenGlobal.editor.cur().fabric.renderAll();
            MatgenGlobal.sidebar.markTemplateDirty();
            MatgenGlobal.sidebar.refresh(
              MatgenGlobal.sidebar,
              null,
              'study-data-connection'
            );
          }

          $(`#study-data-form-modal`).modal('hide');
        });

        $(document).off('submit', '#study-data-form');
        $(document).on('submit', '#study-data-form', async e => {
          e.preventDefault();
          if ($('#study-data-form')[0].checkValidity()) {
            //console.log('SAVE STUDY DATA CONNECTION');
            const objects = MatgenGlobal.editor.cur().fabric.getObjects();
            const obj = MatgenGlobal.UI.findById(
              objects,
              $('#study-data-data-id').val()
            );
            //console.log($('#study-data-data-id').val(), objects, obj);

            if (obj) {
              //console.log(obj.id, obj.name, obj.componentId);
              obj.studyDataConnection = $('#inputDataField').val();
              MatgenGlobal.editor.cur().fabric.renderAll();
              MatgenGlobal.sidebar.markTemplateDirty();
              MatgenGlobal.sidebar.refresh(
                MatgenGlobal.sidebar,
                null,
                'study-data-connection'
              );
            }

            /*let objects;
            if (MatgenGlobal.editor) {
              objects = MatgenGlobal.editor.cur().fabric.getObjects();
            }
            const cid = $('#study-data-data-id')
              .val()
              .replace('gcid-', '');
            const curObj = UI.findById(objects, cid);
            if (
              $('#study-data-data-id')
                .val()
                .includes('gcid')
            ) {

              MatgenGlobal.editor.cur().fabric.renderAll();
              //$(`a.save-option[data-id="${id}"]`).trigger('click');
              MatgenGlobal.sidebar.markTemplateDirty();
            }*/
            //console.log(curObj);
            $(`#study-data-form-modal`).modal('hide');
          } else {
            $(`#study-data-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static userTextForm(id) {
    const prefix = 'text';
    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const curObj = findObjectById(objects, id);
    MatgenUIFunctions.modalForm({
      prefix,
      inputs: [
        new TextAreaInput({
          type: 'text',
          label: 'Text',
          id: 'inputText',
          required: true,
          autofocus: true,
          value: curObj.text,
        }),
      ],
      title: 'Edit Text',
      data: { id },
      options: { inline: false },
      actions: [
        {
          id: 'text-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Update',
        },
      ],
      listeners: () => {
        $(document).off('click', '#text-form-submit');
        $(document).on('click', '#text-form-submit', () => {
          $('#text-form').submit();
        });

        $(document).off('submit', '#text-form');
        $(document).on('submit', '#text-form', async e => {
          e.preventDefault();

          if ($('#text-form')[0].checkValidity()) {
            const objects = MatgenGlobal.editor.cur().fabric.getObjects();
            const curObj = findObjectById(objects, $('#text-data-id').val());
            const text = $('#inputText').val();
            curObj.set({
              text,
            });
            MatgenGlobal.editor.cur().fabric.renderAll();

            $(`#text-form-modal`).modal('hide');
          } else {
            $(`#text-form`)[0].reportValidity();
          }
        });
      },
      width: '45vw',
    });
  }

  static zoomEditor({ width = 'auto', height = 'auto' } = {}) {
    $(`#${MatgenGlobal.editor.cur().canvasContainerId}-scaler`).css({
      width,
      height,
    });
    MatgenGlobal.editor.cur().fabric.renderAll();
    MatgenGlobal.UI.resizeHandler();
  }

  static resizeHandler() {
    if (MatgenGlobal.editor) {
      MatgenGlobal.editor
        .cur()
        .scale(MatgenGlobal.editor.width, MatgenGlobal.editor.height);
      MatgenGlobal.editor.cur().fabric.renderAll();
    }
  }

  static userStudyDataTextForm(id, text = '', fontSize = '') {
    //console.log('userStudyDataTextForm:', text);

    MatgenGlobal.MatgenUIFunctions.modalFormUI({
      title: 'Edit Text',
      inputs: [
        {
          component: 'TextArea',
          options: {
            type: 'text',
            label: 'Text',
            id: 'inputText',
            required: true,
            autofocus: true,
            value: text,
          },
        },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Font Size',
            id: 'inputFontSize',
            required: true,
            autofocus: true,
            value: fontSize,
          },
        },
        {
          component: 'RawHTML',
          html: `<input type ="hidden" id="curObjId" value="${id}" />`,
        },
      ],
      buttons: [
        {
          id: 'study-data-text-form-submit',
          classname: 'btn btn-primary primary',
          label: 'Update',
        },
      ],
      idPrefix: 'study-data-text',
      width: '650px',
    });
  }

  static initFormPickers(edit, curObj, container = '#text-modal') {
    if ($('#font-picker').length > 0) {
      $('#font-picker').fontpicker({
        //variants: false,
        localFonts: false,
        onSelect: async e => {
          console.log('FONT PICKED:', e);
          $('#text-edit-font-type').val(e.fontType);
        },
        parentElement: container,
      });

      MatgenGlobal.UI.initColorPicker(
        {
          el: '.color-picker',
          container,
          default: edit ? curObj.fill : '#74BC1E',
        },
        'text',
        '#inputColor'
      );
    }
  }

  static initTextFormSubmit(
    modalId,
    cb,
    submitBtnId = '#text-submit',
    formId = '#text-form'
  ) {
    $(document).off('click', submitBtnId);
    $(document).on('click', submitBtnId, () => {
      $('#text-form').submit();
    });

    $(document).off('submit', formId);
    $(document).on('submit', formId, e => {
      e.preventDefault();
      UI.validateForm(formId.replace('#', ''), () => {
        const editId = $('#text-edit-id').val();
        const componentId = $('#text-component-id').val();
        if ($('#inputColor').length > 0) {
          let color = $('#inputColor').val();
          if (color === '') {
            color = '#74BC1E';
          }
          const text = $('#inputText').val();
          const fontType = $('#text-edit-font-type').val();
          const size =
            parseInt($('#inputFontSize').val()) *
            (1 / MatgenGlobal.editor.cur().scalingFactor);
          const fontspec = $('#font-picker').val();
          const parts = fontspec.split(':');
          const family = parts[0];
          const weight =
            parts[1] && !isNaN(parseInt(parts[1]))
              ? parseInt(parts[1])
              : 'normal';
          const style =
            parts[1] && parts[1].includes('i') ? 'italic' : 'normal';
          /*if (fontType !== 'local') {
            UI.saveFont(family, parts[1], MatgenGlobal.editor.id);
          }*/
          //const family = $('#inputFontFamily').val();
          //const userEditable = $('#inputUserEditable').is(':checked');
          //const textAlign = $("input[name='textAlign']:checked").val();
          //let left, top;

          const objects = MatgenGlobal.editor.cur().fabric.getObjects();
          const curObj = findObjectById(objects, editId);
          curObj.set({
            fontFamily: family,
            fontWeight: weight,
            fontSize: size,
            fontStyle: style,
            fontType,
            text,
            fontspec,
            fill: color,
          });
        } else {
          const text = $('#inputText').val();
          const objects = MatgenGlobal.editor.cur().fabric.getObjects();
          const curObj = findObjectById(objects, editId);
          curObj.set({
            text,
          });
        }

        MatgenGlobal.editor.cur().setObjectPermissions();
        MatgenGlobal.IgnoreAdd = true;
        MatgenGlobal.editor.cur().fabric.renderAll();
        //console.log(editId, curObj);
        //left = curObj.left;
        //top = curObj.top;
        //componentId = curObj.componentId;
        //MatgenGlobal.editor.cur().fabric.remove(curObj);
        //text = curObj.text;

        $(`#${modalId}`).modal('hide');

        /*MatgenGlobal.sidebar.refresh(
          MatgenGlobal.sidebar,
          componentId,
          'add-option',
          false
        );*/

        if (cb && typeof cb === 'function') {
          cb(componentId);
        }

        $(document).on('matgen-canvas-loaded', () => {
          delete MatgenGlobal.IgnoreAdd;
        });

        /*if (MatgenGlobal.sidebar) {
          MatgenGlobal.sidebar.refresh(
            MatgenGlobal.sidebar,
            null,
            'add-option',
            false
          );
          //window.setTimeout(() => {
          MatgenGlobal.sidebar.markComponentClean(componentId);
          //}, 250);
        }*/

        //MatgenGlobal.pickr.destroy();
      });
    });
  }

  static async adminTextForm({
    edit = false,
    id,
    componentId,
    cb,
    target = 'body',
  } = {}) {
    const modalId = 'text-modal';
    const TextForm = new MatgenFormObjects.Form({
      inputs: [
        /*new MatgenForms.TextInput({
          type: 'text',
          label: 'Text',
          id: 'inputText',
          classes: 'top',
          required: true,
        }),*/
        `
        <div class="form-group">
          <label for="inputText">Text</label>
          <textarea class="form-control top" id="inputText" rows="5"></textarea>
        </div>
        `,
        `
        <label for="font-picker">Font</label>
        <input
          id="font-picker"
          class="form-control middle"
          placeholder="Font"
        />
        `,
        new MatgenFormObjects.TextInput({
          type: 'text',
          label: 'Font Size (px)',
          id: 'inputFontSize',
          classes: 'middle',
          required: true,
        }),
        `
          <input type="hidden" id="inputColor" />
          <div class="color-picker-form form-control middle">Font Color: <span class="color-picker"></span></div>
        `,
        `<input type="hidden" id="text-edit-font-type" value="" />`,
        id ? `<input type="hidden" id="text-edit-id" value="${id}" />` : '',
        componentId
          ? `<input type="hidden" id="text-component-id" value="${componentId}" />`
          : '',
      ],
      id: 'text-form',
    });

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Edit Text',
      content: TextForm.markup,
      buttons: [
        {
          id: 'text-submit',
          classname: 'primary btn btn-primary',
          label: 'Apply Changes',
        },
      ],
      target,
    });
    const content = await TextForm.getHTML();
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Edit Text',
      content,
      buttons: [
        {
          id: 'text-submit',
          classname: 'primary btn btn-primary',
          label: 'Apply Changes',
        },
      ],
      target,
    });

    $('#text-form h1').hide();

    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const curObj = findObjectById(objects, $('#text-edit-id').val());
    //console.log(curObj, $('#text-edit-id').val(), objects);
    if (edit) {
      $('#inputText').val(curObj.text);
      $('#font-picker')
        .val(curObj.fontspec)
        .trigger('change');
      $('#inputFontSize').val(
        Math.round(curObj.fontSize * MatgenGlobal.editor.cur().scalingFactor)
      );
      $('#inputColor').val(curObj.fill);
      $(`input[name=textAlign][value=${curObj.textAlign}]`).attr(
        'checked',
        'checked'
      );
    }

    UI.initFormPickers(edit, curObj);

    UI.initTextFormSubmit(modalId, cb);

    $(`#${modalId}`).modal('toggle');
  }

  static async textForm({ edit = false, id, componentId } = {}) {
    //console.log('TEXT FORM:', id, componentId);
    const modalId = 'text-modal';
    const TextForm = new MatgenFormObjects.Form({
      inputs: [
        /*new MatgenForms.TextInput({
          type: 'text',
          label: 'Text',
          id: 'inputText',
          classes: 'top',
          required: true,
        }),*/
        `
        <div class="form-group">
          <label for="inputText">Text</label>
          <textarea class="form-control top" id="inputText" rows="5"></textarea>
        </div>
        `,
        `
        <label for="font-picker">Font</label>
        <input
          id="font-picker"
          class="form-control middle"
          placeholder="Font"
        />
        `,
        new MatgenFormObjects.TextInput({
          type: 'text',
          label: 'Font Size (px)',
          id: 'inputFontSize',
          classes: 'middle',
          required: true,
        }),
        new MatgenFormObjects.TextInput({
          type: 'text',
          label: 'Line Height (multiplier, ex: 1.25)',
          id: 'inputLineHeight',
          classes: 'middle',
          required: true,
        }),
        /*new MatgenForms.TextInput({
          type: 'text',
          label: 'Color',
          id: 'inputColor',
          classes: 'middle',
          required: true,
        }),*/
        `
          <input type="hidden" id="inputColor" />
          <div class="color-picker-form form-control middle">Font Color: <span class="color-picker"></span></div>
        `,
        `
        <div class="form-control middle text-center">
          <div>Alignment:</div>
          <div class="d-flex flex-wrap">

            <div class="form-check form-check-inline">
              <input checked class="form-check-input" type="radio" name="textAlign" id="text-align-left" value="left">
              <label class="form-check-label" for="text-align-left">Left</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="textAlign" id="text-align-center" value="center">
              <label class="form-check-label" for="text-align-center">Center</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="textAlign" id="text-align-right" value="right">
              <label class="form-check-label" for="text-align-right">Right</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="textAlign" id="text-align-justify" value="justify">
              <label class="form-check-label" for="text-align-justify">Justify</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="textAlign" id="text-align-justify-left" value="justify-left">
              <label class="form-check-label" for="text-align-justify-left">Justify Left</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="textAlign" id="text-align-justify-center" value="justify-center">
              <label class="form-check-label" for="text-align-justify-center">Justify Center</label>
            </div>

            <div class="form-check form-check-inline">
              <input class="form-check-input" type="radio" name="textAlign" id="text-align-justify-right" value="justify-right">
              <label class="form-check-label" for="text-align-justify-right">Justify Right</label>
            </div>

          </div>
        </div>
        `,
        new MatgenFormObjects.CheckboxInput({
          id: 'inputUserEditable',
          label: 'User Editable',
        }),
        new MatgenFormObjects.CheckboxInput({
          id: 'inputMaterialDate',
          label: 'Material Date',
        }),
        new MatgenFormObjects.CheckboxInput({
          id: 'inputUseThemeColorText',
          label: 'Use theme color',
        }),
        `<input type="hidden" id="text-edit-font-type" value="" />`,
        id ? `<input type="hidden" id="text-edit-id" value="${id}" />` : '',
        componentId
          ? `<input type="hidden" id="text-component-id" value="${componentId}" />`
          : '',
      ],
      id: 'text-form',
    });

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Add Text',
      content: await TextForm.getHTML(),
      buttons: [
        {
          id: 'text-submit',
          classname: 'primary btn btn-primary',
          label: 'Apply Changes',
        },
      ],
    });

    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const curObj = findObjectById(objects, $('#text-edit-id').val());
    //console.error(edit, curObj, $('#text-edit-id').val(), objects);
    if (edit) {
      $('#inputText').val(curObj.text);

      $('#font-picker')
        .val(curObj.fontspec)
        .trigger('change');
      $('#inputFontSize').val(
        Math.round(curObj.fontSize * MatgenGlobal.editor.cur().scalingFactor)
      );
      $('#inputLineHeight').val(Math.round(curObj.lineHeight));
      $('#inputColor').val(curObj.fill);
      $(`input[name=textAlign][value=${curObj.textAlign}]`).attr(
        'checked',
        'checked'
      );
      $(`#inputUserEditable`).attr('checked', curObj.userEditable);
      $(`#inputMaterialDate`).attr('checked', curObj.materialDate);
    }

    $('#font-picker').fontpicker({
      //variants: false,
      localFonts: false,
      onSelect: async e => {
        console.log('FONT PICKED:', e);
        $('#text-edit-font-type').val(e.fontType);
      },
      parentElement: '#text-modal',
    });
    //$('#font-picker').fontpicker('show');

    MatgenGlobal.UI.initColorPicker(
      {
        el: '.color-picker',
        container: '#text-modal',
        default: edit ? (curObj.fill ? curObj.fill : '#74BC1E') : '#74BC1E',
      },
      'text2',
      '#inputColor'
    );

    $(document).off('click', '#text-submit');
    $(document).on('click', '#text-submit', () => {
      $('#text-form').submit();
    });

    $(document).off('submit', '#text-form');
    $(document).on('submit', '#text-form', e => {
      e.preventDefault();
      UI.validateForm('text-form', async () => {
        let color = $('#inputColor').val();
        if (color === '') {
          color = '#74BC1E';
        }
        const text = $('#inputText').val();
        const fontType = $('#text-edit-font-type').val();
        const size =
          parseInt($('#inputFontSize').val()) *
          (1 / MatgenGlobal.editor.cur().scalingFactor);
        const lineHeight = parseFloat($('#inputLineHeight').val());
        const fontspec = $('#font-picker').val();
        const parts = fontspec.split(':');
        const family = parts[0];
        const weight =
          parts[1] && !isNaN(parseInt(parts[1]))
            ? parseInt(parts[1])
            : 'normal';
        const style = parts[1] && parts[1].includes('i') ? 'italic' : 'normal';
        const editId = $('#text-edit-id').val();
        const componentId = $('#text-component-id').val();
        if (fontType !== 'local') {
          UI.saveFont(family, parts[1], MatgenGlobal.editor.templateId);
        }
        //const family = $('#inputFontFamily').val();
        const userEditable = $('#inputUserEditable').is(':checked');
        const materialDate = $('#inputMaterialDate').is(':checked');
        const textAlign = $("input[name='textAlign']:checked").val();
        let left, top;
        if (editId) {
          const objects = MatgenGlobal.editor.cur().fabric.getObjects();
          const curObj = findObjectById(objects, editId);
          curObj.set({
            fontFamily: family,
            fontWeight: weight,
            fontSize: size,
            fontStyle: style,
            fontType,
            textAlign,
            lineHeight,
            text,
            fontspec,
            fill: color,
            userEditable,
            materialDate,
            styles: null,
            useThemeColor: $('#inputUseThemeColorText').is(':checked'),
          });
          MatgenGlobal.editor.cur().fabric.renderAll();
          //console.log(editId, curObj);
          //left = curObj.left;
          //top = curObj.top;
          //componentId = curObj.componentId;
          //MatgenGlobal.editor.cur().fabric.remove(curObj);
          //text = curObj.text;
        } else {
          MatgenGlobal.editor.cur().addText({
            text,
            size,
            color,
            weight,
            family,
            style,
            textAlign,
            lineHeight,
            userEditable,
            id: UUID(),
            left,
            top,
            componentId,
            fontspec,
            fontType,
            materialDate,
            useThemeColor: $('#inputUseThemeColorText').is(':checked'),
          });
        }
        MatgenGlobal.editor.cur().setObjectPermissions();

        $(`#${modalId}`).modal('hide');
        if (MatgenGlobal.sidebar) {
          await MatgenGlobal.sidebar.refresh(
            MatgenGlobal.sidebar,
            componentId,
            'add-option',
            true
          );
          //window.setTimeout(() => {
          //MatgenGlobal.sidebar.markComponentClean(componentId);
          //}, 250);
        }

        //MatgenGlobal.pickr.destroy();
      });
    });

    $(`#${modalId}`).modal('toggle');
  }

  static downloadResource({ file, name }) {
    if (!file || !name) {
      console.error('Bad file or filename: ', file, name);
      return false;
    }
    const a = document.createElement('a');
    a.href = file;
    a.download = name;
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
  }

  static canvasDataUrl() {
    let scaleRatio = 1;
    const width = MatgenGlobal.editor.cur().fabric.getWidth();
    if (width > PREVIEW_WIDTH) {
      scaleRatio = PREVIEW_WIDTH / width;
    }

    const image = new Image();
    image.src = MatgenGlobal.editor.cur().fabric.toDataURL({
      multiplier: scaleRatio,
    });

    const w = window.open('');
    w.document.write(image.outerHTML);
  }

  static async saveComponent(id) {
    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const curObj = findObjectById(objects, id);
    //console.log(id, objects, curObj);
    if (!curObj) {
      UI.handleError(
        'No Object',
        'No object was found to match the requested ID.'
      );
      return false;
    }
    MatgenGlobal.editor.cur().fabric.setActiveObject(curObj);
    const newComponentId = UUID();
    curObj.componentId = newComponentId;
    curObj.enabled = true;
    curObj.default = true;

    if (curObj.type === 'group') {
      curObj.getObjects().forEach(o => {
        o.componentId = newComponentId;
      });
    }

    MatgenGlobal.editor.cur().fabric.renderAll();

    UI.loading('Creating component...');

    const component_response = await MatgenGlobal.Data.saveComponent({
      id: curObj.componentId,
      default_option_id: curObj.id,
      template_id: MatgenGlobal.editor.templateId,
    });

    if (component_response === false) {
      UI.handleError(
        'Server Error',
        'There was a problem saving the component data.'
      );
      return false;
    }

    console.log('Component created:', component_response);

    await UI.saveOption(curObj);

    if (MatgenGlobal.sidebar) {
      MatgenGlobal.sidebar.refresh(
        MatgenGlobal.sidebar,
        null,
        'create-component',
        false
      );
    }
    window.setTimeout(() => {
      MatgenGlobal.sidebar.markComponentClean(curObj.componentId);
    }, 250);
    UI.stopLoading();
  }

  static loaderProgress(e) {
    $('#progress-wrapper').remove();
    if (e.loaded !== e.total) {
      $('body').append(
        $(`
        <div id="progress-wrapper">
          <div class="progress" id="loader-progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      `)
      );
      /*$(`
        <div id="progress-wrapper">
          <div class="progress" id="loader-progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      `).insertAfter('#matgen-loader');*/
      //console.log(`Uploaded: ${e.loaded}/${e.total}`);
      const pc = parseInt((e.loaded / e.total) * 100);
      $('#progress-wrapper .progress-bar').attr('aria-valuenow', pc);
      $('#progress-wrapper .progress-bar').css('width', `${pc}%`);
    } else {
      $('body').append(
        $(`
        <div id="progress-wrapper">
          <div class="progress" id="loader-progress">
            <div class="progress-bar" role="progressbar" aria-valuenow="100" aria-valuemin="0" aria-valuemax="100"></div>
          </div>
        </div>
      `)
      );
      window.setTimeout(() => {
        $('#progress-wrapper').remove();
      }, 750);
    }
  }

  static async saveOption(curObj, update = false) {
    $('#matgen-loader').attr(
      'message',
      `${update ? 'Updating' : 'Creating'} option...`
    );
    if (!update) {
      MatgenGlobal.ignoreDirty = true;
      const option_reponse = await MatgenGlobal.Data.saveOptionData({
        id: curObj.id,
        component_id: curObj.componentId,
      });

      if (option_reponse === false) {
        UI.handleError(
          'Server Error',
          'There was a problem saving the option data.'
        );
        return false;
      }
      /*console.log(`Option ${update ? 'updated' : 'created'}`, option_reponse, {
        id: curObj.id,
        component_id: curObj.componentId,
      });*/
    }

    MatgenGlobal.UI.loading('Saving option JSON...');

    const component = await MatgenGlobal.Data.getComponent(curObj.componentId);
    const template = await MatgenGlobal.Data.getTemplate(component.template_id);

    const option_file_reponse = await MatgenGlobal.Data.saveOptionFile(
      curObj.id,
      MatgenGlobal.editor.cur().getObjJSON(curObj),
      template.tenant_id
    );

    if (option_file_reponse === false) {
      UI.handleError(
        'Server Error',
        'There was a problem saving the option JSON file.'
      );
      return false;
    }

    console.log('Option JSON saved:', option_file_reponse);

    MatgenGlobal.UI.loading('Saving option preview image...');

    const option_preview_response = await UI.saveOptionPreview(curObj);

    if (option_preview_response === false) {
      UI.handleError(
        'Server Error',
        'There was a problem saving the option preview image file.'
      );
      return false;
    }

    console.log('Option preview saved:', option_preview_response);

    MatgenGlobal.UI.loading('Saving template JSON...');

    const pageObj = await UI.getPageObject();

    const page_file_response = await MatgenGlobal.Data.savePageFile(
      MatgenGlobal.editor.curPageId,
      pageObj,
      template.tenant_id
    );

    if (page_file_response === false) {
      UI.handleError(
        'Server Error',
        'There was a problem saving the template JSON file.'
      );
      return false;
    }

    if (!update && MatgenGlobal.sidebar) {
      MatgenGlobal.sidebar.refresh(
        MatgenGlobal.sidebar,
        curObj.componentId,
        'add-option',
        false
      );
      //window.setTimeout(() => {
      MatgenGlobal.sidebar.markComponentClean(curObj.componentId);
      //}, 250);
    }
    //window.setTimeout(() => {
    MatgenGlobal.sidebar.markTemplateClean();
    //}, 350);

    console.log('Template JSON saved:', page_file_response);
  }

  static async saveOptionPreview(curObj) {
    //const curObj = MatgenGlobal.editor.cur().fabric.getActiveObject();
    const preview = await MatgenGlobal.editor.generateOptionPreview(curObj);
    //console.log(preview);
    const base64Data = new Buffer.from(
      preview.replace(/^data:image\/\w+;base64,/, ''),
      'base64'
    );

    const component = await MatgenGlobal.Data.getComponent(curObj.componentId);
    const template = await MatgenGlobal.Data.getTemplate(component.template_id);

    return MatgenGlobal.Data.saveOptionImage(
      base64Data,
      curObj.id,
      template.tenant_id
    );
  }

  static async savePreview() {
    UI.loading('Saving template preview image...');
    const response = await MatgenGlobal.Data.saveTemplatePreview({
      id: MatgenGlobal.editor.templateId,
      tenant: MatgenGlobal.editor.tenant,
      preview: MatgenGlobal.editor.cur().fabric.toDataURL(),
    });
    console.log(response);
    UI.stopLoading();
  }

  static objJSON() {
    const json = MatgenGlobal.editor.cur().getObjJSON();
    const el = document.createElement('textarea');
    el.value = json;
    document.body.appendChild(el);
    el.select();
    document.execCommand('copy');
    document.body.removeChild(el);
    console.log(json);
  }

  static async addJSON() {
    const modalId = 'add-json-modal';

    const AddJSONForm = new MatgenFormObjects.Form({
      inputs: [
        '<textarea rows="15" style="width:100%" height:220px; id="inputJSON"></textarea>',
      ],
      id: 'add-json-form',
    });

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Add JSON',
      content: AddJSONForm.markup,
      buttons: [
        {
          id: 'add-json-submit',
          classname: 'primary btn btn-primary',
          label: 'Add',
        },
      ],
    });
    $('#add-text-form h1').hide();

    $(document).off('click', '#add-json-submit');
    $(document).on('click', '#add-json-submit', () => {
      $('#add-json-form').submit();
    });

    $(document).off('submit', '#add-json-form');
    $(document).on('submit', '#add-json-form', e => {
      e.preventDefault();
      UI.validateForm('add-json-form', () => {
        const json = $('#inputJSON').val();
        MatgenGlobal.editor.cur().addObj(JSON.parse(json));
        //MatgenGlobal.editor.cur().addText(text, size, color, weight, family);
        //MatgenGlobal.editor.cur().setObjectPermissions(authUser.getUserRole());
        $('#add-json-modal').modal('hide');
        //MatgenGlobal.pickr.destroy();
      });
    });
  }

  static imageLoaded(img, obj) {
    const height = img.height;
    const width = img.width;
    console.log(height, width, obj.height * obj.scaleY, obj.width * obj.scaleX);
  }

  static calculateAspectRatioFit(srcWidth, srcHeight, maxWidth, maxHeight) {
    const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);

    return { width: srcWidth * ratio, height: srcHeight * ratio };
  }

  static initCroppie(uploader, obj, durl) {
    window.setTimeout(() => {
      const width = obj.width * obj.scaleX;
      const height = obj.height * obj.scaleY;

      const adjusted = UI.calculateAspectRatioFit(width, height, 320, 240);

      const finalWidth = adjusted.width;
      const finalHeight = adjusted.height;

      const boundaryWidth = finalWidth - finalWidth * 0.1;
      const boundaryHeight = finalHeight - finalHeight * 0.1;

      const croppieDiv = $(
        `<div id="croppie-div" style="width:${finalWidth}px;height:${finalHeight}px;" />`
      );
      $('#cropper-div').append(croppieDiv);

      uploader.cropper = croppieDiv.croppie({
        viewport: {
          width: boundaryWidth,
          height: boundaryHeight,
        },
        boundary: {
          width: finalWidth,
          height: finalHeight,
        },
        enforceBoundary: false,
      });

      window.MGCROPPIE = uploader.cropper;
      uploader.cropper.croppie('bind', {
        url: durl,
        //points: [-obj.height/2, -obj.width/2, obj.height/2, obj.width/2],
      });

      window.setTimeout(() => {
        uploader.cropper.croppie('setZoom', 0.3);
      }, 250);
    }, 500);
  }

  static selectUpload(uploader, obj, cb) {
    const id = 'matgen-uploader';
    uploader.fileSelect(id, async () => {
      const file = document.getElementById(id).files[0];
      if (file) {
        const durl = await uploader.bufferUpload(file);
        uploader.mimeType = durl.substring(
          durl.indexOf(':') + 1,
          durl.indexOf(';')
        );

        MatgenGlobal.M4CModal.show({
          id: 'cropper-modal',
          title: 'Edit/Confirm Image',
          content: `
          <div>
          ${
            MatgenGlobal.base_config.uploaderInstructions
              ? MatgenGlobal.base_config.uploaderInstructions
              : `
            <p><strong>Instructions:</strong></p>
            <p>1) Locate your image within the box, you may need to use the "sizing" bar below the box to zoom in or out to find it.</p>
            <p>2) Click and hold on your image to move it around within the box to your liking.</p>
            <p>3) Use the "sizing" bar to zoom in and out and size your image within the box.</p>
            `
          }
          </div>
          <div id="cropper-div"></div>`,
          buttons: [
            {
              id: 'save-upload-crop',
              classname: 'primary btn btn-primary',
              label: 'Continue',
            },
          ],
        });

        $('#save-upload-crop').off('click');
        $('#save-upload-crop').on('click', () => {
          uploader.cropper
            .croppie('result', { type: 'base64', size: 'original' })
            .then(r => {
              cb(r);
            });
        });

        UI.initCroppie(uploader, obj, durl);
      }
      //$(`#${id}`).remove();
    });
  }

  static replaceCanvasImage(curObj, durl) {
    //console.log(curObj);
    if (!curObj.uploaderWidth) {
      curObj.uploaderWidth = curObj.width;
    }
    if (!curObj.uploaderHeight) {
      curObj.uploaderHeight = curObj.height;
    }

    if (!curObj.uploaderScaleX) {
      curObj.uploaderScaleX = curObj.scaleX;
    }
    if (!curObj.uploaderScaleY) {
      curObj.uploaderScaleY = curObj.scaleY;
    }
    curObj.setSrc(durl, obj => {
      /*const scale = fabric.util.findScaleToFit(
        { width: obj.width, height: obj.height },
        { width: obj.uploaderWidth, height: obj.uploaderHeight }
      );*/
      obj.set({
        scaleX: (obj.uploaderWidth * obj.uploaderScaleX) / obj.width,
        scaleY: (obj.uploaderHeight * obj.uploaderScaleY) / obj.height,
        width: obj.width,
        height: obj.height,
        top: obj.top,
        left: obj.left,
      });
      /*const scaleTo =
        obj.uploaderHeight *
        //obj.scaleX *
        scale *
        MatgenGlobal.editor.cur().fabric.getZoom();

      obj.scaleToHeight(parseInt(scaleTo));*/
      MatgenGlobal.editor.cur().fabric.renderAll();
      /*let centerX = obj.uploaderWidth / 2;
        centerX -= (obj.uploaderWidth * scale) / 2;
        obj.left += centerX;*/

      //MatgenGlobal.editor.cur().fabric.renderAll();
    });
  }

  static initUploader(modalId, curObj) {
    const uploader = new Uploader();
    if (!curObj) {
      curObj = MatgenGlobal.editor.cur().fabric.getActiveObject();
    }

    if (!curObj.uploaderWidth) {
      curObj.uploaderWidth = curObj.width;
    }
    if (!curObj.uploaderHeight) {
      curObj.uploaderHeight = curObj.height;
    }

    if (!curObj.uploaderScaleX) {
      curObj.uploaderScaleX = curObj.scaleX;
    }
    if (!curObj.uploaderScaleY) {
      curObj.uploaderScaleY = curObj.scaleY;
    }

    const width = curObj.uploaderWidth;
    const height = curObj.uploaderHeight;

    $('#recommended-size').text(
      `${Math.round(width)}px by ${Math.round(height)}px`
    );

    $('#recommended-example').attr(
      'src',
      `https://via.placeholder.com/${Math.round(width)}x${Math.round(height)}`
    );

    $('#uploader').off('click');
    $('#uploader').on('click', e => {
      e.preventDefault();
      UI.selectUpload(uploader, curObj, durl => {
        $('#alt-text-group').show();
        $('#uploaded-image-group')
          .empty()
          .show();

        $(
          `<input type="hidden" id="uploaded-image-durl" value="${durl}" />`
        ).appendTo('#uploaded-image-group');

        $(
          `<input type="hidden" id="uploaded-image-obj-id" value="${curObj.id}" />`
        ).appendTo('#uploaded-image-group');

        $('<img />')
          .appendTo('#uploaded-image-group')
          .attr('src', durl)
          .attr('id', 'image-upload-preview')
          .css('max-width', '220px');
        //const image = document.getElementById('image-upload-preview');

        $('#cropper-modal').modal('hide');
      });
    });
  }

  static userUpload(el = false) {
    const modalId = 'upload-modal';

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Upload Image',
      content: uploaderForm(),
      buttons: [
        {
          id: 'save-upload',
          classname: 'primary btn btn-primary',
          label: 'Upload &amp; Save',
        },
      ],
      //width: '450px',
    });
    let obj = MatgenGlobal.editor.cur().fabric.getActiveObject();
    if (!obj) {
      obj = MatgenGlobal.curObj;
    }
    if (!obj && el) {
      obj = MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .find(o => o.id === el.attr('data-id'));
    }
    //console.error("TEST:", obj, el);
    if (!obj) {
      console.error('No object found for upload');
      return false;
    }
    UI.initUploader(modalId, obj);

    $('#save-upload').off('click');
    $('#save-upload').on('click', () => {
      if (
        !$('#uploaded-image-durl').val() ||
        $('#uploaded-image-durl').val() === ''
      ) {
        UI.handleError(
          'Upload selection required',
          'You must select an image to upload.'
        );
        return false;
      }
      if ($('#image-upload-form')[0].checkValidity()) {
        const obj = MatgenGlobal.editor
          .cur()
          .fabric.getObjects()
          .find(o => o.id === $('#uploaded-image-obj-id').val());
        obj.altText = $('#alt').val();
        UI.replaceCanvasImage(obj, $('#uploaded-image-durl').val());
        $(`#${modalId}`).modal('hide');
        if (!MatgenGlobal.SuppressSidebarActions) {
          MatgenGlobal.sidebar.markTemplateDirty();
        }
      } else {
        $('#image-upload-form')[0].reportValidity();
      }
    });
  }

  static upload(
    cb,
    targetId = 'file-input',
    accept = '.mp4, .mov, .ppt, .pptx, .pdf, .jpg, .png, .jpeg, .gif, .bmp, .tif, .tiff|image/*'
  ) {
    $(`#${targetId}`).remove();
    const file = document.createElement('input');
    file.setAttribute('type', 'file');
    file.setAttribute('id', targetId);
    file.setAttribute('accept', accept);
    file.className = 'hidden-file-input';
    let selector = 'body';
    if (MatgenGlobal.PageLoaderTarget) {
      selector = MatgenGlobal.PageLoaderTarget;
    }
    document.querySelector(selector).appendChild(file);
    document.getElementById(targetId).addEventListener('change', cb);
    document.getElementById(targetId).click();
  }

  static async saveComponentOption(componentId) {
    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const componentObjects = objects.filter(o => o.componentId === componentId);
    /*const obj = _this.findById(
      MatgenGlobal.editor.cur().fabric.getObjects(),
      $(e.currentTarget).attr('data-id')
    );*/
    if (componentObjects.length < 1) {
      console.error('Objects not found for component id:', componentId);
    } else {
      let saveObj = componentObjects[0];
      const optionId = saveObj.currentOptionId;

      if (componentObjects.length > 1) {
        let name = false;
        let allowUploads = false;

        //group all the objects
        const group = new fabric.Group(componentObjects);
        group.componentId = componentId;
        group.id = optionId;

        //clear previous objects
        componentObjects.forEach(obj => {
          MatgenGlobal.editor.cur().fabric.remove(obj);
          name = obj.name;
          allowUploads = obj.allowUploads;
        });
        group.name = name;
        group.allowUploads = allowUploads;
        MatgenGlobal.editor.cur().fabric.add(group);
        group.setCoords();
        MatgenGlobal.editor.cur().fabric.renderAll();
        //console.log(componentObjects);
        saveObj = group;
      }
      UI.loading('Saving option...');
      MatgenGlobal.IgnoreAdd = true;
      await UI.saveOption(saveObj, true);
      if (saveObj.type === 'group') {
        const items = saveObj._objects;
        saveObj._restoreObjectsState();
        MatgenGlobal.editor.cur().fabric.remove(saveObj);
        for (let i = 0; i < items.length; i++) {
          delete items[i].groupId;
          if (saveObj.currentOptionId) {
            items[i].currentOptionId = saveObj.currentOptionId;
          }
          MatgenGlobal.editor.cur().fabric.add(items[i]);
          MatgenGlobal.editor
            .cur()
            .fabric.item(
              MatgenGlobal.editor.cur().fabric.size() - 1
            ).hasControls = true;
        }

        MatgenGlobal.editor.cur().fabric.renderAll();
        delete MatgenGlobal.IgnoreAdd;
      }
      MatgenGlobal.sidebar.refresh(
        MatgenGlobal.sidebar,
        componentId,
        'save-component-option',
        true
      );
      /*window.setTimeout(() => {
        MatgenGlobal.sidebar.markComponentClean(componentId);
      }, 500);*/
      UI.stopLoading();
    }
  }

  static getPageObject() {
    return new Promise(resolve => {
      const saveObjs = {};
      const curObj = MatgenGlobal.editor.cur().fabric.getActiveObject();

      const ignore = MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .filter(o => o.sidebarIgnore);
      ignore.forEach(o => {
        MatgenGlobal.editor.cur().fabric.remove(o);
      });
      MatgenGlobal.editor.cur().fabric.renderAll();

      const objects = MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .filter(o => !o.sidebarIgnore);
      const components = {};

      objects.forEach(o => {
        if (o.componentId) {
          if (!components[o.componentId]) {
            components[o.componentId] = [];
          }
          components[o.componentId].push(o);
        }
      });

      const componentNames = {};

      Object.keys(components).forEach(k => {
        if ($(`#node-gcid-${k}-name`).length > 0) {
          const name = $(`#node-gcid-${k}-name`)
            .text()
            .trim();
          if (name) {
            componentNames[k] = name;
          }
        }
      });

      Object.keys(components).forEach(k => {
        if (components[k].length > 1) {
          //group all the objects
          const fabric = MatgenGlobal.editor.cur().fabricJS;
          const group = new fabric.Group(components[k]);

          group.componentId = k;
          group.id = k;
          group.name = componentNames[k];

          let allowUploads = false;
          //clear previous objects
          components[k].forEach(obj => {
            MatgenGlobal.editor.cur().fabric.remove(obj);
            if (obj.allowUploads) {
              allowUploads = true;
            }
          });

          group.allowUploads = allowUploads;

          MatgenGlobal.editor.cur().fabric.add(group);
          group.setCoords();
          //console.log(componentObjects);
        }
      });
      MatgenGlobal.editor.cur().fabric.renderAll();

      MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .forEach((o, i) => {
          if (o.componentId && !o.uploader) {
            if (!saveObjs[o.componentId]) {
              saveObjs[o.componentId] = [];
            }
            saveObjs[o.componentId].push(o);
            MatgenGlobal.editor.cur().fabric.remove(o);
            const group = new MatgenGlobal.editor.fabric.Group();
            group.componentId = o.componentId;
            group.id = o.id;
            group.name = o.name;
            group.allowUploads = o.allowUploads;
            group.setCoords();
            MatgenGlobal.editor.cur().fabric.insertAt(group, i);
          }
        });
      MatgenGlobal.ignoreDirty = true;
      MatgenGlobal.editor.cur().fabric.requestRenderAll();
      const template = JSON.parse(MatgenGlobal.editor.cur().getJSON());
      /*const preview = await MatgenGlobal.editor.cur().fabric.toDataURL();

      const template_file_response = await MatgenGlobal.Data.saveEditorTemplate({
        id: MatgenGlobal.editor.id,
        tenant: MatgenGlobal.editor.tenant,
        data: template,
        preview,
      });*/
      //console.log(MatgenGlobal.editor.cur().fabric.getObjects());
      //console.log(saveObjs);
      MatgenGlobal.editor
        .cur()
        .fabric.getObjects()
        .forEach((o, i) => {
          if (o.componentId && !o.uploader) {
            MatgenGlobal.editor.cur().fabric.remove(o);
            saveObjs[o.componentId].forEach((obj, j) => {
              MatgenGlobal.editor
                .cur()
                .fabric.insertAt(saveObjs[o.componentId][j], i);
            });
          }
        });
      MatgenGlobal.editor.cur().fabric.setActiveObject(curObj);
      MatgenGlobal.ignoreDirty = true;
      MatgenGlobal.editor.cur().fabric.requestRenderAll();
      //console.log(MatgenGlobal.editor.cur().fabric.getObjects());
      //console.log('TEMPLATE SAVED', template_file_response);
      //UI.stopLoading();
      /*if (MatgenGlobal.sidebar) {
        MatgenGlobal.sidebar.refresh(MatgenGlobal.sidebar);
      }*/
      resolve(template);
    });
  }

  static async deleteComponent(componentId, _this) {
    UI.loading('Deleting component options...');

    const options = await MatgenGlobal.Data.getComponentOptions(componentId);

    const deleted = await Promise.all(
      options.map(o => UI.deleteOption(o.id, o.component_id, true))
    );

    console.log(deleted);

    MatgenGlobal.UI.loading(`Deleting component data...`);
    const res = await MatgenGlobal.Data.deleteComponent(componentId);

    if (res === false) {
      UI.handleError(
        'Server Error',
        'There was a problem deleting the component data.'
      );
      return false;
    }

    console.log(res);

    MatgenGlobal.UI.loading('Saving template JSON...');

    const pageObj = await UI.getPageObject();

    const component = await MatgenGlobal.Data.getComponent(componentId);
    const template = await MatgenGlobal.Data.getTemplate(component.template_id);
    const page_file_response = await MatgenGlobal.Data.savePageFile(
      MatgenGlobal.editor.curPageId,
      pageObj,
      template.tenant_id
    );
    console.log(page_file_response);
    MatgenGlobal.UI.loading('Saving template preview image...');
    const preview_response = await UI.savePagePreview(
      false,
      template.tenant_id
    );
    console.log(preview_response);

    window.setTimeout(() => {
      _this.markTemplateClean();
    }, 350);

    UI.stopLoading();
  }

  static async deleteOption(optionId, componentId, force = false) {
    UI.loading('Deleting option data...', '#options-modal');
    let res = await MatgenGlobal.Data.deleteOption(
      optionId,
      componentId,
      force
    );

    if (res === false) {
      UI.handleError(
        'Server Error',
        'There was a problem deleting the option data.'
      );
      return false;
    }

    console.log(res);
    let component, template;
    try {
      MatgenGlobal.UI.loading('Deleting option JSON...', '#options-modal');
      component = await MatgenGlobal.Data.getComponent(componentId);
      template = await MatgenGlobal.Data.getTemplate(component.template_id);
      res = await MatgenGlobal.Data.deleteOptionFile(
        optionId,
        template.tenant_id
      );
    } catch (e) {
      console.error(e);
      UI.handleError(
        'Server Error',
        'There was a problem deleting the option JSON file.'
      );
      return false;
    }

    if (res === false) {
      console.error('There was a problem deleting the option JSON file.');
      UI.handleError(
        'Server Error',
        'There was a problem deleting the option JSON file.'
      );
      return false;
    }

    console.log(res);
    MatgenGlobal.UI.loading(
      'Deleting option preview image...',
      '#options-modal'
    );
    res = await MatgenGlobal.Data.deleteOptionPreview(
      optionId,
      template.tenant_id
    );

    if (res === false) {
      UI.handleError(
        'Server Error',
        'There was a problem deleting the option preview image.'
      );
      return false;
    }

    console.log(res);

    const obj = MatgenGlobal.editor
      .cur()
      .fabric.getObjects()
      .find(o => o.componentId === componentId);
    if (obj && obj.currentOptionId === optionId) {
      const component = await MatgenGlobal.Data.getComponent(componentId);
      MatgenGlobal.editor.setComponentOption(
        component.default_option_id,
        component.id,
        obj.name
      );
    }

    UI.stopLoading();
    return true;
  }

  static showHelp() {
    const id = 'help-modal';
    const content = `
    <div id="accordion">

      <div class="card">
        <div class="card-header" id="headingOne">
          <h5 class="mb-0">
            <button class="btn btn-link" data-bs-toggle="collapse" data-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
              Basic Use
            </button>
          </h5>
        </div>

        <div id="collapseOne" class="collapse show" aria-labelledby="headingOne" data-parent="#accordion">
          <div class="card-body">
            ${
              MatgenGlobal.AuthUser.getUserRole() !== 'user'
                ? `<ul>
              <li>
                <h5>Refresh is your friend</h5>
                <p>If you encounter any errors or odd behavior, a page refresh will often resolve the issues (unless a save has been executed on erroneous content).</p>
              </li>
              <li>
                <h5>Basic concepts</h5>
                <ul>
                  <li>You begin with a blank canvas. Use the Add Text <i class="fas fa-xs fa-text"></i><i class="fal fa-xs fa-plus"></i> or Add Image <i class="fas fa-xs fa-image"></i><i class="fal fa-xs fa-plus"></i> buttons/icons to get started.</li>
                  <li>To create an image uploader, add an image, position and size it, then click the Create Uploader icon <i class="fas fa-xs fa-upload"></i>. This object will then have the uploader icon on the left to identify it as an uploader.</li>
                  <li>To create user-editable text, add a text component with the User Editable checkbox checked, or click the Edit icon <i class="fas fa-xs fa-edit"></i> on the object and check the box. Thie object's icon will change to reflect it is user-editable text.</li>
                  <li>Individual text and image objects can be made into simple components by clicking the Create Component icon <i class="fas fa-xs fa-sitemap"></i>, or, to create a more complex component, you can add several objects, then drag or shift+click to select them, then press ctrl+g to group them. You can then create a complex component from that group.</li>
                  <li></li>
                </ul>
              </li>
              <li>
                <h5>Hotkeys</h5>
                <p>Note: ctrl/command keys are both listened to, we'll just list one version though:</p>
                <ul>
                  <li>
                    <b>ctrl+g:</b> Group
                  </li>
                  <li>
                    <b>ctrl+shift+g:</b> Ungroup
                  </li>
                  <li>
                    <b>ctrl+shift+b:</b> Send back
                  </li>
                  <li>
                    <b>ctrl+shift+f:</b> Bring forward
                  </li>
                </ul>
              </li>
              <li>
                <h5>Object/Component Key/Guide</h5>
                <ul>
                  <li><i class="fas fa-xs fa-sitemap"></i>: This icon, on the right of the object list, will create a component from the object. On the left, it indicates an existing component.</li>
                </ul>
              </li>
            </ul>`
                : ''
            }

              ${
                MatgenGlobal.AuthUser.getUserRole() === 'user'
                  ? `
              <ul>
                <li>
                  <h5>Help should go here</h5>
                  <p>Some user facing help text should go here.</p>
                </li>
              </ul>
              `
                  : ''
              }
          </div>
        </div>
      </div>

    </div>
    `;

    MatgenGlobal.M4CModal.show({
      id,
      title: 'Help',
      content,
      buttons: [],
    });
  }

  static async savePage(materialId, pid = false) {
    MatgenGlobal.UI.loading('Saving material page...');

    try {
      const pageObj = JSON.parse(MatgenGlobal.editor.cur().getJSON());
      const page_file_response = await MatgenGlobal.Data.saveMaterialPageFile(
        materialId,
        pid ? pid : MatgenGlobal.editor.curPageId,
        pageObj
      );
      console.log(page_file_response);
      MatgenGlobal.UI.loading('Saving preview image...');
      const preview_response = await MatgenGlobal.UI.savePagePreview(
        materialId
      );
      console.log(preview_response);
    } catch (e) {
      console.error(e);
      return false;
    }

    MatgenGlobal.UI.stopLoading();
  }

  static loadImage(el) {
    return new Promise((resolve, reject) => {
      $(el).attr('src', $(el).attr('data-src'));
      //console.log('LOAD IMG:', $(el).attr('data-src'));
      $(el).css('display', 'none');
      $(el)[0].onerror = e => {
        console.error(e);
        reject();
      };

      $(el)[0].onload = () => {
        $(el).css('display', 'inline-block');
        $(el)[0].onload = null;
        /*$(el).css({
          'max-width': $(el)[0].width / 2,
          margin: 'auto',
        });*/
        resolve(true);
      };
    });
  }

  static async showComponentOptions(componentId) {
    //console.error('SHOW OPTIONS:', componentId);
    UI.loading('Loading options...');
    let options;
    try {
      options = await MatgenGlobal.Data.getComponentOptions(componentId);
    } catch (e) {
      console.error(e);
      return false;
    }
    //console.log(componentId, options);

    //console.log(template);
    MatgenGlobal.UI.stopLoading();

    const obj = MatgenGlobal.editor
      .cur()
      .fabric.getObjects()
      .find(o => o.componentId === componentId);

    if (!obj) {
      console.error('Object not found for id:', componentId);
    }

    /*let buttons = [];
    if (template.uploads === 1) {
      buttons = [
        {
          id: 'upload-component-image',
          classname: 'primary btn btn-primary',
          label: 'Upload',
        },
      ];
    }*/

    const buildTabs = tabs => {
      const nav = `
      <ul class="nav nav-tabs" id="modal-tabs" role="tablist">
        ${tabs
          .map(
            (t, i) => `
          <li class="nav-item" role="presentation">
            <button class="nav-link${i === 0 ? ' active' : ''}" id="${
              t.name
            }-tab" data-bs-toggle="tab" data-target="#${
              t.name
            }" data-bs-toggle="tab" data-bs-target="#${
              t.name
            }" type="button" role="tab" aria-controls="${
              t.name
            }" aria-selected="true">${t.label}</button>
          </li>
          `
          )
          .join('')}
      </ul>
      `;

      const panels = `
      <!-- Tab panes -->
      <div class="tab-content" style="padding:12px;box-shadow: 1px 1px 2px #cfcfcf;border-left: 1px solid #efefef;">
        ${tabs
          .map(
            (t, i) => `
          <div class="tab-pane${i === 0 ? ' active' : ''}" id="${
              t.name
            }" role="tabpanel" aria-labelledby="${t.name}-tab">
            ${t.content}
          </div>
          `
          )
          .join('')}
      </div>
      `;

      return `${nav}${panels}`;
    };

    const id = 'options-modal';
    let title = 'Manage Options';

    if (MatgenGlobal.AuthUser.getUserRole() === 'user') {
      if (obj.allowUploads || MatgenGlobal.AuthUser.getUserRole() === 'admin') {
        title = 'Select/Upload Option';
      } else {
        title = 'Select Option';
      }
    }

    let content, buttons;
    if (
      obj.type === 'image' &&
      (obj.allowUploads || MatgenGlobal.AuthUser.getUserRole() === 'admin')
    ) {
      content = buildTabs([
        {
          name: 'select-image',
          label: 'Select an Image',
          content:
            '<div id="options-container-holder"><loading-indicator loading id="modal-loader"></loading-indicator></div>',
        },
        {
          name: 'upload-image',
          label: 'Upload an Image',
          content: uploaderForm('option'),
        },
      ]);
      buttons = [];
      /*buttons = [
        {
          id: 'save-option-upload',
          classname: 'btn btn-primary primary',
          label: 'Save &amp; Upload',
        },
      ];*/
    } else if (
      obj.type === 'textbox' &&
      (obj.allowUploads || MatgenGlobal.AuthUser.getUserRole() === 'admin')
    ) {
      //const textContent = await TextForm.getHTML();
      const textContent = new MatgenFormObjects.Form({
        inputs: [
          new TextAreaInput({
            type: 'text',
            label: 'Text',
            id: 'inputText',
            required: true,
            autofocus: true,
          }),
          `<input type="hidden" id="text-edit-id" value="${obj.id}" />`,
        ],
        id: 'text-form',
      });
      content = buildTabs([
        {
          name: 'select-text',
          label: 'Select an Option',
          content:
            '<div id="options-container-holder"><loading-indicator loading id="modal-loader"></loading-indicator></div>',
        },
        {
          name: 'edit-text',
          label: 'Enter/Edit Text',
          content: await textContent.getHTML(),
        },
      ]);
      buttons = [
        {
          id: 'save-option-text',
          classname: 'btn btn-primary primary',
          label: 'Save',
        },
      ];
    } else if (
      obj.type === 'group' &&
      (obj.allowUploads || MatgenGlobal.AuthUser.getUserRole() === 'admin')
    ) {
      const objects = obj
        .getObjects()
        .map(
          o => `
        <li class="list-group-item list-group-item-action d-flex justify-content-between align-items-center component-edit-item">
          ${o.text}
          <a class="edit-component-text" data-id="${o.id}" data-component-id="${o.componentId}" href="#" data-bs-toggle="tooltip" data-placement="auto" title="Edit text">
            <i class="fas fa-xs fa-edit"></i>
          </a>
        </li>
      `
        )
        .join('');
      content = buildTabs([
        {
          name: 'select-group',
          label: 'Select an Option',
          content:
            '<div id="options-container-holder"><loading-indicator loading id="modal-loader"></loading-indicator></div>',
        },
        {
          name: 'edit-group',
          label: 'Edit Text',
          content: `<ul class="list-group">${objects}</ul>`,
        },
      ]);
      /*buttons = [
        {
          id: 'save-option-text',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ];*/
      buttons = [];
    } else {
      content =
        '<div id="options-container-holder"><loading-indicator loading id="modal-loader"></loading-indicator></div>';
      buttons = [];
    }

    MatgenGlobal.M4CModal.show({
      id,
      title,
      content,
      buttons,
      classes: 'm4c-matgen',
      width: '90vw',
    });
    MatgenGlobal.UI.stopLoading();
    if (
      obj.type === 'textbox' &&
      (obj.allowUploads || MatgenGlobal.AuthUser.getUserRole() === 'admin')
    ) {
      const objects = MatgenGlobal.editor.cur().fabric.getObjects();
      const curObj = findObjectById(objects, $('#text-edit-id').val());

      $('#inputText').val(curObj.text);
      $('#font-picker')
        .val(curObj.fontspec)
        .trigger('change');
      $('#inputFontSize').val(
        Math.round(curObj.fontSize * MatgenGlobal.editor.cur().scalingFactor)
      );
      $('#inputColor').val(curObj.fill);
      $(`input[name=textAlign][value=${curObj.textAlign}]`).attr(
        'checked',
        'checked'
      );

      UI.initFormPickers(true, curObj, '#options-modal');

      UI.initTextFormSubmit(
        id,
        () => {
          MatgenGlobal.sidebar.markComponentDirty(curObj.componentId);
          //MatgenGlobal.sidebar.markTemplateDirty()
        },
        '#save-option-text'
      );
    } else if (
      obj.type === 'image' &&
      (obj.allowUploads || MatgenGlobal.AuthUser.getUserRole() === 'admin')
    ) {
      UI.initUploader(id, obj);

      const uploader = new Uploader();

      const dropArea = $(`#drop-area-matgen`)[0];

      ['dragenter', 'dragover', 'dragleave'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false);
      });

      $(`#drop-area-matgen`).on('drop', e => {
        e.preventDefault();
        e.stopPropagation();
        const dt = e.originalEvent.dataTransfer;
        const file = dt.files[0];
        editUpload(file, uploader, 'matgen', obj);
      });

      $(`#matgen-uploader-link`).on('click', e => {
        e.preventDefault();
        uploader.fileSelect(id, () => {
          const file = document.getElementById(id).files[0];
          editUpload(file, uploader, 'matgen', obj);
        });
      });

      $('#save-option-upload').off('click');
      $('#save-option-upload').on('click', async () => {
        if (
          !$('#uploaded-image-durl').val() ||
          $('#uploaded-image-durl').val() === ''
        ) {
          UI.handleError(
            'Upload selection required',
            'You must select an image to upload.'
          );
          return false;
        }
        if ($('#image-upload-form')[0].checkValidity()) {
          const obj = MatgenGlobal.editor
            .cur()
            .fabric.getObjects()
            .find(o => o.id === $('#uploaded-image-obj-id').val());
          obj.altText = $('#alt').val();

          UI.replaceCanvasImage(obj, $('#uploaded-image-durl').val());

          $(`#${id}`).modal('hide');

          if (MatgenGlobal.AuthUser.getUserRole() === 'admin') {
            const oldId = obj.id;
            const newId = UUID();
            obj.set('id', newId);

            obj.set('currentOptionId', newId);
            MatgenGlobal.editor.cur().fabric.renderAll();
            MatgenGlobal.UI.loading('Uploading and saving option...');
            let openItems = sessionStorage.getItem('matgen-tree-state');
            if (!openItems) {
              openItems = [];
            } else {
              openItems = JSON.parse(openItems);
            }
            if (openItems.includes(oldId)) {
              openItems.splice(openItems.indexOf(oldId), 1);
              openItems.push(obj.id);
            }
            //console.log('save state:', openItems);
            sessionStorage.setItem(
              'matgen-tree-state',
              JSON.stringify(openItems)
            );
            await MatgenGlobal.UI.saveOption(obj);

            MatgenGlobal.UI.stopLoading();
          } else {
            if (!MatgenGlobal.SuppressSidebarActions) {
              MatgenGlobal.sidebar.markTemplateDirty();
            }
          }
        } else {
          $('#image-upload-form')[0].reportValidity();
        }
      });
    } else if (
      obj.type === 'group' &&
      (obj.allowUploads || MatgenGlobal.AuthUser.getUserRole() === 'admin')
    ) {
      $(document).off('click', '.component-edit-item');
      $(document).on('click', '.component-edit-item', e => {
        e.preventDefault();
        $(e.target)
          .find('.edit-component-text')
          .trigger('click');
      });

      $(document).off('click', '.edit-component-text');
      $(document).on('click', '.edit-component-text', e => {
        e.preventDefault();
        //e.stopPropagation();
        UI.adminTextForm({
          edit: true,
          id: $(e.currentTarget).attr('data-id'),
          componentId: $(e.currentTarget).attr('data-component-id'),
          cb: () => MatgenGlobal.sidebar.markTemplateDirty(),
          target: '#m4c-wrapper',
        });
      });
    }

    if (options) {
      const component = await MatgenGlobal.Data.getComponent(componentId);
      if (Array.isArray(options)) {
        UI.loading('Loading options...');
        MatgenGlobal.stopLoading = true;
        let opts;
        try {
          opts = await Promise.all(
            options.map(async option => {
              const url = await MatgenGlobal.Data.getOptionPreviewURL(
                option.id
              );
              const enabled = option.enabled === 0 ? false : true;
              return { id: option.id, componentId, url, enabled };
            })
          );
        } catch (e) {
          console.error(e);
        }
        MatgenGlobal.UI.stopLoading();
        //console.log(component);
        const optionsDisplay = new OptionsModal(
          opts,
          component.default_option_id
        );
        $(`#options-container-holder`)
          .empty()
          .append(optionsDisplay.markup)
          .addClass('m4c-matgen');

        $(`
          <div id="options-bg">
            Having trouble seeing? Select a different background to preview options on: &nbsp;&nbsp;
            <span class="checkered active">&nbsp;</span>
            <span class="checkered-dark">&nbsp;</span>
            <span style="ackground: transparent;">&nbsp;</span>
            <span style="background:#EDEDED;">&nbsp;</span>
            <span style="background:#555555;">&nbsp;</span>
          </div>
        `).insertAfter('#options-modal-label');

        const previews = [];

        $('.option-wrapper .img-fluid').each((i, el) => {
          if (obj.type === 'textbox' || obj.type === 'group') {
            $(el).css('filter', 'brightness(0)');
          }
          try {
            const img = UI.loadImage(el);
            previews.push(img);
          } catch (e) {
            console.error(`Broken S3 URL: ${$(el).attr('data-src')}`);
          }
        });

        try {
          await Promise.all(previews);
        } catch (e) {
          console.error(e);
        }
        MatgenGlobal.UI.stopLoading();
        $(`#${id}`).on('hidden.bs.modal', () => {
          //console.error('hidden.bs.modal');
          $(`#${id}`).remove();
        });

        $(`#${id}`).on('shown.bs.modal', () => {
          UI.initTooltips();

          if (typeof bootstrap !== 'undefined') {
            const triggerTabList = [].slice.call(
              document.querySelectorAll('#modal-tabs button')
            );
            triggerTabList.forEach(triggerEl => {
              const tabTrigger = new bootstrap.Tab(triggerEl);

              triggerEl.addEventListener('click', event => {
                event.preventDefault();
                tabTrigger.show();
              });
            });
          }

          UI.stopLoading();
        });

        $('.option-action').off('click');
        $('.option-wrapper').off('click');
        $('.option-action').on('click', async e => {
          e.preventDefault();
          let action = $(e.target)
            .closest('a')
            .attr('data-original-title');
          if (!action) {
            action = $(e.target)
              .closest('a')
              .attr('title');
          }
          const componentId = $(e.target)
            .closest('.option-actions')
            .prev()
            .attr('data-component-id');

          const optionId = $(e.target)
            .closest('.option-actions')
            .prev()
            .attr('data-option-id');
          console.log('OPTION ACTION:', optionId, componentId, action);
          /*const component = await MatgenGlobal.Data.apiGet(
              `${API_URL}/public/components/${componentId}`
            );*/

          //let response;
          let res;
          switch (action) {
            default:
              break;
            case 'Delete option':
              console.log('DELETE OPTION');
              UI.loading('Deleting option...', '#options-modal');
              await UI.deleteOption(optionId, componentId);
              if (MatgenGlobal.sidebar) {
                MatgenGlobal.sidebar.refresh(
                  MatgenGlobal.sidebar,
                  null,
                  'delete-option',
                  false
                );
              }
              break;
            case 'Disable option':
              UI.loading('Disabling option...', '#options-modal');
              res = await MatgenGlobal.Data.saveOptionData(
                {
                  id: optionId,
                  enabled: false,
                },
                true
              );
              if (res === false) {
                UI.handleError(
                  'Server Error',
                  'There was a problem disabling the option.'
                );
                return false;
              }

              console.log(res);
              UI.stopLoading();
              if (MatgenGlobal.sidebar) {
                MatgenGlobal.sidebar.refresh(
                  MatgenGlobal.sidebar,
                  null,
                  'disable-option',
                  false
                );
              }
              break;
            case 'Enable option':
              UI.loading('Enabling option...', '#options-modal');
              res = await MatgenGlobal.Data.saveOptionData(
                {
                  id: optionId,
                  enabled: true,
                },
                true
              );
              if (res === false) {
                UI.handleError(
                  'Server Error',
                  'There was a problem enabling the option.'
                );
                return false;
              }

              console.log(res);
              UI.stopLoading();
              if (MatgenGlobal.sidebar) {
                MatgenGlobal.sidebar.refresh(
                  MatgenGlobal.sidebar,
                  null,
                  'enable-option',
                  false
                );
              }
              break;
            case 'Set as default':
              UI.loading('Setting default option...', '#options-modal');
              res = await MatgenGlobal.Data.saveComponent(
                {
                  id: componentId,
                  default_option_id: optionId,
                },
                true
              );
              UI.stopLoading();
              break;
          }
          $(`#${id}`).modal('hide');
        });

        $('.option-wrapper').on('click keypress', e => {
          if (MatgenGlobal.UI.a11yClick(e)) {
            e.preventDefault();
            //MatgenGlobal.ignoreDirty = true;
            //MatgenGlobal.UI.loading('Setting component option...');
            let curObj = MatgenGlobal.editor
              .cur()
              .fabric.getObjects()
              .filter(
                o => o.componentId === e.currentTarget.dataset.componentId
              );
            const groupNames = [];
            if (curObj.length === 0) {
              throw new Error('No object found');
            } else if (curObj.length === 1) {
              curObj = curObj[0];
            } else {
              curObj.forEach(o => {
                groupNames.push({
                  componentName: o.componentName,
                  name: o.name,
                });
              });
            }
            MatgenGlobal.editor.setComponentOption(
              e.currentTarget.dataset.optionId,
              e.currentTarget.dataset.componentId,
              curObj.name,
              groupNames
            );

            //console.log(MatgenGlobal.AuthUser.getUserRole());
            if (MatgenGlobal.AuthUser.getUserRole() === 'user') {
              window.setTimeout(() => {
                MatgenGlobal.sidebar.markTemplateDirty();
              }, 350);
            }

            $(`#${id}`).modal('hide');
            //MatgenGlobal.UI.stopLoading();
          }
        });
        //$(`#${id}`).modal('toggle');
        delete MatgenGlobal.stopLoading;
      } else {
        console.error('Bad component data');
      }
    }
  }

  static handleError(title, message, action) {
    UI.alertModal(
      title,
      UI.bootstrapAlertHTML(
        'danger',
        MatgenGlobal.errorIcon
          ? MatgenGlobal.errorIcon
          : 'fa-duotone fa-circle-exclamation',
        message
      ),
      action
    );
    UI.stopLoading();
  }

  static promptModal(
    title,
    label,
    action,
    buttonText = 'Save',
    listeners = () => {},
    validations = [],
    value = '',
    width = false
  ) {
    MatgenUIFunctions.modalForm({
      prefix: 'prompt',
      inputs: [
        new TextInput({
          type: 'text',
          label,
          id: 'inputPrompt',
          required: true,
          autofocus: true,
          value,
        }),
      ],
      title,
      options: { inline: false },
      actions: [
        {
          id: 'prompt-form-submit',
          classname: 'primary btn btn-primary',
          label: buttonText,
        },
      ],
      listeners: () => {
        listeners();
        $(document).off('click', '#prompt-form-submit');
        $(document).on('click', '#prompt-form-submit', () => {
          $('#prompt-form').submit();
        });

        $(document).off('submit', '#prompt-form');
        $(document).on('submit', '#prompt-form', async e => {
          e.preventDefault();
          let extrasValid = true;
          if (validations && typeof validations === 'function') {
            extrasValid = validations($('#inputPrompt').val());
          }

          if (extrasValid && $('#prompt-form')[0].checkValidity()) {
            try {
              if (action && typeof action === 'function') {
                action($('#inputPrompt').val());
              }
            } catch (e) {
              console.error(e);
            }

            $(`#prompt-form-modal`).modal('hide');
          } else {
            $(`#prompt-form`)[0].reportValidity();
          }
        });
      },
      width,
    });
  }

  static bootstrapAlertHTML(
    type = 'info',
    icon = 'fa-duotone fa-circle-info',
    content
  ) {
    return `
    <div class="alert alert-${type} d-flex align-items-center" role="alert">
      <div class="alert-modal-icon">
        <i class="${icon} fa-2x"></i>
      </div>
      <div class="alert-modal-content">
        ${content}
      </div>
    </div>
    `;
  }

  static alertModal(
    title,
    content,
    action,
    classes = '',
    modalId = 'alert-modal',
    buttons = []
    //alertClass = 'info'
  ) {
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      //content: `<div class="alert alert-${alertClass}" role="alert">${content}</div>`,
      header: false,
      footer: false,
      content,
      buttons,
      classes,
    });
  }

  static confirm(title, content, yes, no, cb, modalId = 'confirm-modal') {
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title,
      content,
      buttons: [
        {
          id: `${modalId}-no-button`,
          classname: 'secondary btn btn-secondary',
          label: no,
        },
        {
          id: `${modalId}-yes-button`,
          classname: 'primary btn btn-primary',
          label: yes,
        },
      ],
      closeButton: false,
    });

    $(`#${modalId}-no-button`).off('click');
    $(`#${modalId}-no-button`).on('click', () => {
      $(`#${modalId}`).modal('toggle');
      if (cb && typeof cb === 'function') {
        cb();
      }
    });

    $(`#${modalId}-yes-button`).off('click');
    $(`#${modalId}-yes-button`).on('click', () => {
      $(`#${modalId}`).modal('toggle');
      if (cb && typeof cb === 'function') {
        cb();
      }
    });
    $(`#${modalId}`).on('hide.bs.modal', () => {
      if (cb && typeof cb === 'function') {
        cb();
      }
    });
  }

  static imgSelect(cb) {
    const modalId = 'image-select-modal';
    const buttons = `
    <div class="text-center">
      <input class="btn btn-primary image-select" type="button" value="JPEG">
      <input class="btn btn-primary image-select" type="submit" value="PNG">
    </div>
    `;

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Select Image Type',
      content: buttons,
      buttons: [],
      closeButton: false,
    });
    MatgenGlobal.UI.stopLoading();
    $('.image-select').off('click');
    $('.image-select').on('click', e => {
      if (cb && typeof cb === 'function') {
        cb($(e.target).val());
      }
    });
  }

  static fontVariants(content, cb) {
    const modalId = 'font-variants-modal';
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Select Font Variants',
      content,
      buttons: [
        {
          id: 'font-variants-cancel',
          classname: 'secondary',
          label: 'Cancel',
        },
        {
          id: 'font-variants-confirm',
          classname: 'primary btn btn-primary',
          label: 'Add Font & Variants',
        },
      ],
    });

    $('#font-variants-cancel').off('click');
    $('#font-variants-cancel').on('click', () => {
      $(`#font-variants-modal`).modal('toggle');
    });

    $('#font-variants-confirm').off('click');
    $('#font-variants-confirm').on('click', () => {
      if (cb && typeof cb === 'function') {
        const selected = $(
          '#font-variants-modal .checkbox input:checkbox:checked'
        );
        if (selected.length === 0) {
          alert('You must select at least 1 font variant');
          return false;
        }
        cb($.map(selected, s => $(s).attr('id')));
      }
      $(`#font-variants-modal`).modal('toggle');
    });
  }

  static async selectQuestionnaire(tenant_id, type) {
    try {
      const questionnaires = await MatgenGlobal.Data.getQuestionnaires(
        tenant_id
      );
      console.log(questionnaires);
      //const tenant = await MatgenGlobal.Data.getTenant(template.tenant_id);
      const q = questionnaires.find(q => q.type === type && q.active === 1);
      const questionnaire = await MatgenGlobal.Data.getQuestionnaire(q.id);
      return questionnaire;
    } catch (e) {
      console.error(e);
      return false;
    }
  }

  static async questionnaire() {
    const urlParams = new URLSearchParams(window.location.search);
    const tenant_id = urlParams.get('tenant_id');
    if (!tenant_id) {
      console.error('No tenant_id in query');
      return false;
    }
    const questionnaire = await UI.selectQuestionnaire(tenant_id, 'material');
    console.log(questionnaire);
  }

  static taggerContainer(content) {
    return `
    <div id="tagger-container" class="container-fluid">
      <div class="container logic-tagging__container">
        <div class="col">
          <div class="logic-tagging__header">
            <h4 class="logic-tagging__header__heading color--gray-a">Choose what attributes relate to your material</h4>
            <div>
              <button class="btn btn-secondary" id="cancel-tags">Back</button>
              <button class="btn btn-primary" id="save-tags">Save</button>
            </div>
          </div>

          <div class="row">
            <div class="logic-tagging__content">
              <div id="preview-col" class="logic-tagging__material-img col-lg-3">
              </div>

              <div class="logic-tagging__options-container col-lg-9">
                ${content}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    `;
  }

  static taggerItem(name, question) {
    return `
    <div class="logic-tagging__card card--with-header">
      <div class="logic-tagging__card__header bg-primary">
        <h6 class="logic-tagging__card__heading text-white">${name}</h6>
        <div class="logic-tagging__card__help-text">Select all that apply</div>
      </div>
      ${UI.loadQuestion(question)}
    </div>
    `;
  }

  static findById(objects, id) {
    for (let i = 0; i < objects.length; i++) {
      if (objects[i].id === id) {
        return objects[i];
      } else if (objects[i].getObjects && objects[i].getObjects().length) {
        return UI.findById(objects[i].getObjects(), id);
      } else if (objects[i].objects && objects[i].objects.length) {
        return UI.findById(objects[i].objects, id);
      }
    }
  }

  static handle508(curObj) {
    if (curObj.type === 'textbox') {
      UI.text508Form(curObj.id);
    } else if (curObj.type === 'image') {
      UI.image508Form(curObj.id);
    } else if (curObj.type === 'group') {
      UI.group508Form(curObj);
    } else {
      UI.handleError('Unhandled Object', `Unknown object type: ${curObj.type}`);
    }
  }

  static async loadTagger(req) {
    $(MatgenGlobal.ControllerTargetSelector)
      .empty()
      .show();
    //$('#sidebar').remove();
    const id = req.param ? req.param.id : req;
    UI.loading('Loading questionnaire...');
    const template = await MatgenGlobal.Data.getTemplate(id);
    const pages = await MatgenGlobal.Data.getPages(id);
    const pageThumbs = pages.sort((a, b) => a.number - b.number).map(p => p.id);
    const questionnaires = await MatgenGlobal.Data.getQuestionnaires(
      template.tenant_id
    );
    //const tenant = await MatgenGlobal.Data.getTenant(template.tenant_id);
    const q = questionnaires.filter(
      q => q.type === 'material' && q.active === 1
    )[0];
    const questionnaire = await MatgenGlobal.Data.getQuestionnaire(q.id);
    const tags = await MatgenGlobal.Data.getTemplateTags(id);
    //console.log(tags);
    //console.log(questionnaire, tags, pageThumbs);
    //console.log(questionnaire);
    const sections = Object.keys(questionnaire).filter(k => k !== 'id');

    const items = [];

    sections.forEach(s => {
      if (questionnaire[s].questions) {
        questionnaire[s].questions.forEach(q => {
          items.push(UI.taggerItem(questionnaire[s].name, q));
        });
      }
    });
    //console.log(items);
    $(MatgenGlobal.ControllerTargetSelector).append(
      $(UI.taggerContainer(items.join('')))
    );

    if (['PDF', 'IMAGE'].includes(template.type)) {
      const ext = '.png';
      const url = await MatgenGlobal.Data.getTemplateFileURL(
        pageThumbs[0],
        template.tenant_id,
        ext
      );

      $('#preview-col').append(
        $(
          `<div class="preview-thumb" id="preview-thumb"><img id="page-thumb" src="${url}" alt="Preview of material">
            ${
              pageThumbs.length > 1
                ? `
              <div id="thumb-pager" class="text-center">
              ${pageThumbs
                .map(
                  (t, i) => `
                  ${i === 0 ? '' : ' - '}
                  <a href="#" data-id="${t}" data-tenant-id="${
                    template.tenant_id
                  }" data-index="${i}" class="thumb-page">${i + 1}</a>
                  `
                )
                .join('')}
              </div>
              `
                : ''
            }
            </div>`
        )
      );
    } else if (template.preview_type === 'IMAGE') {
      const url = MatgenGlobal.Data.getTemplateFileURL(
        template.id,
        template.tenant_id,
        template.preview_image_ext
      );
      $('#preview-col').append(
        $(
          `<div class="preview-thumb" id="preview-thumb"><img id="page-thumb" src="${url}" alt="Preview of material">
            </div>`
        )
      );
    } else if (template.preview_type === 'LINK') {
      $('#preview-col').append(
        $(
          `<div class="preview-thumb" id="preview-thumb">
              <a href="${template.preview_link}" target="_blank">Preview</a>
            </div>`
        )
      );
    } else if (template.preview_type === 'SELF') {
      const url = MatgenGlobal.Data.getTemplateFileURL(
        template.id,
        template.tenant_id,
        template.file_ext
      );
      $('#preview-col').append(
        $(
          `<div class="preview-thumb" id="preview-thumb">
              <a href="${url}" target="_blank">Preview</a>
            </div>`
        )
      );
    }

    $(document).off('click', '#save-tags');
    $(document).on('click', '#save-tags', async e => {
      e.preventDefault();
      UI.loading('Saving new tag state...');
      const answers = $(`[data-qid]`);
      const tags = [];
      answers.each((i, a) => {
        if ($(a).hasClass('--selected')) {
          tags.push({
            id: $(a).attr('data-id'),
            type: 'bool',
          });
        }
      });

      $('.range-slider').each((i, rs) => {
        const sliderVal = $(
          `#range-${$(rs).attr('data-id')}`
        )[0].noUiSlider.get();
        tags.push({
          id: $(rs).attr('data-id'),
          type: 'min',
          value: sliderVal[0],
        });
        tags.push({
          id: $(rs).attr('data-id'),
          type: 'max',
          value: sliderVal[1],
        });
      });

      const response = await MatgenGlobal.Data.saveTags({ tags, id });
      UI.stopLoading();
      console.log(response);
    });

    $(document).off('click', '#cancel-tags');
    $(document).on('click', '#cancel-tags', e => {
      e.preventDefault();
      //e.stopPropagation();
      window.history.back();
      //location.reload();
    });

    $(document).off('click', '.button--option');
    $(document).on('click', '.button--option', e => {
      /*if ($(e.target).attr('data-id') === 'any') {
          if (!$(e.target).hasClass('--selected')) {
            $(`[data-qid="${$(e.target).attr('data-qid')}"]`).each(
              (i, el) => {
                $(el).addClass('--selected');
              }
            );
          } else {
            $(`[data-qid="${$(e.target).attr('data-qid')}"]`).each(
              (i, el) => {
                $(el).removeClass('--selected');
              }
            );
          }
        } else {*/
      $(e.target)
        .toggleClass('--selected')
        .blur();
      //}
    });
    //console.log(noUiSlider);

    $('.range-slider').each((i, rs) => {
      //console.log($(rs).attr('data-id'));
      const id = $(rs).attr('data-id');
      const text = $(rs).attr('data-text');
      $(rs).append($(`<h5>${text}</h5>`));
      $(rs).append($(`<div id="range-${id}"></div>`));
      $(rs).append($(`<div class="form-error" id="${id}-error"></div>`));
      noUiSlider.create($(`#range-${id}`)[0], {
        start: [15, 90],
        connect: true,
        range: {
          min: 15,
          max: 90,
        },
        format: wNumb({
          decimals: 0,
        }),
        step: 5,
        tooltips: [
          wNumb({ decimals: 0, suffix: ' yrs' }),
          wNumb({ decimals: 0, suffix: '+ yrs' }),
        ],
      });

      $(`#range-${id}`)[0].noUiSlider.on('change', () => {
        console.log('SLIDER CHANGED');
      });
    });

    tags.forEach(t => {
      if (t.type === 'bool') {
        $(`.button--option[data-id="${t.answer_id}"]`).addClass('--selected');
      } else if (t.type === 'min') {
        $(`#range-${t.answer_id}`)[0].noUiSlider.set([t.value, null]);
      } else if (t.type === 'max') {
        $(`#range-${t.answer_id}`)[0].noUiSlider.set([null, t.value]);
      }
      /*if (
          $(
            `.button--option[data-qid="${$(
              `.button--option[data-id="${t.answer_id}"]`
            ).attr('data-qid')}"]`
          ).length -
            1 ===
          $(
            `.button--option[data-qid="${$(
              `.button--option[data-id="${t.answer_id}"]`
            ).attr('data-qid')}"].--selected`
          ).length
        ) {
          $(
            `.button--option[data-id="any"][data-qid="${$(
              `.button--option[data-id="${t.answer_id}"]`
            ).attr('data-qid')}"]`
          ).addClass('--selected');
        }*/
    });

    UI.stopLoading();
    //getTaggerMarkup(questionnaire, tags, pageThumbs, id);
  }

  static loadQuestion(q) {
    //console.log(q);
    switch (q.component) {
      default:
        break;
      case 'range':
        return `
        <div class="logic-tagging__card__content">
          <div class="logic-tagging__subcard__multirange">
            <div class="card card--basic card--has-title card--has-range">
              <div class="range-slider" data-text="${q.text}" data-id="${q.answers[0].id}"></div>
            </div>
          </div>
        </div>
        `;
      case 'select-multiple':
      case 'select-single':
        return `
          <div class="logic-tagging__card__content">
            <div class="card--multi-select">
              <div class="option-buttons">
                ${q.answers
                  .map(
                    a => `
                  <button class="button--option" data-qid="${q.id}" data-id="${a.id}">${a.text}</button>
                `
                  )
                  .join('')}
              </div>
            </div>
          </div>
        `;
    }
    return '';
  }
}

export { UI };
