const FabricIncludeProps = [
  'hasControls',
  'hasBorders',
  'lockRotation',
  'lockMovementX',
  'lockMovementY',
  'lockScalingX',
  'lockScalingY',
  'lockUniScaling',
  'hoverCursor',
  'editable',
  'enabled',
  'selectable',
  'id',
  'name',
  'componentName',
  'componentId',
  'selectedOptionId',
  'scaleX',
  'scaleY',
  'zoomX',
  'zoomY',
  'width',
  'height',
  'default',
  'userEditable',
  'materialDate',
  'uploader',
  'uploaderWidth',
  'uploaderHeight',
  'uploaderScaleX',
  'uploaderScaleY',
  'layerLocked',
  'layerVisible',
  'borderScaleFactor',
  'borderColor',
  'cornerColor',
  'fontspec',
  'groupId',
  'isParent',
  'componentRoot',
  'currentOptionId',
  'hasOptions',
  'sidebarIgnore',
  'fontType',
  'pdfTag',
  'altText',
  'link',
  'readOrder',
  'componentReadOrder',
  'componentPdfTag',
  'allowUploads',
  'studyDataConnection',
  'studyid',
  'studyDataOverride',
  'studyDataUpdate',
  'themeColor',
  'useThemeColor',
  'hasRotatingPoint',
];

const FabricObjectDefaults = {
  scaleX: 1,
  scaleY: 1,
  hasControls: true,
  hasBorders: true,
  lockRotation: true,
  hasRotatingPoint: false,
  lockMovementX: false,
  lockMovementY: false,
  lockScalingX: false,
  lockScalingY: false,
  lockUniScaling: false,
  hoverCursor: 'pointer',
  selectable: true,
  layerLocked: false,
  layerVisible: true,
  transparentCorners: false,
  //minScaleLimit: 2,
  cornerStyle: 'circle',
  minScaleLimit: 0,
  lockScalingFlip: true,
  padding: 0,
  selectionDashArray: [10, 5],
  borderDashArray: [10, 5],
  studyDataConnection: '',
  studyDataOverride: false,
  studyDataUpdate: false,
};

const ZoomSettings = {
  MAX_ZOOM: 2,
  MIN_ZOOM: 0.1,
  SCALE_FACTOR: 1.3,
};

export { FabricIncludeProps, FabricObjectDefaults, ZoomSettings };
