/* global bootstrap */

import { MatgenData } from './api/matgen-data.js';
import { UI } from './implementation/ui/ui.js';
import { AuthUser } from './util/AuthUser.js';
//import { Router } from './implementation/router.js';
import { v4 as UUID } from 'uuid';
import { Router } from './implementation/router-navigo.js';
import { emit } from './util/helpers.js';
//import './util/loading-indicator/loading-indicator.js';
import { M4CModal } from './implementation/components/M4CModal.js';
import MatgenUIFunctions from './implementation/ui/ui-functions.js';

import { init as commonInit } from './implementation/events/common-events.js';
import { init as editorInit } from './implementation/events/editor-events.js';

import * as Tables from './implementation/tables';

const MatgenGlobal = (window.MatgenGlobal = {});

MatgenGlobal.emit = emit;
MatgenGlobal.UI = UI;
MatgenGlobal.M4CModal = M4CModal;
MatgenGlobal.Router = new Router();
MatgenGlobal.Data = new MatgenData();
MatgenGlobal.UUID = UUID;
MatgenGlobal.Tables = Tables;
MatgenGlobal.MatgenUIFunctions = MatgenUIFunctions;
MatgenGlobal.MatgenUIFunctions = MatgenUIFunctions;
MatgenGlobal.AuthUserModule = AuthUser;
MatgenGlobal.CurrentAPIRequests = [];
MatgenGlobal.CurrentS3Requests = [];
MatgenGlobal.DynamicForms = {};

MatgenGlobal.CommonInit = commonInit;
MatgenGlobal.EditorInit = editorInit;

MatgenGlobal.hideTooltips = () => {
  const tooltipTriggerList = document.querySelectorAll(
    '[data-bs-toggle="tooltip"]'
  );
  [...tooltipTriggerList].map(tooltipTriggerEl => {
    const tooltip = bootstrap.Tooltip.getInstance(tooltipTriggerEl);
    if (tooltip) {
      tooltip.hide();
    }
  });
};

MatgenGlobal.init = async ({
  token = false,
  identity_id = false,
  containerId = 'matgen-container',
  //editorTargetSelector = '#matgen-editor',
  controllerTargetSelector = '#matgen-controller',
  sidebarTargetSelector = 'main',
  rootPage = 'index.html',
  gridColor = '#aaa',
  borderScale = 4,
  borderColor = '#FB1330',
  borderColorDarkened = '#c71f1f',
  editingBorderColor = '#00a9ff',
  scalingTargetId = 'matgen-scale-container-init-default',
  expiresIn = 60,
} = {}) => {
  MatgenGlobal.UI.loading('Loading materials generator...');
  MatgenGlobal.ContainerId = containerId;
  //MatgenGlobal.EditorTargetSelector = editorTargetSelector;
  MatgenGlobal.ControllerTargetSelector = controllerTargetSelector;
  MatgenGlobal.SidebarTargetSelector = sidebarTargetSelector;
  MatgenGlobal.ScalingTargetId = scalingTargetId;
  if (!MatgenGlobal.RootPage) {
    MatgenGlobal.RootPage = rootPage;
  }
  MatgenGlobal.GridColor = gridColor;
  MatgenGlobal.BorderScale = borderScale;
  MatgenGlobal.BorderColor = borderColor;
  MatgenGlobal.BorderColorDarkened = borderColorDarkened;
  MatgenGlobal.EditingBorderColor = editingBorderColor;
  MatgenGlobal.ExpiresIn = expiresIn;
  MatgenGlobal.StateId = 0;
  MatgenGlobal.PreviousStateId = 0;

  //console.log('authorizing user');
  //console.log('token::', token);
  //console.log('identity id::', identity_id);
  MatgenGlobal.AuthUser = await new AuthUser(token, identity_id);

  MatgenGlobal.CommonInit();
  MatgenGlobal.EditorInit();

  emit({
    event: 'matgen-ready',
  });
  //MatgenGlobal.UI.stopLoading();
};
