/* global MatgenGlobal */

import { Table } from '../components/Table.js';

const MicrositeActions = item => [
  {
    action: 'edit',
    title: 'Edit Microsite',
    icon: 'fas fa-paint-brush',
    color: 'primary',
    role: 'user',
  },
  {
    action: 'toggle-publish',
    title: item.published === 1 ? 'Unpublish' : 'Publish',
    icon: item.published === 1 ? 'fas fa-eye-slash' : 'fas fa-eye',
    color: item.published === 1 ? 'primary' : 'warning',
    role: 'admin',
  },
  {
    action: 'delete',
    title: 'Delete Microsite',
    icon: 'fas fa-trash',
    color: 'danger',
  },
];
const MicrositeColumns = [
  { data: 'id', title: 'ID', visible: false },
  {
    data: 'subdomain',
    title: 'Subdomain',
    render: data =>
      `<a href="https://${data}.${MatgenGlobal.MICROSITE_DOMAIN}" target="_blank">${data}.${MatgenGlobal.MICROSITE_DOMAIN}</a>`,
  },
  { data: 'user_name', title: 'User Name', visible: false },
  { data: 'created', title: 'Created', visible: false },
  //{ data: 'tag_count', title: 'Tags' },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item =>
      Table.actionsHTML(item, 'microsite', MicrositeActions(item), 'study_id'),
  },
];

const MicrositeTable = new Table({
  columns: MicrositeColumns,
  table: 'microsites',
  title: 'My Websites',
  tableSelector: '#microsites-table',
  callbacks: {
    /*createdRow: (row, data) => {
      if (data.type === 'FILE') {
        $(row).addClass('file-type');
      }
    },*/
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
});

export { MicrositeTable };
