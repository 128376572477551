import {
  Form,
  TextInput,
  TextAreaInput,
  //CheckboxInput,
} from '../components/form/index.js';
//import { emit } from '../../util/helpers.js';

const AnswerInputs = [
  /*new TextInput({
    type: 'text',
    label: 'Question ID',
    id: 'inputQuestionID',
    classes: 'top',
  }),*/
  new TextAreaInput({
    label: 'Answer Text',
    id: 'inputText',
    classes: 'top',
  }),
  new TextInput({
    type: 'subtext',
    label: 'Subtext',
    id: 'inputSubtext',
    classes: 'middle',
  }),
  `
  <div id="icon-preview">
    <div id="answer-icon"><i class="fa-duotone fa-circle-question fa-2xl"></i></div>
    <button id="toggle-icon-chooser" type="button" class="btn btn-secondary">Choose Icon</button>
  </div>
  `,
  `
  <input type="hidden" id="question-id" name="question-id"/>
  `,
  /*new TextInput({
    type: 'number',
    label: 'Sort order',
    id: 'inputSortOrder',
    classes: 'bottom',
  }),*/
];

const AnswerForm = new Form({
  inputs: AnswerInputs,
  id: 'answer-form',
  title: 'Create/Edit Answer',
});

export { AnswerForm };
