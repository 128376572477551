/* global MatgenGlobal, $ bootstrap */

import { SidebarHeader } from '../SidebarHeader.js';

const init = () => {
  $(document).off('click', '#grid-submit');
  $(document).on('click', '#grid-submit', () => {
    $('#grid-form').submit();
  });

  $(document).off('click', '#theme-color-submit');
  $(document).on('click', '#theme-color-submit', () => {
    $('#theme-color-form').submit();
  });

  $(document).off('click', '#template-add-item');
  $(document).on('click', '#template-add-item', e => {
    e.preventDefault();
    const cards = [
      {
        id: 'image',
        title: 'Image',
        icon: '<i class="fa-solid fa-image fa-2x"></i>',
        text: 'Select this option to add an image element.',
      },
      {
        id: 'text',
        title: 'Text',
        icon: '<i class="fa-solid fa-text fa-2x"></i>',
        text: 'Select this option to add a text element.',
      },
      {
        id: 'shape',
        title: 'Shape',
        icon: '<i class="fa-solid fa-shapes fa-2x"></i>',
        text: 'Select this option to add a shape element.',
      },
      /*{
        id: 'rich-text',
        title: 'Rich Text',
        icon: '<i class="fa-solid fa-code fa-2x"></i>',
        text: 'Select this option to add a rich text element.',
      },*/
    ];
    SidebarHeader.cardsModal('add-item', 'Add item', 'Select item type', cards);
  });

  $(document).off('click', '#image');
  $(document).on('click', '#image', e => {
    e.preventDefault();
    MatgenGlobal.UI.upload(() => {
      if (document.getElementById('file-input').files[0]) {
        MatgenGlobal.editor
          .cur()
          .addImg(
            document.getElementById('file-input').files[0],
            MatgenGlobal.editor.cur().fabric.getActiveObject()
          );
      }
      document
        .querySelector('body')
        .removeChild(document.getElementById('file-input'));
      $('#add-item-modal').modal('hide');
    });
  });

  $(document).off('click', '#text');
  $(document).on('click', '#text', e => {
    e.preventDefault();
    MatgenGlobal.UI.textForm();
    $('#add-item-modal').modal('hide');
  });

  $(document).off('click', '#rich-text');
  $(document).on('click', '#rich-text', e => {
    e.preventDefault();
    SidebarHeader.richTextModal();
    $('#add-item-modal').modal('hide');
  });

  $(document).off('click', '#shape');
  $(document).on('click', '#shape', e => {
    e.preventDefault();
    /*bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-item-modal')
    ).hide();*/
    SidebarHeader.shapesModal();
  });

  $(document).off('click', '#circle');
  $(document).on('click', '#circle', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addCircle({
      radius: 150,
      fill: MatgenGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: MatgenGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#rectangle');
  $(document).on('click', '#rectangle', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addRectangle({
      width: 150,
      height: 150,
      fill: MatgenGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: MatgenGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#triangle');
  $(document).on('click', '#triangle', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addTriangle({
      width: 150,
      height: 150,
      fill: MatgenGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: MatgenGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#ellipse');
  $(document).on('click', '#ellipse', e => {
    e.preventDefault();
    MatgenGlobal.editor.cur().addEllipse({
      rx: 150,
      ry: 75,
      fill: MatgenGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: MatgenGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      top: 0,
      left: 0,
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
    });
    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#polygon');
  $(document).on('click', '#polygon', e => {
    e.preventDefault();

    MatgenGlobal.editor.loadDrawingCanvas({
      fill: MatgenGlobal.pickr.shape
        .getColor()
        .toHEXA()
        .toString(),
      strokeWidth: parseInt($('#inputShapeStrokeWidth').val()),
      stroke: MatgenGlobal.pickr.shapeStroke
        .getColor()
        .toHEXA()
        .toString(),
      useThemeColor: $('#inputUseThemeColorShape').is(':checked'),
    });

    bootstrap.Modal.getOrCreateInstance(
      document.getElementById('add-shape-modal')
    ).hide();
  });

  $(document).off('click', '#cancel-drawing');
  $(document).on('click', '#cancel-drawing', e => {
    e.preventDefault();
    $('#drawing-options-div').replaceWith(MatgenGlobal.sidebarAddButton);
    $('#drawing-canvas-container').remove();
  });

  $(document).off('click', '#add-shapes');
  $(document).on('click', '#add-shapes', e => {
    e.preventDefault();
    const objects = MatgenGlobal.drawingCanvas.canvas.getObjects();
    for (let i = 0; i < objects.length; i++) {
      objects[i].clone(clone => {
        MatgenGlobal.editor.cur().fabric.add(clone);
      });
    }
    $('#drawing-options-div').replaceWith(MatgenGlobal.sidebarAddButton);
    $('#drawing-canvas-container').remove();
  });

  $(document).off('click', '#template-508');
  $(document).on('click', '#template-508', e => {
    e.preventDefault();
    SidebarHeader.modal508($(e.currentTarget).attr('data-id'));
  });

  $(document).off('click', '.matgen-help');
  $(document).on('click', '.matgen-help', e => {
    e.preventDefault();
    let id = $(e.currentTarget).attr('id');
    if (!id) {
      id = 'help-basics';
    }
    SidebarHeader.helpPage(id);
  });

  $(document).off('click', '.drop-delete');
  $(document).on('click', '.drop-delete', async e => {
    e.stopPropagation();
    e.preventDefault();
    await MatgenGlobal.Data.deleteTemplatePage(
      $(e.currentTarget)
        .parent()
        .attr('data-id')
    );
    MatgenGlobal.sidebar.refresh(MatgenGlobal.sidebar, null, 'drop-delete');
  });

  $(document).off('click', '#show-grid');
  $(document).on('click', '#show-grid', () => {
    MatgenGlobal.UI.showGridForm();
  });

  $(document).off('click', '#theme-color');
  $(document).on('click', '#theme-color', () => {
    MatgenGlobal.UI.showThemeColorForm();
  });

  $(document).off('click', '#inputUseThemeColorShape');
  $(document).on('click', '#inputUseThemeColorShape', e => {
    if ($(e.target).is(':checked')) {
      if (!MatgenGlobal.editor.cur().fabric.themeColor) {
        MatgenGlobal.UI.handleError(
          'Theme color not set',
          '<p>You must set a default theme color before applying it.</p>'
        );
      } else {
        MatgenGlobal.pickr.shape.setColor(
          MatgenGlobal.editor.cur().fabric.themeColor
        );
      }
    }
  });

  $(document).off('click', '#inputUseThemeColorText');
  $(document).on('click', '#inputUseThemeColorText', e => {
    if ($(e.target).is(':checked')) {
      if (!MatgenGlobal.editor.cur().fabric.themeColor) {
        MatgenGlobal.UI.handleError(
          'Theme color not set',
          '<p>You must set a default theme color before applying it.</p>'
        );
      } else {
        MatgenGlobal.pickr.text2.setColor(
          MatgenGlobal.editor.cur().fabric.themeColor
        );
      }
    }
  });
};

export { init };
