class Input {
  constructor({
    type,
    label,
    id,
    dataId,
    name = false,
    classes,
    required = false,
    autofocus = false,
    value = false,
    disabled = false,
    helpText = false,
  } = {}) {
    (this.dataId = dataId), (this.type = type);
    this.label = label;
    this.id = id;
    this.name = name ? name : id;
    this.classes = classes;
    this.required = required;
    this.autofocus = autofocus;
    this.value = value;
    this.disabled = disabled;
    this.helpText = helpText;
  }
}

export { Input };
