import {
  Form,
  TextInput,
  //CheckboxInput,
  ButtonInput,
} from '../components/form/index.js';
//import { emit } from '../../util/helpers.js';

const UserInputs = [
  new TextInput({
    type: 'text',
    label: 'Email Address Begins With',
    id: 'inputEmail',
    classes: 'top',
    required: true,
    autofocus: true,
  }),
  new ButtonInput({
    type: 'submit',
    label: 'Find User',
    id: 'user-form-submit',
    classes: 'btn-lg btn-primary',
  }),
];

const UserForm = new Form({
  inputs: UserInputs,
  id: 'user-form',
  title: 'User Search',
});

export { UserForm };
