/* global $, MatgenGlobal */

import merge from 'deepmerge';
import { fabric } from 'fabric';
import { saveAs } from 'file-saver';
import JSZip from 'jszip';
import { v4 as UUID } from 'uuid';

//import { Modal } from '../components/Modal.js';
import * as MatgenForms from '../forms';
//import { TenantForm } from '../components/forms.js';
import { emit } from '../../util/helpers.js';

import { MatgenPDF } from '../components/MatgenPDF.js';

import { toggleIconChooser } from '../../util/icon-chooser.js';

//import { toggleIconChooser } from '../../util/icon-chooser.js';

import { Form, TextInput } from '../components/form/index.js';

import { MatgenEditor } from '../../core/matgen-editor.js';
import { Uploader } from '../components/Uploader.js';

/*export function getBase64Image(imgUrl) {
  return new Promise((resolve, reject) => {
    const img = new Image();
    img.src = imgUrl;
    img.setAttribute('crossOrigin', 'anonymous');

    img.onload = function() {
      const canvas = document.createElement('canvas');
      canvas.width = img.width;
      canvas.height = img.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(img, 0, 0);
      const dataURL = canvas.toDataURL('image/png');
      resolve(dataURL.replace(/^data:image\/(png|jpg);base64,/, ''));
    };
    img.onerror = function() {
      reject('The image could not be loaded.');
    };
  });
}

export function blobToBase64(blob) {
  return new Promise(resolve => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result);
    reader.readAsDataURL(blob);
  });
}*/
/*
function handlePDFError() {
  //console.error('handlePDFError');
  MatgenGlobal.UI.handleError(
    'PDF Generation Error',
    'There was an error generating the PDF. Missing or corrupted data in material files.'
  );
  MatgenGlobal.UI.stopLoading();
  return false;
}*/

export function initIconPicker() {
  MatgenGlobal.M4CModal.show({
    id: 'icon-picker-modal',
    title: 'Select Icon',
    content: '<div id="fa-icon-chooser-div"></div>',
    buttons: [
      {
        id: 'icon-picker-submit',
        classname: 'primary btn btn-primary',
        label: 'Select',
      },
    ],
  });
  toggleIconChooser();
  $(document).off('hide.bs.modal', `#icon-picker-modal`);
  $(document).on('hide.bs.modal', `#icon-picker-modal`, () => {
    toggleIconChooser();
  });
}

function replaceIds(
  json,
  { id, newTemplateId, pageIdMap, componentIdMap, optionIdMap }
) {
  let json2 = json;
  json2 = json2.replaceAll(id, newTemplateId);
  const pageKeys = Object.keys(pageIdMap);
  for (let j = 0; j < pageKeys.length; j++) {
    json2 = json2.replaceAll(pageKeys[j], pageIdMap[pageKeys[j]]);
  }
  const componentKeys = Object.keys(componentIdMap);

  for (let j = 0; j < componentKeys.length; j++) {
    json2 = json2.replaceAll(
      componentKeys[j],
      componentIdMap[componentKeys[j]]
    );
  }
  const optionKeys = Object.keys(optionIdMap);
  for (let j = 0; j < optionKeys.length; j++) {
    json2 = json2.replaceAll(optionKeys[j], optionIdMap[optionKeys[j]]);
  }
  return json2;
}

export default class MatgenUIFunctions {
  static async modalFormUI({
    title,
    top_content,
    bottom_content,
    inputs,
    buttons = [],
    idPrefix,
    width,
  } = {}) {
    const DynamicForm = await new MatgenForms.DynamicForm(
      inputs,
      [],
      `${idPrefix}-form`
    );
    const content = await DynamicForm.form.getElement();
    MatgenGlobal.M4CModal.show({
      id: `${idPrefix}-modal`,
      title,
      content: `
        ${top_content ? top_content : ''}
        ${content[0].outerHTML}
        ${bottom_content ? bottom_content : ''}
      `,
      buttons,
      width,
      closeText: 'Cancel',
    });
  }

  static async userModal(user) {
    const token = await MatgenGlobal.AuthUser.getSessionToken();
    const opts = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await MatgenGlobal.Amplify.API.get(
      'authenticated',
      `/users/${user.id}`,
      opts
    );
    const groups = response.groups.Groups.map(g => g.GroupName);
    const m = MatgenGlobal.UI.modal(
      'usermodal',
      'Edit User',
      `
      <label for="user-email" class="sr-only">Email address</label>
      <input
        type="email"
        id="user-email-input"
        name="user-email-input"
        class="form-control"
        placeholder="Email address"
        required
        autofocus
        ${user ? `value="${user.email}"` : ''}
      />
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="admin-role" ${
          groups.includes('admin') ? 'checked' : ''
        }>
        <label class="form-check-label" for="admin-role">
          Admin
        </label>
      </div>
      <div class="form-check">
        <input class="form-check-input" type="checkbox" value="" id="microsite-editor-role" ${
          groups.includes('NIA-Microsite-Editor-Group') ? 'checked' : ''
        }>
        <label class="form-check-label" for="microsite-editor-role">
          Microsite Editor
        </label>
      </div>
      <input type="hidden" id="user-id" value="${user.id}" />
      `,
      false,
      [
        {
          id: `usermodal-submit-button`,
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ]
    );
    $('body').append(m.markup);
    $(`#usermodal`).modal('show');

    $(`#usermodal-submit-button`).off('click');
    $(`#usermodal-submit-button`).on('click', async () => {
      const id = $('#user-id').val();
      const email = $('#user-email-input').val();
      const admin = $('#admin-role').is(':checked');
      const micrositeEditor = $('#microsite-editor-role').is(':checked');
      const data = {
        id,
        email,
        admin,
        micrositeEditor,
      };

      const opts = {
        body: data,
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      try {
        const response = await MatgenGlobal.Amplify.API.patch(
          'authenticated',
          `/users`,
          opts
        );
        console.log(response);
        MatgenGlobal.UI.alertModal('User Saved', `The user has been saved.`);
      } catch (e) {
        console.error(e);
        MatgenGlobal.UI.alertModal(
          'Error Saving User',
          `There was an error saving the user data.`
        );
      }
      $(`#usermodal`).modal('toggle');
    });
  }

  static async handleMaterialDownload(id) {
    MatgenGlobal.UI.loading('Loading material...');
    const material = await MatgenGlobal.Data.getMaterial(id);
    const template = await MatgenGlobal.Data.getTemplate(material.template_id);

    let non508 = false;

    if (template.type !== 'FILE') {
      const pages = await MatgenGlobal.Data.getPages(material.template_id);
      pages.sort((a, b) => a.number - b.number);
      const json = await MatgenGlobal.Data.getMaterialPageFile(id, pages[0].id);

      const studyid = json.studyid;
      if (studyid) {
        await MatgenGlobal.generator.getMicrositeData(studyid);
        let languageAnswerIndex = MatgenGlobal.MicrositeData[0].questions
          .find(q => q.text === 'Select a language')
          .answers.findIndex(a => a.userAnswer === true);
        if (languageAnswerIndex === -1) {
          languageAnswerIndex = 0;
        }
        non508 = [2, 4].includes(languageAnswerIndex);
      } else if (
        template.name.includes('(Chinese)') ||
        template.name.includes('(Hindi)')
      ) {
        non508 = true;
      }
    }

    await MatgenGlobal.MatgenUIFunctions.downloadMaterial(
      id,
      true,
      false,
      non508
    );
  }

  static async handleMaterialInstructionsDownload(id) {
    MatgenGlobal.UI.loading('Loading material...');
    const material = await MatgenGlobal.Data.getMaterial(id);
    const template = await MatgenGlobal.Data.getTemplate(material.template_id);

    const url = `https://${MatgenGlobal.AmplifyConfig.Storage.bucket}.s3.amazonaws.com/tenant/${template.tenant_id}/templates/${template.associated_file}`;
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('target', '_blank');
    link.download = template.associated_file;
    link.dispatchEvent(new MouseEvent('click'));
    MatgenGlobal.UI.stopLoading();
  }

  static async showLoginModal() {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Email',
          id: 'm4c-login-email',
          required: true,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Password',
          id: 'm4c-login-password',
          required: true,
        },
      },
    ];
    const DynamicForm = await new MatgenForms.DynamicForm(
      inputs,
      [],
      'm4c-login-form'
    );
    const content = await DynamicForm.form.getElement();
    MatgenGlobal.M4CModal.show({
      id: 'matgen-signin-modal',
      title: `Log In`,
      content: `
      <div class="row">
        <div class="col-sm-6">
          <p>
            <a id="matgen-forgot-password-link" href="#" class="link-primary">Forgot password</a>
          </p>
        </div>
        <div class="col-sm-6">
          <p style="text-align:right;">
            <a id="matgen-reset-password-link" href="#" class="link-primary">Enter confirmation code</a>
          </p>
        </div>
      </div>

      <!--<div class="row form-error"></div>-->

      <div class="row">
        ${content[0].outerHTML}
      </div>

      <div class="row">
        <div class="col-sm-6">
          <p style="margin-top:1rem;">
            <a id="matgen-resend-confirmation-link" href="#" class="link-primary"style="display:none;">Resend Email Verification</a>
          </p>
        </div>
        <div class="col-sm-6">
          <p style="text-align:right;margin-top:1rem;">
            <a id="matgen-login-signup-link" href="#" class="link-primary">
          Create Account</a>
          </p>
        </div>
      </div>

      <!--<div class="row">
        <div class="col-sm-12" style="margin-top:1rem;">
         <a id="matgen-login-signup-link" href="#" class="link-primary">
       Create Account</a>
        </a>
        </div>
      </div>-->
      `,
      buttons: [
        {
          id: `matgen-login-submit`,
          classname: 'primary btn btn-primary',
          label: `Log In`,
        },
      ],
      width: '550px',
    });
  }

  static async showSignupModal() {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Email',
          id: 'm4c-signup-email',
          required: true,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Confirm Email',
          id: 'm4c-signup-email-confirm',
          required: true,
        },
      },
      {
        component: 'RawHTML',
        html: `
          <div class="m-3 signup-form-password-rules">Password must be at least 8 characters long and contain at least one number, symbol, and uppercase letter.</div>
          `,
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Password',
          id: 'm4c-signup-password',
          required: true,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'password',
          label: 'Confirm Password',
          id: 'm4c-signup-password-confirm',
          required: true,
        },
      },
    ];
    const DynamicForm = await new MatgenForms.DynamicForm(
      inputs,
      [],
      'm4c-signup-form'
    );
    const content = await DynamicForm.form.getElement();

    MatgenGlobal.M4CModal.show({
      id: 'm4c-signup-modal',
      title: `Sign Up`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `m4c-signup-submit`,
          classname: 'primary btn btn-primary',
          label: `Sign Up`,
        },
      ],
      width: '550px',
    });
  }

  static async testLoad() {
    const json = await MatgenGlobal.Data.getMaterialPageFile(
      'ed283f1b-4adc-459b-a01b-9598e132e2d1',
      'f15c957f-0be4-4179-9de7-ab8e4a6bd025'
    );
    const template = await MatgenGlobal.Data.getTemplate(
      'b02f2b35-3ade-4f95-b08b-72d38d462ac7'
    );
    const id = UUID();
    const id2 = UUID();
    const editor = new MatgenEditor({
      id: `matgen-editor-${id}`,
      pageId: 'f15c957f-0be4-4179-9de7-ab8e4a6bd025',
      containerId: `matgen-canvas-${id}`,
      templateId: template.id,
    });
    await editor.load({
      json,
      template,
      targetSelector: '#test-div',
      canvasContainerId: `matgen-scale-container-${id}`,
    });
    await editor.load({
      json,
      template,
      targetSelector: '#test-div-2',
      canvasContainerId: `matgen-scale-container-${id2}`,
    });
  }

  static async loadPagePreview(
    template,
    pageId,
    materialId,
    type = 'material',
    temp = false,
    keepLoading = false
  ) {
    let json;
    try {
      if (type === 'template') {
        json = await MatgenGlobal.Data.getTemplateFile(pageId);
      } else {
        json = await MatgenGlobal.Data.getMaterialPageFile(materialId, pageId);
      }
      if (!json) {
        return false;
      }
    } catch (e) {
      console.error(e);
      return MatgenPDF.handlePDFError();
    }

    let editDiv;
    if ($('#matgen-edit-canvas').length > 0) {
      $('#matgen-edit-canvas').empty();
      editDiv = $('#matgen-edit-canvas');
    } else {
      editDiv = $(
        `<div id="matgen-edit-canvas" class="matgen-hidden-canvas"/>`
      );
      $('body').append(editDiv);
    }

    /* eslint-disable */
    //debugger;
    /* eslint-enable */

    //console.error('SET EDITOR');
    let ed = MatgenGlobal.editor;
    if (temp) {
      ed = MatgenGlobal.TempEditor;
    }

    const containerId = `matgen-scale-container-${UUID()}`;
    MatgenGlobal.containerSelector = `.matgen.scale.container`;

    ed = new MatgenEditor({
      id: `${template.id}-tmp`,
      pageId: pageId,
      containerId,
      templateId: template.id,
      width: template.width,
      height: template.height,
    });

    //MatgenGlobal.canvasContainerId = `matgen-scale-container-${UUID()}`;

    await new Promise((resolve, reject) => {
      ed.load({
        json,
        template,
        targetSelector: '#matgen-edit-canvas',
        canvasContainerId: containerId,
        cb: async () => {
          if (type === 'template') {
            try {
              await ed.loadDefaults(template.id);

              emit({
                event: 'matgen-image-editor-loaded',
                detail: { editor: ed },
              });
              resolve(this);
            } catch (e) {
              console.error(e);
              reject(e);
            }
          } else {
            try {
              await ed.loadSelectedOptions(materialId);

              emit({
                event: 'matgen-image-editor-loaded',
                detail: { editor: ed },
              });
              resolve(this);
            } catch (e) {
              console.error(e);
              reject(e);
            }
          }
          if (
            MatgenGlobal.editorCallback &&
            typeof MatgenGlobal.editorCallback === 'function'
          ) {
            MatgenGlobal.editorCallback();
          }
          resolve(ed);
        },
        keepLoading,
      });
    });
    editDiv.remove();
    return ed;

    /*
    new_editor.init('#matgen-edit-canvas');

    //console.error('loadPagePreview', new_editor);

    new_editor.width = template.width;
    new_editor.height = template.height;

    this.width = json.width;
    this.height = json.height;
    this.type = json.type;
    this.id = json.id;
    this.fonts = json.fonts;
    await this.init('#matgen-edit-canvas');
    console.error(
      'loadImageEditor',
      '#matgen-edit-canvas',
      type,
      json,
      this.cur(),
      this.canvases
    );

    return new Promise(resolve => {
      new_editor.cur().loadJSON(json, async () => {
        console.error('LOADED JSON TO CANVAS');
        emit({
          event: 'matgen-image-editor-loaded',
          detail: { editor: this },
        });
        resolve(new_editor);
      });
    });
    */

    /*await new_editor.loadImageEditor(
      {
        fabric: json,
        width: template.width,
        height: template.height,
        id: template.id,
        fonts: template.fonts,
      },
      type,
      '#matgen-edit-canvas'
    );*/

    //editDiv.remove();
    //return new_editor;
  }

  static async createOption(componentId) {
    const objects = MatgenGlobal.editor.cur().fabric.getObjects();
    const componentObjects = objects.filter(o => o.componentId === componentId);
    /*const obj = _this.findById(
      MatgenGlobal.editor.cur().fabric.getObjects(),
      $(e.currentTarget).attr('data-id')
    );*/
    if (componentObjects.length < 1) {
      console.error('Objects not found for component id:', componentId);
    } else {
      let saveObj = componentObjects[0];
      if (componentObjects.length > 1) {
        //group all the objects
        const group = new fabric.Group(componentObjects);

        group.componentId = componentId;

        //clear previous objects
        componentObjects.forEach(obj => {
          MatgenGlobal.editor.cur().fabric.remove(obj);
        });

        MatgenGlobal.editor.cur().fabric.add(group);
        group.setCoords();
        MatgenGlobal.editor.cur().fabric.renderAll();
        //console.log(componentObjects);
        saveObj = group;
      }
      MatgenGlobal.UI.loading('Saving option...');
      saveObj.set('id', UUID());
      await MatgenGlobal.UI.saveOption(saveObj);
      MatgenGlobal.UI.stopLoading();
    }
  }

  static getCanvasDataURL(fileType = null, target = false) {
    if (target === false) {
      target = MatgenGlobal.editor;
    }
    const c = target.cur();

    const origwidth = c.fabric.getWidth();
    const origheight = c.fabric.getHeight();
    const origzoom = c.fabric.getZoom();

    const width = target.width;
    const height = target.height;

    c.fabric.setWidth(width);
    c.fabric.setHeight(height);

    c.fabric.setDimensions({
      width: c.fabric.getWidth(),
      height: c.fabric.getHeight(),
    });
    c.fabric.setZoom(1);
    c.fabric.renderAll();

    const durl = c.fabric.toDataURL({ format: fileType, multiplier: 1.0 });

    c.fabric.setWidth(origwidth);
    c.fabric.setHeight(origheight);

    c.fabric.setDimensions({
      width: c.fabric.getWidth(),
      height: c.fabric.getHeight(),
    });
    c.fabric.setZoom(origzoom);
    c.fabric.renderAll();

    return durl;
  }

  static async downloadMaterial(
    id,
    hideSidebar = false,
    noEditor = false,
    non508 = false
  ) {
    MatgenGlobal.UI.loading('Loading material...');
    const material = await MatgenGlobal.Data.getMaterial(id);
    const template = await MatgenGlobal.Data.getTemplate(material.template_id);

    emit({
      event: 'matgen-material-download',
      detail: { template: template },
    });

    const tenant = {
      id: MatgenGlobal.base_config.tenant_id,
      name: MatgenGlobal.base_config.tenant,
    };

    if (
      template.type === 'PDF' &&
      !MatgenGlobal.Suppress508 &&
      tenant.is508 === 1 &&
      !non508
    ) {
      MatgenUIFunctions.downloadMaterial508(
        template,
        id,
        hideSidebar,
        material
      );
    } else {
      MatgenUIFunctions.downloadMaterialSimple(
        template,
        material,
        id,
        hideSidebar,
        noEditor
      );
    }
  }

  static async downloadMaterialSimple(
    template,
    material,
    id,
    hideSidebar = false,
    noEditor = false
  ) {
    /*console.error(
      'downloadMaterialSimple',
      template,
      material,
      id,
      hideSidebar,
      noEditor
    );*/
    if (hideSidebar === true) {
      MatgenGlobal.hideSidebar = true;
    }
    MatgenGlobal.generating = true;
    MatgenGlobal.UI.loading('Loading material...');
    const pages = await MatgenGlobal.Data.getPages(material.template_id);

    if (template.type === 'PDF') {
      /*const doc = new PDFDocument({
        size: [pt(template.width), pt(template.height)],
        pdfVersion: '1.7',
        tagged: true,
        displayTitle: true,
        lang: 'en-US',
        margins: {
          top: 0,
          bottom: 0,
          left: 0,
          right: 0,
        },
      });*/

      await MatgenPDF.createPDF(
        template,
        material,
        pages,
        id,
        'material',
        true
      );
    } else if (template.type === 'IMAGE') {
      MatgenGlobal.UI.stopLoading(true);
      MatgenGlobal.UI.imgSelect(async fileType => {
        $('#image-select-modal').modal('hide');
        if (noEditor === true || !MatgenGlobal.editor) {
          //console.error('SET EDITOR');
          MatgenGlobal.editor = await MatgenUIFunctions.loadPagePreview(
            template,
            pages[0].id,
            id,
            'material',
            true,
            true
          );
        } else {
          await MatgenGlobal.UI.loadEditor(
            id,
            pages[0].id,
            'material',
            '#matgen-edit-canvas'
          );
        }

        const durl = MatgenUIFunctions.getCanvasDataURL(fileType);

        MatgenGlobal.UI.downloadResource({ file: durl, name: template.name });
        if (MatgenGlobal.Tipsheet) {
          MatgenGlobal.UI.downloadResource(MatgenGlobal.Tipsheet);
        }

        if (hideSidebar === true) {
          delete MatgenGlobal.hideSidebar;
          //$('#sidebar').remove();
        }
        delete MatgenGlobal.generating;
        if ($('#matgen-edit-canvas').length > 0) {
          $('#matgen-edit-canvas').remove();
        }
        delete MatgenGlobal.editor;

        MatgenGlobal.UI.stopLoading();
      });
    } else if (template.type === 'FILE') {
      MatgenUIFunctions.downloadFileTemplateOrMaterial(
        template.id,
        template.tenant_id,
        template.file_ext
      );
    }
  }

  static async generate508PDF(
    id,
    hideSidebar = false,
    template = false,
    material = false,
    type = 'material',
    pdfRender = false
  ) {
    /*console.error(
      'generate508PDF',
      id,
      hideSidebar,
      template,
      material,
      type,
      pdfRender
    );*/
    let template_id = id;
    if (template) {
      template_id = template.id;
    }
    MatgenGlobal.SuppressStopLoading = true;
    MatgenGlobal.generating = true;
    MatgenGlobal.UI.loading(`Loading ${material ? 'material' : 'template'}...`);
    if (template.type !== 'PDF') {
      console.error('Bad template type:', template);
      return false;
    }
    if (hideSidebar === true) {
      MatgenGlobal.hideSidebar = true;
    }

    let pageData = [
      {
        id: 'dummy',
      },
    ];

    if (pdfRender !== true) {
      pageData = await MatgenGlobal.Data.getPages(template_id);
      pageData.sort((a, b) => a.number - b.number);
    }
    try {
      await MatgenPDF.createPDF(template, material, pageData, id, type, false);
    } catch (e) {
      if (e.message === 'Content overflow in PDF') {
        MatgenGlobal.UI.handleError(
          'Content overflow',
          'One or more items has overflown the page. Check your content and try again.'
        );
        MatgenGlobal.UI.stopLoading();
        return false;
      }
    }

    if (hideSidebar === true) {
      delete MatgenGlobal.hideSidebar;
      //$('#sidebar').remove();
    }

    if ($('#matgen-edit-canvas').length > 0) {
      //$('#matgen-edit-canvas').remove();
      //delete MatgenGlobal.editor;
    }
  }

  static async downloadMaterial508(
    template,
    id,
    hideSidebar = false,
    material
  ) {
    MatgenUIFunctions.generate508PDF(id, hideSidebar, template, material);
  }

  static async downloadFileTemplateOrMaterial(id, tenant_id, file_ext) {
    const url = MatgenGlobal.Data.getTemplateFileURL(id, tenant_id, file_ext);
    delete MatgenGlobal.generating;
    MatgenGlobal.UI.stopLoading();
    const a = document.createElement('a');
    a.href = url;
    a.target = '_blank';
    a.download = url.split('/').pop();
    document.body.appendChild(a);
    a.click();
    document.body.removeChild(a);
    //window.open(url);
  }

  static async downloadTemplate(
    id,
    hideSidebar = false,
    pdfRender = false,
    non508 = false
  ) {
    MatgenGlobal.UI.loading('Loading template...');
    const template = await MatgenGlobal.Data.getTemplate(id);

    MatgenGlobal.hideSidebar = hideSidebar;

    const tenant = {
      id: MatgenGlobal.base_config.tenant_id,
      name: MatgenGlobal.base_config.tenant,
    };
    if (
      template.type === 'PDF' &&
      !MatgenGlobal.Suppress508 &&
      tenant.is508 === 1 &&
      !non508
    ) {
      MatgenUIFunctions.downloadTemplate508(
        id,
        hideSidebar,
        template,
        pdfRender
      );
    } else {
      MatgenUIFunctions.downloadTemplateSimple(id, hideSidebar);
    }
  }

  static async downloadTemplateSimple(id, hideSidebar) {
    //console.error('downloadTemplateSimple', id, hideSidebar);
    MatgenGlobal.generating = true;
    MatgenGlobal.UI.loading('Loading template...');
    if (hideSidebar === true) {
      MatgenGlobal.hideSidebar = true;
    }
    //const material = await MatgenGlobal.Data.getMaterial(id);
    const template = await MatgenGlobal.Data.getTemplate(id);

    if (template.type === 'FILE') {
      MatgenUIFunctions.downloadFileTemplateOrMaterial(
        template.id,
        template.tenant_id,
        template.file_ext,
        'template'
      );
    } else {
      const pages = await MatgenGlobal.Data.getPages(template.id);
      pages.sort((a, b) => a.number - b.number);

      /*if (!MatgenGlobal.editor) {
        MatgenGlobal.editor = await MatgenUIFunctions.loadPagePreview(
          template,
          pages[0].id,
          id,
          'template'
        );
      } else {
        await MatgenGlobal.UI.loadEditor(id, pages[0].id);
      }*/

      if (template.type === 'PDF') {
        await MatgenPDF.createPDF(template, false, pages, id, 'template', true);

        /*const doc = new PDFDocument({
          size: [pt(template.width), pt(template.height)],
          pdfVersion: '1.7',
          tagged: true,
          displayTitle: true,
          lang: 'en-US',
          margins: {
            top: 0,
            bottom: 0,
            left: 0,
            right: 0,
          },
        });
        doc.info['Title'] = template.name;
        const stream = doc.pipe(blobStream());

        const docStruct = doc.struct('Document');
        doc.addStructure(docStruct);

        let curPage = 0;
        const docPages = [doc.struct('Sect')];
        docStruct.add(docPages[curPage]);

        for (let i = 0; i < pages.length; i++) {
          if (!MatgenGlobal.editor) {
            console.error('SET EDITOR');
            MatgenGlobal.editor = await MatgenUIFunctions.loadPagePreview(
              template,
              pages[i].id,
              id,
              'template'
            );
          } else {
            await MatgenGlobal.UI.loadEditor(
              id,
              pages[i].id,
              '#matgen-edit-canvas'
            );
          }

          if (i > 0) {
            curPage++;
          }

          const durl = MatgenUIFunctions.getCanvasDataURL();

          await doc.image(durl, 0, 0, {
            width: pt(MatgenGlobal.editor.width),
            height: pt(MatgenGlobal.editor.height),
          });

          docPages[curPage].end();
          if (curPage < pages.length - 1) {
            doc.addPage({ tabs: 'S' });
            docPages.push(doc.struct('Sect'));
            docStruct.add(docPages[docPages.length - 1]);
          }
        }

        doc.end();
        */
        /*
        const docStruct = doc.struct('Document');
        doc.addStructure(docStruct);

        const curPage = 0;
        const docPages = [doc.struct('Sect')];
        docStruct.add(docPages[curPage]);

        for (let i = 0; i < pages.length; i++) {
          if (!MatgenGlobal.editor) {
            MatgenGlobal.editor = await MatgenUIFunctions.loadPagePreview(
              template,
              pages[i].id,
              id,
              'template'
            );
          } else {
            await MatgenGlobal.UI.loadEditor(id, pages[i].id);
          }
          const durl = MatgenUIFunctions.getCanvasDataURL();

          if (curPage < pages.length - 1) {
            doc.addPage();
          }
          await doc.image(durl, 0, 0, {
            width: pt(MatgenGlobal.editor.width),
            height: pt(MatgenGlobal.editor.height),
          });
          docPages[curPage].end();
        }

        doc.end();
        */
        /*
        stream.on('finish', () => {
          //console.log('STREAM FINISH');

          window.setTimeout(() => {
            MatgenGlobal.UI.stopLoading();
            MatgenGlobal.UI.confirm(
              'Download PDF',
              'Your PDF will open in a new window.',
              'Continue',
              'Cancel',
              () => {
                window.open(stream.toBlobURL('application/pdf'));
              }
            );
            delete MatgenGlobal.generating;

            if (hideSidebar === true) {
              delete MatgenGlobal.hideSidebar;
              //$('#sidebar').remove();
            }

            if ($('#matgen-edit-canvas').length > 0) {
              $('#matgen-edit-canvas').remove();
              delete MatgenGlobal.editor;
            }

            //window.location = ctx.stream.toBlobURL('application/pdf');
            //MatgenGlobal.popup.location = ctx.stream.toBlobURL('application/pdf');
          }, 1250);
        });

*/
      } else if (template.type === 'IMAGE') {
        MatgenGlobal.UI.imgSelect(async fileType => {
          $('#image-select-modal').modal('hide');
          if (!MatgenGlobal.editor) {
            //console.error('SET EDITOR');
            MatgenGlobal.editor = await MatgenUIFunctions.loadPagePreview(
              template,
              pages[0].id,
              id,
              'template',
              true,
              true
            );
          } else {
            //console.log('LOAD EDITOR');
            await MatgenGlobal.UI.loadEditor(
              id,
              pages[0].id,
              '#matgen-edit-canvas'
            );
          }

          const durl = MatgenUIFunctions.getCanvasDataURL(fileType);

          MatgenGlobal.UI.downloadResource({ file: durl, name: template.name });
          if (MatgenGlobal.Tipsheet) {
            MatgenGlobal.UI.downloadResource(MatgenGlobal.Tipsheet);
          }

          if (hideSidebar === true) {
            delete MatgenGlobal.hideSidebar;
            //$('#sidebar').remove();
          }
          delete MatgenGlobal.generating;
          MatgenGlobal.UI.stopLoading();
        });
      }
    }
  }

  static async downloadTemplate508(
    id,
    hideSidebar = false,
    template,
    pdfRender = false
  ) {
    MatgenUIFunctions.generate508PDF(
      id,
      hideSidebar,
      template,
      false,
      'template',
      pdfRender
    );
  }

  static compareNumbers(a, b) {
    return a - b || (a || Infinity) - (b || Infinity) || 0;
  }

  static readOrderSort(a, b) {
    return (
      MatgenUIFunctions.compareNumbers(a.ro1, b.ro1) ||
      MatgenUIFunctions.compareNumbers(a.ro2, b.ro2)
    );
  }

  static getReadOrder(o) {
    let ro1, ro2;
    const tag = o.pdfTag ? o.pdfTag : o.componentPdfTag;
    if (tag && tag !== 'ARTIFACT') {
      if (
        typeof o.componentReadOrder !== 'undefined' &&
        typeof o.readOrder === 'undefined'
      ) {
        ro1 = parseInt(o.componentReadOrder);
      }
      if (
        typeof o.componentReadOrder === 'undefined' &&
        typeof o.readOrder !== 'undefined'
      ) {
        ro1 = parseInt(o.readOrder);
      }
      if (
        typeof o.componentReadOrder !== 'undefined' &&
        typeof o.readOrder !== 'undefined'
      ) {
        ro1 = parseInt(o.componentReadOrder);
        ro2 = parseInt(o.readOrder);
      }
    } else {
      ro1 = parseInt(o.readOrder);
    }
    o.ro1 = ro1;
    o.ro2 = ro2;
    return o;
  }

  static async viewMaterial(id) {
    MatgenGlobal.UI.loading('Loading material preview...');
    const material = await MatgenGlobal.Data.getMaterial(id);
    const template = await MatgenGlobal.Data.getTemplate(material.template_id);
    if (['PDF', 'IMAGE'].includes(template.type)) {
      const pages = await MatgenGlobal.Data.getPages(material.template_id);
      const pageThumbs = pages
        .sort((a, b) => a.number - b.number)
        .map(p => p.id);
      const image = new Image();
      image.src = await MatgenGlobal.Data.getMaterialPreviewURL(
        id,
        pageThumbs[0]
      );

      image.onerror = () => {
        MatgenGlobal.UI.stopLoading();
        MatgenGlobal.UI.handleError(
          'Not Found',
          'We are unable to locate the preview image.'
        );
      };
      image.onload = () => {
        MatgenGlobal.UI.stopLoading();
        image.onload = null;
        const img = $(image.outerHTML);
        img.css({
          'max-width': image.width / 2,
          margin: 'auto',
        });
        img.attr('id', 'page-thumb');
        img.attr('alt', 'Preview of template');
        let content = img;
        if (pageThumbs.length > 1) {
          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          wrapper.append(
            $(
              `
                ${
                  pageThumbs.length > 1
                    ? `
                  <div id="thumb-pager" class="text-center">
                  ${pageThumbs
                    .map(
                      (t, i) => `
                      ${i === 0 ? '' : ' - '}
                      <a href="#" data-id="${id}" data-page-id="${
                        pages[i].id
                      }" data-index="${i}" class="thumb-page">${i + 1}</a>
                      `
                    )
                    .join('')}
                  </div>
                  `
                    : ''
                }
                </div>`
            )
          );
          content = wrapper;
        }
        MatgenGlobal.UI.alertModal(
          'Material Preview',
          content[0].outerHTML,
          null,
          'preview-modal'
        );
      };
    } else if (template.type === 'FILE') {
      if (template.preview_type === 'IMAGE') {
        const image = new Image();
        image.src = MatgenGlobal.Data.getTemplateFileURL(
          template.id,
          template.tenant_id,
          template.preview_image_ext
        );

        image.onerror = () => {
          MatgenGlobal.UI.stopLoading();
        };
        image.onload = () => {
          MatgenGlobal.UI.stopLoading();
          image.onload = null;
          const img = $(image.outerHTML);
          img.css({
            'max-width': image.width / 2,
            margin: 'auto',
          });
          img.attr('id', 'page-thumb');
          img.attr('alt', 'Preview of material');
          let content = img;

          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          content = wrapper;

          MatgenGlobal.UI.alertModal(
            'Material Preview',
            content[0].outerHTML,
            null,
            'preview-modal'
          );
        };
      } else if (template.preview_type === 'LINK') {
        delete MatgenGlobal.generating;
        MatgenGlobal.UI.stopLoading();
        window.open(template.preview_link);
      } else if (template.preview_type === 'SELF') {
        MatgenUIFunctions.downloadFileTemplateOrMaterial(
          template.id,
          template.tenant_id,
          template.file_ext
        );
      } else {
        console.error('Bad material type:', material, template);
        MatgenGlobal.UI.handleError(
          'Unknown material type',
          'The system does not recognize the given material type.'
        );
      }
    } else {
      console.error('Bad material type:', material, template);
      MatgenGlobal.UI.handleError(
        'Unknown material type',
        'The system does not recognize the given material type.'
      );
    }

    //const w = window.open('');
    //w.document.write(image.outerHTML);
  }

  static async modalForm({
    prefix,
    inputs,
    title,
    actions = false,
    data = false,
    options = {},
    listeners = false,
    hiddenFields = [],
    width = false,
  } = {}) {
    const form = new Form({
      inputs,
      id: `${prefix}-form`,
      title,
    });

    if (data) {
      form.setValues(data);
    } else {
      const d = inputs.find(i => i.value);
      if (!d) {
        form.reset();
      }
    }

    const formHTML = await form.getHTML(options);

    MatgenGlobal.UI.showFormModal({
      modalId: `${prefix}-form-modal`,
      formId: `${prefix}-form`,
      title,
      content: formHTML,
      actions,
      width,
    });

    if (data && data.id) {
      $(`#${prefix}-form`).append(
        $(`
           <input type="hidden" id="${prefix}-data-id" name="${prefix}-data-id" value="${data.id}" />
         `)
      );
    }

    if (hiddenFields) {
      hiddenFields.forEach(i => {
        $(`#${prefix}-form`).append(
          $(`
             <input type="hidden" id="${i.name}" name="${i.name}" value="${i.value}" />
           `)
        );
      });
    }

    if (listeners && typeof listeners === 'function') {
      listeners();
    }
  }

  static async editMaterialData(id, row) {
    let material = false;
    if (id) {
      MatgenGlobal.UI.loading('Loading material data...');
      material = await MatgenGlobal.Data.getMaterial(id);
      MatgenGlobal.UI.stopLoading();
    }

    const hiddenFields = [];
    if (material) {
      hiddenFields.push({ name: 'inputUserName', value: material.user_name });
    }

    MatgenUIFunctions.modalForm({
      prefix: 'material',
      inputs: [
        new TextInput({
          type: 'text',
          label: 'Name',
          dataId: 'name',
          id: 'inputName',
          required: true,
          autofocus: true,
        }),
      ],
      title: 'Edit Material',
      data: material ? material : false,
      options: { inline: false },
      actions: [
        {
          id: 'material-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Update',
        },
      ],
      hiddenFields,
      listeners: () => {
        $(document).off('click', '#material-form-submit');
        $(document).on('click', '#material-form-submit', () => {
          $('#material-form').submit();
        });

        $(document).off('submit', '#material-form');
        $(document).on('submit', '#material-form', async e => {
          e.preventDefault();

          if ($('#material-form')[0].checkValidity()) {
            MatgenGlobal.UI.loading('Saving material...', '#form-container');
            try {
              const response = await MatgenGlobal.Data.saveMaterial(
                {
                  name: $('#inputName').val(),
                  id: $('#material-data-id').val(),
                  user_name: $('#inputUserName').val(),
                },
                true
              );

              MatgenGlobal.Tables.MaterialTable.updateCellData(
                row,
                1,
                $('#inputName').val()
              );
              console.log(response);
            } catch (e) {
              console.error(e);
              MatgenGlobal.UI.handleError(
                'Error Saving Material',
                'There was an error saving your material.'
              );
            }
            MatgenGlobal.UI.stopLoading();
            $(`#material-form-modal`).modal('hide');
          } else {
            $(`#material-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static async deleteMaterial(id) {
    MatgenGlobal.UI.loading('Deleting material...');
    const response = await MatgenGlobal.Data.deleteMaterial(id);
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem deleting the material.'
      );
      return false;
    }
    //console.log(response);
    //MatgenGlobal.UI.stopLoading();
    window.location.reload();
    //MatgenGlobal.Router.core.reload();
  }

  static async deleteMicrosite(id, row) {
    MatgenGlobal.UI.loading('Deleting microsite...');
    const response = await MatgenGlobal.Data.deleteMicrosite(id);
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem deleting the microsite.'
      );
      return false;
    }
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    if (row) {
      $(row)
        .parent()
        .parent()
        .DataTable()
        .row($(row))
        .remove()
        .draw();
    }
    //window.location.reload();
    //MatgenGlobal.Router.core.reload();
  }

  static editTemplate(id) {
    if (MatgenGlobal.Router.hash) {
      if (MatgenGlobal.adminView) {
        window.location.href = `${MatgenGlobal.adminView}#/templates/${id}`;
      } else {
        window.location.href = `${MatgenGlobal.RootPage}#/templates/${id}`;
      }
    } else {
      window.location.href = `/templates/${id}`;
    }
    //MatgenGlobal.Router.goTo('templateEdit', id);
    //loadMenus();
  }

  static async buildSectionForm(sections) {
    const s = [];
    for (let i = 0; i < sections.length; i++) {
      const questions = await MatgenUIFunctions.buildSectionQuestions(
        sections[i].id,
        sections[i].type
      );
      s.push(`
      <div class="tab-pane fade show ${i === 0 ? ' active' : ''}" id="${
        sections[i].type
      }-tab-pane" role="tabpanel" aria-labelledby="${
        sections[i].type
      }-tab" tabindex="0">
        <div class="section-wrapper">
          <div class="section-detail-wrapper">
            <div class="section-detail">
              <div><b>Details:</b></div>
              <div><b>ID:</b> <u id="${sections[i].type}-detail-s-id">${
        sections[i].id
      }</u></div>
              <div><b>Name:</b> <u id="${sections[i].type}-detail-s-name">${
        sections[i].name
      }</u></div>
              <div><b>Title:</b> <u id="${sections[i].type}-detail-s-title">${
        sections[i].title
          ? sections[i].title
          : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
      }</u></div>
              <div><b>Subtext:</b> <u id="${
                sections[i].type
              }-detail-s-subtext">${
        sections[i].subtext
          ? sections[i].subtext
          : '&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;'
      }</u></div>
              <div><b>Type:</b> <u id="${sections[i].type}-detail-s-type">${
        sections[i].type
      }</u></div>
            </div>
            <div class="icon-link edit-section" data-id="${
              sections[i].id
            }"><i class="fa-solid fa-pen-to-square"></i></div>
          </div>
          <div id="${sections[i].type}-questions" class="questions">
          ${questions}
          </div>
        </div>
      </div>
    `);
    }
    return s.join('');
  }

  static async buildAnswerContent(question_id) {
    let answerContent = `
    <div class="alert alert-info" role="alert">
      <p><i class="fa-solid fa-circle-info"></i> No answers for this question.<br>Click the plus sign above to add.</p>
    </div>
    `;
    const answers = await MatgenGlobal.Data.getQuestionAnswers(question_id);
    answers.sort((a, b) => a.sort_order - b.sort_order);
    if (answers.length > 0) {
      answerContent = answers
        .map((a, i) => {
          return `
          <div id="${answers[i].id}-answer" data-id="${
            answers[i].id
          }" class="answer-card">
            <div class="answer-title">
              <i class="answer-icon ${a.icon}"></i>
              ${a.text}<br>${a.sub_text ? a.sub_text : ''}
            </div>
            <div style="margin-right:.25em;" class="icon-link edit-answer" data-id="${
              a.id
            }"><i class="fa-solid fa-pen-to-square"></i></div>
            <div class="icon-link delete-answer" data-id="${
              a.id
            }"><i class="fa-solid fa-trash-can"></i></div>
          </div>
        `;
        })
        .join('');
    }
    return answerContent;
  }

  static async buildAnswerAccordion(question_id) {
    const answerContent = await MatgenUIFunctions.buildAnswerContent(
      question_id
    );
    return `
    <div>
      <div id="accordion-${question_id}-answers">
      <div class="section-question-answers">
        <div class="answers-controller">
          <div class="answers-controls">
            <div class="answers-controller-title"><b>Answers:</b></div>
            <button type="button" data-question-id="${question_id}" class="sort-section-question-answers btn btn-primary" style="padding: 0;width:1.8em;margin-right:.25em;"><i class="fa-solid fa-sort"></i></button>
            <button type="button" data-question-id="${question_id}" class="add-section-question-answer btn btn-primary" style="padding: 0;width:1.8em;"><i class="fa-solid fa-plus"></i></button>
          </div>
          <div id="accordion-${question_id}-answers-content">
            ${answerContent}
          </div>
        </div>
      </div>
      </div>
    </div>
    `;
  }

  static buildQuestionAccordion(question, answerContent) {
    return `
    <div class="m4c-accordion-item" data-id="${question.id}">
      <div class="d-flex" id="m4c-accordion-item-${
        question.id
      }" style="border:1px solid #eee;padding:1.5em .75em;">
        <div class="question-title">${question.text}<br>${
      question.sub_text ? question.sub_text : ''
    }</div>
        <div class="d-flex">
          <div style="margin-right:.5em;"><i class="icon-link q-edit fa-solid fa-edit" data-id="${
            question.id
          }"></i></div>
          <div style="margin-right:1.5em;"><i class="icon-link q-view fa-solid fa-angles-down" data-id="${
            question.id
          }"></i></div>
          <div><i class="icon-link q-delete fa-solid fa-trash-can" data-id="${
            question.id
          }"></i></div>
        </div>
      </div>
      <div id="accordion-content-${
        question.id
      }" class="question-accordion m4c-accordion-collapse">
        <div class="m4c-accordion-body">
          ${answerContent}
        </div>
      </div>
    </div>
    `;
  }

  static async buildSectionQuestions(id, type) {
    if (type === 'materials') {
      return `
      <div class="alert alert-info" role="alert" style="margin-top:1em;">
        <p><i class="fa-solid fa-circle-info"></i> The materials section exists only to provide the ability to edit the section information for it. The "question" is the section name/title/subtext, and the "answers" are the listed materials.</p>
      </div>
      `;
    }
    const questions = await MatgenGlobal.Data.getSectionQuestions(id);
    questions.sort((a, b) => a.sort_order - b.sort_order);
    let q;
    if (questions.length > 0) {
      q = await Promise.all(
        questions.map(async (q, i) => {
          const answerAccordion = await MatgenUIFunctions.buildAnswerAccordion(
            questions[i].id
          );
          return MatgenUIFunctions.buildQuestionAccordion(
            questions[i],
            answerAccordion
          );
        })
      );
      q = q.join('');
    } else {
      q = `
      <div class="alert alert-info" role="alert">
        <p><i class="fa-solid fa-circle-info"></i> No questions in this section. Click the plus sign above to add.</p>
      </div>
      `;
    }

    return `
    <div class="section-questions">
      <div class="questions-controller">
        <div class="questions-controls">
          <div class="questions-controller-title"><b>Questions:</b></div>
          <button type="button" data-section-id="${id}" class="sort-section-questions btn btn-primary" style="padding: 0;width:1.8em;margin-right:.25em;"><i class="fa-solid fa-sort"></i></button>
          <button type="button" data-section-id="${id}" class="add-section-question btn btn-primary" style="padding: 0;width:1.8em;"><i class="fa-solid fa-plus"></i></button>
        </div>
        <div id="accordion-${type}">
        ${q}
        </div>
      </div>
    </div>
    `;
  }

  static async deleteQuestionAnswer(id) {
    MatgenGlobal.UI.loading('Deleting answer...');
    await MatgenGlobal.Data.deleteAnswer(id);
    const p = $(`#${id}-answer`).parent();
    $(`#${id}-answer`).remove();
    if (p.find('.answer-card').length === 0) {
      p.append(`
        <div class="alert alert-info" role="alert">
          <p><i class="fa-solid fa-circle-info"></i> No answers for this question.<br>Click the plus sign above to add.</p>
        </div>
      `);
    }
    //console.log(`#${id}-answer`);
    MatgenGlobal.UI.stopLoading();
  }

  static async deleteSectionQuestion(id) {
    MatgenGlobal.UI.loading('Deleting question (and its answers)...');
    const answers = await MatgenGlobal.Data.getQuestionAnswers(id);
    //const question = await MatgenGlobal.Data.getQuestion(id);
    //const section = await MatgenGlobal.Data.getSection(question.section_id);
    const answerDeletes = [];
    for (let i = 0; i < answers.length; i++) {
      answerDeletes.push(MatgenGlobal.Data.deleteAnswer(answers[i].id));
    }
    if (answerDeletes.length > 0) {
      await Promise.all(answerDeletes);
    }
    const response = await MatgenGlobal.Data.deleteQuestion(id);
    console.log(response);
    const container = $(`[data-id=${id}]`).parent();

    $(`[data-id=${id}]`).remove();
    if (container.find('.m4c-accordion-item').length === 0) {
      container.append(
        $(`
      <div class="alert alert-info" role="alert">
        <p><i class="fa-solid fa-circle-info"></i> No questions in this section. Click the plus sign above to add.</p>
      </div>
      `)
      );
    }
    MatgenGlobal.UI.stopLoading();
  }

  static async editAnswerData(id) {
    MatgenGlobal.UI.loading('Loading answer data...');
    try {
      const answer = await MatgenGlobal.Data.getAnswer(id);
      console.log(answer);
      const inputs = [
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Answer Text',
            id: 'inputText',
            dataId: 'text',
            value: answer.name,
          },
        },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Subtext',
            id: 'inputSubtext',
            dataId: 'sub_text',
            value: answer.sub_text ? answer.sub_text : '',
          },
        },
        {
          component: 'RawHTML',
          html: `
          <div id="icon-preview">
            <div id="answer-icon"><i class="fa-duotone fa-circle-question fa-2xl"></i></div>
            <button id="toggle-icon-chooser" type="button" class="btn btn-secondary">Choose Icon</button>
          </div>
          `,
        },
        {
          component: 'RawHTML',
          html: `
          <input type="hidden" id="question-id" name="question-id" value="${answer.question_id}"/>
          `,
        },
      ];
      const DynamicForm = await new MatgenForms.DynamicForm(
        inputs,
        [],
        'edit-section-form'
      );
      const content = await DynamicForm.form.getElement();

      //content.find('#create-questionnaire-form').append(sectionContent);
      MatgenGlobal.UI.stopLoading();
      //console.log(content[0].outerHTML);
      MatgenGlobal.M4CModal.show({
        id: 'edit-section-modal',
        title: `Edit Section`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `edit-section-submit`,
            classname: 'primary btn btn-primary',
            label: `Save`,
          },
        ],
        width: '550px',
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve answer data from the API.'
      );
      return false;
    }
  }

  static async editSectionData(id) {
    MatgenGlobal.UI.loading('Loading section data...');
    try {
      const section = await MatgenGlobal.Data.getSection(id);
      //console.log(section);
      const inputs = [
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Name',
            id: 'inputName',
            dataId: 'name',
            value: section.name,
          },
        },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Title',
            id: 'inputTitle',
            dataId: 'title',
            value: section.title ? section.title : '',
          },
        },
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Subtext',
            id: 'inputSubtext',
            dataId: 'sub_text',
            value: section.sub_text ? section.sub_text : '',
          },
        },
        {
          component: 'RawHTML',
          html: `
          <input type="hidden" id="section-id" name="section-id" value="${section.id}"/>
          `,
        },
      ];
      const DynamicForm = await new MatgenForms.DynamicForm(
        inputs,
        [],
        'edit-section-form'
      );
      const content = await DynamicForm.form.getElement();

      //content.find('#create-questionnaire-form').append(sectionContent);
      MatgenGlobal.UI.stopLoading();
      //console.log(content[0].outerHTML);
      MatgenGlobal.M4CModal.show({
        id: 'edit-section-modal',
        title: `Edit Section`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `edit-section-submit`,
            classname: 'primary btn btn-primary',
            label: `Save`,
          },
        ],
        width: '550px',
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve answer data from the API.'
      );
      return false;
    }
  }

  static async editQuestionnaireData(id) {
    MatgenGlobal.UI.loading('Loading questionnaire data...');
    try {
      const questionnaire = await MatgenGlobal.Data.getQuestionnaireRecord(id);
      //console.log(questionnaire);
      const inputs = [
        {
          component: 'Text',
          options: {
            type: 'text',
            label: 'Name',
            id: 'inputName',
            required: true,
            value: questionnaire.name,
          },
        },
        {
          component: 'Select',
          options: {
            label: 'Tenant',
            id: 'inputTenantID',
            classes: 'middle',
            required: true,
            options: async () => {
              const tenants = await MatgenGlobal.Data.getTenants();
              return tenants.map(t => {
                return {
                  label: t.name,
                  value: t.id,
                };
              });
            },
            value: questionnaire.tenant_id,
          },
        },
        {
          component: 'Checkbox',
          options: {
            type: 'checkbox',
            label: 'Active',
            id: 'inputActive',
            dataId: 'active',
            value: questionnaire.active,
          },
        },
        {
          component: 'RawHTML',
          html: `
          <input type="hidden" id="questionnaire-id" name="questionnaire-id" value="${questionnaire.id}"/>
          `,
        },
      ];
      const DynamicForm = await new MatgenForms.DynamicForm(
        inputs,
        [],
        'edit-questionnaire-form'
      );
      const content = await DynamicForm.form.getElement();

      //content.find('#create-questionnaire-form').append(sectionContent);

      //console.log(content[0].outerHTML);
      MatgenGlobal.M4CModal.show({
        id: 'edit-questonnaire-modal',
        title: `Edit Questionnaire`,
        content: content[0].outerHTML,
        buttons: [
          {
            id: `edit-questionnaire-submit`,
            classname: 'primary btn btn-primary',
            label: `Save`,
          },
        ],
        width: '550px',
      });
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve questionnaire data from the API.'
      );
      return false;
    }
  }

  static async createAnswer(question_id) {
    MatgenGlobal.ChosenIcon = {
      prefix: 'solid',
      name: 'circle-question',
    };

    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Text',
          id: 'inputText',
          required: true,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Subtext',
          id: 'inputSubtext',
        },
      },
      {
        component: 'RawHTML',
        html: `
          <div id="icon-preview">
            <div id="answer-icon"><i class="chosen-icon fa-${MatgenGlobal.ChosenIcon.prefix} fa-${MatgenGlobal.ChosenIcon.name} fa-2xl"></i></div>
            <button id="toggle-icon-chooser" type="button" class="btn btn-secondary">Choose Icon</button>
          </div>
        `,
      },
      {
        component: 'RawHTML',
        html: `
          <input type="hidden" id="q-question-id" name="q-question-id" value="${question_id}"/>
          `,
      },
    ];
    const DynamicForm = await new MatgenForms.DynamicForm(
      inputs,
      [],
      'create-answer-form'
    );
    const content = await DynamicForm.form.getElement();

    //content.find('#create-questionnaire-form').append(sectionContent);

    //console.log(content[0].outerHTML);
    MatgenGlobal.M4CModal.show({
      id: 'create-answer-modal',
      title: `Create Answer`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `create-answer-submit`,
          classname: 'primary btn btn-primary',
          label: `Create`,
        },
      ],
      width: '550px',
    });
  }

  static async createQuestion(section_id) {
    const inputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Text',
          id: 'inputText',
          required: true,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Subtext',
          id: 'inputSubtext',
        },
      },
      {
        component: 'Select',
        options: {
          label: 'Type',
          id: 'inputComponent',
          classes: 'middle',
          required: true,
          options: [
            {
              label: 'Single select',
              value: 'select-single',
            },
            {
              label: 'Multi select',
              value: 'select-multiple',
            },
          ],
        },
      },
      {
        component: 'RawHTML',
        html: `
          <input type="hidden" id="q-section-id" name="q-section-id" value="${section_id}"/>
          `,
      },
    ];
    const DynamicForm = await new MatgenForms.DynamicForm(
      inputs,
      [],
      'create-question-form'
    );
    const content = await DynamicForm.form.getElement();

    //content.find('#create-questionnaire-form').append(sectionContent);

    //console.log(content[0].outerHTML);
    MatgenGlobal.M4CModal.show({
      id: 'create-question-modal',
      title: `Create Question`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `create-question-submit`,
          classname: 'primary btn btn-primary',
          label: `Create`,
        },
      ],
      width: '550px',
    });
  }

  static async editQuestionnaire(id) {
    MatgenGlobal.UI.loading('Loading questionnaire data...');
    try {
      const questionnaire = await MatgenGlobal.Data.getQuestionnaireRecord(id);
      const sections = await MatgenGlobal.Data.getQuestionnaireSections(id);
      sections.sort((a, b) => {
        return a.sort_order - b.sort_order;
      });

      const sectionQuestions = await MatgenUIFunctions.buildSectionForm(
        sections
      );

      //console.error(sectionQuestions);

      const tenant = await MatgenGlobal.Data.getTenant(questionnaire.tenant_id);
      //console.log(questionnaire.tenant_id, tenant);
      MatgenGlobal.UI.showPage(`
        <div style="width:50%;margin-left:auto;margin-right:auto;">
          <h2>Edit Questionnaire</h2>
          <div class="questionnaire-detail">
            <div class="questionnaire-details-wrapper">
              <div><b>Details:</b></div>
              <div>ID: <u id="detail-q-id">${questionnaire.id}</u></div>
              <div>Name: <u id="detail-q-name">${questionnaire.name}</u></div>
              <div>Type: <u id="detail-q-type">${questionnaire.type}</u></div>
              <div>Tenant: <u id="detail-q-tenant">${tenant.name}</u></div>
              <div>Active: <u id="detail-q-active">${
                questionnaire.active ? 'Y' : 'N'
              }</u></div>
            </div>
            <div class="icon-link edit-questionnaire" data-id="${
              questionnaire.id
            }"><i class="fa-solid fa-pen-to-square"></i></div>
          </div>
          <div class="questionnaire-sections">
            <div><b>Sections:</b></div>

            <ul class="nav nav-tabs" id="section-tabs-nav" role="tablist">

            ${sections
              .map((s, i) => {
                return `
                <li class="nav-item" role="presentation">
                  <button class="nav-link${i === 0 ? ' active' : ''}" id="${
                  s.type
                }-tab" data-bs-toggle="tab" data-bs-target="#${
                  s.type
                }-tab-pane" type="button" role="tab" aria-controls="${
                  s.type
                }-tab-pane" aria-selected="true">${s.name}</button>
                </li>
              `;
              })
              .join('')}
            </ul>
            <div class="tab-content" id="section-tabs">

            ${sectionQuestions}

            </div>
          </div>
        </div>
      `);
      MatgenGlobal.UI.stopLoading();
    } catch (e) {
      console.error(e);
      MatgenGlobal.UI.handleError(
        'API Error',
        'Failed to retrieve questionnaire data from the API.'
      );
      MatgenGlobal.UI.stopLoading();
      return false;
    }
  }

  static async createQuestionnaire() {
    const sectionInputs = [
      'goal',
      'demographics',
      'distribution',
      'materials',
    ].map(s => {
      const nameInput = new TextInput({
        type: 'text',
        label: 'Name',
        id: `section-${s}-name`,
        value: s.charAt(0).toUpperCase() + s.slice(1),
        required: true,
      });
      const titleInput = new TextInput({
        type: 'text',
        label: 'Title',
        id: `section-${s}-title`,
      });
      const subtextInput = new TextInput({
        type: 'text',
        label: 'Subtext',
        id: `section-${s}-subtext`,
      });
      return [nameInput, titleInput, subtextInput];
    });

    const formInputs = [
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Name',
          id: 'inputName',
          required: true,
        },
      },
      {
        component: 'Select',
        options: {
          label: 'Tenant',
          id: 'inputTenantID',
          classes: 'middle',
          required: true,
          options: async () => {
            const tenants = await MatgenGlobal.Data.getTenants();
            return tenants.map(t => {
              return {
                label: t.name,
                value: t.id,
              };
            });
          },
        },
      },
    ];

    for (let i = 0; i < sectionInputs.length; i++) {
      const inputs = await Promise.all(
        sectionInputs[i].map(i2 => (i2.getHTML ? i2.getHTML() : i2))
      );
      //console.log(inputs);
      let s = 'Goal';
      switch (i) {
        default:
        case 0:
          break;
        case 1:
          s = 'Demographics';
          break;
        case 2:
          s = 'Distribution';
          break;
        case 3:
          s = 'Materials';
          break;
      }
      formInputs.push({
        component: 'RawHTML',
        html: `
        <div class="questionnaire-section" style="border:1px solid #ccc;padding:1.5em;margin-top:2em;border-radius:4px;padding-bottom: 0.25em;">
          <div class="section-label" style="padding-bottom:.5em;"><u>${s} Section:</u></div>
          <div class="section-inputs" style="">
            <div class="section-name">${inputs[0]}</div>
            <div class=section-title-wrapper style="display:none;" id="${s}-title">
              <div class="section-title">${inputs[1]}</div>
            </div>
            <div class=section-subtext-wrapper style="display:none;" id="${s}-subtext">
              <div class="section-subtext">${inputs[2]}</div>
            </div>
            <div class="section-options" style="display: flex;justify-content: space-evenly;">
              <a href="#" class="section-toggle" data-id="${s}-title">Add title/question</a>
              <a href="#" class="section-toggle" data-id="${s}-subtext">Add subtext</a>
            </div>
          </div>
        </div>
        `,
      });
    }

    //console.log(formInputs);

    const DynamicForm = await new MatgenForms.DynamicForm(
      formInputs,
      [],
      'create-questionnaire-form'
    );
    const content = await DynamicForm.form.getElement();

    //content.find('#create-questionnaire-form').append(sectionContent);

    //console.log(content[0].outerHTML);
    MatgenGlobal.M4CModal.show({
      id: 'create-questonnaire-modal',
      title: `Create New Questionnaire`,
      content: content[0].outerHTML,
      buttons: [
        {
          id: `create-questionnaire-submit`,
          classname: 'primary btn btn-primary',
          label: `Create`,
        },
      ],
      width: '650px',
    });
  }

  static async tenantQuestionnaireOld(tenant_id) {
    const formHTML = await MatgenForms.QuestionnaireForm.getHTML();
    const SectionHTML = await MatgenForms.SectionForm.getHTML();
    const formEl = $(formHTML);
    const section1El = $(SectionHTML);
    const section2El = $(SectionHTML);
    const section3El = $(SectionHTML);
    const section4El = $(SectionHTML);

    console.log(tenant_id, formEl, formEl.find('#questionnaire-form'));
    formEl
      .find('#questionnaire-form')
      .prepend(
        $(
          '<h4 style="padding: 1rem;text-align:center">Step 1:<br>(Create questionnaire)</h4>'
        )
      );
    formEl.append(`
      <h4 style="padding: 1rem;text-align:center">Step 2:<br>(Create section questions)</h4>
      <h5 style="padding: 1rem;text-align:center">Section: Goal</h5>
    `);
    formEl.append(`<div id="section-1-container"></div>`);
    formEl.find('#section-1-container').append(section1El);

    formEl.find('#section-1-container').append(`
      <div class="question-container question-container-1">
          <h6>Questions</h6>
      </div>
      <div style="text-align:center;padding:1rem;"><button type="button" class="btn btn-primary questionnaire-form-action" data-action="add-question-section-1"><i class="fas fa-plus"></i> Add Question</button></div>`);
    formEl.find('#section-1-container').append(`
    <h5 style="padding: 1rem;text-align:center">Section: Demographics</h5>
  `);
    formEl.append(`<div id="section-2-container"></div>`);
    formEl.find('#section-2-container').append(section2El);
    formEl.find('#section-2-container').append(`
      <div class="question-container question-container-2">
          <h6>Questions</h6>
      </div>
      <div style="text-align:center;padding:1rem;"><button type="button" class="btn btn-primary questionnaire-form-action" data-action="add-question-section-2"><i class="fas fa-plus"></i> Add Question</button></div>
    `);
    formEl.find('#section-2-container').append(`
    <h5 style="padding: 1rem;text-align:center">Section: Distribution</h5>
  `);
    formEl.append(`<div id="section-3-container"></div>`);
    formEl.find('#section-3-container').append(section3El);
    formEl.find('#section-3-container').append(`
      <div class="question-container question-container-3">
          <h6>Questions</h6>
      </div>
      <div style="text-align:center;padding:1rem;"><button type="button" class="btn btn-primary questionnaire-form-action" data-action="add-question-section-3"><i class="fas fa-plus"></i> Add Question</button></div>
    `);
    formEl.find('#section-3-container').append(`
    <h5 style="padding: 1rem;text-align:center">Section: Materials</h5>
  `);
    formEl.append(`<div id="section-4-container"></div>`);
    formEl.find('#section-4-container').append(section4El);
    formEl.find('#section-4-container').append(`
      <div class="question-container question-container-4">
          <h6>Questions</h6>
      </div>
      <div style="text-align:center;padding:1rem;"><button type="button" class="btn btn-primary questionnaire-form-action" data-action="add-question-section-4"><i class="fas fa-plus"></i> Add Question</button></div>
    `);

    formEl.find('#section-4-container').append(`
      <div style="text-align:center;padding:1rem;"><button type="button" class="btn btn-primary questionnaire-form-action" data-action="submit-save-questionnaire">Save Questionnaire</button></div>
    `);

    MatgenGlobal.UI.showPage(formEl[0].outerHTML, () => {
      $('#study-table').DataTable({
        data: [],
        createdRow: (row, data) => {
          const rowID = data[0];
          $(row).attr('id', rowID);
        },
        order: [[5, 'asc']],
        columns: [
          {
            data: 'id',
            title: 'ID',
          },
          {
            data: 'name',
            title: 'Name',
          },
          {
            data: 'type',
            title: 'Type',
          },
          {
            data: 'title',
            title: 'Title',
          },
          {
            data: 'sub_text',
            title: 'Subtext',
          },
          {
            data: 'sort_order',
            title: 'Sort Order',
          },
          {
            data: null,
            title: 'Actions',
            wrap: true,
            render: item => {
              return `
              <div class="table-actions">

                <button type="button" data-bs-toggle="tooltip" data-placement="auto" title="" data-action="sections-edit" data-id="0d5e4eec-229c-4993-9e21-1ba1a6ee489a" class="btn btn-sm btn-primary table-action hide-file-type" data-bs-original-title="Edit Section">
                  <svg class="svg-inline--fa fa-edit" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="edit" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" data-fa-i2svg=""><path fill="currentColor" d="M490.3 40.4C512.2 62.27 512.2 97.73 490.3 119.6L460.3 149.7L362.3 51.72L392.4 21.66C414.3-.2135 449.7-.2135 471.6 21.66L490.3 40.4zM172.4 241.7L339.7 74.34L437.7 172.3L270.3 339.6C264.2 345.8 256.7 350.4 248.4 353.2L159.6 382.8C150.1 385.6 141.5 383.4 135 376.1C128.6 370.5 126.4 361 129.2 352.4L158.8 263.6C161.6 255.3 166.2 247.8 172.4 241.7V241.7zM192 63.1C209.7 63.1 224 78.33 224 95.1C224 113.7 209.7 127.1 192 127.1H96C78.33 127.1 64 142.3 64 159.1V416C64 433.7 78.33 448 96 448H352C369.7 448 384 433.7 384 416V319.1C384 302.3 398.3 287.1 416 287.1C433.7 287.1 448 302.3 448 319.1V416C448 469 405 512 352 512H96C42.98 512 0 469 0 416V159.1C0 106.1 42.98 63.1 96 63.1H192z"></path></svg><!-- <i class="fas fa-edit"></i> -->
                </button>

                <button type="button" data-bs-toggle="tooltip" data-placement="auto" title="" data-action="sections-view-questions" data-id="0d5e4eec-229c-4993-9e21-1ba1a6ee489a" class="btn btn-sm btn-primary table-action " data-bs-original-title="View Questions">
                  <svg class="svg-inline--fa fa-clipboard-question" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="clipboard-question" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 384 512" data-fa-i2svg=""><path fill="currentColor" d="M282.5 64H320C355.3 64 384 92.65 384 128V448C384 483.3 355.3 512 320 512H64C28.65 512 0 483.3 0 448V128C0 92.65 28.65 64 64 64H101.5C114.6 26.71 150.2 0 192 0C233.8 0 269.4 26.71 282.5 64zM192 128C209.7 128 224 113.7 224 96C224 78.33 209.7 64 192 64C174.3 64 160 78.33 160 96C160 113.7 174.3 128 192 128zM105.4 230.5C100.9 243 107.5 256.7 119.1 261.2C132.5 265.6 146.2 259.1 150.6 246.6L151.1 245.3C152.2 242.1 155.2 240 158.6 240H216.9C225.2 240 232 246.8 232 255.1C232 260.6 229.1 265.6 224.4 268.3L180.1 293.7C172.6 298 168 305.9 168 314.5V328C168 341.3 178.7 352 192 352C205.1 352 215.8 341.5 215.1 328.4L248.3 309.9C267.9 298.7 280 277.8 280 255.1C280 220.3 251.7 192 216.9 192H158.6C134.9 192 113.8 206.9 105.8 229.3L105.4 230.5zM192 384C174.3 384 160 398.3 160 416C160 433.7 174.3 448 192 448C209.7 448 224 433.7 224 416C224 398.3 209.7 384 192 384z"></path></svg><!-- <i class="fa-solid fa-clipboard-question"></i> -->
                </button>

                <button type="button" data-bs-toggle="tooltip" data-placement="auto" title="" data-action="sections-delete" data-id="0d5e4eec-229c-4993-9e21-1ba1a6ee489a" class="btn btn-sm btn-danger table-action " data-bs-original-title="Delete Section">
                  <svg class="svg-inline--fa fa-trash" aria-hidden="true" focusable="false" data-prefix="fas" data-icon="trash" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512" data-fa-i2svg=""><path fill="currentColor" d="M135.2 17.69C140.6 6.848 151.7 0 163.8 0H284.2C296.3 0 307.4 6.848 312.8 17.69L320 32H416C433.7 32 448 46.33 448 64C448 81.67 433.7 96 416 96H32C14.33 96 0 81.67 0 64C0 46.33 14.33 32 32 32H128L135.2 17.69zM394.8 466.1C393.2 492.3 372.3 512 346.9 512H101.1C75.75 512 54.77 492.3 53.19 466.1L31.1 128H416L394.8 466.1z"></path></svg><!-- <i class="fas fa-trash"></i> -->
                </button>

      </div>
              <div class="table-actions">
                <button type="button" data-toggle="tooltip" data-placement="auto" title="Edit section" data-action="section-edit" data-id="${item.id}" class="btn btn-sm btn-primary table-action">
                  <i class="fas fa-edit"></i>
                </button>
                <button type="button" data-toggle="tooltip" data-placement="auto" title="Delete section" data-action="section-delete" data-id="${item.id}" class="btn btn-sm btn-danger table-action">
                  <i class="fas fa-trash"></i>
                </button>
              </div>
            `;
            },
          },
        ],
        stateSave: true,
      });
    });
  }

  static async submitQuestionnaireForm() {
    const questionnaire = [];
    const sections = [];
    const section1 = [];
    const section2 = [];
    const section3 = [];
    const section4 = [];
    const questions = [];
    const answers = [];

    //Questionnaire
    questionnaire['name'] = $('#questionnaire-form #inputName').val();
    questionnaire['tenant_id'] = $('#questionnaire-form #inputTenantID').val();
    questionnaire['type'] = $('#questionnaire-form #inputType').val();
    questionnaire['active'] = $('#questionnaire-form #inputActive').is(
      ':checked'
    );
    questionnaire['id'] = UUID();

    //Sections
    section1['name'] = 'Goal';
    section1['type'] = 'goal';
    section1['title'] = $('#section-1-container #inputTitle').val();
    section1['sub_text'] = $('#section-1-container #inputsubText').val();
    section1['sort_order'] = $('#section-1-container #inputSortOrder').val();
    section1['id'] = UUID();
    section1['questionnaire_id'] = questionnaire['id'];

    section2['name'] = 'Demographics';
    section2['type'] = 'demographics';
    section2['title'] = $('#section-2-container #inputTitle').val();
    section2['sub_text'] = $('#section-2-container #inputsubText').val();
    section2['sort_order'] = $('#section-2-container #inputSortOrder').val();
    section2['id'] = UUID();
    section2['questionnaire_id'] = questionnaire['id'];

    section3['name'] = 'Distribution';
    section3['type'] = 'distribution';
    section3['title'] = $('#section-3-container #inputTitle').val();
    section3['sub_text'] = $('#section-3-container #inputsubText').val();
    section3['sort_order'] = $('#section-3-container #inputSortOrder').val();
    section3['id'] = UUID();
    section3['questionnaire_id'] = questionnaire['id'];

    section4['name'] = 'Materials';
    section4['type'] = 'materials';
    section4['title'] = $('#section-4-container #inputTitle').val();
    section4['sub_text'] = $('#section-4-container #inputsubText').val();
    section4['sort_order'] = $('#section-4-container #inputSortOrder').val();
    section4['id'] = UUID();
    section4['questionnaire_id'] = questionnaire['id'];

    sections.push(section1);
    sections.push(section2);
    sections.push(section3);
    sections.push(section4);

    //questions
    $('#section-1-container .question').each(function() {
      const questionTemp = [];
      questionTemp['section_id'] = section1['id'];
      questionTemp['id'] = $(this).data('id');
      questionTemp['component'] = $(this).data('component');
      questionTemp['text'] = $(this)
        .children('.question-text')
        .text();
      questionTemp['sub_text'] = $(this).data('sub-text');
      questionTemp['tooltip'] = $(this).data('tooltip');
      questionTemp['sort_order'] = $(this).data('sort-order');
      questionTemp['add_name'] = $(this).data('list-add-name');
      questionTemp['insert_sub'] = $(this).data('insert-sub-text');

      questions.push(questionTemp);
    });

    $('#section-2-container .question').each(function() {
      const questionTemp = [];
      questionTemp['section_id'] = section2['id'];
      questionTemp['id'] = $(this).data('id');
      questionTemp['component'] = $(this).data('component');
      questionTemp['text'] = $(this)
        .children('.question-text')
        .text();
      questionTemp['sub_text'] = $(this).data('sub-text');
      questionTemp['tooltip'] = $(this).data('tooltip');
      questionTemp['sort_order'] = $(this).data('sort-order');
      questionTemp['add_name'] = $(this).data('list-add-name');
      questionTemp['insert_sub'] = $(this).data('insert-sub-text');

      questions.push(questionTemp);
    });

    $('#section-3-container .question').each(function() {
      const questionTemp = [];
      questionTemp['section_id'] = section3['id'];
      questionTemp['id'] = $(this).data('id');
      questionTemp['component'] = $(this).data('component');
      questionTemp['text'] = $(this)
        .children('.question-text')
        .text();
      questionTemp['sub_text'] = $(this).data('sub-text');
      questionTemp['tooltip'] = $(this).data('tooltip');
      questionTemp['sort_order'] = $(this).data('sort_order');
      questionTemp['add_name'] = $(this).data('list-add-name');
      questionTemp['insert_sub'] = $(this).data('insert-sub-text');

      questions.push(questionTemp);
    });

    $('#section-4-container .question').each(function() {
      const questionTemp = [];
      questionTemp['section_id'] = section4['id'];
      questionTemp['id'] = $(this).data('id');
      questionTemp['component'] = $(this).data('component');
      questionTemp['text'] = $(this).data('text');
      questionTemp['sub_text'] = $(this).data('sub-text');
      questionTemp['tooltip'] = $(this).data('tooltip');
      questionTemp['sort_order'] = $(this).data('sort-order');
      questionTemp['add_name'] = $(this).data('list-add-name');
      questionTemp['insert_sub'] = $(this).data('insert-sub-text');

      questions.push(questionTemp);
    });

    $('.answer').each(function() {
      const answerTemp = [];
      answerTemp['question_id'] = $(this).data('question-id');
      answerTemp['text'] = $(this)
        .children('.answer-text')
        .text();
      answerTemp['sub_text'] = $(this).data('sub-text');
      answerTemp['sort_order'] = $(this).data('sort-order');
      answerTemp['id'] = UUID();

      answers.push(answerTemp);
    });

    // loop through arrays and post to api

    MatgenGlobal.UI.loading(`Saving questionnaire data...`);

    //questionnaire
    const response = await MatgenGlobal.Data.saveItem(
      'questionnaires',
      {
        name: questionnaire['name'],
        tenant_id: questionnaire['tenant_id'],
        type: questionnaire['type'],
        active: questionnaire['active'],
        id: questionnaire['id'],
      },
      false
    );
    console.log(response);

    for (const section of sections) {
      const r = await MatgenGlobal.Data.saveItem(
        'sections',
        {
          name: section['name'],
          questionnaire_id: section['questionnaire_id'],
          title: section['title'],
          subtext: section['sub_text'],
          sort_order: section['sort_order'],
          id: section['id'],
          type: section['type'],
        },
        false
      );
      console.log(r);
    }

    for (const question of questions) {
      const r = await MatgenGlobal.Data.saveItem(
        'questions',
        {
          section_id: question['section_id'],
          component: question['component'],
          text: question['text'],
          sub_text: question['sub_text'],
          sort_order: question['sort_order'],
          list_add_name: question['add_name'],
          tooltip: question['tooltip'],
          insert_sub_text: question['insert_sub'],
          id: question['id'],
        },
        false
      );
      console.log(r);
    }

    for (const answer of answers) {
      const response = await MatgenGlobal.Data.saveItem(
        'answers',
        {
          question_id: answer['question_id'],
          text: answer['text'],
          sub_text: answer['sub_text'],
          sort_order: answer['sort_order'],
          icon: '',
          id: answer['id'],
        },
        false
      );
      console.log(response);
    }
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();

    //redirect to confirmation page
  }

  static async addAnswer(qid, id = null) {
    const modalId = `answer-form-modal`;
    let args;
    let action = 'create';
    let action_title = 'Add';

    if (id) {
      action = 'edit';
      action_title = 'Edit';
    }

    const Form = MatgenForms.SectionAnswerForm;

    const formHTML = await Form.getHTML();

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: `${action_title} Answer`,
      content: formHTML,
      buttons: [
        {
          id: `${action}-answer-submit`,
          classname: 'primary btn btn-primary',
          label: `${action_title}`,
        },
      ],
      width: '550px',
    });

    $(`#answer-form h1`).hide();
    $(`#answer-form button:not(#toggle-icon-chooser)`).hide();

    $(`#${modalId}`).modal('toggle');

    $(document).off('click', `#${action}-answer-submit`);
    $(document).on('click', `#${action}-answer-submit`, () => {
      $(`#answer-form`).submit();
    });

    $(document).off('submit', `#answer-form`);
    $(document).on('submit', `#answer-form`, async e => {
      e.preventDefault();

      args = {
        question_id: $('#inputQuestionID').val(),
        text: $('#inputText').val(),
        sub_text: $('#inputSubtext').val(),
        sort_order: $('#inputSortOrder').val(),
        icon: $('#inputIconButton').val(),
        id: id ? id : UUID(),
      };
      console.log(args);

      MatgenGlobal.UI.validateForm(`answer-form`, async () => {
        $(`.list-group[data-id="${qid}"] .answer-container`).append(`
          <li class="list-group-item answer" style="display: flex; justify-content: space-between;" data-question-id="${qid}" data-id="${args.id}" data-sub-text="${args.sub_text}" data-sort-order="${args.sort_order}">
            <div style="width: 75%;" class="answer-text">${args.text}</div>
          </li>
        `);
        $(`#answer-form-modal`).modal('hide');
      });
    });
  }

  static async addQuestion(section = 1, id = null) {
    const modalId = `question-form-modal`;

    let args;
    let action = 'create';
    let action_title = 'Add';

    if (id) {
      action = 'edit';
      action_title = 'Edit';
    }

    const Form = MatgenForms.SectionQuestionForm;

    const formHTML = await Form.getHTML();

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: `${action_title} Question`,
      content: formHTML,
      buttons: [
        {
          id: `${action}-question-submit`,
          classname: 'primary btn btn-primary',
          label: `${action_title}`,
        },
      ],
      width: '550px',
    });

    $(`#question-form h1`).hide();
    $(`#question-form button:not(#toggle-icon-chooser)`).hide();

    $(`#${modalId}`).modal('toggle');

    $(document).off('click', `#${action}-question-submit`);
    $(document).on('click', `#${action}-question-submit`, () => {
      $(`#question-form`).submit();
    });

    $(document).off('submit', `#question-form`);
    $(document).on('submit', `#question-form`, async e => {
      e.preventDefault();

      args = {
        component: $('#inputComponent').val(),
        text: $('#inputText').val(),
        sub_text: $('#inputSubtext').val(),
        sort_order: $('#inputSortOrder').val(),
        list_add_name: $('#inputListAddName').val(),
        tooltip: $('#inputTooltip').val(),
        insert_sub_text: $('#inputInsertSubText').val(),
        id: id ? id : UUID(),
      };
      console.log(args);

      MatgenGlobal.UI.validateForm(`question-form`, async () => {
        $(`.question-container-${section}`).append(`
        <ul class="list-group" data-id="${args.id}">
          <li class="list-group-item list-group-item-secondary question" style="display: flex; justify-content: space-between;" data-id="${args.id}" data-component="${args.component}" data-sub-text="${args.sub_text}" data-sort-order="${args.sort_order}" data-list-add-name="${args.list_add_name_name}" data-tooltip="${args.tooltip}" data-insert-sub-text="${args.insert_sub_text}">
            <div style="width: 75%;" class="question-text">${args.text}</div><span class="questionnaire-form-action" data-action="add-answer" data-id="${args.id}"><i class="fas fa-pen"></i></span></div></div>
          </li>
          <div class="answer-container">
          </div>
        </ul>
        <br>
        `);
        $(`#question-form-modal`).modal('hide');
      });
    });
  }

  static async saveForm(type, name, id = null) {
    const modalId = `${type}-form-modal`;

    let Form;
    let args;
    let action = 'create';
    let action_title = 'Create';
    let questionOptions = [
      { label: 'Select Single', value: 'select-single' },
      { label: 'Select Multiple', value: 'select-multiple' },
    ];

    if (type === 'question') {
      MatgenGlobal.UI.loading('Loading question...');
      const question = await MatgenGlobal.Data.getQuestion(id);
      const section = await MatgenGlobal.Data.getSection(question.section_id);
      const questionnaire = await MatgenGlobal.Data.getQuestionnaireRecord(
        section.questionnaire_id
      );
      if (questionnaire.type === 'microsite') {
        questionOptions = [
          { label: 'Select Single', value: 'select-single' },
          { label: 'Select Multiple', value: 'select-multiple' },
          { label: 'Text', value: 'text' },
          { label: 'Text Area', value: 'textarea' },
          { label: 'Range', value: 'range' },
          { label: 'Card Select Single', value: 'card-select-single' },
          { label: 'List Select Single', value: 'list-select-single' },
          { label: 'Image Select Single', value: 'img-select-single' },
          { label: 'List FAQ', value: 'list-faq' },
          { label: 'Upload Single', value: 'upload-single' },
          { label: 'Multi-Input', value: 'multi-input' },
          { label: 'List Multi-Input', value: 'list-multi-input' },
        ];
      }
    }

    const questionInputs = [
      {
        component: 'Select',
        options: {
          label: 'Select Component',
          id: 'inputComponent',
          classes: 'top',
          options: questionOptions,
          disabled: id ? true : false,
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Question',
          id: 'inputText',
          classes: 'middle',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'subtext',
          label: 'Subtext',
          id: 'inputSubtext',
          classes: 'middle',
        },
      },
      {
        component: 'Text',
        options: {
          type: 'text',
          label: 'Tooltip',
          id: 'inputTooltip',
          classes: 'bottom',
        },
      },
      {
        component: 'RawHTML',
        html: `
        <input type="hidden" id="section-id" name="section-id" value="${id}"/>
        `,
      },
    ];

    if (id) {
      action = 'edit';
      action_title = 'Save';
    }
    switch (type) {
      default:
        break;
      case 'tenant':
        Form = MatgenForms.TenantForm;
        break;
      case 'questionnaire':
        Form = MatgenForms.QuestionnaireForm;
        break;
      case 'section':
        Form = MatgenForms.SectionForm;
        break;
      case 'question':
        Form = await new MatgenForms.DynamicForm(
          questionInputs,
          [],
          'edit-question-form'
        );
        Form = Form.form;
        //const content = await DynamicForm.form.getElement();
        break;
      case 'answer':
        Form = MatgenForms.AnswerForm;
        break;
    }

    const formHTML = await Form.getHTML();
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: `${action_title} ${name}`,
      content: formHTML,
      buttons: [
        {
          id: `${action}-${type}-submit`,
          classname: 'primary btn btn-primary',
          label: `${action_title}`,
        },
      ],
      width: '550px',
    });

    $(`#${type}-form h1`).hide();
    $(
      `#${type}-form button:not(#toggle-icon-chooser):not(#template-file-associate)`
    )
      .not('#toggle-icon-chooser, #template-file-associate')
      .hide();
    switch (type) {
      default:
        break;
      case 'tenant':
        if (id) {
          const item = await MatgenGlobal.Data.getTenant(id);
          //console.log(item);
          $('#inputName').val(item.name);
          $('#inputIs508').val(item.is508);
        }
        break;
      case 'questionnaire':
        if (id) {
          const item = await MatgenGlobal.Data.getQuestionnaire(id);
          //console.log(item);
          $('#inputName').val(item.name);
          $('#inputTenantID').val(item.tenant_id);
          $('#inputType').val(item.type);
          $('#inputActive').val(item.active);
        }
        break;
      case 'section':
        if (id) {
          const item = await MatgenGlobal.Data.getSection(id);
          //console.log(item);
          $('#inputName').val(item.name);
          $('#inputQuestionnaireID').val(item.questionnaire_id);
          $('#inputType').val(item.type);
          $('#inputTitle').val(item.title);
          $('#inputSubtext').val(item.subtext);
          $('#inputSortOrder').val(item.sort_order);
        }
        break;
      case 'question':
        if (id) {
          const item = await MatgenGlobal.Data.getQuestion(id);
          //console.log(item);
          $('#inputComponent').val(item.component);
          $('#inputText').val(item.text);
          $('#inputSubtext').val(item.sub_text);
          $('#inputSortOrder').val(item.sort_order);
          $('#inputListAddName').val(item.list_add_name);
          $('#inputTooltip').val(item.tooltip);
          $('#inputInsertSubText').val(item.insert_sub_text);
          $('#section-id').val(item.section_id);
        }
        break;
      case 'answer':
        if (id) {
          const item = await MatgenGlobal.Data.getAnswer(id);
          //console.log(item);
          $('#inputQuestionID').val(item.question_id);
          $('#inputText').val(item.text);
          $('#inputSubtext').val(item.sub_text);
          $('#inputSortOrder').val(item.sort_order);
          $('#question-id').val(item.question_id);
          $('#answer-icon')
            .empty()
            .append(`<i class="${item.icon}"></i>`);
          //$('#inputIconButton').val(item.icon);
          //$('#inputFontAwesome').css('display', 'block');
          //$('#iconPreview').addClass(item.icon);
        }

        /*IconPicker.Init({
          jsonUrl: '/assets/iconpicker-1.5.0.json',
          searchPlaceholder: 'Search Icon',
          cancelButton: 'Cancel',
          noResultsFound: 'No results found.',
          borderRadius: '20px',
        });

        IconPicker.Run('#inputIconButton');*/
        break;
    }

    $(`#${modalId}`).modal('toggle');

    $(document).off('click', `#${action}-${type}-submit`);
    $(document).on('click', `#${action}-${type}-submit`, () => {
      $(`#${type}-form`).submit();
    });

    $(document).off('submit', `#${type}-form`);
    $(document).on('submit', `#${type}-form`, async e => {
      e.preventDefault();

      switch (type) {
        default:
          break;
        case 'tenant':
          args = {
            name: $('#inputName').val(),
            is508: $('#inputIs508').val() == '1' ? 1 : 0,
            id: id ? id : UUID(),
          };
          break;
        case 'questionnaire':
          args = {
            name: $('#inputName').val(),
            tenant_id: $('#inputTenantID').val(),
            type: $('#inputType').val(),
            active: $('#inputActive').val(),
            id: id ? id : UUID(),
          };
          break;
        case 'section':
          args = {
            name: $('#inputName').val(),
            questionnaire_ID: $('#inputQuestionnaireID').val(),
            type: $('#inputType').val(),
            title: $('#inputTitle').val(),
            subtext: $('#inputSubtext').val(),
            sort_order: $('#inputSortOrder').val(),
            id: id ? id : UUID(),
          };
          break;
        case 'question':
          args = {
            section_id: $('#inputSectionID').val(),
            component: $('#inputComponent').val(),
            text: $('#inputText').val(),
            sub_text: $('#inputSubtext').val(),
            sort_order: $('#item.sort_order').val(),
            list_add_name: $('#inputListAddName').val(),
            tooltip: $('#inputTooltip').val(),
            insert_sub_text: $('#inputInsertSubText').val(),
            id: id ? id : UUID(),
          };
          break;
        case 'answer':
          args = {
            question_id: $('#question-id').val(),
            text: $('#inputText').val(),
            sub_text: $('#inputSubtext').val(),
            icon: `${$('#answer-icon svg').attr('data-prefix')} fa-${$(
              '#answer-icon svg'
            ).attr('data-icon')}`,
            id: id ? id : UUID(),
          };
          break;
      }

      MatgenGlobal.UI.validateForm(`${type}-form`, async () => {
        MatgenGlobal.UI.loading(`Saving ${type} data...`);
        const response = await MatgenGlobal.Data.saveItem(
          type,
          args,
          id ? true : false
        );
        console.log(response);
        $(`#${type}-form-modal`).modal('hide');
        MatgenGlobal.UI.stopLoading();
        if (['question', 'answer'].includes(type)) {
          if (type === 'question') {
            $(`[data-id=${args.id}]`)
              .find('.question-title')
              .text(args.text);
          }
          if (type === 'answer') {
            $(`[data-id=${args.id}]`)
              .find('.answer-title')
              .text(args.text);
            $(`[data-id=${args.id}]`)
              .find('.answer-title svg')
              .remove();
            $(`[data-id=${args.id}]`)
              .find('.answer-title')
              .prepend(`<i class="${args.icon}"></i>`);
          }
        } else {
          MatgenGlobal.Router.core.reload();
        }
      });
    });
  }

  static viewQuestionnaires(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/questionnaires?tenant_id=${id}`;
    } else {
      window.location.href = `/questionnaires?tenant_id=${id}`;
    }
  }

  static viewSections(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/sections?questionnaire_id=${id}`;
    } else {
      window.location.href = `/sections?questionnaire_id=${id}`;
    }
  }

  static viewQuestions(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/questions?section_id=${id}`;
    } else {
      window.location.href = `/questions?section_id=${id}`;
    }
  }

  static viewAnswers(id) {
    if (MatgenGlobal.Router.hash) {
      window.location.href = `/${MatgenGlobal.RootPage}#/answers?question_id=${id}`;
    } else {
      window.location.href = `/answers?question_id=${id}`;
    }
  }

  static async sortAnswers(question_id) {
    MatgenGlobal.UI.loading('Loading answers...');
    const answers = await MatgenGlobal.Data.getQuestionAnswers(question_id);
    answers.sort((a, b) => a.sort_order - b.sort_order);
    const answerContent = `
    <div id="sort-div">
      <ul id="sort-list">
        ${answers
          .map(
            a => `
          <li data-question-id="${question_id}" data-id="${a.id}">${a.text}</li>
        `
          )
          .join('')}
      </ul>
    </div>
    `;
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.M4CModal.show({
      id: 'sort-answer-modal',
      title: 'Sort Answers',
      content: answerContent,
      buttons: [
        {
          id: 'sort-answer-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
    });
    $('#sort-list').sortable();
  }

  static async sortQuestions(section_id) {
    MatgenGlobal.UI.loading('Loading questions...');
    const questions = await MatgenGlobal.Data.getSectionQuestions(section_id);
    questions.sort((a, b) => a.sort_order - b.sort_order);
    const questionContent = `
    <div id="sort-div">
      <ul id="sort-list">
        ${questions
          .map(
            q => `
          <li data-section-id="${section_id}" data-id="${q.id}">${q.text}</li>
        `
          )
          .join('')}
      </ul>
    </div>
    `;
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.M4CModal.show({
      id: 'sort-question-modal',
      title: 'Sort Questions',
      content: questionContent,
      buttons: [
        {
          id: 'sort-question-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
    });
    $('#sort-list').sortable();
  }

  static async tenantFormEdit(id) {
    const tenant = await MatgenGlobal.Data.getTenant(id);
    const modalId = 'tenant-form-modal';

    const formHTML = MatgenForms.TenantForm.getHTML();

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Create/Edit Tenant',
      content: formHTML,
      buttons: [
        {
          id: 'create-tenant-submit',
          classname: 'primary btn btn-primary',
          label: 'Create',
        },
      ],
    });

    $('#tenant-form h1').hide();
    $('#tenant-form button').hide();
    $('#inputName').val(tenant.name);
    $('#InputIs508').val(tenant.is508);

    $(document).off('click', '#edit-tenant-submit');
    $(document).on('click', '#edit-tenant-submit', () => {
      $('#tenant-form').submit();
    });
    $(document).off('submit', '#tenant-form');
    $(document).on('submit', '#tenant-form', e => {
      e.preventDefault();
      MatgenGlobal.UI.validateForm('tenant-form', async () => {
        MatgenGlobal.UI.loading('Saving tenant data...');
        const response = await MatgenGlobal.Data.saveTenant(
          {
            name: $('#inputName').val(),
            is508: $('#InputIs508').val() == '1' ? 1 : 0,
            id: id,
          },
          true
        );
        console.log(response);
        $('#tenant-form-modal').modal('hide');
        MatgenGlobal.UI.stopLoading();
        MatgenGlobal.Router.core.reload();
      });
    });
  }

  static async questionnaireFormEdit(id) {
    const questionnaire = await MatgenGlobal.Data.getQuestionnaire(id);
    const modalId = 'questionnaire-form-modal';

    const formHTML = await MatgenForms.QuestionnaireForm.getHTML();

    MatgenGlobal.M4CModal.show({
      id: modalId,
      title: 'Edit Questionnaire',
      content: formHTML,
      buttons: [
        {
          id: 'edit-questionnaire-submit',
          classname: 'primary btn btn-primary',
          label: 'Edit',
        },
      ],
    });

    $('#questionnaire-form h1').hide();
    $('#questionnaire-form button').hide();
    $('#inputName').val(questionnaire.name);
    $('#InputIs508').val(questionnaire.is508);

    $(document).off('click', '#edit-questionnaire-submit');
    $(document).on('click', '#edit-questionnaire-submit', () => {
      $('#questionnaire-form').submit();
    });
    $(document).off('submit', '#questionnaire-form');
    $(document).on('submit', '#questionnaire-form', e => {
      e.preventDefault();
      MatgenGlobal.UI.validateForm('questionnaire-form', async () => {
        MatgenGlobal.UI.loading('Saving questionnaire data...');
        const response = await MatgenGlobal.Data.saveQuestionnaire(
          {
            name: $('#inputName').val(),
            is508: $('#InputIs508').val() == '1' ? 1 : 0,
            id: id,
          },
          true
        );
        console.log(response);
        $('#questionnaire-form-modal').modal('hide');
        MatgenGlobal.UI.stopLoading();
        MatgenGlobal.Router.core.reload();
      });
    });
  }

  static async addOption(id) {
    const obj = MatgenGlobal.UI.findById(
      MatgenGlobal.editor.cur().fabric.getObjects(),
      id
    );
    MatgenGlobal.UI.promptModal(
      'Alt Text For Image:',
      'Alt Text:',
      alt => {
        const uploader = new Uploader();
        obj.altText = alt;
        uploader.fileSelect(`uploader-${id}`, async () => {
          const file = document.getElementById(`uploader-${id}`).files[0];
          if (file) {
            const durl = await uploader.bufferUpload(file);
            MatgenGlobal.UI.replaceCanvasImage(obj, durl);
            MatgenGlobal.sidebar.markComponentDirty(obj.componentId);
          }
        });
      },
      'Continue'
    );
  }

  static async addOptionOld(id) {
    const obj = MatgenGlobal.UI.findById(
      MatgenGlobal.editor.cur().fabric.getObjects(),
      id
    );

    const uploaderForm = `
      <form id="image-upload-form">
        <div class="form-group">
          <div>
            Choose an Image:<br>Recommended <span id="recommended-size"></span>
            <br><br>
          </div>
          <button type="button" class="btn btn-primary" id="uploader">
            Select file
          </button>
        </div>
        <div class="form-group" id="uploaded-image-group" style="display:none;"></div>
        <div class="form-group" id="alt-text-group" style="display:none;">
          <label for="title">Alt Text</label>
          <input class="form-control" placeholder="Alt text" name="alt" type="text" id="alt">
        </div>
      </form>
    `;

    if (obj) {
      if (obj.type === 'image') {
        const modalId = 'upload-modal';
        console.error('UPLOADER FORM UI FUNCTIONS');
        MatgenGlobal.M4CModal.show({
          id: modalId,
          title: 'Upload Image',
          content: uploaderForm,
          buttons: [
            {
              id: 'save-upload',
              classname: 'primary btn btn-primary',
              label: 'Upload &amp; Save',
            },
          ],
        });

        MatgenGlobal.UI.initUploader(id, obj);

        $('#save-upload').off('click');
        $('#save-upload').on('click', async () => {
          if (
            !$('#uploaded-image-durl').val() ||
            $('#uploaded-image-durl').val() === ''
          ) {
            MatgenGlobal.UI.handleError(
              'Upload selection required',
              'You must select an image to upload.'
            );
            return false;
          }
          if ($('#image-upload-form')[0].checkValidity()) {
            const obj = MatgenGlobal.editor
              .cur()
              .fabric.getObjects()
              .find(o => o.id === $('#uploaded-image-obj-id').val());
            obj.altText = $('#alt').val();

            MatgenGlobal.UI.replaceCanvasImage(
              obj,
              $('#uploaded-image-durl').val()
            );
            $(`#${id}`).modal('hide');

            const oldId = obj.id;
            const newId = UUID();
            obj.set('id', newId);

            obj.set('currentOptionId', newId);
            MatgenGlobal.editor.cur().fabric.renderAll();
            $(`#${modalId}`).modal('hide');
            MatgenGlobal.UI.loading('Uploading and saving option...');
            let openItems = sessionStorage.getItem('matgen-tree-state');
            if (!openItems) {
              openItems = [];
            } else {
              openItems = JSON.parse(openItems);
            }
            if (openItems.includes(oldId)) {
              openItems.splice(openItems.indexOf(oldId), 1);
              openItems.push(obj.id);
            }
            //console.log('save state:', openItems);
            sessionStorage.setItem(
              'matgen-tree-state',
              JSON.stringify(openItems)
            );
            await MatgenGlobal.UI.saveOption(obj);

            MatgenGlobal.UI.stopLoading();
          } else {
            $('#image-upload-form')[0].reportValidity();
          }
        });
      } else if (obj.type === 'textbox') {
        MatgenGlobal.UI.textForm({ edit: false, id: obj.id });
      }
    }
  }

  static async viewTemplate(id) {
    MatgenGlobal.UI.loading('Loading template preview...');
    const template = await MatgenGlobal.Data.getTemplate(id);
    if (['PDF', 'IMAGE'].includes(template.type)) {
      const pages = await MatgenGlobal.Data.getPages(id);
      const pageThumbs = pages
        .sort((a, b) => a.number - b.number)
        .map(p => p.id);
      const image = new Image();
      const ext = '.png';
      image.src = MatgenGlobal.Data.getTemplateFileURL(
        pageThumbs[0],
        template.tenant_id,
        ext
      );

      image.onerror = () => {
        MatgenGlobal.UI.stopLoading();
      };
      image.onload = () => {
        MatgenGlobal.UI.stopLoading();
        image.onload = null;
        const img = $(image.outerHTML);
        img.css({
          'max-width': image.width / 2,
          margin: 'auto',
        });
        img.attr('id', 'page-thumb');
        img.attr('alt', 'Preview of template');
        let content = img;
        if (pageThumbs.length > 1) {
          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          wrapper.append(
            $(
              `
                ${
                  pageThumbs.length > 1
                    ? `
                  <div id="thumb-pager" class="text-center">
                  ${pageThumbs
                    .map(
                      (t, i) => `
                      ${i === 0 ? '' : ' - '}
                      <a href="#" data-id="${t}" data-tenant-id="${
                        template.tenant_id
                      }" data-index="${i}" class="thumb-page">${i + 1}</a>
                      `
                    )
                    .join('')}
                  </div>
                  `
                    : ''
                }
                </div>`
            )
          );
          content = wrapper;
        }

        MatgenGlobal.UI.alertModal(
          'Template Preview',
          content[0].outerHTML,
          null,
          'preview-modal'
        );
      };
    } else if (template.type === 'FILE') {
      if (template.preview_type === 'IMAGE') {
        const image = new Image();
        image.src = MatgenGlobal.Data.getTemplateFileURL(
          template.id,
          template.tenant_id,
          template.preview_image_ext
        );

        image.onerror = () => {
          MatgenGlobal.UI.stopLoading();
        };
        image.onload = () => {
          MatgenGlobal.UI.stopLoading();
          image.onload = null;
          const img = $(image.outerHTML);
          img.css({
            'max-width': image.width / 2,
            margin: 'auto',
          });
          img.attr('id', 'page-thumb');
          img.attr('alt', 'Preview of material');
          let content = img;

          const wrapper = $('<div class="preview-thumb" id="preview-thumb" />');
          wrapper.append(img);
          content = wrapper;

          MatgenGlobal.UI.alertModal(
            'Material Preview',
            content[0].outerHTML,
            null,
            'preview-modal'
          );
        };
      } else if (template.preview_type === 'LINK') {
        delete MatgenGlobal.generating;
        MatgenGlobal.UI.stopLoading();
        window.open(template.preview_link);
      } else if (template.preview_type === 'SELF') {
        const fileURL = MatgenGlobal.Data.getTemplateFileURL(
          template.id,
          template.tenant_id,
          template.file_ext
        );
        delete MatgenGlobal.generating;
        MatgenGlobal.UI.stopLoading();
        window.open(fileURL);
      } else {
        console.error('Bad template type:', template);
        MatgenGlobal.UI.handleError(
          'Unknown template type',
          'The system does not recognize the given template type.'
        );
      }
    } else {
      console.error('Bad template type:', template);
      MatgenGlobal.UI.handleError(
        'Unknown template type',
        'The system does not recognize the given template type.'
      );
    }
  }

  static async editTemplateName(id, row) {
    let template = false;
    if (id) {
      MatgenGlobal.UI.loading('Loading template data...');
      template = await MatgenGlobal.Data.getTemplate(id);
      MatgenGlobal.UI.stopLoading();
    }

    MatgenUIFunctions.modalForm({
      prefix: 'template',
      inputs: [
        new TextInput({
          type: 'text',
          label: 'Name',
          dataId: 'name',
          id: 'inputName',
          required: true,
          autofocus: true,
        }),
      ],
      title: 'Edit Template Name',
      data: template ? template : false,
      options: { inline: false },
      actions: [
        {
          id: 'template-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Update',
        },
      ],
      listeners: () => {
        $(document).off('click', '#template-form-submit');
        $(document).on('click', '#template-form-submit', () => {
          $('#template-form').submit();
        });

        $(document).off('submit', '#template-form');
        $(document).on('submit', '#template-form', async e => {
          e.preventDefault();

          if ($('#template-form')[0].checkValidity()) {
            MatgenGlobal.UI.loading('Saving template...', '#form-container');
            try {
              const response = await MatgenGlobal.Data.saveTemplate(
                {
                  name: $('#inputName').val(),
                  id: $('#template-data-id').val(),
                  tenant_id: template.tenant_id,
                },
                true
              );
              console.log(response);
            } catch (e) {
              console.error(e);
              MatgenGlobal.UI.handleError(
                'Error Saving Template',
                'There was an error saving the template.'
              );
            }

            MatgenGlobal.Tables.TemplateTable.updateCellData(
              row,
              1,
              $('#inputName').val()
            );

            MatgenGlobal.UI.stopLoading();
            $(`#template-form-modal`).modal('hide');
          } else {
            $(`#template-form`)[0].reportValidity();
          }
        });
      },
    });
  }

  static async editTemplateData(id) {
    let type, template;
    emit({
      event: 'matgen-loading-start',
      detail: { message: 'Loading form data...' },
    });

    if (id) {
      template = await MatgenGlobal.Data.getTemplate(id);
      type = template.type;
      MatgenGlobal.tmp = MatgenForms.TemplateForm;
      MatgenForms.TemplateForm.setValues(template);
    } else {
      MatgenForms.TemplateForm.reset();
    }

    MatgenGlobal.UI.showFormModal({
      modalId: 'template-form-modal',
      formId: 'template-form',
      title: id ? 'Edit Template' : 'Create Template',
      content: await MatgenForms.TemplateForm.getHTML({
        inline: false,
        hide: id ? ['type', 'tenant'] : [],
      }),
      actions: [
        {
          id: 'template-form-submit',
          classname: 'primary btn btn-primary',
          label: 'Save',
        },
      ],
    });

    if (id) {
      $('#template-form').append(
        $(`
           <input type="hidden" id="inputTemplateID" name="inputTemplateID" value="${id}" />
         `)
      );
    }

    if (template.associated_file) {
      $('#file-association').append(`
      <div id="file-association-file">
        <b>Link text: </b>${template.associated_file_link_text}<br>
        <b>File: </b><a target="_blank" href="https://${MatgenGlobal.AMPLIFY_VARS.s3Bucket}.s3.amazonaws.com/tenant/${template.tenant_id}/templates/${template.associated_file}">${template.associated_file}</a>
        <span id="remove-associated-file" style="color:#f00;cursor:pointer;">
          <i class="fa-sharp fa-solid fa-delete-left"></i>
        </span><br>
      </div>
    `);
    }

    if (!template || template.type !== 'FILE') {
      $('#inputPreviewType')
        .removeAttr('required')
        .closest('.row')
        .hide();
      $('#inputPreviewLink')
        .removeAttr('required')
        .closest('.row')
        .hide();
    }
    if (!template || template.type === 'FILE') {
      $('#inputWidth')
        .removeAttr('required')
        .closest('.row')
        .hide();
      $('#inputHeight')
        .removeAttr('required')
        .closest('.row')
        .hide();
      if (template && template.preview_type === 'LINK') {
        $('#inputPreviewLink')
          .attr('required', true)
          .closest('.row')
          .show();
      }
    }
    if (type) {
      $('#template-form').append(
        $(`
         <input type="hidden" id="inputTemplateType" name="inputTemplateType" value="${type}" />
       `)
      );
    }
    emit({
      event: 'matgen-loading-stop',
    });
  }

  static async togglePublished(id, type = 'template') {
    MatgenGlobal.UI.loading('Updating microsite record...');
    let published = false;
    if (type === 'microsite') {
      let microsite;
      try {
        microsite = await MatgenGlobal.Data.getMicrosite(id);
      } catch (e) {
        console.error(e);
        return false;
      }
      console.log('MS:', microsite);
      if (!microsite || microsite.length === 0) {
        console.error(Error('Microsite not found'));
        return false;
      } else {
        published = !microsite.published;
        try {
          const db_response = await MatgenGlobal.Data.API.request(
            `/microsites`,
            'POST',
            {
              id: microsite.id,
              subdomain: microsite.subdomain,
              user_name: microsite.user_name,
              study_id: microsite.study_id,
              published,
              edit: true,
            }
          );
          console.log(db_response);
          if (published) {
            $(`[data-id="${id}"]`).removeClass('btn-warning');
            $(`[data-id="${id}"]`).addClass('btn-primary');
          } else {
            $(`[data-id="${id}"]`).removeClass('btn-primary');
            $(`[data-id="${id}"]`).addClass('btn-warning');
          }
        } catch (e) {
          console.error(e);
          return false;
        }
      }
    } else {
      let template;
      try {
        template = await MatgenGlobal.Data.getTemplate(id);
      } catch (e) {
        console.error(e);
        return false;
      }
      if (!template) {
        console.error(Error('Template not found'));
      } else {
        published = !template.published;
        try {
          const response = await MatgenGlobal.Data.saveTemplate(
            {
              id: template.id,
              tenant_id: template.tenant_id,
              published,
            },
            true
          );
          console.log(response);
        } catch (e) {
          console.error(e);
          return false;
        }
      }
    }

    if (published) {
      $(`.table-action[data-action=template-toggle-publish][data-id=${id}]`)
        .closest('tr')
        .removeClass('unpublished');
    } else {
      $(`.table-action[data-action=template-toggle-publish][data-id=${id}]`)
        .closest('tr')
        .addClass('unpublished');
    }
    $(
      `.table-action[data-action=template-toggle-publish][data-id=${id}]`
    ).replaceWith(
      $(
        `
       <button type="button" data-bs-toggle="tooltip" data-placement="auto" title="${
         published ? 'Unpublish' : 'Publish'
       }" data-action="template-toggle-publish" data-id="${id}" class="btn btn-sm ${
          published ? 'btn-primary' : 'btn-warning'
        } table-action">
         <i class="${published ? 'fas fa-eye-slash' : 'fas fa-eye'}"></i>
       </button>
     `
      )
    );

    MatgenGlobal.UI.stopLoading();
  }

  static async deleteTenant(id) {
    MatgenGlobal.UI.loading('Deleting tenant...');
    const response = await MatgenGlobal.Data.deleteTenant(id);
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();
  }

  static async deleteAnswer(id) {
    MatgenGlobal.UI.loading('Deleting answer...');
    const response = await MatgenGlobal.Data.deleteAnswer(id);
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();
  }

  static async deleteQuestion(id) {
    MatgenGlobal.UI.loading('Deleting answer...');
    const response = await MatgenGlobal.Data.deleteQuestion(id);
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();
  }

  static async deleteSection(id) {
    MatgenGlobal.UI.loading('Deleting answer...');
    const response = await MatgenGlobal.Data.deleteSection(id);
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();
  }

  static async deleteQuestionnaire(id) {
    MatgenGlobal.UI.loading('Deleting answer...');
    const response = await MatgenGlobal.Data.deleteQuestionnaire(id);
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();
  }

  static async getTemplateExport(id) {
    const template = await MatgenGlobal.Data.getTemplate(id);
    if (template === false) {
      return false;
    }

    const pages = await MatgenGlobal.Data.getPages(id);
    if (pages === false) {
      return false;
    }

    const components = await MatgenGlobal.Data.getComponents(id);
    if (components === false) {
      return false;
    }

    const options = await Promise.all(
      components.map(c => MatgenGlobal.Data.getComponentOptions(c.id))
    );

    for (let i = 0; i < components.length; i++) {
      components[i].options = options.find(o =>
        o.find(opt => opt.component_id === components[i].id)
      );
    }

    const pageFiles = {};
    for (let i = 0; i < pages.length; i++) {
      pageFiles[pages[i].id] = {};
      const pageJSON = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${template.tenant_id}/templates/${pages[i].id}.json`,
        type: 'json',
        customPrefix: { public: '' },
      });
      pageFiles[pages[i].id].json = pageJSON;

      const pagePreview = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${template.tenant_id}/templates/${pages[i].id}.png`,
        customPrefix: { public: '' },
      });
      pageFiles[pages[i].id].preview = pagePreview;
    }

    const optionFiles = {};

    for (let i = 0; i < options.length; i++) {
      for (let j = 0; j < options[i].length; j++) {
        optionFiles[options[i][j].id] = {};
        const optionJSON = await MatgenGlobal.Data.API.getS3File({
          path: `tenant/${template.tenant_id}/options/${options[i][j].id}.json`,
          type: 'json',
          customPrefix: { public: '' },
        });
        optionFiles[options[i][j].id].json = optionJSON;

        const optionPreview = await MatgenGlobal.Data.API.getS3File({
          path: `tenant/${template.tenant_id}/options/${options[i][j].id}.png`,
          customPrefix: { public: '' },
        });
        optionFiles[options[i][j].id].preview = optionPreview;
      }
    }
    return {
      id,
      template,
      pages,
      components,
      pageFiles,
      optionFiles,
    };
  }

  static async zipTemplate(templateData) {
    const zip = new JSZip();
    const dir = zip.folder(templateData.id);

    dir.file('template.json', JSON.stringify(templateData.template));
    dir.file('pages.json', JSON.stringify(templateData.pages));
    dir.file('components.json', JSON.stringify(templateData.components));

    const pages = Object.keys(templateData.pageFiles);
    for (let i = 0; i < pages.length; i++) {
      const json = JSON.stringify(templateData.pageFiles[pages[i]].json);
      dir.file(`${pages[i]}.json`, json);
      dir.file(`${pages[i]}.png`, templateData.pageFiles[pages[i]].preview);
    }

    const options = Object.keys(templateData.optionFiles);
    const opts = dir.folder('options');

    for (let i = 0; i < options.length; i++) {
      const json = JSON.stringify(templateData.optionFiles[options[i]].json);
      opts.file(`${options[i]}.json`, json);
      opts.file(
        `${options[i]}.png`,
        templateData.optionFiles[options[i]].preview
      );
    }

    await new Promise((resolve, reject) => {
      zip
        .generateAsync({ type: 'blob' })
        .then(content => {
          saveAs(content, `${templateData.id}.zip`);
          resolve(content);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
  }

  static async copyExport(exp, name) {
    const copy = merge({}, exp);
    const newTemplateId = UUID();
    const pageIdMap = {};
    const componentIdMap = {};
    const optionIdMap = {};

    copy.id = newTemplateId;
    copy.template.id = newTemplateId;
    copy.template.name = name;

    copy.pages = copy.pages.map(p => {
      const newPageId = UUID();
      pageIdMap[p.id] = newPageId;
      return {
        id: newPageId,
        template_id: newTemplateId,
        number: p.number,
      };
    });

    copy.components = copy.components.map(c => {
      const component = { ...c };
      const newComponentId = UUID();
      let defaultFound = false;
      componentIdMap[component.id] = newComponentId;
      component.id = newComponentId;
      component.template_id = newTemplateId;
      component.options = component.options
        ? component.options.map(o => {
            const option = { ...o };
            const newOptionId = UUID();
            optionIdMap[option.id] = newOptionId;
            option.id = newOptionId;
            option.component_id = newComponentId;
            if (o.id === component.default_option_id) {
              component.default_option_id = newOptionId;
              defaultFound = true;
            }
            return option;
          })
        : [];
      if (!defaultFound) {
        component.default_option_id =
          component.options && component.options[0]
            ? component.options[0].id
            : false;
      }
      return component;
    });

    const newPageFiles = {};
    const pages = Object.keys(copy.pageFiles);
    for (let i = 0; i < pages.length; i++) {
      newPageFiles[pageIdMap[pages[i]]] = {};
      const pageJSON = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${copy.template.tenant_id}/templates/${pages[i]}.json`,
        type: 'json',
        customPrefix: { public: '' },
      });
      const json = JSON.stringify(pageJSON);
      const json2 = replaceIds(json, {
        id: copy.template.id,
        newTemplateId,
        pageIdMap,
        componentIdMap,
        optionIdMap,
      });
      newPageFiles[pageIdMap[pages[i]]].json = json2;

      const pagePreview = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${copy.template.tenant_id}/templates/${pages[i]}.png`,
        customPrefix: { public: '' },
      });
      newPageFiles[pageIdMap[pages[i]]].preview = pagePreview;
    }

    copy.pageFiles = newPageFiles;

    const newOptionFiles = {};
    const options = Object.keys(copy.optionFiles);
    for (let i = 0; i < options.length; i++) {
      newOptionFiles[optionIdMap[options[i]]] = {};
      const optionJSON = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${copy.template.tenant_id}/options/${options[i]}.json`,
        type: 'json',
        customPrefix: { public: '' },
      });
      const json = JSON.stringify(optionJSON);
      const json2 = replaceIds(json, {
        id: copy.template.id,
        newTemplateId,
        pageIdMap,
        componentIdMap,
        optionIdMap,
      });
      newOptionFiles[optionIdMap[options[i]]].json = json2;

      const optionPreview = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${copy.template.tenant_id}/options/${options[i]}.png`,
        customPrefix: { public: '' },
      });
      newOptionFiles[optionIdMap[options[i]]].preview = optionPreview;
    }
    copy.optionFiles = newOptionFiles;

    return copy;
  }

  static async saveQueue(a) {
    MatgenGlobal.UI.loading('Saving template file...');
    const item = a.pop();
    const res = await MatgenGlobal.Data.saveTemplateFile(
      item.name,
      item.tenant_id,
      item.data,
      item.option
    );
    console.log(res);
    if (a.length > 0) {
      window.setTimeout(() => MatgenUIFunctions.saveQueue(a), 25);
    } else {
      MatgenGlobal.UI.stopLoading();
      window.location.reload();
    }
  }

  static async saveExport(exp) {
    let res;

    res = await MatgenGlobal.Data.saveTemplate(exp.template);
    console.log(res);
    for (let i = 0; i < exp.pages.length; i++) {
      res = await MatgenGlobal.Data.savePage(exp.pages[i]);
      console.log(res);
    }
    for (let i = 0; i < exp.components.length; i++) {
      const c = merge({}, exp.components[i]);
      const opts = c.options;
      delete c.options;
      res = await MatgenGlobal.Data.saveComponent(c);
      console.log(res);
      for (let j = 0; j < opts.length; j++) {
        res = await MatgenGlobal.Data.saveOptionData(opts[j]);
        console.log(res);
      }
    }
    const pages = Object.keys(exp.pageFiles);
    const throttledRequests = [];
    for (let i = 0; i < pages.length; i++) {
      throttledRequests.push({
        name: `${pages[i]}.json`,
        tenant_id: exp.template.tenant_id,
        data: exp.pageFiles[pages[i]].json,
      });
      throttledRequests.push({
        name: `${pages[i]}.png`,
        tenant_id: exp.template.tenant_id,
        data: exp.pageFiles[pages[i]].preview,
      });
    }
    const options = Object.keys(exp.optionFiles);

    for (let i = 0; i < options.length; i++) {
      throttledRequests.push({
        name: `${options[i]}.json`,
        tenant_id: exp.template.tenant_id,
        data: exp.optionFiles[options[i]].json,
        option: true,
      });
      throttledRequests.push({
        name: `${options[i]}.png`,
        tenant_id: exp.template.tenant_id,
        data: exp.optionFiles[options[i]].preview,
        option: true,
      });
    }
    MatgenUIFunctions.saveQueue(throttledRequests);
  }

  static async exportTemplate(id) {
    MatgenGlobal.UI.loading('Retrieving template data...');
    const exp = await MatgenUIFunctions.getTemplateExport(id);
    MatgenUIFunctions.zipTemplate(exp);

    MatgenGlobal.UI.stopLoading();
  }

  static async duplicateTemplate(id, name) {
    MatgenGlobal.UI.loading('Retrieving template data...');
    const exp = await MatgenUIFunctions.getTemplateExport(id);
    MatgenGlobal.UI.loading('Duplicating template...');
    const copy = await MatgenUIFunctions.copyExport(exp, name);
    MatgenGlobal.UI.loading('Saving duplicated template...');
    await MatgenUIFunctions.saveExport(copy);
  }

  static async exportTemplate2(id) {
    MatgenGlobal.UI.loading('Retrieving template data...');

    const zip = new JSZip();
    const dir = zip.folder(id);

    const newTemplateId = UUID();
    const zip2 = new JSZip();
    const dir2 = zip2.folder(newTemplateId);

    const pageIdMap = {};
    const componentIdMap = {};
    const optionIdMap = {};

    const template = await MatgenGlobal.Data.getTemplate(id);
    if (template === false) {
      return false;
    }

    const pages = await MatgenGlobal.Data.getPages(id);
    if (pages === false) {
      return false;
    }

    const components = await MatgenGlobal.Data.getComponents(id);
    if (components === false) {
      return false;
    }

    const options = await Promise.all(
      components.map(c => MatgenGlobal.Data.getComponentOptions(c.id))
    );

    for (let i = 0; i < components.length; i++) {
      components[i].options = options.find(o =>
        o.find(opt => opt.component_id === components[i].id)
      );
    }

    dir.file('template.json', JSON.stringify(template));
    dir.file('pages.json', JSON.stringify(pages));
    dir.file('components.json', JSON.stringify(components));

    const template2 = {
      ...template,
    };
    template2.id = newTemplateId;
    dir2.file('template.json', JSON.stringify(template2));
    const pages2 = pages.map(p => {
      const newPageId = UUID();
      pageIdMap[p.id] = newPageId;
      return {
        id: newPageId,
        template_id: newTemplateId,
        number: p.number,
      };
    });
    dir2.file('pages.json', JSON.stringify(pages2));
    const components2 = components.map(c => {
      const component = { ...c };
      const newComponentId = UUID();
      let defaultFound = false;
      componentIdMap[component.id] = newComponentId;
      component.id = newComponentId;
      component.template_id = newTemplateId;
      component.options = component.options.map(o => {
        const option = { ...o };
        const newOptionId = UUID();
        optionIdMap[option.id] = newOptionId;
        option.id = newOptionId;
        option.component_id = newComponentId;
        if (o.id === component.default_option_id) {
          component.default_option_id = newOptionId;
          defaultFound = true;
        }
        return option;
      });
      if (!defaultFound) {
        component.default_option_id = component.options[0].id;
      }
      return component;
    });
    dir2.file('components.json', JSON.stringify(components2));

    const pageFiles = {};
    for (let i = 0; i < pages.length; i++) {
      pageFiles[pages[i].id] = {};
      const pageJSON = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${template.tenant_id}/templates/${pages[i].id}.json`,
        type: 'json',
        customPrefix: { public: '' },
      });
      pageFiles[pages[i].id].json = pageJSON;
      const json = JSON.stringify(pageJSON);
      dir.file(`${pages[i].id}.json`, json);

      const json2 = replaceIds(json, {
        id,
        newTemplateId,
        pageIdMap,
        componentIdMap,
        optionIdMap,
      });
      dir2.file(`${pageIdMap[pages[i].id]}.json`, json2);

      const pagePreview = await MatgenGlobal.Data.API.getS3File({
        path: `tenant/${template.tenant_id}/templates/${pages[i].id}.png`,
        customPrefix: { public: '' },
      });
      pageFiles[pages[i].id].preview = pagePreview;
      dir.file(`${pages[i].id}.png`, pagePreview);

      dir2.file(`${pageIdMap[pages[i].id]}.png`, pagePreview);
    }

    const optionFiles = {};
    const opts = dir.folder('options');

    const opts2 = dir2.folder('options');

    for (let i = 0; i < options.length; i++) {
      for (let j = 0; j < options[i].length; j++) {
        optionFiles[options[i][j].id] = {};
        const optionJSON = await MatgenGlobal.Data.API.getS3File({
          path: `tenant/${template.tenant_id}/options/${options[i][j].id}.json`,
          type: 'json',
          customPrefix: { public: '' },
        });
        optionFiles[options[i][j].id].json = optionJSON;
        const json = JSON.stringify(optionJSON);
        opts.file(`${options[i][j].id}.json`, json);

        const json2 = replaceIds(json, {
          id,
          newTemplateId,
          pageIdMap,
          componentIdMap,
          optionIdMap,
        });
        opts2.file(`${optionIdMap[options[i][j].id]}.json`, json2);

        const optionPreview = await MatgenGlobal.Data.API.getS3File({
          path: `tenant/${template.tenant_id}/options/${options[i][j].id}.png`,
          customPrefix: { public: '' },
        });
        optionFiles[options[i][j].id].preview = optionPreview;
        opts.file(`${pages[i].id}.png`, optionPreview);
        opts2.file(`${optionIdMap[options[i][j].id]}.png`, optionPreview);
      }
    }

    console.log('template', template);
    console.log('pages', pages);
    console.log('components', components);

    await new Promise((resolve, reject) => {
      zip
        .generateAsync({ type: 'blob' })
        .then(content => {
          saveAs(content, `${id}.zip`);
          resolve(content);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });

    await new Promise((resolve, reject) => {
      zip2
        .generateAsync({ type: 'blob' })
        .then(content => {
          saveAs(content, `${newTemplateId}.zip`);
          resolve(content);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
    MatgenGlobal.UI.stopLoading();

    /*
    const zip = new JSZip();

    const dir = zip.folder('template');
    dir.file('Hello.txt', 'Hello World\n');

    await new Promise((resolve, reject) => {
      zip
        .generateAsync({ type: 'blob' })
        .then(content => {
          // Force down of the Zip file
          saveAs(content, `${UUID()}.zip`);
          MatgenGlobal.UI.stopLoading();
          resolve(content);
        })
        .catch(e => {
          console.error(e);
          reject(e);
        });
    });
    */
  }

  static async duplicateTemplate2(id, name) {
    MatgenGlobal.UI.loading('Duplicating template...');
    const response = await MatgenGlobal.Data.duplicateTemplate(id, name);
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem duplicating the template.'
      );
      return false;
    }
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();
  }

  static async deleteTemplate(id) {
    MatgenGlobal.UI.loading('Deleting template...');
    const response = await MatgenGlobal.Data.deleteTemplate(id);
    if (response === false) {
      MatgenGlobal.UI.handleError(
        'Server Error',
        'There was a problem deleting the template.'
      );
      return false;
    }
    console.log(response);
    MatgenGlobal.UI.stopLoading();
    MatgenGlobal.Router.core.reload();
  }
}
