/* global $, bootstrap */

class M4CModal {
  static show({
    id,
    title,
    content,
    buttons = [],
    classes = '',
    header = true,
    footer = true,
    closeButton = false,
    closeText = 'Close',
    closeX = true,
    target = 'body',
    width = 'fit-content',
    matgen = false,
    style = '',
  } = {}) {
    const html = M4CModal.getHTML({
      id,
      title,
      content,
      buttons,
      classes,
      header,
      footer,
      closeButton,
      width,
      closeText,
      closeX,
      matgen,
      style,
    });
    $(target).append($(html));
    const myModal = new bootstrap.Modal(document.getElementById(id));
    myModal.show();

    $(`#${id}`).on('hidden.bs.modal', () => {
      //console.error('HIDDEN.BS.MODAL');
      //myModal.dispose();
      $(`#${id}`).remove();
    });

    /*$(`#${id}`).on('shown.bs.modal', () => {
      MatgenGlobal.UI.initTooltips();
    });*/
  }

  static getHTML({
    id,
    title,
    content,
    buttons = [],
    header = true,
    footer = true,
    //closeX = true,
    width = 'fit-content',
    style = '',
  } = {}) {
    content = `
      ${content}
      ${
        header
          ? ''
          : `
      <button type="button" class="btn-close alert-modal-close-button matgen-core" data-bs-dismiss="modal" aria-label="Close"><span aria-hidden="true">&times;</span></button>
        `
      }
    `;
    return `
      <div class="modal revised fade" id="${id}" tabindex="-1" role="dialog" aria-labelledby="${id}-label" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable" role="document"${
          style && style !== '' ? ` style="transform:unset;"` : ''
        }>
          <div class="modal-content"  style="width:${width}">


            ${
              header
                ? `
              <div class="modal-header">
                ${
                  title
                    ? `<h4 class="modal-title" id="${id}-label">${title}</h4>`
                    : ''
                }
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
            `
                : ''
            }

            <div class="modal-body" role="document">
            ${content}
            </div>

            ${
              footer && buttons.length
                ? `
              <div class="modal-footer">
              ${buttons
                .map(button => {
                  const classes = button.classes || button.classname;
                  return `<button ${
                    button.attributes ? button.attributes.join(' ') : ''
                  } id="${button.id}" type="button" class="${classes}">${
                    button.label
                  }</button>`;
                })
                .join('')}

              </div>
              `
                : ''
            }
          </div>
        </div>
      </div>
      `;
  }

  static getHTMLOld({
    id,
    title,
    content,
    buttons = [],
    classes = '',
    header = true,
    footer = true,
    closeButton = false,
    closeText = 'Close',
    closeX = true,
    width = 'auto',
  } = {}) {
    return `
      <div class="modal revised ${classes} M4CModal" id="${id}" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-dialog-scrollable">
          <div class="modal-content" style="width:${width}">

            ${
              header
                ? `
              <div class="modal-header">
                ${title ? `<h4 class="modal-title">${title}</h4>` : ''}
                ${
                  closeX
                    ? `<button type="button" class="close btn-close" data-bs-dismiss="modal" aria-label="Close">
                      <span aria-hidden="true">&times;</span>
                    </button>`
                    : ''
                }
              </div>
              `
                : ''
            }

            <div id="${id}-modal-body" class="modal-body">
              ${content}
            </div>

            ${
              footer
                ? `
              <div class="modal-footer">
                ${
                  closeButton
                    ? `<button type="button" class="btn btn-secondary" data-bs-dismiss="modal">${closeText}</button>`
                    : ''
                }
                ${buttons
                  .map(
                    button =>
                      `<button ${
                        button.attributes ? button.attributes.join(' ') : ''
                      } id="${button.id}" type="button" class="btn btn-${
                        button.classname
                      }">${button.label}</button>`
                  )
                  .join('')}
              </div>
              `
                : ''
            }

          </div>
        </div>
      </div>
    `;
  }
}

export { M4CModal };
