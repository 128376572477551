/* global $, MatgenGlobal */

const localConfig = {
  props: {
    'kit-token': '19821bd946',
  },
  apiToken: '1732BFBB-D841-4ACE-BEA6-C5B04D08AABE',
};
const localDevMissingMsg =
  'DEV: your local dev config in local.json is required but has not yet been loaded.';

function getUrlText(url) {
  // To simulate a fatal error, uncomment the following line:
  // return Promise.reject('simulated fatal error')

  return fetch(url).then(response => {
    if (response.ok) {
      return response.text();
    } else {
      throw new Error(`DEBUG: bad query for url: ${url}`);
    }
  });
}

function handleQuery(query) {
  return new Promise((resolve, reject) => {
    const headers = {
      'Content-Type': 'application/json',
    };

    getAccessToken()
      .then(token => {
        if (token) {
          headers['Authorization'] = `Bearer ${token}`;
          console.log(
            'handleQuery: using fresh access token to issue authorized request'
          );
        } else {
          console.log(
            'handleQuery: no access token found -- sending an unauthorized request'
          );
        }

        return fetch('https://api.fontawesome.com', {
          method: 'POST',
          headers,
          body: JSON.stringify({ query }),
        });
      })
      .then(response => {
        if (response.ok) {
          response
            .json()
            .then(json => resolve(json))
            .catch(e => reject(e));
        } else {
          reject('bad query');
        }
      })
      .catch(e => reject(e));
  });
}

function handleResult(result) {
  MatgenGlobal.showingIconChooser = true;
  toggleIconChooser();
  MatgenGlobal.ChosenIcon = result.detail;
  $('#icon-picker-modal').modal('hide');
  $('#answer-icon')
    .empty()
    .append(
      $(`
        <i class="${result.detail.prefix} fa-${result.detail.iconName} fa-2xl" />
      `)
    );
}

/*function clearResult() {
  document.querySelectorAll('#icon-preview i').forEach(child => child.remove());
}*/

function addIconChooser(props) {
  const container = document.querySelector('#fa-icon-chooser-div');
  const el = document.createElement('fa-icon-chooser');
  el.handleQuery = handleQuery;
  el.getUrlText = getUrlText;
  el.addEventListener('finish', handleResult);

  const slotFatalErrorHeader = document.createElement('p');
  slotFatalErrorHeader.setAttribute('slot', 'fatal-error-heading');
  const slotFatalErrorHeaderMsg = document.createTextNode('Fatal Error');
  slotFatalErrorHeader.appendChild(slotFatalErrorHeaderMsg);
  el.appendChild(slotFatalErrorHeader);

  Object.keys(props).map(prop => {
    el.setAttribute(prop, props[prop]);
  });

  container.appendChild(el);
}

function closeIconChooser() {
  //$('fa-icon-chooser').remove();
  $('#icon-picker-modal')
    .find('.btn-close')
    .click();
}

function toggleIconChooser() {
  if (MatgenGlobal.showingIconChooser) {
    closeIconChooser();
    //clearResult();
    MatgenGlobal.showingIconChooser = false;
  } else {
    showIconChooser();

    const toggleIconContainer = document.querySelector(
      '#toggle-icon-container'
    );
    if (toggleIconContainer) {
      while (toggleIconContainer.firstChild) {
        toggleIconContainer.removeChild(toggleIconContainer.firstChild);
      }
      const newIcon = document.createElement('i');
      newIcon.setAttribute('class', 'fas fa-toggle-on');
      toggleIconContainer.appendChild(newIcon);
    }

    MatgenGlobal.showingIconChooser = true;
  }
}

function getAccessToken() {
  const apiToken = localConfig && localConfig.apiToken;
  if (!apiToken) {
    // If there's no apiToken, then it's not an error to resolve an undefined access token.
    return Promise.resolve(undefined);
  }
  const tokenJSON = window.localStorage.getItem('token');
  const tokenObj = tokenJSON ? JSON.parse(tokenJSON) : undefined;
  const freshToken =
    tokenObj && Math.floor(Date.now() / 1000) <= tokenObj.expiresAtEpochSeconds
      ? tokenObj.token
      : undefined;

  if (freshToken) return Promise.resolve(freshToken);

  return fetch('https://api.fontawesome.com/token', {
    method: 'POST',
    headers: {
      authorization: `Bearer ${localConfig.apiToken}`,
    },
  })
    .then(response => {
      if (response.ok) {
        response
          .json()
          .then(obj => {
            const expiresAtEpochSeconds =
              Math.floor(Date.now() / 1000) + obj['expires_in'];

            // WARNING: storing an access token in localStorage may not be good enough
            // security in other situations. This is a development-only situation
            // intended to run on a local development machine, so this seems like
            // good enough security for that use case.
            window.localStorage.setItem(
              'token',
              JSON.stringify({
                token: obj['access_token'],
                expiresAtEpochSeconds,
              })
            );
          })
          .catch(e => {
            throw e;
          });
      } else {
        const msg = 'DEV: unexpected token endpoint response';
        console.error(msg, response);
        throw new Error(msg);
      }
    })
    .catch(e => {
      throw e;
    });
}

function showIconChooser() {
  if (!localConfig) throw new Error(localDevMissingMsg);
  if (!localConfig.props)
    throw new Error('DEV: missing props key in your local.json');

  const { props } = localConfig;

  if (!props['kit-token']) {
    if (!props.version) {
      throw new Error(
        'DEV: your local.json must have a props key with either a version subkey or a kit-token subkey'
      );
    }
    addIconChooser(props);
    return;
  }

  getAccessToken()
    .then(() => addIconChooser(props))
    .catch(e => {
      throw e;
    });
}

export { toggleIconChooser };
