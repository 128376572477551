/* global MatgenGlobal, $ */

import { fabric } from 'fabric';
import { v4 as UUID } from 'uuid';
import * as WebFont from 'webfontloader';
import { debounce } from 'throttle-debounce';
import { emit } from '../util/helpers.js';
import {
  FabricIncludeProps,
  ZoomSettings,
  FabricObjectDefaults,
} from './fabric-vars.js';

//import 'fabric-history';

const debouncedMouseOver = debounce(125, false, (e, _this) => {
  if (!e.target.isHover) {
    return false;
  }
  let eventName = null;
  if (e.target === null) {
    eventName = 'canvas-mouse-over';
  } else {
    if (e.target.type === 'activeSelection') {
      e.target.hoverCursor = 'pointer';
    }
    eventName = 'object-mouse-over';
    if (
      (MatgenGlobal.AuthUser.getUserRole() === 'admin' ||
        !MatgenGlobal.AuthUser.user ||
        MatgenGlobal.AuthUser.getUserRole() === 'user') &&
      ((e.target.componentId &&
        (e.target.hasOptions ||
          MatgenGlobal.AuthUser.getUserRole() === 'admin')) ||
        e.target.uploader ||
        e.target.userEditable ||
        e.target.studyDataConnection)
    ) {
      _this.setSelectionStyles(e);
      e.target._renderControls(e.target.canvas.contextTop, {
        hasControls: false,
      });
      _this.fabric.clearContext(e.target.canvas.contextTop);
      _this.fabric.discardActiveObject();
      _this.fabric.setActiveObject(e.target);

      _this.fabric.renderAll();
    }
  }
  _this.emitEvent(eventName, e);
});

class MatgenCanvas {
  constructor({
    id = UUID(),
    opts = {},
    canvasContainerId = false,
    width,
    height,
    editor,
    gridSize = 5,
  } = {}) {
    if (!canvasContainerId) {
      throw new Error('MatgenCanvas constructor requires a containerId.');
    }
    this.id = id;
    this.includeProps = FabricIncludeProps;
    this.opts = Object.assign(opts, {
      fireRightClick: true,
      stopContextMenu: true,
      preserveObjectStacking: true,
      defaultCursor: 'default',
      renderOnAddRemove: false,
      hoverCursor: 'auto',
      targetFindTolerance: 2,
      selectionColor: `${FabricObjectDefaults.borderColor}80`,
      selectionBorderColor: FabricObjectDefaults.borderColor,
      selectionLineWidth: FabricObjectDefaults.borderScaleFactor,
      containerClass: `canvas-container-${UUID()}`,
    });
    this.FabricObjectDefaults = FabricObjectDefaults;

    this.FabricObjectDefaults.borderScaleFactor = MatgenGlobal.BorderScale;
    this.FabricObjectDefaults.borderColor = MatgenGlobal.BorderColor;
    this.FabricObjectDefaults.cornerColor = MatgenGlobal.BorderColor;
    this.FabricObjectDefaults.cornerStrokeColor =
      MatgenGlobal.BorderColorDarkened;
    this.FabricObjectDefaults.editingBorderColor =
      MatgenGlobal.EditingBorderColor;

    this.scalingFactor = 1;
    this.canvasContainerId = canvasContainerId;
    this.width = width;
    this.height = height;
    this.editor = editor;
    this.util = fabric.util;
    this.fabricJS = fabric;
    this.gridSize = gridSize;
    //console.error('CANVAS CONSTRUCTOR:', this);
  }

  emitEvent(eventName, e) {
    /*console.log(
      eventName,
      this.canvasContainerId,
      document.getElementById(this.canvasContainerId)
    );*/
    emit({
      event: eventName,
      detail: {
        canvasEvent: e,
      },
      target: $(`${MatgenGlobal.containerSelector}`)[0],
    });
  }

  setSelectionStyles(e) {
    this.hoverOutline(e, true);
    if (e.selected) {
      e.selected.forEach(o => {
        if (
          o.width * o.scaleX === this.width ||
          o.height * o.scaleY === this.height ||
          o.left < 0 ||
          o.top < 0
        ) {
          this.hoverOutline(o);
          o.borderScaleFactor = 0;
          o.borderColor = '#00000000';
        } else {
          o.transparentCorners = FabricObjectDefaults.transparentCorners;
          o.borderColor = FabricObjectDefaults.borderColor;
          o.cornerColor = FabricObjectDefaults.cornerColor;
          o.minScaleLimit = FabricObjectDefaults.minScaleLimit;
          o.cornerStrokeColor = FabricObjectDefaults.cornerStrokeColor;
          o.cornerStyle = FabricObjectDefaults.cornerStyle;
          o.lockScalingFlip = FabricObjectDefaults.lockScalingFlip;
          o.padding = FabricObjectDefaults.padding;
          o.selectionDashArray = FabricObjectDefaults.selectionDashArray;
          o.borderDashArray = FabricObjectDefaults.borderDashArray;
          o.borderScaleFactor = FabricObjectDefaults.borderScaleFactor;
          //o.hoverCursor = FabricObjectDefaults.hoverCursor;
          o.hoverCursor = 'pointer';
          o.editingBorderColor = FabricObjectDefaults.editingBorderColor;
        }
      });
    }

    if (
      e.target.width * e.target.scaleX === this.width ||
      e.target.height * e.target.scaleY === this.height ||
      e.target.left < 0 ||
      e.target.top < 0
    ) {
      this.hoverOutline(e);
      e.target.borderScaleFactor = 0;
      e.target.borderColor = '#00000000';
    } else {
      e.target.transparentCorners = FabricObjectDefaults.transparentCorners;
      e.target.borderColor = FabricObjectDefaults.borderColor;
      e.target.cornerColor = FabricObjectDefaults.cornerColor;
      e.target.minScaleLimit = FabricObjectDefaults.minScaleLimit;
      e.target.cornerStrokeColor = FabricObjectDefaults.cornerStrokeColor;
      e.target.cornerStyle = FabricObjectDefaults.cornerStyle;
      e.target.lockScalingFlip = FabricObjectDefaults.lockScalingFlip;
      e.target.padding = FabricObjectDefaults.padding;
      e.target.selectionDashArray = FabricObjectDefaults.selectionDashArray;
      e.target.borderDashArray = FabricObjectDefaults.borderDashArray;
      e.target.borderScaleFactor = FabricObjectDefaults.borderScaleFactor;
      //e.target.hoverCursor = FabricObjectDefaults.hoverCursor;
      e.target.hoverCursor = 'pointer';
      e.target.editingBorderColor = FabricObjectDefaults.editingBorderColor;
    }
  }

  handleObjectSelection(e) {
    this.emitEvent('selection-changed', e);
    /*if (
      !MatgenGlobal.AuthUser.user ||
      MatgenGlobal.AuthUser.getUserRole() !== 'user' ||
      e.target.hasOptions
    ) {*/
    //window.setTimeout(() => {
    this.setSelectionStyles(e);
    this.fabric.renderAll();
    //}, 200);
    //}
  }

  canvasClickCallback(e) {
    //console.log('CANVAS CALLBACK', e.target);
    // Prevent accidental drags on click
    if (e.target) {
      const lockX = e.target.lockMovementX;
      const lockY = e.target.lockMovementY;
      e.target.lockMovementX = true;
      e.target.lockMovementY = true;
      this.fabric.renderAll();
      window.setTimeout(() => {
        e.target.lockMovementX = lockX;
        e.target.lockMovementY = lockY;
        this.fabric.renderAll();
      }, 350);
    }
    // End accidental drag handling

    let eventName = null;
    if (e.target !== null) {
      if (e.button === 3) {
        eventName = 'object-right-click';
      } else {
        eventName = 'object-left-click';
      }

      if (
        !MatgenGlobal.AuthUser.user ||
        MatgenGlobal.AuthUser.getUserRole() !== 'user' ||
        e.target.hasOptions ||
        e.target.uploader ||
        e.target.userEditable ||
        e.target.studyDataConnection
      ) {
        this.handleObjectSelection(e);
        this.fabric.renderAll();
      }
    } else {
      if (e.button === 3) {
        eventName = 'canvas-right-click';
      } else {
        eventName = 'canvas-left-click';
      }
      MatgenGlobal.editor.cur().fabric.discardActiveObject();
      MatgenGlobal.editor.cur().fabric.requestRenderAll();
    }
    this.emitEvent(eventName, e);
  }

  handleCanvasClick(e) {
    //console.error('canvas click', e.target, MatgenGlobal.handleCanvasClick);
    if (
      MatgenGlobal.handleCanvasClick &&
      typeof MatgenGlobal.handleCanvasClick === 'function'
    ) {
      MatgenGlobal.handleCanvasClick(
        {
          event: 'matgen-canvas-click',
          detail: {
            canvasEvent: e,
          },
          target: document.getElementById(this.canvasContainerId),
        },
        e => {
          this.canvasClickCallback(e);
        }
      );
    } else {
      emit({
        event: 'matgen-canvas-click',
        detail: { e },
      });
      this.canvasClickCallback(e);
    }
  }

  initCanvasEvents() {
    // Prevent default browser context menu from appearing,
    // but only when necessary where we're handling right clicks
    document.addEventListener('contextmenu', e => {
      let preventDefault = false;
      if (e.target.id && e.target.id === this.canvasContainerId) {
        preventDefault = true;
      } else {
        preventDefault = e.composedPath().find(el => {
          if (
            (el.classList && el.classList.contains('modal-backdrop')) ||
            (el.id && el.id === this.canvasContainerId)
          ) {
            return true;
          }
          return false;
        });
      }
      if (preventDefault) {
        e.preventDefault();
      }
    });

    // Add event listener for double click
    this.fabric.upperCanvasEl.addEventListener(
      'dblclick',
      event => {
        // Prevent the default action
        event.preventDefault();
        event.stopPropagation();
        // console.log('Double click disabled');
      },
      true
    );

    // this.fabric.on('mouse:dblclick', e => {
    //   // e.preventDefault();
    //   // e.stopPropagation();
    //   console.log(`The clicked object was a ${e.target.type}`);

    //   if (e.target.type === 'textbox') {
    //     e.e.preventDefault();
    //     e.e.stopPropagation();
    //     return;
    //   }

    //   let eventName = null;
    //   if (e.target === null) {
    //     eventName = 'canvas-dbl-click';
    //   } else {
    //     eventName = 'object-dbl-click';
    //   }
    //   this.emitEvent(eventName, e);
    // });

    this.fabric.on('mouse:over', e => {
      if (e.target) {
        e.target.isHover = true;
        debouncedMouseOver(e, this);
      }
    });

    this.fabric.on('mouse:out', e => {
      if (e.target) {
        e.target.isHover = false;
      }
      let eventName = null;
      if (e.target === null) {
        eventName = 'matgen-canvas-mouse-out';
      } else {
        eventName = 'matgen-object-mouse-out';
      }
      if (!this.fabric.getActiveObject()) {
        this.hoverOutline(e, true);
      }
      this.emitEvent(eventName, e);
    });

    this.fabric.on('mouse:down', e => {
      this.handleCanvasClick(e);
    });

    this.fabric.off({
      'selection:created': e => this.handleObjectSelection(e),
      'selection:updated': e => this.handleObjectSelection(e),
      'object:selected': e => this.handleObjectSelection(e),
      'object:modified': e => this.handleObjectSelection(e),
      'object:moved': e => this.handleObjectSelection(e),
    });
    this.fabric.on({
      'selection:created': e => this.handleObjectSelection(e),
      'selection:updated': e => this.handleObjectSelection(e),
      'object:selected': e => this.handleObjectSelection(e),
      'object:modified': e => this.handleObjectSelection(e),
      'object:moved': e => this.handleObjectSelection(e),
    });

    /*this.fabric.off('selection:created');
        this.fabric.on('selection:created', e => {
          this.emitEvent('selection-created', e);
          this.setSelectionStyles(e);
        });

        this.fabric.off('object:selected');
        this.fabric.on('object:selected', e => {
          this.emitEvent('object-selected', e);
          this.setSelectionStyles(e);
        });

        this.fabric.off('selection:updated');
        this.fabric.on('selection:updated', e => {
          this.emitEvent('selection-updated', e);
          this.setSelectionStyles(e);
        });*/

    this.fabric.off('selection:cleared');
    this.fabric.on('selection:cleared', e => {
      this.emitEvent('selection-cleared', e);
    });
  }

  initCanvas(id) {
    //console.error('INIT CANVAS:', id, document.getElementById(id));
    this.fabric = new fabric.Canvas(document.getElementById(id), this.opts);
    //this.fabric.historyInit();
  }

  display(prefix = 'matgen', classes = 'matgen-main') {
    const div = document.createElement('div');
    div.setAttribute('class', classes);
    div.setAttribute('data-id', this.id);
    div.id = `${prefix}-canvas-instance-${UUID()}`;
    this.displayId = `${prefix}-canvas-instance-${UUID()}`;
    div.innerHTML = `<canvas id="${this.canvasContainerId}-canvas"></canvas>`;
    //console.error('CANVAS DISPLAY', prefix, classes, div, this.displayId);
    return div;
  }

  getScalingFactor(width, height) {
    /*console.error(
      'getScalingFactor:',
      this.canvasContainerId,
      document.getElementById(this.canvasContainerId)
    );*/
    // if (MatgenGlobal.editor.templateId) {
    //   this.canvasContainerId = `matgen-scale-container-${MatgenGlobal.editor.templateId}`;
    // }

    const computedStyle = getComputedStyle(
      document.getElementById(this.canvasContainerId)
    );

    const cwidth =
      parseInt(computedStyle.width) -
      parseInt(computedStyle.marginLeft) -
      parseInt(computedStyle.marginRight) -
      parseInt(computedStyle.paddingLeft) -
      parseInt(computedStyle.paddingRight) -
      (parseInt(computedStyle['border-left-width']) +
        parseInt(computedStyle['border-right-width']));
    const cheight =
      parseInt(computedStyle.height) -
      parseInt(computedStyle.marginTop) -
      parseInt(computedStyle.marginBottom) -
      parseInt(computedStyle.paddingTop) -
      parseInt(computedStyle.paddingBottom) -
      (parseInt(computedStyle['border-top-width']) +
        parseInt(computedStyle['border-bottom-width']));

    const scalingFactor = Math.min(cwidth / width, cheight / height);
    /*const .scalingFactor = Math.min(
      document.getElementById(canvasContainerId).offsetWidth / width,
      document.getElementById(canvasContainerId).offsetHeight / height
    );*/
    this.scalingFactor = scalingFactor;
    return scalingFactor;
  }

  scale(width, height) {
    this.fabric.setWidth(width);
    this.fabric.setHeight(height);

    const scalingFactor = this.getScalingFactor(width, height);

    //console.error('SCALE:', width, height, scalingFactor);
    this.fabric.setDimensions({
      width: this.fabric.getWidth() * scalingFactor,
      height: this.fabric.getHeight() * scalingFactor,
    });
    this.fabric.setZoom(scalingFactor);
    this.fabric.requestRenderAll();
  }

  copy() {
    // clone what are you copying since you
    // may want copy and paste on different moment.
    // and you do not want the changes happened
    // later to reflect on the copy.
    this.fabric.getActiveObject().clone(cloned => {
      this.clipboard = cloned;
    });
  }

  addObj(obj) {
    fabric.util.enlivenObjects([obj], enlivenedObjects => {
      enlivenedObjects.forEach(o => {
        this.fabric.add(o);
      });
      this.fabric.requestRenderAll();
    });
  }

  addCircle(obj) {
    obj.type = 'circle';
    obj.id = UUID();
    const circle = new fabric.Circle(obj);
    this.fabric.add(circle);
    this.fabric.requestRenderAll();
  }

  addRectangle(obj) {
    obj.type = 'rect';
    obj.id = UUID();
    const rectangle = new fabric.Rect(obj);
    this.fabric.add(rectangle);
    this.fabric.requestRenderAll();
  }

  addTriangle(obj) {
    obj.type = 'triangle';
    obj.id = UUID();
    const triangle = new fabric.Triangle(obj);
    this.fabric.add(triangle);
    this.fabric.requestRenderAll();
  }

  addEllipse(obj) {
    obj.type = 'ellipse';
    obj.id = UUID();
    const ellipse = new fabric.Ellipse(obj);
    this.fabric.add(ellipse);
    this.fabric.requestRenderAll();
  }

  paste() {
    // clone again, so you can do multiple copies.
    const _this = this;
    this.clipboard.clone(clonedObj => {
      _this.fabric.discardActiveObject();
      clonedObj.set({
        left: clonedObj.left + 10,
        top: clonedObj.top + 10,
        evented: true,
      });
      if (clonedObj.type === 'activeSelection') {
        // active selection needs a reference to the canvas.
        clonedObj.canvas = _this.fabric;
        clonedObj.forEachObject(obj => {
          _this.fabric.add(obj);
        });
        // this should solve the unselectability
        clonedObj.setCoords();
      } else {
        _this.fabric.add(clonedObj);
      }
      _this.clipboard.top += 10;
      _this.clipboard.left += 10;
      _this.fabric.setActiveObject(clonedObj);

      _this.fabric.requestRenderAll();
    });
  }

  toObject(toObject) {
    const _this = this;
    return function() {
      return fabric.util.object.extend(toObject.call(this), {
        scaleX: this.scaleX * _this.scalingFactor,
        scaleY: this.scaleY * _this.scalingFactor,
      });
    };
  }

  zoom(out) {
    let zoom = this.fabric.getZoom();
    let width = this.fabric.getWidth();
    let height = this.fabric.getHeight();
    if (out) {
      if (zoom.toFixed(5) <= ZoomSettings.MIN_ZOOM) {
        return;
      }
      zoom = zoom / ZoomSettings.SCALE_FACTOR;
      width = width / ZoomSettings.SCALE_FACTOR;
      height = height / ZoomSettings.SCALE_FACTOR;
    } else {
      if (zoom.toFixed(5) > ZoomSettings.MAX_ZOOM) {
        return;
      }
      zoom = zoom * ZoomSettings.SCALE_FACTOR;
      width = width * ZoomSettings.SCALE_FACTOR;
      height = height * ZoomSettings.SCALE_FACTOR;
    }
    this.fabric.setZoom(zoom);
    this.fabric.setWidth(width);
    this.fabric.setHeight(height);
  }

  getJSON() {
    return JSON.stringify(this.fabric.toJSON(FabricIncludeProps));
  }

  getObjJSON(obj) {
    if (!obj) {
      console.error(Error('Empty object'));
      return false;
    }
    return JSON.stringify(obj.toJSON(FabricIncludeProps));
  }

  traverseObjects(objects, options = () => {}) {
    objects.forEach(o => {
      let opts = Object.assign({}, options(o));
      if (
        (!MatgenGlobal.AuthUser.user ||
          MatgenGlobal.AuthUser.getUserRole() === 'user') &&
        o.userEditable
      ) {
        opts.editable = true;
      }
      if (o.uploader) {
        opts = Object.assign(opts, {
          hasControls: true,
          hasRotatingPoint: false,
          lockRotation: true,
          //lockMovementX: true,
          //lockMovementY: true,
          //lockScalingX: true,
          //lockScalingY: true,
          //lockUniScaling: true,
        });
      }
      if (
        MatgenGlobal.AuthUser &&
        MatgenGlobal.AuthUser.getUserRole() === 'super'
      ) {
        opts.selectable = o.layerLocked !== true;
        opts.visible = o.layerVisible !== false;
      }
      if (
        MatgenGlobal.AuthUser &&
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        opts.selectable =
          o.studyDataConnection ||
          o.userEditable ||
          o.uploader ||
          o.componentId ||
          false;
      }
      o = Object.assign(o, opts);
      if (o.objects) {
        this.traverseObjects(o.objects, options);
      }
    });
  }

  setObjectPermissions() {
    const json = this.getJSON();
    this.loadJSONCanvas(json);
  }

  loadJSONCanvas(json, cb = false, keepLoading = false) {
    this.fabric.off({
      'object:added': this.fabric.historySaveAction,
      'object:removed': this.fabric.historySaveAction,
      'object:modified': this.fabric.historySaveAction,
    });
    MatgenGlobal.IgnoreAdd = true;
    const parsed = JSON.parse(json);
    if (parsed.objects) {
      parsed.objects = parsed.objects.filter(o => o.id !== 'hover-rect');
      if (
        !MatgenGlobal.AuthUser.user ||
        MatgenGlobal.AuthUser.getUserRole() === 'user'
      ) {
        this.traverseObjects(parsed.objects, o => {
          const perms = {
            hasControls: false,
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockUniScaling: true,
            editable: o.userEditable,
            dirty: false,
            selectable:
              (o.componentId && o.hasOptions) ||
              o.uploader ||
              o.userEditable ||
              o.studyDataConnection
                ? true
                : false,
            hoverCursor:
              (o.componentId && o.hasOptions) ||
              o.uploader ||
              o.userEditable ||
              o.studyDataConnection
                ? 'pointer'
                : 'default',
            evented:
              (o.componentId && o.hasOptions) ||
              o.uploader ||
              o.userEditable ||
              o.studyDataConnection
                ? true
                : false,
          };
          return perms;
        });
      }
      if (
        MatgenGlobal.AuthUser &&
        MatgenGlobal.AuthUser.getUserRole() === 'admin'
      ) {
        this.traverseObjects(parsed.objects, o => {
          return {
            hasControls: false,
            lockRotation: true,
            lockMovementX: true,
            lockMovementY: true,
            lockScalingX: true,
            lockScalingY: true,
            lockUniScaling: true,
            editable: false,
            selectable:
              o.componentId || o.uploader || o.userEditable ? true : false,
            hoverCursor: 'default',
          };
        });
      }
      if (
        MatgenGlobal.AuthUser &&
        MatgenGlobal.AuthUser.getUserRole() === 'super'
      ) {
        this.traverseObjects(parsed.objects, () => {
          return {
            hasControls: true,
            lockRotation: true,
            hasRotatingPoint: false,
            lockMovementX: false,
            lockMovementY: false,
            lockScalingX: false,
            lockScalingY: false,
            lockUniScaling: false,
            selectable: true,
            subTargetCheck: true,
            editable: true,
          };
        });
      }
    }
    parsed.objects = parsed.objects.map(o => {
      if (o.type === 'rectangle') {
        o.type = 'rect';
      }
      return o;
    });

    parsed.width = this.fabric.getWidth();
    parsed.height = this.fabric.getHeight();

    this.fabric.loadFromJSON(JSON.stringify(parsed), async () => {
      //const parsed = JSON.parse(json);
      let id = parsed.id;
      if (!id || id.toString().length !== 36) {
        id = UUID();
      }
      let name = parsed.name;
      if (!name) {
        name = 'Canvas Object';
      }
      this.fabric.id = id;
      this.fabric.name = name;
      //this.id = id;
      this.fabric.requestRenderAll();
      emit({
        event: 'matgen-canvas-loaded',
        detail: { canvas: this },
      });
      /*if (MatgenGlobal.SuppressStopLoading !== true) {
        emit({
          event: 'matgen-loading-stop',
        });
      }*/
      //console.error('KEEPLOADING?', keepLoading);
      if (cb && typeof cb === 'function') {
        if (!keepLoading) {
          MatgenGlobal.UI.stopLoading();
        }
        cb();
      } else {
        if (!keepLoading) {
          MatgenGlobal.UI.stopLoading();
        }
      }

      this.fabric.on({
        'object:added': this.fabric.historySaveAction,
        'object:removed': this.fabric.historySaveAction,
        'object:modified': this.fabric.historySaveAction,
      });
      delete MatgenGlobal.IgnoreAdd;
    });
  }

  loadJSON(json, cb = false, keepLoading = false) {
    const _this = this;
    if (json.fonts) {
      const fonts = json.fonts.split('|');
      emit({
        event: 'matgen-loading-start',
        detail: { message: 'Loading fonts...' },
      });
      WebFont.load({
        google: {
          families: fonts,
        },
        active: function() {
          MatgenGlobal.UI.loading('Loading canvas...');
          _this.loadJSONCanvas(JSON.stringify(json.fabric), cb, keepLoading);
        },
      });
    } else {
      MatgenGlobal.UI.loading('Loading canvas...');
      this.loadJSONCanvas(JSON.stringify(json.fabric), cb, keepLoading);
    }
  }

  loadImg(file, callback) {
    // generate a new FileReader object
    const reader = new FileReader();
    const _this = this;
    // inject an image with the src url
    reader.onload = function(event) {
      const the_url = event.target.result;

      const i = new Image();

      i.onload = function() {
        fabric.Image.fromURL(the_url, img => callback(img, i, _this));
      };

      i.src = the_url;
    };

    // when the file is read it triggers the onload event above.
    reader.readAsDataURL(file);
  }

  addImg(file, componentId) {
    this.loadImg(file, (img, i, _this) => {
      img = Object.assign(img, FabricObjectDefaults);
      img.left = 0;
      img.top = 0;
      img.id = UUID();
      if (componentId) {
        img.componentId = componentId;
      }
      _this.fabric.add(img);
      _this.fabric.requestRenderAll();
    });
  }

  addBgImg(file) {
    this.loadImg(file, (img, i, _this) => {
      const f_img = new fabric.Image(i);
      _this.fabric.setBackgroundImage(f_img);
      _this.fabric.requestRenderAll();
    });
  }

  removeBgImg() {
    this.fabric.backgroundImage = 0;
    this.fabric.requestRenderAll();
  }

  changeImg(file, obj) {
    //this.fabric.setActiveObject(obj);
    //this.fabric.renderAll();
    return new Promise(resolve => {
      // generate a new FileReader object
      const reader = new FileReader();
      const _this = this;
      const width = obj.width;
      const height = obj.height;
      const scaleX = obj.scaleX;
      const scaleY = obj.scaleY;
      // inject an image with the src url

      reader.onload = function(event) {
        const the_url = event.target.result;
        obj.setSrc(the_url, () => {
          obj.width = width;
          obj.height = height;
          obj.scaleX = scaleX;
          obj.scaleY = scaleY;
          //obj.id = UUID();
          _this.fabric.requestRenderAll();
        });
        resolve(true);
      };

      // when the file is read it triggers the onload event above.
      reader.readAsDataURL(file);
    });
  }

  userUpload(curObj, file) {
    //modalState.open = false;
    const reader = new FileReader();
    const _this = this;
    const height = curObj.height;
    //const width = this.fabric.getActiveObject().width;
    let saveObj = null;
    reader.onload = function(event) {
      const the_url = event.target.result;
      curObj.setSrc(the_url, img => {
        const scale = fabric.util.findScaleToFit(img, saveObj);
        const scaleTo = height * scale * _this.fabric.getZoom();

        img.scaleToHeight(scaleTo);

        let centerX = saveObj.width / 2;
        centerX -= (img.width * scale) / 2;
        img.left += centerX;

        _this.fabric.requestRenderAll();
      });
    };

    curObj.clone(obj => {
      saveObj = obj;
      reader.readAsDataURL(file);
    });
  }

  addText({
    text,
    size,
    color,
    weight,
    family,
    style,
    textAlign,
    userEditable,
    lineHeight,
    id,
    left,
    top,
    componentId,
    fontspec,
    fontType,
    materialDate,
    useThemeColor,
  } = {}) {
    const textEl = new fabric.Textbox(
      text,
      Object.assign({}, FabricObjectDefaults)
    );
    //text.left = this.width / 2 - (text.width * text.scaleX) / 2;
    //text.top = this.height / 2 - (text.height * text.scaleY) / 2;
    textEl.set('hasRotatingPoint', false);
    textEl.set('fontspec', fontspec);
    textEl.set('fontType', fontType);
    if (componentId) {
      textEl.set('componentId', componentId);
    }
    const newId = UUID();
    let openItems = sessionStorage.getItem('matgen-tree-state');
    if (!openItems) {
      openItems = [];
    } else {
      openItems = JSON.parse(openItems);
    }

    textEl.set('id', id ? id : newId);
    textEl.set('left', left ? left : 0);
    textEl.set('top', top ? top : 0);
    textEl.set('fill', color);
    textEl.set('fontSize', size);
    textEl.set('fontWeight', weight);
    textEl.set('fontFamily', family);
    textEl.set('fontStyle', style);
    textEl.set('userEditable', userEditable);
    textEl.set('textAlign', textAlign);
    textEl.set('lineHeight', lineHeight);
    textEl.set('materialDate', materialDate);
    textEl.set('useThemeColor', useThemeColor);

    this.fabric.add(textEl);
    this.fabric.renderAll();
    //this.fabric.bringToFront(textEl);
    this.fabric.setActiveObject(textEl);
  }

  hoverOutline(e, off = false) {
    if (off) {
      this.fabric.remove(this.hoverRect);
      return true;
    }
    const type = e.target ? e.target.type : e.type;
    //console.log('FAKE HOVER:', type, e);
    if (
      !e.target ||
      //MatgenGlobal.AuthUser.isM4CSuper() ||
      MatgenGlobal.suppressHoverOutline === true ||
      type === 'textbox'
    ) {
      return false;
    }
    const show =
      !e.target.sidebarIgnore &&
      //e.target.selectable &&
      (e.target.componentId ||
        e.target.uploader ||
        e.target.groupId ||
        e.target.studyDataConnection);

    if (MatgenGlobal.AuthUser.getUserRole() === 'admin' && !show) {
      return false;
    }
    //const w = e.target.width * this.fabric.getZoom();
    //const h = e.target.height * this.fabric.getZoom();

    /*const w = Math.min(
      (e.target.width - 10) *
        Math.round(e.target.scaleX) *
        this.fabric.getZoom(),
      (this.width - 10) * this.fabric.getZoom()
    );
    const h = Math.min(
      (e.target.height - 10) *
        Math.round(e.target.scaleY) *
        this.fabric.getZoom(),
      (this.height - 10) * this.fabric.getZoom()
    );
    */

    /*const sx =
      (e.target.uploaderWidth * e.target.uploaderScaleX) / e.target.width;
    const sy =
      (e.target.uploaderHeight * e.target.uploaderScaleY) / e.target.height;
    const w = e.target.width - 10;
    const h = e.target.height - 10;*/

    const sx = e.target.scaleX;
    const sy = e.target.scaleY;
    //let w = e.target.width - 10 * (1 / this.fabric.getZoom());
    //let h = e.target.height - 10 * (1 / this.fabric.getZoom());

    let w = e.target.width;
    let h = e.target.height;

    const l = Math.max(e.target.left, 0);
    const t = Math.max(e.target.top, 0);

    if (e.target.left < 0) {
      w = w + e.target.left;
    }

    if (e.target.top < 0) {
      h = h + e.target.top;
    }

    if (e.target.width * e.target.scaleX + l >= this.width) {
      w =
        (this.width - 4 * (1 / this.fabric.getZoom())) * (1 / e.target.scaleX);
    }

    if (e.target.height * e.target.scaleY + t >= this.height) {
      h =
        (this.height - 4 * (1 / this.fabric.getZoom())) * (1 / e.target.scaleY);
    }
    this.hoverRect = new fabric.Rect({
      //fill: '#f80',
      fill: '#00000000',
      //width: (e.target.width - 10) * this.fabric.getZoom(),
      //height: (e.target.height - 10) * this.fabric.getZoom(),
      //width: (e.target.width - 10) * e.target.scaleX * this.fabric.getZoom(),
      //height: (e.target.height - 10) * e.target.scaleY * this.fabric.getZoom(),
      left: l,
      top: t,
      width: w,
      height: h,
      scaleX: sx,
      scaleY: sy,
      strokeWidth: 4 * (1 / this.fabric.getZoom()) * (1 / e.target.scaleY),
      stroke: MatgenGlobal.BorderColor,
      strokeDashArray: [
        5 * (1 / this.fabric.getZoom()),
        5 * (1 / this.fabric.getZoom()),
      ],
      //scaleX: 1 / this.scalingFactor,
      //scaleY: 1 / this.scalingFactor,
      //scaleX: e.target.scaleX,
      //scaleY: e.target.scaleY,

      id: 'hover-rect',
      selectable: false,
      evented: false,
      sidebarIgnore: true,
    });
    //this.hoverRect.toObject = this.toObject(this.hoverRect.toObject);
    //rect.left = this.width / 2 - (rect.width * rect.scaleX) / 2;
    //rect.top = this.height / 2 - (rect.height * rect.scaleY) / 2;
    //this.hoverRect.top = 0;
    //this.hoverRect.left = 0;
    //this.hoverRect.id = UUID();

    this.fabric.add(this.hoverRect);

    this.fabric.requestRenderAll();
  }
}

export { MatgenCanvas };
