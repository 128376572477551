/* global $, MatgenGlobal */

import { Menu } from './Menu.js';

//import { init as eventsInit } from '../events/canvas-events.js';

let MenuItems;

function buildMenus() {
  return [
    {
      menu: 'matgen',
      title: 'Materials Generator',
      id: 'mat-drop',
      actions: [
        /*{
          label: 'Tenants',
          action: () => {
            MatgenGlobal.Router.goTo('/tenants');
            loadMenus();
          },
          access: { tenants: ['m4c'], roles: ['master', 'super', 'admin'] },
        },
        {
          label: 'Users',
          action: () => {
            MatgenGlobal.Router.goTo('/authenticateds');
            loadMenus();
          },
          access: { roles: ['master', 'super', 'admin'] },
        },*/
        {
          label: 'Templates',
          action: () => {
            MatgenGlobal.Router.goTo('templates');
            //loadMenus();
          },
          access: { roles: ['master', 'super', 'admin'] },
        },
        {
          label: 'Tenants',
          action: () => {
            MatgenGlobal.Router.goTo('tenants');
            //loadMenus();
          },
          access: { roles: ['dev'] },
        },
        {
          label: 'Questionnaires',
          action: () => {
            MatgenGlobal.Router.goTo('questionnaires');
            //loadMenus();
          },
          access: { roles: ['super'] },
        },
        {
          label: 'Sections',
          action: () => {
            MatgenGlobal.Router.goTo('sections');
            //loadMenus();
          },
          access: { roles: ['super'] },
        },
        {
          label: 'Questions',
          action: () => {
            MatgenGlobal.Router.goTo('questions');
            //loadMenus();
          },
          access: { roles: ['super'] },
        },
        {
          label: 'Answers',
          action: () => {
            MatgenGlobal.Router.goTo('answers');
            //loadMenus();
          },
          access: { roles: ['super'] },
        },
        {
          label: 'Users',
          action: () => {
            MatgenGlobal.Router.goTo('users');
            //loadMenus();
          },
          access: { roles: ['dev'] },
        },
        /*{
          label: 'Materials',
          action: async () => {
            const tenantName = MatgenGlobal.AuthUser.getUserTenant();
            if (tenantName) {
              const tenant = await MatgenGlobal.Data.getTenantId(tenantName);
              MatgenGlobal.Router.goTo(
                'templates',
                false,
                `?tenant_id=${tenant.id}`
              );
            } else {
              MatgenGlobal.Router.goTo('notFound');
            }
            loadMenus();
          },
          access: { roles: ['master', 'super', 'admin', 'user'] },
        },*/
        {
          label: 'My Materials',
          action: () => {
            MatgenGlobal.Router.goTo('materials');
            //loadMenus();
          },
          access: { roles: ['user'] },
          //access: { roles: ['master', 'super', 'admin', 'user'] },
        },
        {
          label: 'My Microsites',
          action: () => {
            MatgenGlobal.Router.goTo('microsites');
            //loadMenus();
          },
          access: { roles: ['user'] },
          //access: { roles: ['master', 'super', 'admin', 'user'] },
        },
        /*{
          label: 'Questionnaires',
          action: () => {
            MatgenGlobal.Router.goTo('/questionnaires');
            loadMenus();
          },
          access: { roles: ['master', 'super', 'admin'] },
        },
        { menu: 'matgen', label: 'Materials', action: menuDemo },*/
      ],
    },
    {
      menu: 'user',
      title: '<i class="fas fa-user-cog fa-lg"></i>',
      id: 'user-drop',
      classes: 'dropdown-menu-right',
      actions: [
        /*{ label: 'My Materials', action: menuDemo },*/
        {
          label: 'Change Password',
          action: () => {
            MatgenGlobal.MatgenGlobal.UI.route('/change-password');
          },
          access: { roles: ['master', 'super', 'admin', 'user'] },
        },
        {
          label: 'Sign Out',
          id: 'matgen-sign-out',
          action: () => MatgenGlobal.AuthUser.signOut(),
          access: { roles: ['master', 'super', 'admin', 'user'] },
        },
        {
          label: 'Log In',
          id: 'm4c-login',
          //action: () => MatgenGlobal.AuthUser.signOut(),
        },
      ],
    },
    {
      menu: 'viewas',
      title: 'View As',
      id: 'view-drop',
      sections: [
        {
          title: 'User Role:',
          actions: [
            { label: 'Master', action: viewAsRole },
            { label: 'Super', action: viewAsRole },
            { label: 'Admin', action: viewAsRole },
            { label: 'User', action: viewAsRole },
          ],
        },
        {
          title: 'User Tenant:',
          actions: [
            { label: 'M4C', action: viewAsTenant },
            { label: 'NIA', action: viewAsTenant },
            { label: 'NVFC', action: viewAsTenant },
          ],
        },
      ],
    },
    {
      menu: 'data',
      title: 'Material Data',
      id: 'data-drop',
      classes: 'dropdown-menu-right',
      actions: [
        { label: 'Canvas Data URL', action: MatgenGlobal.UI.canvasDataUrl },
        { label: 'Save Template Preview', action: MatgenGlobal.UI.savePreview },
        { label: 'Get Object JSON', action: MatgenGlobal.UI.objJSON },
      ],
    },
    {
      menu: 'canvas',
      title: 'Template/Canvas',
      id: 'canvas-drop',
      actions: [
        {
          label: 'Add Image',
          action: () =>
            MatgenGlobal.UI.upload(() => {
              if (document.getElementById('file-input').files[0]) {
                MatgenGlobal.editor
                  .cur()
                  .addImg(
                    document.getElementById('file-input').files[0],
                    MatgenGlobal.editor.cur().fabric.getActiveObject()
                  );
              }
              document
                .querySelector('body')
                .removeChild(document.getElementById('file-input'));
            }),
        },
        { label: 'Add Text', action: MatgenGlobal.UI.addText },
        { label: 'Add JSON', action: MatgenGlobal.UI.addJSON },
        { label: 'Save', action: MatgenGlobal.UI.saveEditorTemplate },
      ],
    },
  ];
}

async function loadMenus() {
  MenuItems = buildMenus();
  const menus = await Promise.all([
    new Menu(MenuItems.find(m => m.menu === 'matgen')),
    new Menu(MenuItems.find(m => m.menu === 'user')),
    new Menu(MenuItems.find(m => m.menu === 'viewas')),
    new Menu(MenuItems.find(m => m.menu === 'data')),
    /*new Menu(
      MenuItems.find(m => m.menu === 'canvas'),
      MatgenGlobal.AuthUser
    ),*/
  ]);
  const mainMenu = menus[0];
  const userMenu = menus[1];
  const viewMenu = menus[2];
  const dataMenu = menus[3];
  //const canvasMenu = menus[4];

  $('#main-nav').empty();
  $('#user-nav').empty();
  $('#view-as-nav').empty();
  $('#data-nav').empty();

  if (!MatgenGlobal.AuthUser.user) {
    //return false;
    $('#user-nav').append($(userMenu.markup));
  } else {
    $('#main-nav').append($(mainMenu.markup));
    $('#user-nav').append($(userMenu.markup));

    /*if (/\/templates(-old)?\/.{36}/gm.test(new URL(window.location).pathname)) {
      $('#main-nav .navbar-nav').append($(canvasMenu.markupLi));
    }*/

    if (MatgenGlobal.AuthUser.user && MatgenGlobal.AuthUser.user.attributes) {
      $('#user-nav >ul > li > .dropdown-menu').prepend(`
        <div class="dropdown-item text-primary disabled">${MatgenGlobal.AuthUser.user.attributes.email}</div>
      `);
      $('#m4c-login').hide();
    } else {
      $('#m4c-login').show();
    }

    if (
      MatgenGlobal.AuthUser.isM4CSuper() &&
      MatgenGlobal.AuthUser.hasRole('dev')
    ) {
      $('#view-as-nav').append($(viewMenu.markup));
      const role = MatgenGlobal.AuthUser.getUserRole();
      $(
        `#view-as-nav .dropdown-item:contains(${role.charAt(0).toUpperCase() +
          role.slice(1)})`
      ).addClass('active');
      const tenant = MatgenGlobal.AuthUser.getUserTenant();
      $(
        `#view-as-nav .dropdown-item:contains(${tenant.toUpperCase()})`
      ).addClass('active');

      $('#data-nav').append($(dataMenu.markup));
    }
  }
}

function viewAsRole(e) {
  const role = $(e.target)
    .text()
    .toLowerCase();
  console.log('VIEW AS ROLE:', role);
  MatgenGlobal.AuthUser.overrideRole = role;
  sessionStorage.setItem('overrideRole', role);
  if (MatgenGlobal.editor) {
    //eventsInit(MatgenGlobal.editor);
    MatgenGlobal.editor.cur().setObjectPermissions(role);
  }
  //loadMenus();
  MatgenGlobal.Router.goTo(new URL(window.location).pathname);
}

function viewAsTenant(e) {
  const tenant = $(e.target)
    .text()
    .toLowerCase();
  console.log('VIEW AS TENANT:', tenant);
  MatgenGlobal.AuthUser.overrideTenant = tenant;
  //loadMenus();
  MatgenGlobal.Router.goTo(new URL(window.location).pathname);
}

$(document).on('click', '.dropdown-item.primary', e => {
  e.preventDefault();
  if (MenuItems) {
    const menu = MenuItems.find(
      o =>
        o.id ===
        $(e.target)
          .closest('.dropdown-menu')
          .attr('aria-labelledby')
    );
    let menuItem;
    if (menu.sections) {
      menu.sections.forEach(s => {
        if (!menuItem) {
          menuItem = s.actions.find(o => o.label === $(e.target).text());
        }
      });
    } else {
      menuItem = menu.actions.find(o => o.label === $(e.target).text());
      if (!menuItem) {
        menuItem = menu.actions.find(o => o.label === $(e.target).text());
      }
    }
    if (menuItem && menuItem.action) {
      menuItem.action(e);
    }
  }
});

export { buildMenus, loadMenus };
