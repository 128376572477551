/* global MatgenGlobal */

import { Table } from '../components/Table.js';

const AnswersActions = () => {
  const actions = [
    {
      action: 'edit',
      title: 'Edit Answer',
      icon: 'fas fa-edit',
      color: 'primary',
      classes: 'hide-file-type',
    },
    {
      action: 'delete',
      title: 'Delete Answers',
      icon: 'fas fa-trash',
      color: 'danger',
      role: 'super',
    },
  ];

  return actions;
};

const AnswersColumns = [
  { data: 'id', title: 'ID' },
  { data: 'question_id', title: 'Question ID' },
  { data: 'text', title: 'Text' },
  { data: 'sub_text', title: 'subtext' },
  { data: 'sort_order', title: 'Sort Order' },
  {
    data: 'icon',
    title: 'Icon',
    render: function(data) {
      return `<i class="${data}"></i>`;
    },
  },
  {
    data: null,
    title: 'Actions',
    wrap: true,
    render: item => Table.actionsHTML(item, 'Answers', AnswersActions(item)),
  },
];

const AnswersTable = new Table({
  columns: AnswersColumns,
  table: 'answer',
  title: 'Answers',
  callbacks: {
    drawCallback: () => {
      MatgenGlobal.UI.initTooltips();
    },
  },
  arg: window.location.search,
});

export { AnswersTable };
