/* global MatgenGlobal, $ */

import Navigo from 'navigo';
import { RouterCore } from './router-core.js';

import { UI } from './ui/ui.js';

class Router {
  constructor() {
    this.core = new RouterCore();
  }

  init(options) {
    const { config, hash } = options || {};
    this.hash = hash;
    this.core.init({
      hash,
      config,
      routerInit: c => this.routerInit(c),
      goTo: this.goTo,
    });
  }

  matgenRouteBefore(done, match) {
    //console.error('ROUTE BEFORE:', match);
    $('#step-5-buttons').remove();
    //const segments = match.url.trim('/').split('/');
    const segments = window.location.href
      .split('?')[0]
      .trim('/')
      .split('/');
    const uuidTest = /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/gi;
    const hasId = uuidTest.test(segments[segments.length - 1]);
    let isEditor = false;

    if (!MatgenGlobal.EditorPages) {
      MatgenGlobal.EditorPages = [];
    }

    if (!MatgenGlobal.EditorHashes) {
      MatgenGlobal.EditorHashes = [];
    }

    if (hasId) {
      if (
        $(MatgenGlobal.SidebarTargetSelector).length === 0 &&
        (segments[segments.length - 2] === 'materials' ||
          segments[segments.length - 2] === 'templates')
      ) {
        isEditor = true;
      }
    }
    if (
      MatgenGlobal.EditorHashes.find(
        e => e.includes(window.location.hash) || e.includes(match.route.name)
      )
    ) {
      isEditor = true;
    }
    if (
      match.url.includes('step5') ||
      match.url.includes('nextpage') ||
      match.url.includes('materials/') ||
      match.url.includes('templates/')
    ) {
      isEditor = true;
    }

    if (
      match.url.includes('step5') &&
      MatgenGlobal.SelectedTemplate &&
      MatgenGlobal.SelectedTemplate.type === 'FILE'
    ) {
      isEditor = false;
    }

    if (isEditor) {
      $(MatgenGlobal.ControllerTargetSelector).addClass('editor');
      if (MatgenGlobal.SidebarTargetSelector) {
        $(MatgenGlobal.SidebarTargetSelector).remove();
        $(
          `<div id="${MatgenGlobal.SidebarTargetSelector.replace(
            '#',
            ''
          )}" class="col-2 m4c-matgen"></div>`
        ).insertBefore(MatgenGlobal.ControllerTargetSelector);
      }
    } else {
      $(MatgenGlobal.ControllerTargetSelector).removeClass('editor');
      $(MatgenGlobal.SidebarTargetSelector).remove();
    }

    if (!match.url.includes('step')) {
      $('#m4c-progress-bar-wrapper').remove();
    } else {
      if ($('#m4c-progress-bar-wrapper').length === 0) {
        $(`
            <div class="container" id="m4c-progress-bar-wrapper">
              <div id="progress-bar-container">
                <div class="matgen-progress-bar">
                </div>
              </div>
            </div>
          `).insertBefore('#matgen-main');
      }
    }
    //}
    done();
  }

  routerInit(config) {
    const router = this;
    //console.error('INIT ROUTER:', MatgenGlobal.rootPage);
    this.router = new Navigo('/', {
      hash: this.hash,
    });

    this.router.hooks({
      leave(done) {
        // do something
        //console.log('LEAVING ROUTE', match);
        MatgenGlobal.Data.API.cancel();
        done();
      },
      before(done, match) {
        if (
          MatgenGlobal.Options &&
          MatgenGlobal.Options.router &&
          MatgenGlobal.Options.router.globalBefore &&
          typeof MatgenGlobal.Options.router.globalBefore === 'function'
        ) {
          MatgenGlobal.Options.router.globalBefore(done, match);
        }
        router.matgenRouteBefore(done, match);
      },
      after() {
        // do something
        //console.log('AFTER ROUTE', match, match.url.includes('step'));
        if (
          MatgenGlobal.MatgenControllerUI &&
          MatgenGlobal.MatgenControllerUI.authUI &&
          typeof MatgenGlobal.MatgenControllerUI.authUI === 'function'
        ) {
          MatgenGlobal.MatgenControllerUI.authUI();
        }
        MatgenGlobal.CurrentAPIRequests = [];
        MatgenGlobal.CurrentS3Requests = [];
        //console.log('CLEAR API STACK:', MatgenGlobal.CurrentAPIRequests);
        //MatgenGlobal.UI.stopLoading();
      },
    });

    this.router.notFound(() => {
      UI.notFound();
    });

    this.router.on('/', () => {
      if (MatgenGlobal.RootRoute) {
        if (typeof MatgenGlobal.RootRoute === 'function') {
          MatgenGlobal.RootRoute();
        } else {
          UI.route(MatgenGlobal.RootRoute);
        }
      } else {
        UI.route('/');
      }
    });

    Object.keys(config).forEach(k => {
      const route = `${
        this.hash
          ? config[k].hash === ''
            ? ''
            : `${config[k].hash}`
          : config[k].route
      }`;
      this.router.on({
        [route]: {
          as: k,
          uses: match => {
            config[k].action(match.data);
          },
        },
      });
      /*this.router.on(route, ({ data }) => {
        config[k].action(data);
      });*/
      //console.log(route, config[k], k);
      if (config[k].before) {
        this.router.addBeforeHook(k, (done, match) => {
          config[k].before(done, match);
        });
      }
      if (config[k].after) {
        this.router.addAfterHook(k, match => {
          config[k].after(match);
        });
      }
      if (config[k].leave) {
        this.router.addLeaveHook(k, match => {
          config[k].leave(match);
        });
      }
    });
    if (MatgenGlobal.resolveRoot) {
      //console.error('ROUTER RESOLVE ROOT');
      this.router.resolve('/');
    } else {
      //console.error('ROUTER RESOLVE CURRENT');
      this.router.resolve();
    }
  }
  goTo(route, replace = false) {
    const rt = `${this.hash ? (route === '/' ? '' : `${route}`) : route}`;
    //console.error('GOTO:', rt, this, this.router);
    this.router.navigate(rt, {
      historyAPIMethod: replace === true ? 'replaceState' : 'pushState',
    });
  }
}

export { Router };
