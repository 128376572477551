/* global MatgenGlobal, PDFDocument, blobStream, Buffer, $ */

import { pt, traverse } from '../../util/helpers.js';
import MatgenUIFunctions from '../../implementation/ui/ui-functions.js';

class MatgenPDF {
  constructor({ width, height, name }) {
    //console.log('MatgenPDF constructed:', width, height, pt(width), pt(height));
    this.doc = new PDFDocument({
      size: [pt(width), pt(height)],
      pdfVersion: '1.7',
      tagged: true,
      displayTitle: true,
      info: {
        Title: name,
      },
      lang: 'en-US',
      margins: {
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
      },
      /*page: {
        tabs: 'S',
      },*/
    });

    this.stream = this.doc.pipe(blobStream());
    this.docStruct = this.doc.struct('Document');
    this.doc.addStructure(this.docStruct);

    this.curPage = 0;
    this.pages = [];
  }

  static handlePDFError() {
    console.error('handlePDFError');
    MatgenGlobal.UI.handleError(
      'PDF Generation Error',
      'There was an error generating the PDF. Missing or corrupted data in material files.'
    );
    MatgenGlobal.UI.stopLoading();
    return false;
  }

  static async createPDF(
    template,
    material,
    pageData,
    id,
    type,
    non508 = true,
    overlay = true
  ) {
    console.error(
      'createPDF',
      non508 ? 'NON 508' : '508 COMPLIANT',
      overlay ? 'OVERLAY IMAGE' : 'NO OVERLAY',
      template,
      material,
      pageData,
      id,
      type,
      non508,
      overlay
    );
    const pdf = new MatgenPDF({
      width: template.width,
      height: template.height,
      name: material ? material.name : template.name,
    });

    pdf.addPage();

    let genError = false;

    for (let i = 0; i < pageData.length; i++) {
      const pdfObjects = [];
      MatgenGlobal.UI.loading(`Loading page ${i + 1}...`);
      MatgenGlobal.TempEditor = await MatgenUIFunctions.loadPagePreview(
        template,
        pageData[i].id,
        id,
        type,
        true,
        true
      );

      if (!MatgenGlobal.TempEditor) {
        return MatgenPDF.handlePDFError();
      }

      if (non508 === false) {
        const objects = MatgenGlobal.TempEditor.cur()
          .fabric.getObjects()
          .filter(o => o.visible)
          .map(o => MatgenUIFunctions.getReadOrder(o));

        objects.sort(MatgenUIFunctions.readOrderSort);

        await traverse(objects, async o => {
          try {
            const obj = await MatgenPDF.getPDFObject(o, pdf.doc);
            if (obj) {
              pdfObjects.push(obj);
            }
          } catch (e) {
            console.error(e);
            genError = true;
          }
        });

        pdfObjects.sort(MatgenUIFunctions.readOrderSort);
        for (let i = 0; i < pdfObjects.length; i++) {
          if (pdfObjects[i].version) {
            pdf.addArtifact(pdfObjects[i]);
          } else {
            //if (parseInt(pdf.doc.y) < parseInt(pdf.doc.page.height)) {
            pdf.addStructure(pdfObjects[i]);
            //} else {
            //  throw new Error('Content overflow in PDF');
            //}
          }
        }
      }

      if (non508 || overlay) {
        const pageImage = MatgenUIFunctions.getCanvasDataURL(
          null,
          MatgenGlobal.TempEditor
        );
        console.log('ADD OVERLAY');
        pdf.addArtifact({
          src: pageImage,
          left: 0,
          top: 0,
          width: template.width,
          height: template.height,
          scaleX: 1,
          scaleY: 1,
        });
      }
      pdf.pages[pdf.curPage - 1].end();

      if (pdf.curPage < pageData.length) {
        pdf.addPage();
      }
    }

    pdf.stream.on('finish', () => {
      if (!genError) {
        window.setTimeout(() => {
          MatgenGlobal.UI.stopLoading();
          MatgenGlobal.UI.confirm(
            'Download PDF',
            'Your PDF will open in a new window.',
            'Continue',
            'Cancel',
            () => {
              window.setTimeout(() => {
                $('html, body').css({
                  overflow: 'auto',
                  height: 'auto',
                });
              }, 400);
              window.open(pdf.stream.toBlobURL('application/pdf'));
            }
          );
        }, 1250);
      } else {
        return MatgenPDF.handlePDFError();
      }

      delete MatgenGlobal.generating;
      delete MatgenGlobal.TempEditor;
    });

    pdf.doc.end();
  }

  addPage() {
    if (this.curPage > 0) {
      this.doc.addPage();
    }
    this.pages.push(this.doc.struct('Sect'));
    this.docStruct.add(this.pages[this.curPage]);
    this.curPage++;
  }

  addArtifact(obj) {
    this.doc.markContent('Artifact', { type: 'Layout' });
    this.doc.image(obj.src, pt(obj.left), pt(obj.top), {
      width: pt(obj.width * obj.scaleX),
      height: pt(obj.height * obj.scaleY),
    });
    this.doc.endMarkedContent();
  }

  addStructure(obj) {
    this.pages[this.curPage - 1].add(obj.struct);
  }

  static async loadFont(spec) {
    const url = `https://2i9mwjmz45.execute-api.us-east-1.amazonaws.com/${
      MatgenGlobal.AMPLIFY_VARS.apiStage
    }/fonts?family=${spec.replace(/\s+/g, '+')}`;
    //console.log('GET FONT:', url);
    let res, file;

    try {
      res = await fetch(url);
      file = await res.text();
      //console.log(res);
    } catch (e) {
      console.error(e);
      return false;
    }
    //const subsetfilter = new RegExp(/latin \*\/[^{]*{[^}]*}/gms);
    //const matches = body.match(subsetfilter);
    //const srcFilter = new RegExp(/.*src:\s+url\(([^)]*)\)/gm);
    //const src = [...matches[0].matchAll(srcFilter)];
    //console.log('FONT FILE:', file);
    let ab;
    try {
      res = await fetch(file);
      ab = await res.arrayBuffer();
    } catch (e) {
      console.error(e);
      return false;
    }
    return ab;
  }

  static async getPDFObject(o, doc) {
    //console.log('GET PDF OBJECT:', o.type, o);
    let left, top, fill, opacity, width, align;
    let tag = o.pdfTag ? o.pdfTag : o.componentPdfTag;
    let link = null;
    if (!tag) {
      if (o.type === 'image') {
        tag = 'FIGURE';
      }
      if (o.type === 'textbox') {
        tag = 'P';
      }
      //throw `No PDF tag: ${o.name} - ${o.type}`;
    }
    if (tag === 'FIGURE' && (!o.altText || o.altText === '')) {
      tag = 'ARTIFACT';
    }
    if (tag === 'ARTIFACT') {
      return o;
    }
    if (tag === 'A') {
      tag = 'Link';
    }
    switch (o.type) {
      case 'image':
        return {
          struct: doc.struct(
            tag,
            {
              alt: o.altText,
            },
            () => {
              doc.image(o.src, pt(o.left), pt(o.top), {
                width: pt(o.width * o.scaleX),
                height: pt(o.height * o.scaleY),
              });
            }
          ),
          ro1: o.ro1,
          ro2: o.ro2,
        };
      case 'textbox':
        if (o.fontspec && o.fontType !== 'local') {
          //console.log('LOAD FONT:', o.fontspec, o);
          try {
            const font = await MatgenPDF.loadFont(o.fontspec);
            if (font) {
              doc.registerFont(o.fontspec, new Buffer(font));
            }
          } catch (e) {
            console.error(e);
          }
        }
        /*if (o.scaleY < 1) {
            left = pt(o.left * o.scaleX);
            top = pt(o.top * o.scaleY);
          } else {
            left = pt(o.left);
            top = pt(o.top);
          }*/

        //left = pt(o.left * o.scaleX);
        //top = pt(o.top * o.scaleY);

        left = pt(o.left);
        top = pt(o.top);

        /*console.log(
            'top:',
            top,
            'o.top:',
            o.top,
            'scaleY',
            o.scaleY,
            'translatey',
            pt(o.translateY),
            'o.translatey',
            o.translateY,
            'zoom',
            o.canvas.getZoom()
          );*/
        width = pt(o.width * o.scaleX);
        align = o.textAlign;
        if (o.group) {
          //left += pt(o.group.left) + pt((o.group.width * o.scaleX) / 2);
          //top += pt(o.group.top) + pt((o.group.height * o.scaleY) / 2);
          //left = o.group.left * o.group.scaleX;
          left = o.group.left;
          top +=
            //pt(o.group.top * o.scaleY) +
            pt(o.group.top) + pt((o.group.height * o.group.scaleY) / 2);
          width = pt(o.group.width * o.group.scaleX);
        }
        o.text = o.text.replace(/^\s+|\s+$/g, '');
        o.text = o.text.replace(/(\r?\n){2,}/g, '$1');
        fill = o.fill;
        opacity = 1;

        if (o.fill.length > 7) {
          fill = o.fill.substring(0, 7);
          opacity = parseInt(o.fill.substring(7), 16) / 255.0;
        }

        return {
          struct: doc.struct(tag, () => {
            if (o.text.match(/\r?\n/g)) {
              const lines = o.text.split(/\r?\n/);
              const first = lines.shift();
              if (o.fontType !== 'local') {
                doc.font(o.fontspec);
              }
              doc
                .fontSize(pt(o.fontSize * o.scaleX))
                .fillColor(fill)
                .opacity(opacity)
                .text(`${first} `, left, top, { width, align });
              /*console.log(
                  'TEXT:',
                  `${first} `,
                  left,
                  o.left,
                  o.scaleX,
                  'DOCX:',
                  doc.x
                );*/
              lines.forEach(l => {
                if (tag === 'Link') {
                  if (o.link) {
                    link = o.link;
                  } else {
                    if (l.includes('http')) {
                      link = l;
                    } else {
                      link = `https://${l}`;
                    }
                  }
                }
                doc.text(`${l} `, { width, align, link });
              });
            } else {
              if (tag === 'Link') {
                if (o.link) {
                  link = o.link;
                } else {
                  if (o.text.includes('http')) {
                    link = o.text;
                  } else {
                    link = `https://${o.text}`;
                  }
                }
              }

              if (o.fontType !== 'local') {
                doc.font(o.fontspec);
              }

              /*console.log(
                  'TEXT:',
                  `${o.text} `,
                  left,
                  o.left,
                  o.scaleX,
                  'DOCX:',
                  doc.x
                );*/
              if (o.text === '[Study ID]') {
                top = 1634;
              }
              doc
                .fontSize(pt(o.fontSize * o.scaleX))
                .fillColor(fill)
                .opacity(opacity)
                .text(`${o.text} `, left, top, {
                  width,
                  align,
                  link,
                  //lineBreak: false,
                });
            }
          }),
          ro1: o.ro1,
          ro2: o.ro2,
        };
      default:
        break;
    }
  }
}

export { MatgenPDF };
