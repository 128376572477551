import {
  Form,
  TextInput,
  SelectInput,
  CheckboxInput,
} from '../components/form/index.js';
//import { emit } from '../../util/helpers.js';

const SectionQuestionInputs = [
  new SelectInput({
    label: 'Select Component',
    id: 'inputComponent',
    classes: 'middle',
    options: [
      {
        label: 'Single Select',
        value: 'select-single',
      },
      {
        label: 'Multi Select',
        value: 'select-multiple',
      },
    ],
  }),
  new TextInput({
    type: 'text',
    label: 'Question',
    id: 'inputText',
    classes: 'middle',
  }),
  new TextInput({
    type: 'subtext',
    label: 'Subtext',
    id: 'inputSubtext',
    classes: 'middle',
  }),
  new CheckboxInput({
    label: 'Insert Subtext?',
    id: 'inputInsertSubText',
    classes: 'middle',
  }),
  new TextInput({
    type: 'text',
    label: 'Add Name',
    id: 'inputListAddName',
    classes: 'middle',
  }),
  new TextInput({
    type: 'text',
    label: 'Tooltip',
    id: 'inputTooltip',
    classes: 'middle',
  }),
  new TextInput({
    type: 'number',
    label: 'Sort order',
    id: 'inputSortOrder',
    classes: 'bottom',
  }),
];

const SectionQuestionForm = new Form({
  inputs: SectionQuestionInputs,
  id: 'question-form',
});

export { SectionQuestionForm };
